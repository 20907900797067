import React from 'react'

const NoContentAvailable = ({ image, title }) => {
    return (
        <div className='no-content-available'>
            {image && <img src={image} alt />}
            <p>{title}</p>
        </div>
    )
}

export default NoContentAvailable