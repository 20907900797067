import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DragDropUploadButton from "../../../components/common/DragDropUploadButton";
import {
  latestDegreeProofReader,
  specilizationsProofReader,
  transExpProofReader,
  nativeLangProofReader,
  languageToTranslateFromProofReader,
  pagesYouCanTranslatePerDayProofReader,
  activeHoursProofReader,
} from "../../../constants/config";
import { setAddQualificationsProf } from "../../../redux/reducers/qualificationSlice";
import {
  addQualiProofReaderApi,
  getProofReaderQualification,
  uploadCertificate,
  uploadResume,
  uploadSampleWork,
  uploadDegreeCertificate,
} from "../../../services/api/profileApi";
import { addQualiProofReaderValidator } from "../../../services/formValidator/proofReaderValidator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import { removeFileFromS3 } from "../../../services/api/jobPostApis";
import { getAllLanguages } from "../../../services/api/languagesApis";

const AddQualificationsProofreader = () => {
  const { profQualificationDetails } = useSelector(
    (state) => state.qualification
  );
  const [uploadCertificatedata, setUploadCertificateData] = useState([]);
  const [uploadResumeData, setUploadResumeData] = useState([]);
  const [uploadSampleWorkData, setUploadSampleWorkData] = useState([]);
  const [uploadDegreeCertificateData, setUploadDegreeCertificateData] =
    useState([]);
  const dispatch = useDispatch();
  const [langArray, setLangArray] = useState([]);

  const addQualiFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: profQualificationDetails?.email ?? "",
      certificatePhoto: !!profQualificationDetails?.certificatePhoto
        ? profQualificationDetails?.certificatePhoto
        : uploadCertificatedata,
      resume: !!profQualificationDetails?.resume
        ? profQualificationDetails?.resume
        : uploadResumeData,
      sampleWork: !!profQualificationDetails?.sampleWork
        ? profQualificationDetails?.sampleWork
        : uploadSampleWorkData,
      degreeCertificate: !!profQualificationDetails?.degreeCertificate
        ? profQualificationDetails?.degreeCertificate
        : uploadSampleWorkData,
      latestDegree: profQualificationDetails?.latestDegree ?? "",
      other: profQualificationDetails?.other ?? "",
      specialization: profQualificationDetails?.specialization ?? "",
      additional: profQualificationDetails?.additional ?? "",
      translatingExperience:
        profQualificationDetails?.translatingExperience ?? "",
      nativeLanguage: profQualificationDetails?.nativeLanguage ?? "",
      languagesToProofread:
        profQualificationDetails?.languagesToProofread ?? "",
      languageToTranslateFrom:
        profQualificationDetails?.languageToTranslateFrom ?? "",
      hasAbroadExperience:
        profQualificationDetails?.hasAbroadExperience ?? false,
      abroadExperienceLong:
        profQualificationDetails?.abroadExperienceLong ?? "",
      abroadExperienceWhere:
        profQualificationDetails?.abroadExperienceWhere ?? "",
      citationConventions: profQualificationDetails?.citationConventions ?? "",
      portfolioLink: profQualificationDetails?.portfolioLink ?? "",
      // pagesYouCanTranslatePerDay: profQualificationDetails?.pagesYouCanTranslatePerDay ?? "",
      activeHours: profQualificationDetails?.activeHours ?? "",
    },
    validationSchema: addQualiProofReaderValidator.validationSchema,
    onSubmit: async (values) => {
      let { serverResponse, result } = (await profQualificationDetails?._id)
        ? addQualiProofReaderApi(values, profQualificationDetails?._id)
        : addQualiProofReaderApi(values);
      if (serverResponse.code === 201) {
        toast.success("Qualification added successfully");
        dispatch(setAddQualificationsProf({ qualifications: result.data }));
      } else {
        toast.error("Qualification not added");
      }
    },
  });

  const uploadCertificateFile = async (data) => {
    let resData = await uploadCertificate(data);
    if (resData?.result) {
      setUploadCertificateData(
        uploadCertificatedata?.concat(resData?.result.data)
      );
      addQualiFormik.setFieldValue(
        "certificatePhoto",
        addQualiFormik?.values.certificatePhoto.concat(resData?.result.data)
      );
    }
  };
  const uploadResumeFile = async (data) => {
    let resData = await uploadResume(data);
    if (resData?.result) {
      setUploadResumeData(uploadResumeData?.concat(resData?.result.data));
      addQualiFormik.setFieldValue(
        "resume",
        addQualiFormik?.values.resume.concat(resData?.result.data)
      );
    }
  };
  const uploadSampleWorkFile = async (data) => {
    let resData = await uploadSampleWork(data);
    if (resData?.result) {
      setUploadSampleWorkData(
        uploadSampleWorkData?.concat(resData?.result.data)
      );
      addQualiFormik.setFieldValue(
        "sampleWork",
        addQualiFormik?.values.sampleWork.concat(resData?.result.data)
      );
    }
  };
  const uploadDegreeCertificateFile = async (data) => {
    console.log(data);
    let resData = await uploadDegreeCertificate(data);
    console.log(resData);
    if (resData?.result) {
      setUploadDegreeCertificateData(
        uploadDegreeCertificateData?.concat(resData?.result.data)
      );
      console.log(uploadDegreeCertificateData);
      addQualiFormik.setFieldValue(
        "degreeCertificate",
        addQualiFormik?.values.degreeCertificate.concat(resData?.result.data)
      );
    }
  };

  useEffect(() => {
    (async () => {
      let resData = await getProofReaderQualification();
      if (resData?.result?.data) {
        dispatch(
          setAddQualificationsProf({ qualifications: resData?.result.data })
        );
      }
    })();
  }, []);
  const removeItem = async (item, type) => {
    let data = {
      key: item?.key,
    };
    dispatch(startLoading());
    let { serverResponse } = await removeFileFromS3(data);
    if (serverResponse?.code === 200) {
      addQualiFormik.setFieldValue(
        `${type}`,
        addQualiFormik?.values[type].filter((val) => val?.key !== item?.key)
      );
    }
    dispatch(stopLoading());
  };
  useEffect(() => {
    (async () => {
      let languageJson = await getAllLanguages();
      setLangArray(languageJson.data);
    })();
  }, []);
  return (
    <>
      <div className="col-lg-10">
        <div className="translator-edit-profile-right">
          <div className="content common-btm-mrgn">
            <h4>Qualificationss</h4>
          </div>
          <div className="signin-form-wrap">
            <form onSubmit={addQualiFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadCertificateFile}
                    />
                    {/* <p className="form-error-message">
                      {addQualiFormik.touched.certificatePhoto &&
                        addQualiFormik.errors.certificatePhoto}
                    </p> */}
                  </div>
                  {addQualiFormik.values.certificatePhoto &&
                    addQualiFormik.values.certificatePhoto?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.certificatePhoto.map(
                          (item, index) => {
                            return (
                              <div className="show-file">
                                <div
                                  onClick={() =>
                                    removeItem(item, "certificatePhoto")
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                                <p className="extenstionText">{item?.name}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>1. Email</label>
                    <input
                      type="email"
                      placeholder="example@gmail.com"
                      className="form-control"
                      name="email"
                      id="email"
                      value={addQualiFormik.values.email}
                      onChange={addQualiFormik.handleChange}
                    />
                  </div>
                  <p className="form-error-message">
                    {addQualiFormik.touched.email &&
                      addQualiFormik.errors.email}
                  </p>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>2. Latest degree</label>
                    <div className="row">
                      {latestDegreeProofReader.map((item, id) => (
                        <div key={id} className="col-lg-4">
                          <div className="rdio-btn-for-db-frm">
                            <input
                              type="radio"
                              id={`latestDegree${id}`}
                              name="latestDegree"
                              value={item?.value}
                              onClick={() =>
                                addQualiFormik.setFieldValue("other", "")
                              }
                              checked={
                                addQualiFormik.values.latestDegree ===
                                item?.value
                              }
                              onChange={addQualiFormik.handleChange}
                            />
                            <label htmlFor={`latestDegree${id}`}>
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p className="form-error-message">
                        {addQualiFormik.touched.latestDegree &&
                          addQualiFormik.touched.other !== "" &&
                          addQualiFormik.errors.latestDegree}
                      </p>
                      <div className="col-lg-12">
                        <label className="mt-3">
                          Other (Please specify in the box below)
                        </label>
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="other"
                          id="other"
                          onClick={() =>
                            addQualiFormik.setFieldValue("latestDegree", "")
                          }
                          onChange={addQualiFormik.handleChange}
                          value={addQualiFormik.values.other}
                        />
                        <p className="form-error-message">
                          {addQualiFormik.touched.other &&
                            addQualiFormik.errors.other}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Upload certificate/diploma</label>
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadDegreeCertificateFile}
                    />
                  </div>
                  {addQualiFormik.values.degreeCertificate &&
                    addQualiFormik.values.degreeCertificate?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.degreeCertificate.map(
                          (item, index) => {
                            return (
                              <div className="show-file" key={index}>
                                <div
                                  onClick={() =>
                                    removeItem(item, "degreeCertificate")
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                                <p className="extenstionText">{item?.name}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      3. How many years of proofreading experience do you have?
                    </label>
                    <div className="row">
                      {transExpProofReader.map((item, id) => (
                        <div key={id} className="col-lg-4">
                          <div className="rdio-btn-for-db-frm">
                            <input
                              type="radio"
                              id={`translatingExperience${id}`}
                              name="translatingExperience"
                              value={item?.value}
                              checked={
                                addQualiFormik.values.translatingExperience ===
                                item?.value
                              }
                              onChange={addQualiFormik.handleChange}
                            />
                            <label htmlFor={`translatingExperience${id}`}>
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p className="form-error-message">
                        {addQualiFormik.touched.translatingExperience &&
                          addQualiFormik.errors.translatingExperience}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>4. What is your native language?</label>
                    <select
                      name="nativeLanguage"
                      id="nativeLanguage"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                      value={addQualiFormik?.nativeLanguage}
                    >
                      {langArray &&
                        langArray.length > 0 &&
                        langArray.map((item, id) => (
                          <option
                            key={id}
                            value={item?.languageName}
                            selected={
                              item?.languageName ===
                              addQualiFormik.values.nativeLanguage
                            }
                          >
                            {item?.languageName}
                          </option>
                        ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.nativeLanguage &&
                        addQualiFormik.errors.nativeLanguage}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      5. Which language(s) do you proofread? (use control to
                      choose mor than one)
                    </label>
                    <select
                      name="languagesToProofread"
                      id="languagesToProofread"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                      value={addQualiFormik?.languagesToProofread}
                      multiple
                    >
                      {langArray &&
                        langArray.length > 0 &&
                        langArray.map((item, id) => (
                          <option
                            key={id}
                            value={item?.languageName}
                            selected={
                              item?.languageName ===
                              addQualiFormik.values.languagesToProofread
                            }
                          >
                            {item?.languageName}
                          </option>
                        ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.languagesToProofread &&
                        addQualiFormik.errors.languagesToProofread}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      6. Which citation conventions are you familiar with?
                      (select all that apply)
                    </label>
                    <select
                      name="citationConventions"
                      id="citationConventions"
                      className="form-select form-control"
                      onChange={(event) => {
                        const { options } = event.target;
                        const value = [];
                        for (let i = 0, l = options.length; i < l; i++) {
                          if (options[i].selected) {
                            value.push(options[i].value);
                          }
                        }
                        addQualiFormik.setFieldValue(
                          "citationConventions",
                          value
                        );
                      }}
                      value={addQualiFormik.values.citationConventions}
                      multiple
                    >
                      <option value="APA">APA</option>
                      <option value="MLA">MLA</option>
                      <option value="Harvard">Harvard</option>
                      <option value="Chicago">Chicago</option>
                      <option value="IEEE">IEEE</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      id="hasAbroadExperience"
                      name="hasAbroadExperience"
                      onChange={(e) => {
                        addQualiFormik.setFieldValue(
                          "hasAbroadExperience",
                          e.target.checked
                        );
                        // Optionally clear the details field if the user unchecks the box
                        if (!e.target.checked) {
                          addQualiFormik.setFieldValue(
                            "abroadExperienceDetails",
                            ""
                          );
                        }
                      }}
                      checked={addQualiFormik.values.hasAbroadExperience}
                    />
                    <label className="ml-2" htmlFor="hasAbroadExperience">
                      7. Have you lived/worked/studied abroad?
                    </label>
                  </div>
                </div>

                {addQualiFormik.values.hasAbroadExperience && (
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="abroadExperienceWhere">
                        If yes, where?
                      </label>
                      <textarea
                        className="form-control"
                        id="abroadExperienceWhere"
                        name="abroadExperienceWhere"
                        placeholder="Enter details where..."
                        value={addQualiFormik.values.abroadExperienceWhere}
                        onChange={addQualiFormik.handleChange}
                      ></textarea>

                      <label htmlFor="abroadExperienceLong">
                        If yes, how long?
                      </label>
                      <textarea
                        className="form-control"
                        id="abroadExperienceLong"
                        name="abroadExperienceLong"
                        placeholder="Enter details how long..."
                        value={addQualiFormik.values.abroadExperienceLong}
                        onChange={addQualiFormik.handleChange}
                      ></textarea>
                    </div>
                  </div>
                )}

                <div className="col-lg-12">
                  <label>8. Upload resume / CV</label>
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadResumeFile}
                    />
                  </div>
                  {addQualiFormik.values.resume &&
                    addQualiFormik.values.resume?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.resume.map((item, index) => {
                          return (
                            <div className="show-file">
                              <div onClick={() => removeItem(item, "resume")}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </div>
                              <p className="extenstionText">{item?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  <p className="form-error-message">
                    {addQualiFormik.touched.resume &&
                      addQualiFormik.errors.resume}
                  </p>
                </div>
                <div className="col-lg-12">
                  <label>9. Sample of your work</label>
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadSampleWorkFile}
                    />
                  </div>
                  {addQualiFormik.values.sampleWork &&
                    addQualiFormik.values.sampleWork?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.sampleWork.map((item, index) => {
                          return (
                            <div className="show-file">
                              <div
                                onClick={() => removeItem(item, "sampleWork")}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </div>
                              <p className="extenstionText">{item?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  {/* <p className="form-error-message">
                    {addQualiFormik.touched.sampleWork &&
                      addQualiFormik.errors.sampleWork}
                  </p> */}
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>10. Link to portfolio</label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      name="portfolioLink"
                      id="portfolioLink"
                      onChange={addQualiFormik.handleChange}
                      value={addQualiFormik.values.portfolioLink}
                    />
                    <p className="form-error-message">
                      {addQualiFormik.touched.portfolioLink &&
                        addQualiFormik.errors.portfolioLink}
                    </p>
                  </div>
                </div>

                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      10. How many pages (page = 250 words) can you translate
                      per day?
                    </label>
                    <div className="row">
                      {pagesYouCanTranslatePerDayProofReader.map((item, id) => (
                        <div key={id} className="col-lg-4 mb-3">
                          <div className="rdio-btn-for-db-frm">
                            <input
                              type="radio"
                              id={`pagesYouCanTranslatePerDay${id}`}
                              name="pagesYouCanTranslatePerDay"
                              value={item?.value}
                              checked={
                                addQualiFormik.values
                                  .pagesYouCanTranslatePerDay === item?.value
                              }
                              onChange={addQualiFormik.handleChange}
                            />

                            <label htmlFor={`pagesYouCanTranslatePerDay${id}`}>
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p className="form-error-message">
                        {addQualiFormik.touched.pagesYouCanTranslatePerDay &&
                          addQualiFormik.errors.pagesYouCanTranslatePerDay}
                      </p>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>11. Choose your active hours</label>
                    <select
                      name="activeHours"
                      id="activeHours"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                    >
                      {activeHoursProofReader.map((item, id) => (
                        <option key={id} value={item?.value} selected={item?.value === addQualiFormik.values.activeHours}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.activeHours &&
                        addQualiFormik.errors.activeHours}
                    </p>
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="form-group mb-0">
                    <button type="submit" className="btn w-100">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQualificationsProofreader;
