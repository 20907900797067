import React from 'react'

const WebsiteMaintain = () => {
    return (
        <div style={{
            textAlign: "center",
            marginTop: "100px"
        }}>
            <h1>Under Maintenance</h1>
            <p>We are currently performing maintenance. Please check back later.</p>
        </div>
    )
}

export default WebsiteMaintain