import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cancelJobs: 0,
  completedProjects: 0,
  ongoingProjects: 0,
  correctionAskedByClient: 0,
  lateSubmission: 0,
  rejectedByClient: 0,
};

export const dashBoardSlice = createSlice({
  name: "dashBoard",
  initialState,
  reducers: {
    setJobCounts: (state, actions) => {
      const {
        cancelJobs,
        completedProjects,
        ongoingProjects,
        correctionAskedByClient,
        lateSubmission,
        rejectedByClient,
      } = actions.payload;
      state.cancelJobs = cancelJobs;
      state.completedProjects = completedProjects;
      state.ongoingProjects = ongoingProjects;
      state.correctionAskedByClient = correctionAskedByClient;
      state.lateSubmission = lateSubmission;
      state.rejectedByClient = rejectedByClient;
    },
  },
});

// this is for dispatch
export const { setJobCounts } = dashBoardSlice.actions;

// this is for configureStore
export default dashBoardSlice.reducer;
