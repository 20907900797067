import React from 'react'

const AffilateTermsConditions = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <h1>Affilation Terms and Conditions</h1>

                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec">

                <div className="container">

                    <p><strong>Affiliate Marketing Program Terms and Conditions</strong></p>
                    <p>These Terms and Conditions ("Agreement") govern your participation in the Affiliate Marketing Program ("Program") offered by Lingvara ("we," "us," or "our"). By participating in the Program, you ("Affiliate," "you," or "your") agree to be bound by these terms and conditions.</p>
                    <p><strong>Eligibility</strong></p>
                    <ol>
                        <li>
                            <p>To be eligible for the Program, you must be at least 18 years old or the legal age of majority in your jurisdiction.</p>
                        </li>
                        <li>
                            <p>Employees, contractors, or immediate family members of Lingvara are not eligible to participate in the Program.</p>
                        </li>
                    </ol>
                    <p><strong>Affiliate Responsibilities</strong></p>
                    <ol>
                        <li>
                            <p>As an Affiliate, you agree to promote Lingvara's translation and proofreading services in a lawful and ethical manner.</p>
                        </li>
                        <li>
                            <p>You will receive a unique affiliate link that is exclusively associated with your account. You are responsible for ensuring the proper use and security of this affiliate link.</p>
                        </li>
                        <li>
                            <p>You must comply with all applicable laws, regulations, and guidelines related to marketing, advertising, and privacy.</p>
                        </li>
                        <li>
                            <p>You shall not engage in any activities that may damage the reputation of Lingvara or its services.</p>
                        </li>
                        <li>
                            <p>You are solely responsible for any content you create or distribute in connection with the Program. Content must be accurate, truthful, and not misleading.</p>
                        </li>
                    </ol>
                    <p><strong>Commission Expiration</strong></p>
                    <p>Commissions earned through the Affiliate Marketing Program will remain in your wallet on our website. If funds are not withdrawn within 365 days from the date they were credited to your wallet, the affiliate link associated with the earnings will cease to work, and the amount will be forfeited. We strongly encourage affiliates to regularly review their earnings and initiate withdrawals in a timely manner to avoid any loss of funds. Lingvara will not be responsible for any expired commissions or lost earnings resulting from a failure to withdraw within the specified timeframe. Note: It is your responsibility to monitor your earnings and withdrawal status to ensure the timely utilization of your funds.</p>
                    <p><strong>Commission Rates and Payouts</strong></p>
                    <ol>
                        <li>
                            <p>Affiliate earnings are based on successful referrals made through your unique affiliate link.</p>
                        </li>
                        <li>
                            <p>For regular website orders made using your affiliate link, you will earn a commission of 5% of the total order value.</p>
                        </li>
                        <li>
                            <p>For subscription plan orders made via your affiliate link or face-to-face contract signing, you will earn a commission of 3% of the total order value.</p>
                        </li>
                        <li>
                            <p>All commissions will be credited to your wallet on our website.</p>
                        </li>
                        <li>
                            <p>To be eligible for a payout, your earnings must reach a minimum of $50 USD.</p>
                        </li>
                        <li>
                            <p>Payouts can be requested via PayPal. Please ensure you provide accurate and up-to-date PayPal account information.</p>
                        </li>
                    </ol>
                    <p><strong>Prohibited Activities</strong></p>
                    <ol>
                        <li>
                            <p>Affiliates are strictly prohibited from engaging in self-purchases using their own affiliate links. Any self-purchases made will not be eligible for commission earnings.</p>
                        </li>
                        <li>
                            <p>Affiliates shall not engage in any deceptive, misleading, or unethical practices to generate referrals.</p>
                        </li>
                        <li>
                            <p>Affiliates shall not use any unauthorized or misleading marketing materials or trademarks of Lingvara.</p>
                        </li>
                        <li>
                            <p>Affiliates shall not engage in any activities that may violate intellectual property rights, privacy rights, or any other legal rights of third parties.</p>
                        </li>
                    </ol>
                    <p><strong>Termination</strong></p>
                    <ol>
                        <li>
                            <p>Lingvara reserves the right to terminate an Affiliate's participation in the Program at any time and for any reason, including but not limited to violations of these Terms and Conditions or suspected fraudulent activities.</p>
                        </li>
                        <li>
                            <p>Affiliates may terminate their participation in the Program at any time by notifying Lingvara in writing.</p>
                        </li>
                    </ol>
                    <p><strong>Modifications</strong></p>
                    <p>Lingvara reserves the right to modify, suspend, or terminate the Program or these Terms and Conditions at any time and for any reason. Any modifications will be effective immediately upon posting on our website. It is your responsibility to review these terms periodically for updates.</p>
                    <p><strong>Governing Law</strong></p>
                    <p><a name="_GoBack"></a>This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction where Lingvara is based, without regard to its conflict of law provisions.</p>
                    <p><br /><br /></p>
                </div>

            </section>


        </>
    )
}

export default AffilateTermsConditions