import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// import LoaderTr from '../common/Loader/custom.loader-tr';
import { toast } from "react-toastify";
// import { updateBlockStatus } from '../redux/reducers/userList.slice';
import { useDispatch } from 'react-redux';
import Loading from '../../../components/common/Loading';
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { updateBusinessUserBlockStatus } from '../../../services/api/profileApi';


const BusinessAccountTable = (props) => {

	const navigate = useNavigate();
	const [isBlocked, setIsBlocked] = useState(false);
	const dispatch = useDispatch();
	// const [activePage, setActivePage] = useState(props.data.pageNumber);
	// const totalPages = Math.ceil(props.data.dataCount / props.data.dataPerPage);

	function addPhoneAndCode(phoneNumber, countryCode) {
		const newPhone =
			`(${countryCode})` +
			`${phoneNumber}`;
		return newPhone;
	}

	const blockingHandler = async (e, id) => {
		const result = await

			updateBusinessUserBlockStatus({
				isBlocked: e.target.checked,
				idReq: id,
			});

		if (result?.serverResponse?.code === 200) {
			setIsBlocked();
			window.location.reload();
			toast.success(result.serverResponse?.message);
		}
	};


	return (
		<Fragment>
			<Table striped>
				<thead>
					<tr className='bg-gray-2 overflow-hidden '>
						<th className="">Name</th>
						<th className="">Company Code</th>
						<th className="">Email</th>
						<th className="">Phone Number</th>
						{/* <th className="">Status</th> */}
						<th className="">BLOCKING STATUS</th>
					</tr>
				</thead>
				<tbody>
					{!props.isLoading && props.data?.length > 0 ? (
						props.data.map((user, index) => (
							<tr key={index} className="dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 border-b bg-white">
								<td className="px-6 py-4 text-center font-medium">
									<div className="flex gap-x-2 items-center">

										<p className='font-bold'>{user.name}</p>
									</div>
								</td>
								<td className="px-6 py-4 font-medium text-sm text-start">{user.companyCode}</td>
								<td className="px-6 py-4 font-medium text-sm text-start">{user.email}</td>
								<td className="px-6 py-4 font-medium text-sm text-start">{user?.phoneNumber ? addPhoneAndCode(user?.phoneNumber, user?.countryCode) : "N/A"}</td>
								{/* <td className={`px-6 py-4 font-medium text-sm text-start ${user.isEnabled ? 'text-green-500 ' : ''}`}>
									{user.isEnabled ? 'Enabeld' : 'Disabled'}
								</td> */}
								<td>
									<div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
										<Form.Check // prettier-ignore
											type="switch"
											id="custom-switch"
											className="toggle-checkbox"
											onChange={(e) =>
												blockingHandler(
													e,

													user?._id
												)
											}

											checked={user?.isBlocked}
										/>
										<label
											className="inline-block pl-[0.15rem] hover:cursor-pointer"
											htmlFor="flexSwitchCheckedMain"
										>
											{user?.isBlocked === true ? (
												<strong className="text-lingvara">Blocked</strong>
											) : (
												<strong className="text-red">Unblocked</strong>
											)}
										</label>

									</div>
								</td>
							</tr>
						))
					) : props.isLoading ? (
						<Loading />
					) : null}
				</tbody>
			</Table>

			{/* <ul className='flex gap-x-2 items-center justify-center mt-6'>
				{
					Array.from({ length: totalPages }, (_, index) => (
						<li
							key={index + 1}
						>
						<button
							className={`w-8 h-8 rounded-md text-sm font-semibold hover:bg-blue-600 text-white ease-in-out duration-200 ${activePage === index + 1 ? 'bg-blue-600 cursor-default' : 'bg-blue-300'}`}
							onClick={() => handlePageClick(index + 1)}
						>
						{index + 1}
						</button>
						</li>
					))
			  }
			  
			</ul> */}
		</Fragment>
	)
}

export default BusinessAccountTable
