import React, { useEffect, useState } from 'react'
import { getUserList } from '../../../services/api/jobApis';
import { setAssignUserList } from '../../../redux/reducers/jobSlice';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ChangeRatingModal from '../../../components/common/changeRatingModal/ChangeRatingModal';
import SingleCardProofreader from './SingleCardProofreader';


const ProofreaderList = () => {
    const dispatch = useDispatch()
    const [userGetPage, setUserGetPage] = useState(0);
    const [userSearchinput, setUserSearchinput] = useState("");
    const { currentPage, assignUserList, selectedJobDetails } = useSelector((state) => state.job);

    const getUserListData = async () => {
        let userList = await getUserList();
        dispatch(setAssignUserList({ users: userList?.data }));
        setUserGetPage(userList?.pageNumber);
    }

    const handleSearch = debounce(async (input) => {
        setUserGetPage(0);
        setUserSearchinput(input.target.value);
        let userList = await getUserList(input.target.value);
        dispatch(setAssignUserList({ users: null }));
        dispatch(setAssignUserList({ users: userList?.data }));
    }, 500);

    const loadMoreData = async (page) => {
        let userList = await getUserList(userSearchinput, page);
        dispatch(setAssignUserList({ users: userList?.data }));
        setUserGetPage(userList?.pageNumber);
    };


    useEffect(() => {
        getUserListData()
        return () => {
            dispatch(setAssignUserList({ users: null }));
            setUserGetPage(0);
        };
    }, []);

    return (
        <div className="page-content page-container" id="page-content">
            <div className="padding">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="list list-row block">
                            {assignUserList && (
                                <InfiniteScroll
                                    dataLength={assignUserList.length} //This is important field to render the next data
                                    next={() => loadMoreData(userGetPage + 1)}
                                    hasMore={true}
                                    height={600}
                                >
                                    {assignUserList?.map((item) => {
                                        return (
                                            <SingleCardProofreader item={item} getUserListData={getUserListData} />
                                        )
                                    })}
                                </InfiniteScroll>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ProofreaderList