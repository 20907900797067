import React from 'react';

const BusinessTermsConditions = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />
                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Business Service Agreement</h1>
                                    <p>This Business Service Agreement ("Agreement") is entered into by and between the Service Provider, hereinafter referred to as "Lingvara," and the Client.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-sec common-btm-padd">
                <div className="container">
                    <p className='static-text'><strong>1. Services Provided</strong></p>
                    <p>a. Lingvara agrees to provide online translation and proofreading services to the Client in accordance with the terms and conditions set forth in this Agreement.</p>
                    <p>b. The Client shall create an account on Lingvara's platform and upload the documents to be translated or proofread. Payment for services must be made upfront before the commencement of the service.</p>
                    <p>c. The Client agrees to provide all necessary information and materials required for the completion of the services accurately and in a timely manner.</p>
                    <p><strong>2. Quality Assurance</strong></p>
                    <p>a. Lingvara shall perform the translation and proofreading services with due diligence and in a professional manner.</p>
                    <p>b. The Client has the right to request corrections or revisions if the delivered services do not meet the agreed standards.</p>
                    <p>c. In the event of dissatisfaction with the services, the Client may file a claim with the project manager within [number] days of service delivery.</p>
                    <p><strong>3. Representations and Warranties</strong></p>
                    <p>a. The Client represents and warrants that all information provided to Lingvara is accurate, complete, and for legitimate business purposes.</p>
                    <p>b. The Client further represents and warrants that they are the official authorized signatory on behalf of their business.</p>
                    <p><strong>4. Confidentiality</strong></p>
                    <p>a. Both parties agree to maintain the confidentiality of all proprietary and sensitive information exchanged during the course of this Agreement.</p>
                    <p>b. Confidential information shall not be disclosed to any third party without the prior written consent of the disclosing party, except as required by law.</p>
                    <p><strong>5. Term and Termination</strong></p>
                    <p>a. This Agreement shall commence on the Effective Date and shall continue until the completion of the services unless earlier terminated by either party in accordance with this Agreement.</p>
                    <p>b. Either party may terminate this Agreement with [number] days' written notice to the other party for any material breach of the terms herein.</p>
                    <p><strong>6. Governing Law and Jurisdiction</strong></p>
                    <p>a. This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].</p>
                    <p>b. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p>
                    <p><strong>7. Entire Agreement</strong></p>
                    <p>This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether written or oral.</p>
                    <p>By creating an account with Lingvara, the Client acknowledges and agrees to the terms and conditions set forth in this Agreement.</p>
                </div>
            </section>
        </>
    );
};

export default BusinessTermsConditions;
