import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProfileApi } from "../../../services/api/profileApi";
import { editProfileValidatorTranslator } from "../../../services/formValidator/translatorValidator";
import {
  iAmATranslator,
  chooseCountryTranslator,
  chooseTimezoneTranslator,
  fieldOfStudyTranslator,
  levelOfStudyTranslator,
  countryCode,
} from "../../../constants/config";
import { updateUser } from "../../../redux/reducers/authSlice";
import Select from "react-select";
import countryList from 'react-select-country-list'
const TranslatorEditProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const [selectedDialCode, setSelectedDialCode] = useState(user?.countryCode);
  const dispatch = useDispatch();
  const [selectCountry, setSelectCountry] = useState({
    countryVal: user.country,

  })
  const [getPhoneNumber, setGetPhoneNumber] = useState(false);
  const editProfileFormik = useFormik({
    initialValues: {
      name: user.name,
      schoolName: user.schoolName,
      collegeName: user.collegeName,
      country: user.country,
      fieldOfStudy: user.fieldOfStudy,
      levelOfStudy: user.levelOfStudy,
      expectedGraduationDate: user.expectedGraduationDate,
      timezone: user.timezone,
      phoneNumber: user?.phoneNumber,
      countryCode: user?.countryCode
    },
    validationSchema: editProfileValidatorTranslator.validationSchema,
    onSubmit: async (values) => {
      let data = values;
      let editProfiledata = await editProfileApi(data);
      dispatch(updateUser({ user: editProfiledata?.result?.data }));
    },
  });
  const countrySelectHandler = (countryVal) => {
    editProfileFormik.setFieldValue("country", countryVal?.label)
    setSelectCountry({ countryVal });
    countryCode.forEach((item) => {
      if (item.name === countryVal?.label) {
        setGetPhoneNumber(true)
        setSelectedDialCode(item.dial_code);
        editProfileFormik.setFieldValue("countryCode", item.dial_code)

      }
    });
  };;
  // const handleKeyDownForPhone = (e) => {
  //   const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  //   // Allow backspace (keyCode 8)
  //   if (e.keyCode === 8) {
  //     return;
  //   }

  //   if (!allowedKeys.includes(e.key)) {
  //     e.preventDefault();
  //   }
  // };
  const handleInputChangeForPhone = (e) => {
    e.target.value = e.target.value.replace(/\D/g, ''); // Filter out non-digit characters
    editProfileFormik.handleChange(e); // Call Formik's handleChange with the modified event
  };
  useEffect(() => {


    if (selectedDialCode?.length === 3) {


      if (!getPhoneNumber) {

        editProfileFormik.setFieldValue("phoneNumber", user?.phoneNumber)
      }
      else {
        editProfileFormik.setFieldValue("phoneNumber", 0)

      }
    }
    if (selectedDialCode?.length === 4) {

      if (!getPhoneNumber) {

        editProfileFormik.setFieldValue("phoneNumber", user?.phoneNumber)
      }
      else {
        editProfileFormik.setFieldValue("phoneNumber", "")
      }


    }
  }, [selectedDialCode])

  return (
    <>
      <div className="col-lg-10">
        <div className="translator-edit-profile-right">
          <div className="content common-btm-mrgn">
            <h4>Edit profile</h4>
          </div>
          <div className="signin-form-wrap">
            <form onSubmit={editProfileFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      onChange={editProfileFormik.handleChange}
                      type="text"
                      placeholder=""
                      className="form-control"
                      id="name"
                      name="name"
                      value={editProfileFormik.values.name}
                    />
                  </div>
                </div>
                <p className="form-error-message">
                  {editProfileFormik.touched.name &&
                    editProfileFormik.errors.name}
                </p>
                {/* <div className="col-lg-12">
                        <div className="form-group">
                          <label>Paypal email</label>
                          <input
                            type="email"
                            placeholder="example@gmail.com"
                            className="form-control"
                          />
                        </div>
                      </div> */}
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>I am a</label>
                    <select
                      id="collegeName"
                      name="collegeName"
                      className="form-select form-control"
                      onChange={editProfileFormik.handleChange}
                    >
                      {iAmATranslator?.map((item, index) => (
                        <option key={index} value={item?.value} selected={item?.value === editProfileFormik?.values?.collegeName}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}
                {/* <p className="form-error-message">
                  {editProfileFormik.touched.collegeName &&
                    editProfileFormik.errors.collegeName}
                </p> */}
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      disabled
                      id="email"
                      name="email"
                      type="email"
                      placeholder=""
                      className="form-control"
                      value={user.email}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Country</label>
                    {/* <select
                      name="country"
                      id="country"
                      className="form-select form-control"
                      onChange={editProfileFormik.handleChange}
                    >
                      {chooseCountryTranslator.map((item, id) => (
                        <option key={id} value={item?.value} selected={item?.value === editProfileFormik?.values?.country}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {editProfileFormik.touched.country &&
                        editProfileFormik.errors.country}
                    </p> */}
                    <div
                      className='form-control select-country'
                    >
                      <Select
                        id='country' name='country' onChange={countrySelectHandler} value={{ label: editProfileFormik?.values.country }}
                        isSearchable={true}
                        options={countryList().getData()}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: "none",
                          }),
                        }}

                      />
                      <p className='form-error-message'>{editProfileFormik.touched.country && editProfileFormik.errors.country}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label>Timezone</label>
                    <select
                      name="timezone"
                      id="timezone"
                      onChange={editProfileFormik.handleChange}
                      className="form-select form-control"
                    >
                      {chooseTimezoneTranslator.map((item, id) => (
                        <option key={id} value={item?.value} selected={item?.value === editProfileFormik?.values?.timezone}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {editProfileFormik.touched.timezone &&
                        editProfileFormik.errors.timezone}
                    </p>
                  </div>
                </div> */}
                <div className="col-lg-6">
                  <div className="form-group with-selectbox">
                    <label>Phone</label>
                    <input type="text" placeholder="" className="form-control" id='phoneNumber' name='phoneNumber' onChange={handleInputChangeForPhone} value={editProfileFormik.values.phoneNumber} />
                    <select className="form-select" disabled>
                      <option>{selectedDialCode}</option>

                    </select>
                    <p className='form-error-message'>{editProfileFormik.touched.phoneNumber && editProfileFormik.errors.phoneNumber}</p>

                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>School</label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      name="schoolName"
                      id="schoolName"
                      onChange={editProfileFormik.handleChange}
                      value={editProfileFormik.values.schoolName}
                    />
                  </div>
                </div> */}
                {/* <p className="form-error-message">
                  {editProfileFormik.touched.schoolName &&
                    editProfileFormik.errors.schoolName}
                </p> */}
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>Field of study</label>
                    <select
                      onChange={editProfileFormik.handleChange}
                      name="fieldOfStudy"
                      id="fieldOfStudy"
                      className="form-select form-control"
                    >
                      {fieldOfStudyTranslator?.map((item, id) => (
                        <option key={id} value={item?.value} selected={item?.value === editProfileFormik?.values?.fieldOfStudy}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="form-error-message">
                  {editProfileFormik.touched.fieldOfStudy &&
                    editProfileFormik.errors.fieldOfStudy}
                </p> */}
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>Level of study</label>
                    <select
                      onChange={editProfileFormik.handleChange}
                      name="levelOfStudy"
                      id="levelOfStudy"
                      className="form-select form-control"
                    >
                      {levelOfStudyTranslator?.map((item, id) => (
                        <option key={id} value={item?.value} selected={item?.value === editProfileFormik?.values?.levelOfStudy}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="form-error-message">
                  {editProfileFormik.touched.levelOfStudy &&
                    editProfileFormik.errors.levelOfStudy}
                </p>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Expected graduation date</label>
                    <input
                      type="date"
                      placeholder=""
                      className="form-control"
                      name="expectedGraduationDate"
                      id="expectedGraduationDate"
                      onChange={editProfileFormik.handleChange}
                      value={editProfileFormik.values.expectedGraduationDate}
                    />
                  </div>
                </div>
                <p className="form-error-message">
                  {editProfileFormik.touched.expectedGraduationDate &&
                    editProfileFormik.errors.expectedGraduationDate}
                </p> */}
                <div className="col-lg-12">
                  <div className="form-group mb-0">
                    <button type="submit" className="btn w-100">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslatorEditProfile;
