import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { serviceTypeStaticArray } from '../../../constants/config'
import { setActiveStep, setDeadlineOptions, updateJob } from '../../../redux/reducers/jobPostSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import ResponsiveStepper from './ResponsiveStepper'
import { getBusinessDeadlines, getDeadlines } from '../../../services/api/jobPostApis'
const GeneralService = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { serviceType, jobWordCount } = useSelector((state) => state.jobPost)
    const { user }=useSelector((state) => state.auth)
    const [servicesType, setServicesType] = useState(serviceType)
    const selectServiceType = (value) => {
        setServicesType(value.target.value)
    }
    const gotoNext = async () => {

        if (servicesType) {
            let values = {
                wordCount: Number(jobWordCount),
                serviceType: servicesType
            }
            let data = user.role == "company" ? await getDeadlines(values) : await getDeadlines(values)

            if (typeof data?.result?.data?.speedOptions !== 'string' && data?.result?.data?.speedOptions?.length) {
                dispatch(setDeadlineOptions({ deadlineOptions: data?.result?.data?.speedOptions }))
                dispatch(setActiveStep({ activeStep: 'chooseDeadline' }))
                dispatch(updateJob({
                    type: 'UPDATE_SERVICE_TYPE',
                    value: servicesType
                }))
                navigate('/job-post/choose-deadline')
            }
            else {
                toast.error("No deadline available for this file")
            }
        }
        else {
            toast.error("plase choose one service type")
        }
    }
    const goPreviousPage = () => {
        dispatch(setActiveStep({ activeStep: 'typeOfDoc' }))
        navigate('/job-post/document-type')
    }
    return (
        <>
            <ResponsiveStepper goNext={gotoNext} goPrevious={goPreviousPage} />
            <div className="card2 show">
                <h4>Choose General Service type:</h4>
                <div className="g-servi">
                    <div className="row">
                        {serviceTypeStaticArray.map((item, index) => <div key={index} className="col-lg-6">
                            <div className="radio-group">
                                <input type="radio" name="plan" value={item?.value} onChange={selectServiceType} checked={servicesType === item?.value} />
                                <label htmlFor="html">
                                    <img src={item?.image} alt="" />
                                    <h5>{item?.name}</h5>
                                </label>
                            </div>
                        </div>)}

                    </div>
                    <div className="button-set">
                        <div onClick={goPreviousPage} className="Previous-button  text-center mt-1 ml-2 btn btn-border-black">Previous
                        </div>
                        <div onClick={gotoNext} className="next-button text-center mt-1 ml-2 btn">Next
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default GeneralService