import React, { useEffect, useRef, useState } from 'react'
import useOutsideObserver from '../../utils/outsideObserver/outsideObserver'
import { getAllLanguages } from '../../services/api/languagesApis'

const LanguageSelector = ({ title, getLanguage, jobPostTranslationFormik }) => {
    const [showLangs, setShowLangs] = useState(false)
    const [selectedLang, setSelectedLang] = useState('')
    const closeLangOptions = useRef(null);
    const [langSelection, setLangSelection] = useState([])
    const [search, setSearch] = useState("")
    const [languageArray, setLanguageArray] = useState([])
    const selectLanguage = (lang) => {
        setSelectedLang(lang)
        setShowLangs(false)
        getLanguage(lang)
    }

    useEffect(() => {
        setSearch("")
        setLangSelection(langSelection)
    }, [showLangs])
    useEffect(() => {
        (async () => {
            let languageJson = await getAllLanguages()
            let newArray = []
            for (let i = 0; i < languageJson?.data.length; i++) {
                let newObj = {
                    language: languageJson?.data[i].languageName,
                    subLang: languageJson?.data[i].subLanguages.map((sub) => sub.languageName),
                }
                newArray.push(newObj)
            }
            setLanguageArray(newArray)
            setLangSelection(newArray)
        })()
    }, [])
    const onSearchHandler = (e) => {
        setSearch(e.target.value)
        let newSearchLang = []
        if (!!e.target.value) {

            newSearchLang = languageArray.filter((val) => val.language.toLowerCase().includes(e.target.value.toLowerCase()))
            if (langSelection.filter((val) => val.language.toLowerCase().includes(e.target.value.toLowerCase()))?.length) {
            }
            else {
                for (let i of languageArray) {
                    if (i?.subLang?.length) {
                        for (let j of i?.subLang) {
                            if (j.toLowerCase().includes(e.target.value.toLowerCase())) {
                                newSearchLang?.push(i)
                            }
                        }
                    }
                }
            }
        }
        else {
            newSearchLang = languageArray

        }
        setLangSelection(newSearchLang)
    }
    useOutsideObserver(closeLangOptions, () => setShowLangs(false));

    return (
        <div className="form-group"  >
            <label className="font-weight-bold text-muted" htmlFor="item">{title}</label>
            <div className="select2" ref={closeLangOptions}>
                <input
                    className="form-control form-select select2-input bg-white text-capitalize"
                    type="text"
                    placeholder="-Select-"
                    readOnly="readonly"
                    onClick={() => setShowLangs(!showLangs)}
                    value={selectedLang}
                />
                <input
                    className="d-none select2-value invisible"
                    id="item"
                    name="item"
                    type="hidden"
                    readOnly="readonly"
                    defaultValue=""
                />
                <div
                    className="select2-list bg-light w-100 rounded-bottom select-with-serach"
                    style={{ display: showLangs ? 'block' : 'none' }}
                >
                    <input
                        className="select2-search form-control form-control-sm"
                        type="text"
                        placeholder="search..."
                        onChange={(e) => onSearchHandler(e)}
                        value={search}
                    />
                    <ol type="I">
                        {langSelection.map((item, idx) =>
                            <li className="select2-item" key={idx} onClick={() => (!item?.subLang.length) && ((jobPostTranslationFormik.values.fromLang || jobPostTranslationFormik.values.toLang) !== item.language) && selectLanguage(item.language)}>
                                <div>
                                    <p style={{ cursor: ((jobPostTranslationFormik.values.fromLang || jobPostTranslationFormik.values.toLang) === item.language) && "not-allowed" }}>{item.language}</p>
                                    <ol className="select2-item-sub" type='a'>
                                        {item?.subLang && item?.subLang?.map((item, idx) =>
                                            <li key={idx} onClick={() => (jobPostTranslationFormik.values.fromLang || jobPostTranslationFormik.values.toLang) !== item && selectLanguage(item)}>
                                                <p style={{ cursor: ((jobPostTranslationFormik.values.fromLang || jobPostTranslationFormik.values.toLang) === item) && "not-allowed" }}>{item}</p>
                                            </li>)}
                                    </ol>
                                </div>
                            </li>)}
                    </ol>
                </div>
            </div>

        </div>
    )
}

export default LanguageSelector

