import { toast } from "react-toastify";
import http from "./http";

export const withDrawMoneyCLIQDataApi = async (item) => {
  let withDrawMoneyCLIQDataApiData = await http.put(
    "/profile/update-withdraw",
    item
  );
  if (withDrawMoneyCLIQDataApiData?.serverResponse?.code === 200) {
    toast.success(withDrawMoneyCLIQDataApiData?.serverResponse?.message);
    return withDrawMoneyCLIQDataApiData;
  }
};
export const withDrawMoneyDataApiTranslator = async (item) => {
  let withDrawMoneyCLIQDataApiData = await http.put(
    "/profile/update-wallet",
    item
  );
  console.log(withDrawMoneyCLIQDataApiData?.serverResponse?.code)
  if (withDrawMoneyCLIQDataApiData?.serverResponse?.code === 200) {
    toast.success(
      "The request has been sent to accounting and your pay will be sent at the end of the month"
    );
    return withDrawMoneyCLIQDataApiData;
  }
};
export const withDrawMoneyDataApiProofreader = async (item) => {
  let withDrawMoneyCLIQDataApiData = await http.put(
    "/profile/update-wallet",
    item
  );
  if (withDrawMoneyCLIQDataApiData?.serverResponse?.code === 200) {
    toast.success(
      "The request has been sent to accounting and your pay will be sent at the end of the month"
    );
    return withDrawMoneyCLIQDataApiData;
  }
};
export const withDrawMoneyAlawnehDataApi = async (item) => {
  // let withDrawMoneyAlawnehDataApiData = await http.post("/jobs/create", item);
  // if (withDrawMoneyAlawnehDataApiData?.serverResponse?.code === 201) {
  //   toast.success(withDrawMoneyAlawnehDataApiData?.serverResponse?.message);
  //   return withDrawMoneyAlawnehDataApiData;
  // }
};
export const withDrawMoneyNotJordanDataApi = async (item) => {
  // let withDrawMoneyNotJordanDataApiData = await http.post("/jobs/create", item);
  // if (withDrawMoneyNotJordanDataApiData?.serverResponse?.code === 201) {
  //   toast.success(withDrawMoneyNotJordanDataApiData?.serverResponse?.message);
  //   return withDrawMoneyNotJordanDataApiData;
  // }
};
