import React from "react";
import { Route, Routes } from "react-router-dom";
import CustomerEditProfile from "./CustomerEditProfile";
import LeftSide from "./LeftSide";
import CustomerChangePassword from "./CustomerChangePassword";
import CustomerDeleteProfile from "./CustomerDeleteProfile";
import CertificatePinCode from "./CertificatePinCode";

const SettingsUser = () => {
  return (
    <>
      <section className="user-dashbord sec_pad">
        <div className="container">
          <div className="row">
            <LeftSide />
            <Routes>
              <Route
                path="/edit-profile"
                element={<CustomerEditProfile />}
              ></Route>
               <Route
                path="/ceritifcate-pincode"
                element={<CertificatePinCode />}
              ></Route>
              <Route
                path="/change-password"
                element={<CustomerChangePassword />}
              ></Route>
              <Route
                path="/delete-profile"
                element={<CustomerDeleteProfile />}
              ></Route>
            </Routes>
          </div>
        </div>
      </section>
    </>
  );
};

export default SettingsUser;
