import http from "./http";

export const chatAccess = async (receiverId) => {
  let payload = { receiverId: receiverId };
  let chatBoxData = await http.post(`/chat/access`, payload);
  if (chatBoxData?.serverResponse?.code === 200) {
    return chatBoxData;
  }
};

export const checkChatAvailability = async (userId) => {
  let payload = { userId: userId };
  let chatBoxData = await http.post(`/chat/check/chatroom/access`, payload);
  if (chatBoxData?.serverResponse?.code === 200) {
    return chatBoxData;
  }
};
export const createChatRoom = async ({ userId, projectManagerId }) => {
  // let payload={userId:userId,projectManagerId:projectManagerId}
  // let chatRoomData= await http.post(`/create-chat-room`,payload)
  // if(chatRoomData?.serverResponse?.code === 200){
  //     return chatRoomData
  // }
};
