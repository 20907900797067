import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setActiveStep, updateJob } from '../../../redux/reducers/jobPostSlice'
import { docTypesJsonData } from '../../../constants/config'
import { toast } from 'react-toastify';
import ResponsiveStepper from './ResponsiveStepper'

const DocumentType = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { documentType } = useSelector((state) => state.jobPost)
    const [selectedDoc, setSelectedDoc] = useState(documentType)
    const selectDocType = (value) => {
        setSelectedDoc(value.target.value)
    }

    const gotoNextPage = () => {
        if (selectedDoc) {
            dispatch(updateJob({
                type: 'UPDATE_DOC_TYPE',
                value: selectedDoc
            }))
            dispatch(setActiveStep({ activeStep: 'serviceType' }))
            navigate('/job-post/service-type')
        }
        else {
            toast.error("please choose one option")
        }
    }
    const goPreviousPage = () => {
        dispatch(setActiveStep({ activeStep: 'uploadFile' }))
        navigate('/job-post/upload-file')
    }
    return (
        <>
            <ResponsiveStepper goNext={gotoNextPage} goPrevious={goPreviousPage} />
            <div className="card2 show">
                <h4>Which type of document did you upload?</h4>
                <div className="doc-upld">
                    <div className="row up-ld">
                        {docTypesJsonData?.map((item, index) =>
                            <div className="col-lg-4 col-md-6" key={index}>
                                <div className="radio-group">
                                    <input type="radio" name="plan" value={item?.value} onChange={selectDocType} checked={(selectedDoc ?? documentType) === item?.value} />
                                    <label htmlFor="html">
                                        <img src={item.image} alt="" />
                                        <h5>{item.name}</h5>
                                    </label>
                                </div>
                            </div>)}
                    </div>
                </div>
                <div className="button-set">
                    <div onClick={goPreviousPage} className="Previous-button text-center mt-1 ml-2 btn btn-border-black">Previous
                    </div>
                    <div onClick={gotoNextPage} className="next-button text-center mt-1 ml-2 btn">Next
                    </div>

                </div>
            </div>
        </>
    )
}

export default DocumentType