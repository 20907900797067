import React, { useEffect, useState } from 'react'
import ProcessBanner from './ProcessBanner'
import { ProcessStepBar } from './ProcessStepBar'
import ProcessWorking from './ProcessWorking'
import { ProcessFaq } from './ProcessFaq'
import ProcessContact from './ProcessContact'
import { useLocation, useSearchParams } from 'react-router-dom'
import SpecializedEarnMore from './SpecializedEarnMore'

const Process = () => {
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const [slectedUserRole, setSelectedtUserRole] = useState(Number(searchParams.get('membertype') ? searchParams.get('membertype') : 1))
    useEffect(() => {
        if (location?.state?.faq === "faq") {
            const element = document.getElementById('faqTarget');
            element.scrollIntoView({ behavior: 'smooth' });
        }



    }, [location?.state?.faq])
    return (
        <div>
            <ProcessBanner slectedUserRole={slectedUserRole} />
            <ProcessStepBar slectedUserRole={slectedUserRole} setSelectedtUserRole={setSelectedtUserRole} />
            {/* <ProcessWorking /> */}
            <SpecializedEarnMore slectedUserRole={slectedUserRole} />
            <ProcessFaq slectedUserRole={slectedUserRole} setSelectedtUserRole={setSelectedtUserRole} />
            {/* <ProcessContact /> */}
        </div>
    )
}

export default Process