import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChatRoomMessage, gettingAllChatRooms, joinChat } from '../../../services/socket'
import { createChatRooms, setActiveroom, setMessages } from '../../../redux/reducers/chatRoomSlice'
import { timeAgoFunc } from '../../../utils/timeAgoFunc'
import InfiniteScroll from 'react-infinite-scroll-component'

const LeftSideChatSection = ({ setShowChat }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { chatRooms, activeRoom, typingData } = useSelector((state) => state.chatRoom)
    const [pageNumber, setPageNumber] = useState(1)
    const createChatRoom = async (item) => {
        dispatch(setMessages({ messages: null }))
        joinChat([user?._id, item?._id])
        dispatch(setActiveroom({ activeRoom: item })) // will put this to socket event when roomid and userid will be send in an object
        getChatRoomMessage({ userId: user?._id, chatRoomId: item?._id, pageNumber: 1, dataPerPage: 10 })
        setShowChat(true)
    }
    useEffect(() => {
        let payload = { userId: user?._id, pageNumber: pageNumber, dataPerPage: 10 };
        gettingAllChatRooms(payload)
        return () => {
            dispatch(createChatRooms({ chatRoom: null }))
            dispatch(setMessages({ messages: null }))

        }
    }, [])
    useEffect(() => {
        if (!activeRoom?._id && chatRooms?.length) {
            dispatch(setActiveroom({ activeRoom: chatRooms[0] }))
            getChatRoomMessage({ userId: user?._id, chatRoomId: chatRooms[0]?._id, pageNumber: 1, dataPerPage: 10 })
        }
    }, [chatRooms])
    const loadMoreChatRooms = () => {
        let payload = { userId: user?._id, pageNumber: pageNumber + 1, dataPerPage: 10 };
        setPageNumber(pageNumber + 1)
        gettingAllChatRooms(payload)
    }

    return (
        <div className="all-chat-contact">
            <ul>
                {chatRooms?.length && <InfiniteScroll
                    dataLength={chatRooms?.length} //This is important field to render the next data
                    next={() => loadMoreChatRooms()}
                    hasMore={true}
                    height={600}
                >
                    {chatRooms && chatRooms.map((item, index) => {
                        let member = item?.members?.find((usr) => usr?._id !== user?._id)
                        return (
                            <li key={index} onClick={() => createChatRoom(item)}>
                                <a href='/' className={`${item?._id === activeRoom?._id && "active"}`} style={{ pointerEvents: 'none' }}>
                                    <div className="sngl-box">
                                        <div className="user-dtls">
                                            {/* <div className="user-img" ><img src={`/images/user-two.png`} alt="" /></div> */}
                                            <div className="user-cntnt">
                                                <h5>
                                                    {member?.name} {item?.lastMessage && <span>{timeAgoFunc(item?.lastMessage?.createdAt)}</span>}
                                                </h5>
                                                <h6>{member?.role === 'project-manager-for-translator' ? 'Translator Manager' : member?.role === 'project-manager-for-proofreader' ? 'Proofreader Manager' : member?.role === 'translator' ? 'Translator' : member?.role === 'proofreader' ? 'Proofreader' : 'Customer'}</h6>
                                                {(typingData?.userId !== user?._id) && (typingData?.chatRoomId === activeRoom?._id) && <h6>typing...</h6>}
                                            </div>
                                        </div>
                                        {item?.lastMessage && <p>
                                            {item?.lastMessage?.messageText}
                                        </p>}
                                    </div>
                                </a>
                            </li>
                        )
                    })}
                </InfiniteScroll>}
            </ul>
        </div >
    )
}

export default LeftSideChatSection