import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { resetPassFormValidator } from '../../../services/formValidator/authValidator'
import { resetPassword } from '../../../services/api/authApis'
import { useLocation, useNavigate } from 'react-router-dom'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const ResetPassword = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const resetPassFormik = useFormik({
        initialValues: resetPassFormValidator.initialValues,
        validationSchema: resetPassFormValidator.validationSchema,
        onSubmit: async (values) => {
            let { serverResponse } = await resetPassword(values.confrmPassword, values.otp, location.state.email)
            if (serverResponse.code === 200) {
                navigate('/login')
            }
        }
    })
    useEffect(() => {
        if (!location.state?.email) {
            navigate('/forgot-password')
        }
    }, [])
    const [newShowPassword, setNewShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const gotoMailChange = () => {
        navigate('/forgot-password')
    }

    const newPasswordHandler = () => {
        setNewShowPassword((prev) => !prev)
    }
    const confirmPasswordHandler = () => {
        setShowConfirmPassword((prev) => !prev)
    }
    return (
        <>
            <section className="user-dashbord sec_pad">

                <div className="container">
                    <div className="signin-form-wrap">
                        <form onSubmit={resetPassFormik.handleSubmit}>
                            <h4>Reset Password</h4>
                            <p className='reset-password-mail'>An OTP has been sent to your email address. ({location.state?.email}) <span onClick={gotoMailChange}>change mail?</span></p>
                            <div className="form-group">
                                <label>OTP</label>
                                <input type="text" placeholder="Enter OTP" id='otp' name='otp' value={resetPassFormik?.values.otp} onChange={resetPassFormik.handleChange} className="form-control" />
                                <p className='form-error-message'>{resetPassFormik.touched.otp &&
                                    resetPassFormik.errors.otp}</p>
                            </div>
                            <div className="form-group password">
                                <label>New Password</label>
                                <input type={newShowPassword ? "text" : "password"} placeholder="" id='newPassword' name='newPassword' value={resetPassFormik?.values.newPassword} onChange={resetPassFormik.handleChange} className="form-control" />
                                <p className='form-error-message'>{resetPassFormik.touched.newPassword &&
                                    resetPassFormik.errors.newPassword}</p>
                                <span onClick={newPasswordHandler} className="forgot-password-eye-toggle "> <FontAwesomeIcon icon={newShowPassword ? faEyeSlash : faEye} /></span>

                            </div>
                            <div className="form-group password">
                                <label>Confirm Password</label>
                                <input type={showConfirmPassword ? "text" : "password"} placeholder="" id='confrmPassword' name='confrmPassword' value={resetPassFormik?.values.confrmPassword} onChange={resetPassFormik.handleChange} className="form-control" />
                                <p className='form-error-message'>{resetPassFormik.touched.confrmPassword &&
                                    resetPassFormik.errors.confrmPassword}</p>
                                <span onClick={confirmPasswordHandler} className="forgot-password-eye-toggle "> <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} /></span>

                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn w-100">
                                    Reset Password
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword