import { Outlet } from "react-router-dom";
import { NavigateTo } from "../services/appNavigator";
function Auth({ user }) {
  if (user) {
    return (
      <NavigateTo
        role={user.role}
        isQualificationVerified={user.isQualificationVerified}
        userType={user}
      />
    );
  } else {
    return <Outlet />;
  }
}
export default Auth;
