import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearjobPost, updateBlobFile, updateJobPostFiles } from '../../../redux/reducers/jobPostSlice'
import { createJob, createPayment, paymentStatus, updateJobPayment, uploadJobFile } from '../../../services/api/jobPostApis'
import { toast } from 'react-toastify'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
const ThankYouPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [paymentFailed, setPaymentFailed] = useState(false)
    const [paymentFailedMsg, setPaymentFailedMsg] = useState('')
    const [timer, setTimer] = useState(0)
    const {
        jobWordCount,
        uploadFiles,
        fileDetails,
        blobFile,
        documentType,
        serviceType,
        deadlineTime,
        configTranslation,
        configProofreading,
        perWordPrice,
        jobPrice,
        rewardPointUsed,
        promoCode,
        walletFundUsed,
        cartCurrency,
        totalInitial,
        multipliedBy,
        fixedRate,
        styleSheetLink
    } = useSelector((state) => state.jobPost);
    let jobPostApi = {
        jobWordCount,
        uploadFiles,
        fileDetails,
        blobFile,
        documentType,
        serviceType,
        deadlineTime,
        configTranslation,
        configProofreading,
        perWordPrice,
        jobPrice,
        rewardPointUsed,
        promoCode,
        walletFundUsed,
        cartCurrency,
        totalInitial,
        multipliedBy,
        fixedRate,
        styleSheetLink
    }


    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const payForJob = async () => {
        dispatch(startLoading());
        let uploadFileData = await uploadJobFile(dataURLtoFile(blobFile?.b64, blobFile?.name))
        if (uploadFileData?.result) {
            dispatch(updateJobPostFiles({ file: uploadFileData?.result.data }))
            jobPostApi = {
                ...jobPostApi,
                uploadFiles: uploadFileData?.result.data
            }
            let data = await createJob(jobPostApi, dispatch)
            if (data?.serverResponse.code === 201) {
                toast.success(data?.serverResponse.message)
                dispatch(clearjobPost());
                let bodyData = {
                    tran_ref: sessionStorage?.getItem('transRef'),
                    paymentStatus: 'success',
                    jobId: data?.result?.data?._id
                }
                await updateJobPayment(bodyData)
                dispatch(updateBlobFile({ blobFile: null }))

                sessionStorage?.removeItem('transRef')
            }
            else {
                toast.error(data?.serverResponse.message)
            }
        }
    }
    const rePayment = async () => {
        let bodyData = {
            cartCurrency:cartCurrency,
            cartAmount: Number(
                (Number(perWordPrice) * jobWordCount).toFixed(4)
            ),
            returnUrl: `${window.location.origin}`
        }
        let payment = await createPayment(bodyData)
        if (payment) {
            sessionStorage.setItem('transRef', payment?.result?.data?.tran_ref)
            window.location.href = payment?.result?.data?.redirect_url;
        }
    }
    const orderAgainHandler = () => {
        dispatch(clearjobPost())
        navigate("/job-post/")
    }
    useEffect(() => {
        (async () => {
            if (sessionStorage?.getItem('transRef')) {
                dispatch(startLoading());
                let bodyData = {
                    tran_ref: `${sessionStorage.getItem('transRef')}`
                }
                let paymentData = await paymentStatus(bodyData)
                if (paymentData?.result?.data?.payment_result?.response_status === 'A') {
                    payForJob()
                    // dispatch(stopLoading());
                }
                else {
                    let bodyData = {
                        tran_ref: sessionStorage?.getItem('transRef'),
                        paymentStatus: 'failed'
                    }
                    await updateJobPayment(bodyData)
                    sessionStorage?.removeItem('transRef')
                    setPaymentFailedMsg(paymentData?.result?.data?.payment_result?.response_message)
                    setPaymentFailed(true)
                    startTimer();
                    dispatch(stopLoading());
                    toast.error(paymentData?.result?.data?.payment_result?.response_message)
                }
            }
        })()
    }, [sessionStorage])

    function startTimer() {
        var seconds = 10;
        var countdown = setInterval(function () {
            setTimer(seconds);
            seconds--;
            if (seconds < 0) {
                clearInterval(countdown);
                setTimer(0);
            }
        }, 1000);
    }
    return (
        <section className="thank-u padding-bottom padding-top">
            <div className="container">
                <div className="tanku-sec">
                    {!paymentFailed ?
                        <img src="images/2.png" alt="" />
                        :
                        <div className={`error-payment`}>
                            <FontAwesomeIcon icon={faWarning} color='red' size={50} />
                        </div>
                    }
                    <h3>{paymentFailed ? 'Payment failed' : 'Thank you! for choosing our service.'}</h3>
                    <p>
                        {paymentFailed ? `Payment failed :- ${paymentFailedMsg}` : 'Your order is confirmed. Please go to dashboard for track your service.'}
                    </p>
                    <div className="th-d-flex">
                        {!paymentFailed ? <p onClick={orderAgainHandler} className="btn btn-green">
                            Order Again
                        </p> :
                            <p onClick={() => !timer && rePayment()} className="btn btn-green" style={{ cursor: timer ? 'not-allowed' : 'pointer' }}>
                                {timer ? `Repay after ${timer} second.` : 'Repay'}
                            </p>}
                        <p onClick={() => {
                            navigate("/customer-dashboard"); dispatch(clearjobPost());
                        }} className="btn">
                            Dashboard
                        </p>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ThankYouPage