import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllReceipts } from '../../../services/api/transactionsApis'
import { setMyReceipts, setSelectedReceiptDetails } from '../../../redux/reducers/jobSlice'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'
import NoContentAvailable from '../../../components/common/NoContentAvailable'
import ReceiptSinglecard from './ReceiptSinglecard'


const CustomerReceiptsList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { myReceipts, pageNumberReceipts, dataPerPageReceipts, totalReceiptCount } = useSelector((state) => state.job)


    const allReceipts = async (page) => {
        dispatch(startLoading())
        let data = await getAllReceipts({ pageNumber: page ?? 1, dataPerPage: 10 })
        if (data?.data?.length > 0) {
            dispatch(setMyReceipts({ receipts: data?.data, pageNumber: data?.pageNumber, dataPerPage: data?.dataPerPage, totalReceiptCount: data?.dataCount }))
        }

        dispatch(stopLoading())
    }
    const loadMoreData = () => {
        allReceipts(pageNumberReceipts + 1)
    }
    useEffect(() => {
        allReceipts()
        return () => {
            dispatch(setMyReceipts({ receipts: null, pageNumber: 1, dataPerPage: 10, totalReceiptCount: 0 }));
        }
    }, [])
    const gotoSelectedReceipt = (data) => {
        dispatch(setSelectedReceiptDetails({ selectedReceipt: data }))
        navigate(`/customer/receipt-details`, { state: { id: data._id } })
    }

    return (
        <div className="user-dashbord">
            <div className="section-head-two common-btm-mrgn common-top-mrgn">
                <div className="container">
                    <div className="content">
                        <h3>My Receipts</h3>
                    </div>
                </div>
                <div className="ongong-and-post-project-sec common-btm-padd">
                    <div className="container">
                        <div className="card">
                            <div className="ongong-post-wraper">
                                <div className="row">
                                    {(myReceipts && myReceipts?.length > 0) ? myReceipts?.map((item, idx) => {
                                        return (
                                            <ReceiptSinglecard item={item} gotoSelectedReceipt={gotoSelectedReceipt} id={idx} />
                                        )
                                    }) :
                                        <NoContentAvailable title={'No Receipts available'} />
                                    }
                                </div>
                            </div>

                            {(totalReceiptCount - myReceipts?.length > 0) && <div className="end-btn" onClick={loadMoreData}>
                                <p className="btn light-btn">
                                    Load more
                                </p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CustomerReceiptsList