import React from 'react'
import CallToSignup from '../../../components/common/callToSignup/CallToSignup'

const Job = () => {
    return (
        <>

            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/jobs-banner.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Jobs</h1>
                                    <p>Et assumenda voluptates ex explicabo voluptatem non earum dolor ea velit consectetur At
                                        reiciendis aliquam est dolores tempora.</p>


                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </section>



            <section className="interpreters-sec-wrap padding-bottom padding-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="interpreters-left-sec">
                                <h6>Translation</h6>
                                <p>Total 15 open positions</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="interpreters-right-sec">
                                <div className="inter-yellow-bg">
                                    <div className="inter-h-flex">
                                        <div className="h-flex">
                                            <h5>Medical interpreters</h5>
                                            <ul className="tags-sec">
                                                <li className="light-green-db"><span><img src="/images/bag.svg" /></span>2-6 years</li>
                                                <li className="light-green-db"><span><img src="/images/Vacancies.svg" /></span> 7
                                                    Vacancies</li>
                                                <li className="light-green-db"><span><img src="/images/location.svg" /></span>Kolkata
                                                </li>

                                            </ul>
                                        </div>
                                        <a href="#" className="btn btn-blue-solid">Apply</a>
                                    </div>
                                    <h6>Responsibilities:</h6>
                                    <ul>
                                        <li><a href="#">Demonstrable UI design skills with a strong portfolio. (Mobile app, Web App, Websites etc.).</a></li>
                                        <li><a href="#">Should possess hands-on experience in App Design, Layout, Logo & Stationary Design.</a></li>
                                        <li><a href="#">Strong understanding of mobile technologies, web technologies, web architectures.</a></li>
                                        <li><a href="#">Solid experience in creating wireframes, user flows, process flows and site-maps.</a></li>
                                        <li><a href="#">Up-to-date with the latest UI trends, techniques, and technologies.</a></li>
                                    </ul>
                                </div>
                                <div className="inter-yellow-bg">
                                    <div className="inter-h-flex">
                                        <div className="h-flex">
                                            <h5>Medical interpreters</h5>
                                            <ul className="tags-sec">
                                                <li className="light-green-db"><span><img src="/images/bag.svg" /></span>2-6 years</li>
                                                <li className="light-green-db"><span><img src="/images/Vacancies.svg" /></span> 7
                                                    Vacancies</li>
                                                <li className="light-green-db"><span><img src="/images/location.svg" /></span>Kolkata
                                                </li>

                                            </ul>
                                        </div>
                                        <a href="#" className="btn btn-blue-solid">Apply</a>
                                    </div>
                                    <h6>Responsibilities:</h6>
                                    <ul>
                                        <li><a href="#">Demonstrable UI design skills with a strong portfolio. (Mobile app, Web App, Websites etc.).</a></li>
                                        <li><a href="#">Should possess hands-on experience in App Design, Layout, Logo & Stationary Design.</a></li>
                                        <li><a href="#">Strong understanding of mobile technologies, web technologies, web architectures.</a></li>
                                        <li><a href="#">Solid experience in creating wireframes, user flows, process flows and site-maps.</a></li>
                                        <li><a href="#">Up-to-date with the latest UI trends, techniques, and technologies.</a></li>
                                    </ul>
                                </div>
                                <div className="inter-yellow-bg">
                                    <div className="inter-h-flex">
                                        <div className="h-flex">
                                            <h5>Medical interpreters</h5>
                                            <ul className="tags-sec">
                                                <li className="light-green-db"><span><img src="/images/bag.svg" /></span>2-6 years</li>
                                                <li className="light-green-db"><span><img src="/images/Vacancies.svg" /></span> 7
                                                    Vacancies</li>
                                                <li className="light-green-db"><span><img src="/images/location.svg" /></span>Kolkata
                                                </li>

                                            </ul>
                                        </div>
                                        <a href="#" className="btn btn-blue-solid">Apply</a>
                                    </div>
                                    <h6>Responsibilities:</h6>
                                    <ul>
                                        <li><a href="#">Demonstrable UI design skills with a strong portfolio. (Mobile app, Web App, Websites etc.).</a></li>
                                        <li><a href="#">Should possess hands-on experience in App Design, Layout, Logo & Stationary Design.</a></li>
                                        <li><a href="#">Strong understanding of mobile technologies, web technologies, web architectures.</a></li>
                                        <li><a href="#">Solid experience in creating wireframes, user flows, process flows and site-maps.</a></li>
                                        <li><a href="#">Up-to-date with the latest UI trends, techniques, and technologies.</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="interpreters-left-sec">
                                <h6>Proofreading</h6>
                                <p>Total 7 open positions</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="interpreters-right-sec">
                                <div className="inter-yellow-bg">
                                    <div className="inter-h-flex">
                                        <div className="h-flex">
                                            <h5>Medical interpreters</h5>
                                            <ul className="tags-sec">
                                                <li className="light-green-db"><span><img src="/images/bag.svg" /></span>2-6 years</li>
                                                <li className="light-green-db"><span><img src="/images/Vacancies.svg" /></span> 7
                                                    Vacancies</li>
                                                <li className="light-green-db"><span><img src="/images/location.svg" /></span>Kolkata
                                                </li>

                                            </ul>
                                        </div>
                                        <a href="#" className="btn btn-blue-solid">Apply</a>
                                    </div>
                                    <h6>Responsibilities:</h6>
                                    <ul>
                                        <li><a href="#">Demonstrable UI design skills with a strong portfolio. (Mobile app, Web App, Websites etc.).</a></li>
                                        <li><a href="#">Should possess hands-on experience in App Design, Layout, Logo & Stationary Design.</a></li>
                                        <li><a href="#">Strong understanding of mobile technologies, web technologies, web architectures.</a></li>
                                        <li><a href="#">Solid experience in creating wireframes, user flows, process flows and site-maps.</a></li>
                                        <li><a href="#">Up-to-date with the latest UI trends, techniques, and technologies.</a></li>
                                    </ul>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="interpreters-left-sec">
                                <h6>Proofreading</h6>
                                <p>Total 7 open positions</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="interpreters-right-sec">
                                <div className="inter-yellow-bg">
                                    <div className="inter-h-flex">
                                        <div className="h-flex">
                                            <h5>Medical interpreters</h5>
                                            <ul className="tags-sec">
                                                <li className="light-green-db"><span><img src="/images/bag.svg" /></span>2-6 years</li>
                                                <li className="light-green-db"><span><img src="/images/Vacancies.svg" /></span> 7
                                                    Vacancies</li>
                                                <li className="light-green-db"><span><img src="/images/location.svg" /></span>Kolkata
                                                </li>

                                            </ul>
                                        </div>
                                        <a href="#" className="btn btn-blue-solid">Apply</a>
                                    </div>
                                    <h6>Responsibilities:</h6>
                                    <ul>
                                        <li><a href="#">Demonstrable UI design skills with a strong portfolio. (Mobile app, Web App, Websites etc.).</a></li>
                                        <li><a href="#">Should possess hands-on experience in App Design, Layout, Logo & Stationary Design.</a></li>
                                        <li><a href="#">Strong understanding of mobile technologies, web technologies, web architectures.</a></li>
                                        <li><a href="#">Solid experience in creating wireframes, user flows, process flows and site-maps.</a></li>
                                        <li><a href="#">Up-to-date with the latest UI trends, techniques, and technologies.</a></li>
                                    </ul>
                                </div>
                                <div className="inter-yellow-bg">
                                    <div className="inter-h-flex">
                                        <div className="h-flex">
                                            <h5>Medical interpreters</h5>
                                            <ul className="tags-sec">
                                                <li className="light-green-db"><span><img src="/images/bag.svg" /></span>2-6 years</li>
                                                <li className="light-green-db"><span><img src="/images/Vacancies.svg" /></span> 7
                                                    Vacancies</li>
                                                <li className="light-green-db"><span><img src="/images/location.svg" /></span>Kolkata
                                                </li>

                                            </ul>
                                        </div>
                                        <a href="#" className="btn btn-blue-solid">Apply</a>
                                    </div>
                                    <h6>Responsibilities:</h6>
                                    <ul>
                                        <li><a href="#">Demonstrable UI design skills with a strong portfolio. (Mobile app, Web App, Websites etc.).</a></li>
                                        <li><a href="#">Should possess hands-on experience in App Design, Layout, Logo & Stationary Design.</a></li>
                                        <li><a href="#">Strong understanding of mobile technologies, web technologies, web architectures.</a></li>
                                        <li><a href="#">Solid experience in creating wireframes, user flows, process flows and site-maps.</a></li>
                                        <li><a href="#">Up-to-date with the latest UI trends, techniques, and technologies.</a></li>
                                    </ul>
                                </div>



                            </div>
                        </div>

                    </div>

                </div>

            </section>
            <CallToSignup/>
        </>
    )
}

export default Job