import { NavLink, useNavigate } from "react-router-dom"
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from "react"
import { useFormik } from 'formik'
import { signupFromValidatorTranslator } from '../../../services/formValidator/authValidator'
import { signUpFormCall } from '../../../services/api/authApis'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import SocialLogin from "../../../components/common/socialLogin/SocialLoginSignup"
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import countryList from 'react-select-country-list'
import { countryCode } from "../../../constants/config"
const Translator = ({ slectedUserRole, termCondition, settermsCondition }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedDialCode, setSelectedDialCode] = useState(null);
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [selectCountry, setSelectCountry] = useState({
        countryVal: null,

    })
    const signUpFormik = useFormik({
        initialValues: signupFromValidatorTranslator.initialValues,
        validationSchema: signupFromValidatorTranslator.validationSchema,
        onSubmit: (values) => termCondition ? signUpFormCall(values, navigate, slectedUserRole, signUpFormik, dispatch) : toast.warning('Please select terms and conditions before signup')
    })
    const passwordHandler = () => {
        setShowPassword((prev) => !prev)
    }
    const confirmPasswordHandler = () => {
        setShowConfirmPassword((prev) => !prev)
    }
    const countrySelectHandler = (countryVal) => {
        signUpFormik.setFieldValue("country", countryVal?.label)
        setSelectCountry({ countryVal });
        countryCode.forEach((item) => {
            if (item.name === countryVal?.label) {
                setSelectedDialCode(item.dial_code);
                signUpFormik.setFieldValue("countryCode", item.dial_code)

            }
        });
    };
    // const handleKeyDownForCountryCode = (e) => {
    //     const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', "+"];

    //     // Allow backspace (keyCode 8)
    //     if (e.keyCode === 8) {
    //         return;
    //     }

    //     if (!allowedKeys.includes(e.key)) {
    //         e.preventDefault();
    //     }
    // };
    // const handleKeyDownForPhone = (e) => {
    //     const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    //     // Allow backspace (keyCode 8)
    //     if (e.keyCode === 8) {
    //         return;
    //     }

    //     if (!allowedKeys.includes(e.key)) {
    //         e.preventDefault();
    //     }
    // };
    useEffect(() => {
        if (selectedDialCode?.length === 3) {
            signUpFormik.setFieldValue("phoneNumber", 0)
        }
        if (selectedDialCode?.length === 4) {
            signUpFormik.setFieldValue("phoneNumber", "")

        }
    }, [selectedDialCode])
    const handleInputChangeForPhone = (e) => {
        e.target.value = e.target.value.replace(/\D/g, ''); // Filter out non-digit characters
        signUpFormik.handleChange(e); // Call Formik's handleChange with the modified event
    };
    return (
        <>
            <div className="tab-pane fade  active show">
                <div className="signin-form-wrap">
                    <form onSubmit={signUpFormik.handleSubmit}>
                        <h4>Sign up as Translator</h4>
                        <p>Please enter your actual full first, middle and last name for successful payment purposes.</p>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Name (Full Name as it appears in passport or ID)</label>
                                    <input type="text" placeholder="" className="form-control" id='name' name='name' onChange={signUpFormik.handleChange} value={signUpFormik.values.name} />
                                    <p className='form-error-message'>{signUpFormik.touched.name && signUpFormik.errors.name}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Country</label>
                                    {/* <ReactCountryFlag
                                        countryCode={
                                            selectCountry.countryVal ? selectCountry.countryVal.value : ""
                                        }
                                        svg
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        cdnSuffix="svg"
                                        title={selectCountry.countryVal ? selectCountry.countryVal.value : ""}
                                    /> */}
                                    <div
                                        className="form-control select-country"
                                    >
                                        <Select
                                            id='country' name='country' onChange={countrySelectHandler} value={signUpFormik.countryVal}
                                            isSearchable={true}
                                            options={countryList().getData()}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    border: "none",
                                                }),
                                            }}

                                        />
                                        <p className='form-error-message'>{signUpFormik.touched.country && signUpFormik.errors.country}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Country Code</label>
                                    <input type="text" placeholder="" className="form-control" id='countryCode' name='countryCode' onChange={signUpFormik.handleChange} value={signUpFormik.values.countryCode} onKeyDown={handleKeyDownForCountryCode} />
                                    <p className='form-error-message'>{signUpFormik.touched.countryCode && signUpFormik.errors.countryCode}</p>

                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                <div className="form-group with-selectbox">
                                    <label>Phone</label>
                                    <input type="text" placeholder="" className="form-control" id='phoneNumber' name='phoneNumber' onChange={handleInputChangeForPhone} value={signUpFormik.values.phoneNumber} />
                                    <select className="form-select" disabled>
                                        <option>{selectedDialCode}</option>

                                    </select>
                                    <p className='form-error-message'>{signUpFormik.touched.phoneNumber && signUpFormik.errors.phoneNumber}</p>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" placeholder="" className="form-control" id='email' name='email' onChange={signUpFormik.handleChange} value={signUpFormik.values.email} />
                                    <p className='form-error-message'>{signUpFormik.touched.email && signUpFormik.errors.email}</p>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Repeat email</label>
                                    <input type="email" placeholder="" className="form-control" id='repeatEmail' name='repeatEmail' onChange={signUpFormik.handleChange} value={signUpFormik.values.repeatEmail} />
                                    <p className='form-error-message'>{signUpFormik.touched.repeatEmail && signUpFormik.errors.repeatEmail}</p>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group password">
                                    <label>Password</label>
                                    <input type={showPassword ? "text" : "password"} placeholder="" className="form-control" id='password' name='password' onChange={signUpFormik.handleChange} value={signUpFormik.values.password} />
                                    <p className='form-error-message'>{signUpFormik.touched.password && signUpFormik.errors.password}</p>
                                    <span onClick={passwordHandler} className="forgot-password-eye-toggle "> <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></span>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group password">
                                    <label>Confirm password</label>
                                    <input type={showConfirmPassword ? "text" : "password"} placeholder="" className="form-control" id='confirmPassword' name='confirmPassword' onChange={signUpFormik.handleChange} value={signUpFormik.values.confirmPassword} />
                                    <p className='form-error-message'>{signUpFormik.touched.confirmPassword && signUpFormik.errors.confirmPassword}</p>
                                    <span onClick={confirmPasswordHandler} className="forgot-password-eye-toggle "> <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} /></span>

                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <button type="submit" className="btn w-100">Sign Up</button>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group custom-checkbox mb-0">
                                    <input type="checkbox" id="createaccount" name="createaccount" checked={termCondition} onChange={(e) => { settermsCondition(e.target.checked) }} />
                                    <label htmlFor="createaccount">By creating an account, you are agreeing to Lingvara's <NavLink to="/terms-conditions">Terms and conditions</NavLink></label>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="or-divider text-center">
                                    <span>Or</span>
                                </div>
                                <SocialLogin type='signup' role='translator' />
                                <div className="form-group mb-0 text-center">
                                    <p>Already have and account? <a onClick={() => navigate('/login')}>Sign In</a></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Translator