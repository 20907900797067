import * as Yup from "yup";

/**
 * Login form validator for login
 */
export const loginFromValidator = {
  initialValues: {
    email: "",
    password: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    password: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(/\w/, "Please enter valid password"),
  }),
};

/**
 * Signup form validator for signup
 */

// Phone number validation regexp
const phoneRegExp = "^[0-9]{9}$";
const countryCodeRegExp = /^\+\d{2}$/;

export const signupFromValidatorUser = {
  initialValues: {
    name: "",
    phoneNumber: null,
    // countryCode: "",
    email: "",
    repeatEmail: "",
    password: "",
    confirmPassword: "",
    country: "",
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    phoneNumber: Yup.string()
      .test(
        "is-valid-phone-number",
        "Phone number is not valid",
        (value, { parent }) => {
          if (parent.countryCode && parent.countryCode.length === 3) {
            return /^\d{10}$/.test(value);
          } else if (parent.countryCode && parent.countryCode.length === 4) {
            return /^\d{9}$/.test(value);
          }
          return true; // Allow empty value if countryCode is not set
        }
      )
      .required("Phone number is required"),
    // countryCode: Yup.string()
    //   .required("Country code can not be empty")
    //   .matches(
    //     countryCodeRegExp,
    //     "Country code must start with a plus sign followed by 2 digits"
    //   ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    country: Yup.string().required("Country field cannot be empty"),
    repeatEmail: Yup.string()
      .oneOf([Yup.ref("email"), ""], "Email must match")
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    password: Yup.string()
      .required("Password field is Required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Password must match")
      .required("Confirm password field is required"),
  }),
};
export const signupBusinessFromValidatorUser = {
  initialValues: {
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field cannot be empty"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    password: Yup.string()
      .required("Password field is Required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Password must match")
      .required("Confirm password field is required"),
  }),
};
export const signupFromValidatorTranslator = {
  initialValues: {
    name: "",
    phoneNumber: null,
    // countryCode: "",
    email: "",
    repeatEmail: "",
    password: "",
    confirmPassword: "",
    country: "",
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    phoneNumber: Yup.string()
      .test(
        "is-valid-phone-number",
        "Phone number is not valid",
        (value, { parent }) => {
          if (parent.countryCode && parent.countryCode.length === 3) {
            return /^\d{10}$/.test(value);
          } else if (parent.countryCode && parent.countryCode.length === 4) {
            return /^\d{9}$/.test(value);
          }
          return true; // Allow empty value if countryCode is not set
        }
      )
      .required("Phone number is required"),
    // countryCode: Yup.string()
    //   .required("Country code can not be empty")
    //   .matches(
    //     countryCodeRegExp,
    //     "Country code must start with a plus sign followed by 2 digits"
    //   ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    country: Yup.string().required("Country field cannot be empty"),
    repeatEmail: Yup.string()
      .oneOf([Yup.ref("email"), ""], "Email must match")
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    password: Yup.string()
      .required("Password field is Required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Password must match")
      .required("Confirm password field is required"),
  }),
};
export const signupFromValidatorProfReader = {
  initialValues: {
    name: "",
    phoneNumber: null,
    // countryCode: "",
    email: "",
    repeatEmail: "",
    password: "",
    confirmPassword: "",
    country: "",
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    phoneNumber: Yup.string()
      .test(
        "is-valid-phone-number",
        "Phone number is not valid",
        (value, { parent }) => {
          if (parent.countryCode && parent.countryCode.length === 3) {
            return /^\d{10}$/.test(value);
          } else if (parent.countryCode && parent.countryCode.length === 4) {
            return /^\d{9}$/.test(value);
          }
          return true; // Allow empty value if countryCode is not set
        }
      )
      .required("Phone number is required"),
    // countryCode: Yup.string()
    //   .required("Country code can not be empty")
    //   .matches(
    //     countryCodeRegExp,
    //     "Country code must start with a plus sign followed by 2 digits"
    //   ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    country: Yup.string().required("Country field cannot be empty"),
    repeatEmail: Yup.string()
      .oneOf([Yup.ref("email"), ""], "Email must match")
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    password: Yup.string()
      .required("Password field is Required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Password must match")
      .required("Confirm password field is required"),
  }),
};
/**
 * Forgot password form validator
 */
export const forgotPassFromValidator = {
  initialValues: {
    email: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
  }),
};
/**
 *Otp form validator
 */
export const otpFromValidator = {
  initialValues: {
    otp: "",
  },
  validationSchema: Yup.object().shape({
    otp: Yup.string()
      .required("Otp field is Required")
      .matches(/^[0-9]/, "Please enter valid otp"),
  }),
};
/**
 *Reset Password form validator
 */
export const resetPassFormValidator = {
  initialValues: {
    newPassword: "",
    confrmPassword: "",
    otp: "",
  },
  validationSchema: Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(/\w/, "Please enter valid password"),
    confrmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), ""], "Password must match")
      .required("Confirm password field is required")
      .matches(/\w/, "Please enter valid password"),
    otp: Yup.string()
      .required("Otp field is Required")
      .matches(/^[0-9]/, "Please enter valid otp"),
  }),
};

/**
 * Email otp verification
 */
export const emailOtpVerification = {
  initialValues: {
    otp: "",
  },
  validationSchema: Yup.object().shape({
    otp: Yup.string()
      .min(6, "Must be 6 characters")
      .required("OTP field is Required"),
  }),
};

// social login email add to non mail account form
export const addEmailVerification = {
  initialValues: {
    email: "",
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
  }),
};
