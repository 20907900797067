import React from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
const WhyUsSec = () => {
    const { t } = useTranslation();

    return (
        <section className="why-us-sec padding-bottom padding-top">
            <div className="container">
                <div className="section-heading">
                    <h3>{t("why-us-heading")}</h3>
                    <p>
                        {t("why-us-paragraph")}
                    </p>
                </div>
                <div className="us-row-wrap">
                    <div className="row">
                        <div
                            className="col-lg-3 col-md-6"
                            data-aos="fade-right"
                            data-aos-offset={300}
                            data-aos-easing="ease-in-sine"
                        >
                            <div className="us-col">
                                <div className="us-i">
                                    <img className="n-img" src="/images/Wall-clock.png" alt="" />
                                    <img className="h-img" src="/images/Wall-clock1.png" alt="" />
                                </div>
                                <h4>{t("why-us-imageFirst-heading")}</h4>
                                <p>
                                    {t("why-us-imageFirst-paragraph")}
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                            data-aos="fade-up"
                            data-aos-offset={300}
                            data-aos-easing="ease-in-sine"
                        >
                            <div className="us-col">
                                <div className="us-i">
                                    <img className="n-img" src="/images/Gear1.png" alt="" />
                                    <img className="h-img" src="/images/Gear.png" alt="" />
                                </div>
                                <h4>{t("why-us-imageSecond-heading")}</h4>
                                <p>
                                    {t("why-us-imageSecond-paragraph")}
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                            data-aos="fade-left"
                            data-aos-offset={300}
                            data-aos-easing="ease-in-sine"
                        >
                            <div className="us-col">
                                <div className="us-i">
                                    <svg className="n-img" width={"60px"} height={"60px"} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 114.86"><defs></defs><title>leaves</title><path class="cls-1" d="M59.07,110.77C110.92,105,139.6,71.12,112.44,0c-21.29,14.9-50.39,24.6-65,44.55C57,52.94,64.89,62.23,67.08,74.37c13.19-16.08,27.63-30.72,35.23-47-7.79,39.07-20,53.84-38.78,73.81a93.64,93.64,0,0,1-4.46,9.62Zm-14.9,4C4,105-15.18,76.09,14.27,24.75c23.8,22.92,65.79,37.48,38.39,85.86a27.08,27.08,0,0,1-1.83,2.93C45.9,89.62,26.21,70.69,20.43,50.61,21.77,83.42,31.23,93,45.88,114.86c-.57,0-1.14-.06-1.71-.13Z" /></svg>
                                    <svg className="h-img" width={"60px"} height={"60px"} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 114.86"><defs></defs><title>leaves</title><path class="cls-1" d="M59.07,110.77C110.92,105,139.6,71.12,112.44,0c-21.29,14.9-50.39,24.6-65,44.55C57,52.94,64.89,62.23,67.08,74.37c13.19-16.08,27.63-30.72,35.23-47-7.79,39.07-20,53.84-38.78,73.81a93.64,93.64,0,0,1-4.46,9.62Zm-14.9,4C4,105-15.18,76.09,14.27,24.75c23.8,22.92,65.79,37.48,38.39,85.86a27.08,27.08,0,0,1-1.83,2.93C45.9,89.62,26.21,70.69,20.43,50.61,21.77,83.42,31.23,93,45.88,114.86c-.57,0-1.14-.06-1.71-.13Z" /></svg>
                                </div>
                                <h4>{t("why-us-imageForth-heading")}</h4>
                                <p>
                                    {t("why-us-imageForth-paragraph")} <Link to='/environmental-pioneers'>{t("learn-more")}</Link>
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                            data-aos="fade-left"
                            data-aos-offset={300}
                            data-aos-easing="ease-in-sine"
                        >
                            <div className="us-col">
                                <div className="us-i">
                                    <img className="n-img" src="/images/Star.png" alt="" />
                                    <img className="h-img" src="/images/Star1.png" alt="" />
                                </div>
                                <h4>{t("why-us-imageThird-heading")}</h4>
                                <p>
                                    {t("why-us-imageThird-paragraph")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyUsSec