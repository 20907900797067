import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { userType } from '../../../constants/config'
import { toast } from 'react-toastify'
import { navigateTo } from '../../../services/appNavigator'

const SignUpType = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const [signMeup, setSignMeup] = useState(false)
    const [signupmessage, setSignupMessage] = useState("")

    const [selectedUserType, setSelectedUserType] = useState('')
    useEffect(() => {
        if (user && user.role === 'user') {
            navigate('/job-post/upload-file', { replace: true })
        }
        else if (user && user.role !== 'user') {
            navigateTo(user?.role, navigate)
        }
    }, [])
    const selectuserRole = (event) => {
        setSelectedUserType(event.target.value)
    }
    const goToSignUp = () => {
        navigate('/signup');
        localStorage.setItem('reDirect_url', '/job-post/upload-file')
    }
    // const signUpHandler = (e) => {
    //     setSignMeup(e.target.checked)
    //     setSignupMessage("Please enter your information (name, country, etc.) accurately in the next page to be able to receive cash generated from your affiliate link")
    // }
    return (
        <div className="card2 show">

            <div className="sign-me">
                <p> Tell your friends about our services and receive cash to your wallet which you can use for future payments or withdraw as cash. You can earn 5% on all orders made through your affiliate link. <NavLink to='/affilate'>Learn more</NavLink>
                </p>
                <form action="">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <div className="form-check">
                                    {/* <input className="form-check-input" type="checkbox" checked={signMeup} onChange={(e) => signUpHandler(e)} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <b>Yes, Sign me up</b>
                                    </label> */}
                                    You will be automatically be given an affiliate link with which you can earn money.
                                </div>

                            </div>
                        </div>
                        {/* <div className="col-lg-12">
                            <div className="form-group sign-f">
                                <label htmlFor="email1" className="form-label">Enter you Paypal Email</label>
                                <input type="email" className="form-control" id="email1" />
                            </div>
                        </div> */}
                    </div>
                    {/* {signupmessage} */}
                </form>

            </div>
            <div className="button-set">
                <div className="Previous-button text-center mt-1 ml-2 btn btn-border-black">Previous Or Cancel
                </div>
                <div onClick={goToSignUp} className="next-button text-center mt-1 ml-2 btn">Next
                </div>

            </div>
        </div >
    )
}

export default SignUpType