import * as Yup from "yup";
export const contactFormValidatorCustomer = (prevData, type) => {
  if (type === "initialValues") {
    let initialValues = {
      name: prevData?.name ?? "",
      //   lastName: prevData?.lastName ?? "",
      email: prevData?.email ?? "",
      phoneNumber: prevData?.phoneNumber ?? "",
      message: prevData?.message ?? "",
    };
    return initialValues;
  } else if (type === "validationSchema") {
    let validationSchema = Yup.object().shape({
      name: Yup.string().required("Name field cannot be empty"),
      //   lastName: Yup.string().required("Last name cannot be empty"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email field cannot be empty"),
      phoneNumber: Yup.string().required("Phone field can not be empty"),
      message: Yup.string().required("Message cannot be empty"),
    });
    return validationSchema;
  }
};
