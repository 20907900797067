import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { gettReviewListApis } from '../../../services/api/authApis';
const CustomerReview = () => {
    const [dynamicReviewData, setDynamicReviewData] = useState([])
    useEffect(() => {
        getReveiwData()
    }, [])
    const getReveiwData = async () => {
        let data = await gettReviewListApis()

        setDynamicReviewData(data)

    }
    const customerStaticData = [{ review: "I used their service for my business papers and was super happy with the result. Everything was clear and just perfect for my international audience", role: "Client", name: "Ahmed Faisal", highlight: "Super happy with the result." },
    { review: "They went through my dissertation and made it shine. It felt great knowing there were no mistakes, and it read so much better.", role: "Client", name: "Zhang Wei", highlight: "Made it shine." },
    { review: "I've been translating for them for a while, and it's great. The team is helpful, and we always aim for the best quality in our translations.", role: "Translator", name: "Maria García", highlight: "Always aim for the best quality." },
    { review: "Promoting their services has been easy and rewarding. They have a good reputation, and I see steady earnings from our partnership.", role: "Affiliate", name: "Emily Miller", highlight: "Steady earnings from our partnership." }]

    function getRandomColor() {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    return (
        <section className="customer-sec padding-top padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="customer-left-sec">
                            <h3>
                                What <span className="g-bg">Clients</span>,<span className="g-bg">Translators</span> and{" "}
                                <span className="g-bg">Affiliates</span> have to say
                            </h3>
                            <div className="student_future_imgstack d-flex">
                                <div className="imgslack">
                                    <img src="/images/img1.png" />
                                </div>
                                <div className="imgslack">
                                    <img src="/images/img2.png" />
                                </div>
                                <div className="imgslack">
                                    <img src="/images/img3.png" />
                                </div>
                                <div className="imgslack">
                                    {" "}
                                    <div className="imgslack-t">1K+</div>{" "}
                                </div>
                            </div>
                            <p>
                                We value your feedback;  <br />
                                <strong>it drives us to enhance our services.</strong>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="customer-right-sec">
                            <OwlCarousel className='customer-slider owl-carousel owl-theme' items={1} dots={false} loop margin={10} nav>
                                {customerStaticData?.map((val, index) => <div key={index} className="item customer-item">
                                    <p>
                                        {val?.review}
                                    </p>
                                    {/* <h5>
                                        {val?.highlight}
                                    </h5> */}
                                    <div className="slider-bottom">
                                        <div className="c-slider-img">
                                            {/* <img src="/images/img5.jpg" alt="" /> */}
                                            <span style={{ background: `${getRandomColor()}`, fontSize: "50px", width: "100%", height: "100%", textAlign: "center" }}>{val?.name.charAt(0)}</span>
                                        </div>
                                        <div className="c-slider-c">
                                            <h4>{val?.name}</h4>
                                            <p>{val?.role} </p>
                                        </div>
                                    </div>
                                </div>)}
                                {dynamicReviewData && dynamicReviewData?.length > 0 && dynamicReviewData?.map((val, index) => <div key={index} className="item customer-item">
                                    <p>
                                        {val?.review}
                                    </p>
                                    <h5>
                                        {val?.userId
                                            ?.highlight}
                                    </h5>
                                    <div className="slider-bottom">
                                        <div className="c-slider-img">
                                            <span style={{ background: `${getRandomColor()}`, fontSize: "50px", width: "100%", height: "100%", textAlign: "center" }}>{val?.userId
                                                ?.name.charAt(0)}</span>
                                        </div>
                                        <div className="c-slider-c">
                                            <h4>{val?.userId
                                                ?.name}</h4>
                                            <p>{val?.userId
                                                ?.role} </p>
                                        </div>
                                    </div>
                                </div>)}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default CustomerReview