import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';

const SocialShare = ({ setShowSocialShare, showSocialShare }) => {
    const { user } = useSelector((state) => state.auth)
    return (
        <Modal
            className="modal fade"
            show={showSocialShare}
            onHide={() => setShowSocialShare(false)}
            centered
        >
            <ModalHeader closeButton>
                <div className="assign-modal">Share</div>
            </ModalHeader>
            <ModalBody>
                {user && <div className='social-share'>
                    <FacebookShareButton
                        url={`${window?.location?.origin}/signup?code=${user?._id}`}
                        quote={'Got a document you need to translate? Lingvara can help! Join us'}
                        hashtag="#lingvara"
                    >
                        <FacebookIcon size={50} round />
                    </FacebookShareButton>
                    <WhatsappShareButton
                        url={`${window?.location?.origin}/signup?code=${user?._id}`}
                        title={'Got a document you need to translate? Lingvara can help! Join us'}
                        separator="&nbsp;"
                    >
                        <WhatsappIcon size={50} round />
                    </WhatsappShareButton>
                    <EmailShareButton
                        url={`${window?.location?.origin}/signup?code=${user?._id}`}
                        subject={'Affiliate join'}
                        body={'Got a document you need to translate? Lingvara can help! Join us'}
                    >
                        <EmailIcon size={50} round />
                    </EmailShareButton>
                    <LinkedinShareButton
                        url={`${window?.location?.origin}/signup?code=${user?._id}`}
                        title={'Affiliate join'}
                        summary={'Got a document you need to translate? Lingvara can help! Join us'}
                        source={'https://lingvara.weavers-web.com'}
                    >
                        <LinkedinIcon size={50} round />
                    </LinkedinShareButton>
                </div>}
            </ModalBody>
        </Modal >
    )
}

export default SocialShare