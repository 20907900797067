import React from "react";
import { useSelector } from "react-redux";
import CustomerHeader from "./UserHeaders/CustomerHeader";
import BusinessHeader from "./UserHeaders/BusinessHeader";
import ProofreaderHeader from "./UserHeaders/ProofreaderHeader";
import ProofreaderManagerHeader from "./UserHeaders/ProofreaderManagerHeader";
import TranslatorHeader from "./UserHeaders/TranslatorHeader";
import TranslatorManagerHeader from "./UserHeaders/TranslatorManagerHeader";

const UserHeader = () => {
  const { user } = useSelector((state) => state.auth);

  console.log('userType', user?.userType);
  console.log('role', user?.role);
  return (
    <>
      {user?.role === "proofreader" && <ProofreaderHeader />}
      {user?.role === "translator" && <TranslatorHeader />}
      {user?.role === "project-manager-for-proofreader" && <ProofreaderManagerHeader />}
      {user?.role === "project-manager-for-translator" && <TranslatorManagerHeader />}
      {user?.role === "company" && <BusinessHeader />}
      {user?.role === "user" && <CustomerHeader />}
    </>
  );
};

export default UserHeader;
