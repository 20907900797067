import * as Yup from "yup";
const phoneRegExp = "^[0-9]{10}$";
export const withDrawMoneyFormikTranslatorValidator = {
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    country: Yup.string()
      .required("city field can not be empty")
      .max(250, "Max can be 250 characters"),
    // phoneNumber: Yup.string()
    //   .required("Phone field can not be empty")
    //   .matches(phoneRegExp, "Phone number is not valid"),
    phoneNumber: Yup.string().min(9).max(10).matches(/^[1-9]\d{8,9}$/,"Phone number is not valid.").required("Phone number is not valid."),
  }),
};
export const withDrawMoneyFormikProofreaderValidator = {
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    country: Yup.string()
      .required("city field can not be empty")
      .max(250, "Max can be 250 characters"),
      phoneNumber: Yup.string().min(9).max(10).matches(/^[1-9]\d{8,9}$/,"Phone number is not valid.").required("Phone number is not valid."),
  }),
};
export const drawMoneyFormikCLIQvalidator = {
  validationSchema: Yup.object().shape({
    alias: Yup.string()
      .required("this field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    phoneNumber: Yup.string()
      .required("Phone field can not be empty")
      .matches(phoneRegExp, "Phone number is not valid"),
  }),
};
export const drawMoneyFormikAlawnevalidator = {
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    city: Yup.string()
      .required("city field can not be empty")
      .max(250, "Max can be 250 characters"),
  }),
};
export const drawMoneyFormikNotJordanvalidator = {
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(250, "Max can be 250 characters"),
    city: Yup.string()
      .required("city field can not be empty")
      .max(250, "Max can be 250 characters"),
  }),
};
