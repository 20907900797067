import { toast } from "react-toastify";
import http from "./http";

/**
 * Download certificate download
 */
export const downloadCertificate = async (values) => {
 
  let downloadCeritificateData = await http.get("/guest/download-certificate", {
    params: {
      values
    }
  });
  if (downloadCeritificateData?.serverResponse.code === 200) {
    return downloadCeritificateData;
  } else {
    toast.error(downloadCeritificateData?.serverResponse.message);
  }
};

/**
 * Check certificate download
 */
export const checkCertificate = async (values) => {
  let checkCertificateData = await http.post("/guest/check-certificate", values);
  console.log(checkCertificateData)
  if (checkCertificateData?.serverResponse.code === 200) {
    return checkCertificateData;
  }else if (!checkCertificateData) {
    return 'redirect';
  } else {
    return false;
  }
};