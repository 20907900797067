import React from 'react'
import { useSelector } from 'react-redux';
import ProofReaderConfigure from './ProofReaderConfigure'
import TranslationConfigure from './TranslationConfigure'

const Configure = () => {
    const { user } = useSelector((state) => state.auth);
    const { serviceType, uploadFiles, blobFile, jobWordCount, jobPrice } = useSelector((state) => state.jobPost)
    return (
        <>
            <div className="card2 show">
                <h3>Details:</h3>
                <div className="Details-card-r">

                    <div className="Details-card-box">
                        <p>document name</p>
                        <h6>{blobFile?.name}</h6>
                    </div>
                    <div className="Details-card-box">
                        <p>word count</p>
                        <h6>{jobWordCount}</h6>
                    </div>
                    <div className="Details-card-box">
                        <p>price</p>
                        <h6><a href="#">{jobPrice} USD</a></h6>
                    </div>


                </div>

                {serviceType === "translation" && <TranslationConfigure />}
                {/* <!-- Proofreading --> */}
                {serviceType === "proofreading" && <ProofReaderConfigure />}


            </div>
        </>
    )
}

export default Configure