import React from 'react'

const MoneyBackGurantee = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <h1>Money-Back Guarantee</h1>
                                <p>At Lingvara, we are committed to providing high-quality translation and proofreading services that meet your expectations. We understand that exceptional service and customer satisfaction are paramount. In the event that you are not fully satisfied with the received translation or proofreading, we offer a Money-Back Guarantee to ensure your peace of mind.</p>

                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec common-btm-padd">

                <div className="container">

                    <p className='static-text'><strong>Requesting Corrections</strong></p>
                    <ol>
                        <li>
                            <p><strong>Client Feedback</strong>: If you have concerns about the quality or timeliness of the received translation or proofreading, please contact us within 48 hours of receiving the completed work. We encourage you to provide specific details and examples of any errors or deviations from the agreed requirements.</p>
                        </li>
                        <li>
                            <p><strong>Correction Process</strong>: Upon receiving your feedback, we will initiate a correction process to address the identified issues. Our dedicated Translation Project Manager will work closely with the assigned translator or proofreader to rectify any errors or deficiencies in the document.</p>
                        </li>
                        <li>
                            <p><strong>Revision</strong>: You have the option to request corrections once within 48 hours of receiving the completed work. We will prioritize the revision process to ensure a prompt resolution. Our team will diligently review and address the specific concerns you raised, aiming to deliver an improved version of the translation or proofreading that meets your requirements.</p>
                        </li>
                    </ol>
                    <p><strong>Refund Process</strong></p>
                    <ol>
                        <li>
                            <p><a name="_GoBack"></a><strong>Client Rejection</strong>: If, after receiving the revised version, you still find the translation or proofreading unsatisfactory, you may choose to proceed with rejection. Rejection should be communicated to us within 48 hours of receiving the revised work. Please provide detailed reasoning and examples to support your rejection.</p>
                        </li>
                        <li>
                            <p><strong>Investigation</strong>: Upon receiving your rejection request, our Translation Project Manager will conduct a thorough investigation into the matter. The Project Manager will review the original requirements, the revised work, and any supporting documentation provided by both parties.</p>
                        </li>
                        <li>
                            <p><strong>Resolution</strong>: Based on the findings of the investigation, we will determine the appropriate course of action. If it is concluded that the translation or proofreading fails to meet the agreed requirements and our high-quality standards, we may offer one of the following options:</p>
                        </li>
                    </ol>
                    <p>a. <strong>Full Refund</strong>: If the investigation confirms the failure to meet quality or timeliness standards, we may choose to provide a full refund of the amount you paid for the service. The refund will be processed promptly and using the original payment method.</p>
                    <p>b. <strong>Alternative Solution</strong>: In some cases, instead of a full refund, we may opt to propose an alternative solution, such as assigning a different translator or proofreader to address the identified issues and deliver a revised version of the work.</p>
                    <p><strong>Exceptional Situations</strong></p>
                    <p>Please note that there may be exceptional circumstances where a refund cannot be granted. These include:</p>
                    <ul>
                        <li>
                            <p>Client failure to request corrections within the specified timeframe of 48 hours.</p>
                        </li>
                        <li>
                            <p>Client failure to provide sufficient and accurate source material or instructions initially.</p>
                        </li>
                        <li>
                            <p>Client violation of the Terms and Conditions or engagement in fraudulent or unethical behavior.</p>
                        </li>
                    </ul>
                    <p>In such cases, we will make every effort to address any concerns and find a mutually acceptable resolution.</p>
                    <p><strong>Contact Us</strong></p>
                    <p>If you have any questions or wish to request corrections or a refund, please contact our customer support team through the channels provided on our website. We are committed to working with you to address any issues and ensure your satisfaction.</p>
                </div>

            </section>


        </>
    )
}

export default MoneyBackGurantee