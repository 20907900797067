import { toast } from "react-toastify";
import http from "./http";
import { clearjobPost } from "../../redux/reducers/jobPostSlice";
import { startLoading, stopLoading } from "../../redux/reducers/loadingSlice";

export const uploadJobFile = async (values) => {
  let formdata = new FormData();
  formdata.append("file", values);
  let jobFileUploadData = await http.post("/uploads/file/upload-job", formdata);
  if (jobFileUploadData?.serverResponse.code === 200) {
    return jobFileUploadData;
  } else {
    toast.error(jobFileUploadData?.serverResponse.message);
  }
};
export const uploadStyleSheetFile = async (values) => {
  let formdata = new FormData();
  formdata.append("file", values);
  let jobFileUploadData = await http.post(
    "/uploads/file/upload-stylesheet",
    formdata
  );
  if (jobFileUploadData?.serverResponse.code === 200) {
    return jobFileUploadData;
  } else {
    toast.error(jobFileUploadData?.serverResponse.message);
  }
};

export const checkWordCount = async (values) => {
  if (values) {
    let formdata = new FormData();
    formdata.append("myFile", values);
    let wordCount = await http.post("/uploads/file/word/count", formdata);
    if (wordCount?.serverResponse.code === 200) {
      return wordCount;
    } else {
      toast.error(wordCount?.serverResponse.message);
    }
  }
};

export const removeFileFromS3 = async (values) => {
  let removeFileData = await http.delete("/uploads/file/delete", {
    data: values,
  });
  if (removeFileData?.serverResponse.code === 200) {
    return removeFileData;
  }
};

export const createJob = async (values, dispatch) => {
  let apiData = {
    jobTitle: values.fileDetails.name,
    // jobDescription: values.fileDetails.description,
    // promoCodeAmount: Number(values?.promoCode?.discountAmount) ?? 0,
    promoCodeAmount: values?.promoCode?.discountedAmmount
      ? values?.promoCode?.discountedAmmount
      : 0,
    jobDocumentType: values.documentType,
    specificService: values.serviceType,
    wordCount: Number(values.jobWordCount),
    walletFundUsed: values?.walletFundUsed,
    deadline: values.deadlineTime,
    perWordPrice: Number(values?.perWordPrice.toFixed(4)),
    totalJobPrice: values?.jobPrice,
    total: values?.totalInitial,
    cartCurrency: values?.cartCurrency,
    multipliedBy: values?.multipliedBy,
    fixedRate: values?.fixedRate,
    jobFile: {
      key: values.uploadFiles?.responseData.key,
      name: values.uploadFiles?.responseData.name,
      location: values.uploadFiles?.responseData.location,
      size: values.uploadFiles?.responseData.size,
      mimetype: values.uploadFiles?.responseData.mimetype,
    },
  };
  if (values?.styleSheetLink !== null) {
    apiData.styleSheetLink = values?.styleSheetLink;
  }
  if (!!values?.rewardPointUsed) {
    apiData.rewardPointUsed = values?.rewardPointUsed;
  }

  // config section add
  if (values.serviceType === "translation") {
    apiData.config = {
      comment: values.configTranslation?.addComments,
      translateFrom: values.configTranslation.fromLang,
      translateTo: values.configTranslation.toLang,
      chooseTranslator: values.configTranslation?.chooseTranslator,
      lingVaraWaterMark: values.configTranslation.showWatermark,
    };
  } else if (values.serviceType === "proofreading") {
    apiData.config = {
      proofReadingService:
        values?.configProofreading?.specService ||
        values?.configProofreading?.proofReadingService,
      intendedStyle: values?.configProofreading?.styleOfWriting,
      comment: values?.configProofreading?.addComments,
      citationStyle: values?.configProofreading?.citationstyle,
      lingVaraWaterMark: values?.configProofreading?.showWatermark,
      requiredEnglishDialect: values?.configProofreading?.dialect,
    };
  }
  if (!!values.configTranslation.specialisation) {
    apiData.config.translatorSpecialisation =
      values.configTranslation.specialisation;
  }
  let createJobData = await http.post("/jobs/create", apiData);
  if (createJobData?.serverResponse.code === 201) {
    dispatch(stopLoading())
    dispatch(clearjobPost());
    return createJobData;
  }
};

/*
 *Job post from translator manager section
 */
// Job post from translator manager with split
export const jobPostSplitJob = async (apiData) => {
  if (apiData.config.translatorSpecialisation === "") {
    delete apiData.config["translatorSpecialisation"];
  }
  let data = {
    ...apiData,
    isSplit: true,
    totalJobPrice: Number(apiData?.perWordPrice) * apiData?.wordCount,
    total: Number(apiData?.perWordPrice) * apiData?.wordCount,
  };
  let splitJobData = await http.post("/jobs/create", data);
  if (splitJobData?.serverResponse.code === 201) {
    toast.success(splitJobData?.serverResponse?.message);
    return splitJobData;
  }
};
// Direct job post as translator manager
export const directPostJob = async (item) => {
  let apiData = {
    jobTitle: item?.jobTitle,
    // jobDescription: item?.jobDescription,
    perWordPrice: item?.perWordPrice,
    totalJobPrice: Number(item?.perWordPrice) * item?.wordCount,
    jobDocumentType: item?.jobDocumentType,
    specificService: item?.specificService,
    total: Number(item?.perWordPrice) * item?.wordCount,
    wordCount: item?.wordCount,
    deadline: item?.deadline,
    jobFile: item?.jobFile,
    config: {
      comment: item?.comment,
      translateFrom: item.translateFrom,
      translateTo: item.translateTo,
      chooseTranslator: item?.chooseTranslator,
      lingVaraWaterMark: item.lingVaraWaterMark,
    },
    jobId: item?._id,
    isSplit: false,
  };
  if (!!item?.translatorSpecialisation) {
    apiData.config.translatorSpecialisation = item?.translatorSpecialisation;
  }
  let directPostJobData = await http.post("/jobs/create", apiData);
  if (directPostJobData?.serverResponse?.code === 201) {
    toast.success(directPostJobData?.serverResponse?.message);
    return directPostJobData;
  }
};
/*
 *Job post from proofreader manager section
 */
// Job post from proofreader manager with split
export const jobPostSplitJobProof = async (apiData) => {
  if (apiData.config.translatorSpecialisation === "") {
    delete apiData.config["translatorSpecialisation"];
  }
  // apiData.jobId=apiData?._id,
  apiData.isSplit = true;
  apiData.totalJobPrice = Number(apiData?.perWordPrice) * apiData?.wordCount;
  apiData.total = Number(apiData?.perWordPrice) * apiData?.wordCount;
  let splitJobData = await http.post("/jobs/create", apiData);
  if (splitJobData?.serverResponse.code === 201) {
    toast.success(splitJobData?.serverResponse?.message);
    return splitJobData;
  }
};
// Direct job post as proofreader manager

export const directPostJobProof = async (item) => {
  let apiData = {
    jobTitle: item?.jobTitle,
    // jobDescription: item?.jobDescription,
    perWordPrice: item?.perWordPrice,
    totalJobPrice: Number(item?.perWordPrice) * item?.wordCount,
    jobDocumentType: item?.jobDocumentType,
    specificService: item?.specificService,
    total: Number(item?.perWordPrice) * item?.wordCount,
    wordCount: item?.wordCount,
    deadline: item?.deadline,
    jobFile: item?.jobFile,
    styleSheetLink: item?.styleSheetLink,
    config: {
      proofReadingService: item?.proofReadingService,
      intendedStyle: item?.intendedStyle,
      comment: item?.comment,
      citationStyle: item?.citationStyle,
      lingVaraWaterMark: item?.lingVaraWaterMark,
      requiredEnglishDialect: item?.requiredEnglishDialect,
    },
    jobId: item?._id,
    isSplit: false,
  };
  let directPostJobDataProof = await http.post("/jobs/create", apiData);
  if (directPostJobDataProof?.serverResponse?.code === 201) {
    toast.success(directPostJobDataProof?.serverResponse?.message);
    return directPostJobDataProof;
  }
};

/**Payment through meps integration */

export const createPayment = async (value) => {
  let data = await http.post("/payment/create", value);
  if (data.serverResponse.code === 201) {
    return data;
  }
};

/**
 * Get payment status
 */

export const paymentStatus = async (values) => {
  let paymentStatus = await http.post("/payment/query", values);
  if (paymentStatus?.serverResponse?.code === 201) {
    return paymentStatus;
  }
};

/**
 * Update payment status
 */
export const updateJobPayment = async (values) => {
  let paymentStatus = await http.patch("/payment/update", values);
  if (paymentStatus?.serverResponse?.code === 200) {
    return paymentStatus;
  }
};

// Get deadLines

export const getDeadlines = async (values) => {
  let paymentStatus = await http.post("/uploads/file/speed/options", values);
  if (paymentStatus?.serverResponse?.code === 200) {
    return paymentStatus;
  }
};

// Get business deadLines

export const getBusinessDeadlines = async (values) => {
  let paymentStatus = await http.post("/uploads/file/speed/company-options", values);
  if (paymentStatus?.serverResponse?.code === 200) {
    return paymentStatus;
  }
};

/**
 * Check Promocode
 */
export const checkPromoCode = async (values) => {
  let promocodeResponse = await http.post("/users/use/promocode", values);
  if (promocodeResponse?.serverResponse?.code === 200) {
    return promocodeResponse?.result?.data;
  }
};
