import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import DragDropUploadButton from '../../../components/common/DragDropUploadButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateBlobFile } from '../../../redux/reducers/jobPostSlice';
const TranslationSolution = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const uploadJobFiles = (data) => {
        dispatch(updateBlobFile({ blobFile: data }))
        if (user) {
            navigate('/job-post/upload-file')
        }
        else {
            navigate('/job-post')
        }
    }
    return (
        <div>
            <section className="quick-solution-sec ">
                <div className="container">
                    <div className="quick-row-wrap">
                        <h3>{t("quick-solution-sec-heading")}</h3>
                        <p>
                            {t("quick-solution-sec-paragraph")}
                        </p>
                        <form action="upload.php" method="POST">
                            <DragDropUploadButton btnCss={"upload-f"} onChange={uploadJobFiles} multiple={false} btnName={t("quick-solution-sec-upload")} />
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TranslationSolution