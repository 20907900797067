import React from 'react'
import AuthHeader from '../components/common/headers/AuthHeader'
import AuthFooter from '../components/common/footers/AuthFooter'


const AuthLayout = ({ children }) => {
    return (
        <div>
            <AuthHeader />
            <div>
                {children}
            </div>
            <div>
                <AuthFooter />
            </div>
        </div>
    )
}

export default AuthLayout