import React from "react";
import ProofReaderEditProfile from "./ProofReaderEditProfile";
import LeftSide from "./LeftSide";
import { Route, Routes } from "react-router-dom";
import ChangePassProofReader from "./ChangePassProofReader";
import AddQualificationsProofreader from "./AddQualificationsProofreader";

const SettingsProofreader = () => {
  return (
    <>
      <section className="user-dashbord sec_pad">
        <div className="container">
          <div className="row">
            <LeftSide />
            <Routes>
              <Route
                path="/edit-profile"
                element={<ProofReaderEditProfile />}
              ></Route>
              <Route
                path="/change-password"
                element={<ChangePassProofReader />}
              ></Route>
              <Route
                path="/add-qualifications"
                element={<AddQualificationsProofreader />}
              ></Route>
            </Routes>
          </div>
        </div>
      </section>
    </>
  );
};

export default SettingsProofreader;
