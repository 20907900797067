import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "../../constants/config";
import { updateJobDeadLine } from "../../redux/reducers/jobSlice";
import {
  addMessages,
  createChatRooms,
  setMessages,
  setTyping,
  setUpdateMsg,
  updateChatRoom,
} from "../../redux/reducers/chatRoomSlice";
import { getAllNotifications } from "../api/profileApi";
import { updateNotification, updateNotificationSocket } from "../../redux/reducers/authSlice";

// get timer data

export default function SocketEvents({ io }) {
  const dispatch = useDispatch();
  const getChatRoom = (data) => {
    dispatch(createChatRooms({ chatRoom: data }));
  };
  const messageReceived = (receiveData) => {
    dispatch(addMessages({ message: receiveData }));
  };
  const chatRoomsReceived = (chatRoomData) => {
    dispatch(updateChatRoom({ updateRoom: chatRoomData }));
  };
  const getAllChatroomMessages = (data) => {
    dispatch(setMessages({ messages: data }));
  };
  const userTyping = (data) => {
    dispatch(setTyping(data));
    setTimeout(() => dispatch(setTyping({})), 2000);
  };
  const msgSeen = (data) => {
    dispatch(setUpdateMsg({ message: data }));
  };
  const receiveNotification = async () => {
    let notifis = await getAllNotifications({ dataPerPage: 10, pageNumber: 1 });
    if (notifis?.result?.data) {
      dispatch(updateNotificationSocket({ notifications: notifis?.result?.data }));
    }
  };
  const receiveNotificationChat = async () => {
    let notifis = await getAllNotifications({ dataPerPage: 10, pageNumber: 1 });
    if (notifis?.result?.data) {
      dispatch(updateNotificationSocket({ notifications: notifis?.result?.data }));
    }
  };
  useEffect(() => {
    io.on("get-all-chatrooms", getChatRoom);
    io.on("message-recieved", messageReceived);
    io.on("chatrooms-recieved", chatRoomsReceived);
    io.on("get-all-chatroom-messages", getAllChatroomMessages);
    io.on("typing-user", userTyping);
    io.on("seen-message", msgSeen);
    io.on("created-notification", receiveNotification);
    io.on("created-chat-notification", receiveNotificationChat);
  }, [io]);
}
