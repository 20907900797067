import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import { withDrawMoneyAlawnehDataApi, withDrawMoneyCLIQDataApi, withDrawMoneyDataApiTranslator, withDrawMoneyNotJordanDataApi } from '../../../services/api/withDrawApis';
import { updateUser } from '../../../redux/reducers/authSlice';
import { withDrawMoneyFormikTranslatorValidator } from '../../../services/formValidator/withdrawFormValidator';
import { countryCode } from '../../../constants/config'
import Select from "react-select";
import countryList from 'react-select-country-list'
const WithDrawModelTranslator = ({ showWithDrawModel, setShowWithDrawModel }) => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const [checkBox, setCheckBox] = useState(false)
    const [selectedDialCode, setSelectedDialCode] = useState(null);
    const [selectCountry, setSelectCountry] = useState({
        countryVal: null,

    })
    const withDrawMoneyFormikTranslator = useFormik({
        initialValues: {
            name: user?.withdraw.name ? user?.withdraw.name : user?.name,
            phoneNumber: user?.withdraw.phoneNumber ? user?.withdraw.phoneNumber : user?.phoneNumber,
            country: user?.withdraw.country ? user?.withdraw.country : user?.country,



        },
        validationSchema: withDrawMoneyFormikTranslatorValidator.validationSchema,
        onSubmit: async (values) => {
            let data = values;
            let withDrawMoneyData = await withDrawMoneyDataApiTranslator(data);
            if (withDrawMoneyData?.serverResponse?.code === 200) {
                dispatch(updateUser({ user: withDrawMoneyData?.result?.data }))
                setShowWithDrawModel(false)

            }


        },
    });
    const countrySelectHandler = (countryVal) => {
        withDrawMoneyFormikTranslator.setFieldValue("country", countryVal?.label)
        setSelectCountry({ countryVal });
        countryCode.forEach((item) => {
            if (item.name === countryVal?.label) {
                setSelectedDialCode(item.dial_code);
                withDrawMoneyFormikTranslator.setFieldValue("countryCode", item.dial_code)

            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        withDrawMoneyFormikTranslator.handleSubmit()
    }

    const handleInputChangeForPhone = (e) => {
        e.target.value = e.target.value.replace(/\D/g, ''); // Filter out non-digit characters
        withDrawMoneyFormikTranslator.handleChange(e); // Call Formik's handleChange with the modified event
    };

    useEffect(() => {
        const currentCountry = countryList().getData().filter(option =>
            option.label === withDrawMoneyFormikTranslator.values.country)
        countrySelectHandler(currentCountry[0]);
    }, [])

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showWithDrawModel}
            onHide={() => setShowWithDrawModel(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Withdraw Money
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>



                <form>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={withDrawMoneyFormikTranslator.handleChange}
                            value={withDrawMoneyFormikTranslator.values.name}
                        />
                        <p className="form-error-message">
                            {withDrawMoneyFormikTranslator.touched.name &&
                                withDrawMoneyFormikTranslator.errors.name}
                        </p>
                    </div>
                    <div className="form-group">
                        <label> Country</label>
                        <div
                            className="form-control select-country"
                        >
                            <Select
                                id='country' name='country' onChange={countrySelectHandler} value={
                                    countryList().getData().filter(option =>
                                        option.label === withDrawMoneyFormikTranslator.values.country)
                                }
                                isSearchable={true}
                                options={countryList().getData()}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: "none",
                                    }),
                                }}

                            />
                            <p className="form-error-message">
                                {withDrawMoneyFormikTranslator.touched.country &&
                                    withDrawMoneyFormikTranslator.errors.country}
                            </p>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group with-selectbox">
                            <label> Phone Number</label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                onChange={handleInputChangeForPhone}

                                value={withDrawMoneyFormikTranslator.values.phoneNumber}
                            />
                            <select className="form-select" disabled>
                                <option>{selectedDialCode}</option>

                            </select>
                            <p className="form-error-message">
                                {withDrawMoneyFormikTranslator.touched.phoneNumber &&
                                    withDrawMoneyFormikTranslator.errors.phoneNumber}
                            </p>
                        </div>
                    </div>

                    <div className="msg-txt">
                        <input type='checkbox' onChange={(e) => setCheckBox(e.target.checked)} style={{ marginRight: "10px" }} />
                        <span style={{ backgroundColor: "red" }}>
                            Please confirm that your full legal name, country and phone number are correct
                        </span>
                    </div>
                </form>



            </Modal.Body>
            <Modal.Footer>
                <button className="btn w-100"
                    onClick={(e) => handleSubmit(e)}
                    disabled={!checkBox}
                >
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default WithDrawModelTranslator;
