import { memo, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { v4 } from "uuid";
import Protected from "./ProtectedRoute";
import { useSelector } from "react-redux";
import Auth from "./AuthRoute";

const RouteIdentifier = ({ routes }) => {
    const { user } = useSelector((state) => state.auth)
    const fallback = <div className="loading" />
    return (
        <Suspense fallback={fallback}>
            <Routes>
                {routes.map((route, index) => {
                    switch (route.type) {
                        case "auth":
                            return (
                                <Route key={`r.${index}-${v4()}`} element={<Auth route={route} user={user} />} >
                                    <Route path={route.path} element={route.element} />
                                </Route>
                            )
                        case "protected":
                            return (
                                <Route key={`r.${index}-${v4()}`} element={<Protected route={route} user={user} />} >
                                    <Route path={route.path} element={route.element} />
                                </Route>
                            )

                        default:
                            return (<Route
                                key={`${index}-${v4()}`}
                                path={route.path}
                                element={route.element}
                            />);
                    }
                }
                )}
            </Routes>
        </Suspense>
    )
};

export default memo(RouteIdentifier);
