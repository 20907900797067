import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'
import { setSelectedReceiptDetails } from '../../../redux/reducers/jobSlice'
import { getSingleReceiptCustomer } from '../../../services/api/transactionsApis'


const ReceiptDetailsCustomer = () => {
    const { selectedReceiptDetails } = useSelector((state) => state.job)
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        (async () => {
            if (!selectedReceiptDetails && location?.state?.id) {
                dispatch(startLoading())
                let { data } = await getSingleReceiptCustomer(location?.state?.id)
                if (data) {
                    dispatch(setSelectedReceiptDetails({ selectedReceipt: data }))
                }
                dispatch(stopLoading())
            }
        })()
        return () => {
            dispatch(setSelectedReceiptDetails({ selectedReceipt: null }))
        }
    }, [])
    return (
        <div className="user-dashbord">
            <div className="user-dtls-jb-pst-page common-padd">
                <div className="container">
                    <div className="user-dtls-jb-pst-wraper">
                        <div className="user-dtls-jb-pst-top">
                            <h4>
                                {/* {selectedReceiptDetails?.jobId?.jobTitle} */}

                            </h4>
                            <ul className="tags-sec">
                                <li>
                                    <span>
                                        <img src="/images/icon51.svg" />
                                    </span>{" "}
                                    Translation
                                </li>


                            </ul>
                            <h5>Job Description:</h5>
                            {/* <p>
                                {selectedReceiptDetails?.jobId?.jobTitle}
                            </p> */}
                        </div>
                        <div className="user-dtls-jb-pst-mdl common-btm-mrgn">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Total:</h6>
                                                <p>{selectedReceiptDetails?.total.toFixed(2)}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Subtotal:</h6>
                                                <p>{selectedReceiptDetails?.subtotal?.toFixed(2)}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Word count:</h6>
                                                <p>{selectedReceiptDetails?.wordCount}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="sngl-box">
                                                <h6>Currency(type):</h6>
                                                <p>{selectedReceiptDetails?.currency}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`download-job`}>
                            <div className="user-dtls-jb-pst-end col-lg-5">
                                <h5>Receipt File:</h5>
                                <div className="row">
                                    <div className="">
                                        <div className="download-file-box">
                                            <a href={selectedReceiptDetails?.recieptLink} download>
                                                <span>
                                                    <img src="/images/icon55.svg" alt="" />
                                                </span>
                                                {selectedReceiptDetails?.jobId?.jobTitle && selectedReceiptDetails?.jobId?.jobTitle}'s Receipt
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default ReceiptDetailsCustomer