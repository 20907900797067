import React from 'react'
import { emailOtpVerification } from '../../../services/formValidator/authValidator'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { resendOtp, verifyEmail } from '../../../services/api/authApis'
import { useNavigate, useLocation } from 'react-router'


const VerifyEmail = () => {
  const location = useLocation();
  const business = location.state?.business
 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const emailOtpFormik = useFormik({
    initialValues: emailOtpVerification.initialValues,
    validationSchema: emailOtpVerification.validationSchema,
    onSubmit: (values) => {
      let data = {
        email: user.email,
        otp: values.otp
      }
  
      verifyEmail(data, dispatch, navigate, user.userType)
    }
  })
  return (
    <>
      <section className="user-dashbord sec_pad">
        <div className="container">
          <div className="signin-form-wrap">
            <form onSubmit={emailOtpFormik.handleSubmit}>
              <div className="form-group">
                <label>Verify Email</label>
                <input type="text" placeholder="Enter OTP" id='otp' name='otp' maxLength={6} value={emailOtpFormik.values.otp} onChange={emailOtpFormik.handleChange} className="form-control" />
                <p className='form-error-message'>{emailOtpFormik.touched.otp &&
                  emailOtpFormik.errors.otp}</p>
              </div>
              <div className="form-group">
                <button type="submit" className="btn w-100">
                  Verify OTP
                </button>
              </div>
              <div className='resend-email-otp' onClick={() => resendOtp(user.email)}>Resend OTP?</div>
            </form>
          </div>
        </div>
      </section>
    </>

  )
}

export default VerifyEmail