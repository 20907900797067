import { toast } from "react-toastify";
import catchAsync from "../../utils/catchAsync";
import http from "./http";
import { logOutUser, updateUser } from "../../redux/reducers/authSlice";
import { clearjobPost } from "../../redux/reducers/jobPostSlice";
import { navigateTo } from "../appNavigator";
import { clearJobs } from "../../redux/reducers/jobSlice";
import { io } from "../../constants/config";
import { clearChat } from "../../redux/reducers/chatRoomSlice";




export const refreshLoggedInUser = catchAsync(async (refreshToken) => {
  return http
    .post("/auth/token/refresh", refreshToken)
    .then((tokenData) => {
      const { serverResponse } = tokenData;
      if (serverResponse && serverResponse.code === 200) {
        setSession({
          accessToken: serverResponse?.result?.data?.access.token,
          refreshToken: serverResponse?.result?.data?.refresh.token,
        });
        return serverResponse?.result?.data;
      }
    })
    .catch((error) => {
      userLogout();
      window.location.reload();
      return false;
    });
});

/**
 * *Set Session Fot User
 * @param {object} tokens
 */
export const setSession = (tokens) => {
  if (tokens) {
    const { accessToken, refreshToken } = tokens;
    if (accessToken && refreshToken) {
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
    }
  } else {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("_e");
  }
};

/**
 * *User login API.
 * @function ({{email: string, password: string}} values)
 * @returns {object}
 */
export const userLogin = catchAsync(async (values) => {
  const data = await http.post("/auth/login", values);

  if (data?.result?.data?.role === "admin") {
    toast.warning("Please login to admin portal for admin login");
  } else {
    if (data && data?.serverResponse.code === 200) {
      setSession(data.result.tokens);
    }
    return data;
  }
});

/**
 * *ProofReader Registration API.
 * @body {
  "name": "string",
  "email": "string",
  "password": "string",
  "countryCode": "string",
  "phoneNumber": "string"
}
 */
export const proofReaderSignUp = catchAsync(async (values) => {
  const data = await http.post("/proofreaders/register", values);
  return data;
});

/**
 * *Business user Registration API.
 * @body {
  "email": "string",
  "password": "string",
}
 */
export const businessSignUp = catchAsync(async (values) => {
  const data = await http.post("/company-users/register", values);
  console.log(businessSignUp);
  return data;
});

/**
 * *ProofReader Registration API.
 * @body {
  "name": "string",
  "email": "string",
  "password": "string",
  "countryCode": "string",
  "phoneNumber": "string"
}
 */
export const translatorSignUp = catchAsync(async (values) => {
  const data = await http.post("/translators/register", values);
  return data;
});
/**
 * *User Registration API.
 * @body {
  "name": "string",
  "email": "string",
  "password": "string",
  "countryCode": "string",
  "phoneNumber": "string"
}
 */
export const userSignUp = catchAsync(async (values, findCode) => {
  // const urlParams = new URLSearchParams(window.location.search);
  // const myParam = urlParams.get("code");
  let myParam;
  if (findCode) {
    myParam = findCode;
  }
  if (myParam === "businessUser") {
    const data = http.post("/company-users/guest/create", values);
    return data
  } else {

    const data = myParam
      ? await http.post(`/users/affiliate/register/${myParam}`, values)
      : await http.post("/users/register", values);
    return data;
  }
});

// user logout functio
export const userLogout = (dispatch) => {
  clearStorage();
  setSession(null);
  dispatch(clearjobPost());
  dispatch(logOutUser());
  dispatch(clearJobs());
  dispatch(clearChat());
  io && io.disconnect();
  io && io.connect();
};
/**
 * Clear local storage
 */
const clearStorage = () => {
  localStorage.removeItem("persist:lingvara");
  localStorage.removeItem("reDirect_url");
};

// Signup function call
export const signUpFormCall = async (
  values,
  navigate,
  slectedUserRole,
  signUpFormik,
  dispatch,
  findCode
) => {
  let data = {
    name: values.name,
    email: values.email,
    password: values.password,
    countryCode: values.countryCode,
    phoneNumber: values.phoneNumber,
    userType: values.userType,
    country: values.country,
  };

  if (slectedUserRole === 0) {
    let userSignUpResult = await userSignUp(data, findCode);
    if (userSignUpResult?.serverResponse?.code === 201) {
      setSession(userSignUpResult.result.tokens);
      signUpFormik.resetForm();
      dispatch(updateUser({ user: userSignUpResult.result.data }));
      sessionStorage.removeItem("userType");
      navigate("/verify-email");
    } else {
      toast.error(userSignUpResult?.serverResponse?.message);
    }
  } else if (slectedUserRole === 1) {
    let translatorSignupResult = await translatorSignUp(data);
    if (translatorSignupResult?.serverResponse.code === 201) {
      setSession(translatorSignupResult.result.tokens);
      signUpFormik.resetForm();
      dispatch(updateUser({ user: translatorSignupResult.result.data }));
      navigate("/verify-email");
    } else {
      toast.error(translatorSignupResult?.serverResponse.message);
    }
  } else if (slectedUserRole === 2) {
    let proofreaderSignupResult = await proofReaderSignUp(data);
    if (proofreaderSignupResult?.serverResponse.code === 201) {
      setSession(proofreaderSignupResult.result.tokens);
      signUpFormik.resetForm();
      dispatch(updateUser({ user: proofreaderSignupResult.result.data }));
      navigate("/verify-email");
    } else {
      toast.error(proofreaderSignupResult?.serverResponse.message);
    }
  } else if (slectedUserRole === 3) {
    let businessSignupResult = await businessSignUp(data);
    if (businessSignupResult?.serverResponse.code === 201) {
      setSession(businessSignupResult.result.tokens);
      signUpFormik.resetForm();
      dispatch(updateUser({ user: businessSignupResult.result.data }));
      navigate("/verify-email");
    } else {
      toast.error(businessSignupResult?.serverResponse.message);
    }
  }
};
/**
 * Email verification
 */
export const verifyEmail = async (values, dispatch, navigate) => {
  let verifyResult = await http.post("/profile/verify/otp", values);

  // Check if the current URL contains the "business" parameter
  if (verifyResult?.serverResponse.code === 200) {
    dispatch(updateUser({ user: verifyResult.result.data }));
    navigateTo(verifyResult.result.data.role, navigate);
  } else {
    toast.error(verifyResult?.serverResponse.message);
  }
};
/**
 * Email verification otp resend
 */
export const resendOtp = async (email) => {
  let otpResendData = await http.post("/profile/resend/otp", { email: email });
  if (otpResendData?.serverResponse.code === 200) {
    toast.success(otpResendData?.serverResponse.message);
  } else {
    toast.error(otpResendData?.serverResponse.message);
  }
};

export const navigateToEditProfile = (role, navigate) => {

  if (role === "proofreader") {
    navigate("/proofreader-settings/edit-profile");
  }
  if (role === "translator") {
    navigate("/translator-settings/edit-profile");
  }
  if (role === "user") {
    navigate("/user-settings/edit-profile");
  }
  if (role === "company") {
    navigate("/user-settings/edit-profile");
  }
  if (role === "project-manager-for-translator") {
    navigate("/translator-manager-settings/edit-profile");
  }
  if (role === "project-manager-for-proofreader") {
    navigate("/proofreader-manager-settings/edit-profile");
  }
};

/**
 * Forgot passeord get otp via email address
 * @body {email:string}
 */
export const forgotPassword = async (values) => {
  let otpResendData = await http.post("/profile/forget/password", values);
  if (otpResendData?.serverResponse.code === 200) {
    return otpResendData;
  } else {
    toast.error(otpResendData?.serverResponse.message);
  }
};

/**
 * Reset password api call
 * @body {"email": "string",
        "passwird": "string",
        "otp": "string"
    }
 */

export const resetPassword = async (password, otp, email) => {
  let bodyData = {
    email: email,
    password: password,
    otp: otp,
  };
  let resetPassResult = await http.post("/profile/reset/password", bodyData);
  if (resetPassResult?.serverResponse.code === 200) {
    toast.success(resetPassResult?.serverResponse.message);
    return resetPassResult;
  } else {
    toast.error(resetPassResult?.serverResponse.message);
  }
};

/**
 * User login/signup through social login
 */
export const socialSignUp = async (values) => {
  let socialSignUpResult = await http.post("/auth/social/signup", values);
  if (socialSignUpResult?.serverResponse.code === 200) {
    setSession(socialSignUpResult?.result.tokens);
    return socialSignUpResult;
  } else {
    toast.error(socialSignUpResult?.serverResponse.message);
  }
};
export const socialSignIn = async (values) => {
  let socialLoginResult = await http.post("/auth/social/login", values);
  if (socialLoginResult?.serverResponse.code === 200) {
    setSession(socialLoginResult?.result.tokens);
    return socialLoginResult;
  } else {
    toast.error(socialLoginResult?.serverResponse.message);
  }
};

export const webMaintainApi = async () => {
  let webMaintain = await http.get("/auth/website-maintenance");
  if (webMaintain?.serverResponse?.code === 200) {
    return webMaintain?.result?.data;
  }
};
export const getDashboardData = async () => {
  let dashBoardData = await http.get("/auth/website-data");
  if (dashBoardData?.serverResponse?.code === 200) {
    return dashBoardData?.result?.data;
  }
};
export const gettReviewListApis = async () => {
  let reviewData = await http.get("/review-rating/list");
  if (reviewData?.serverResponse?.code === 200) {
    return reviewData?.result?.data;
  }
};

/**
 * Delete Account
 * @body {email:string}
 */
export const deleteAccount = async (userID) => {
  let deleteAccountData = await http.post(`/users/delete/${userID}`);
  if (deleteAccountData?.serverResponse.code === 200) {
    return deleteAccountData;
  } else {
    toast.error(deleteAccountData?.serverResponse.message);
  }
};