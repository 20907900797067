import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Privacy Policy</h1>
                                    {/* <p><strong>Privacy Policy</strong></p> */}
                                    <p>This Privacy Policy describes how Lingvara ("we," "us," or "our") collects, uses, and protects the personal information you ("user" or "you") provide on our website. We are committed to safeguarding your privacy and ensuring the security of your personal information. By using our website, you consent to the practices described in this Privacy Policy.</p>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec common-btm-padd">

                <div className="container">

                    <p className='static-text' ><strong>1. Information We Collect</strong></p>
                    <p>a. Personal Information: We may collect personal information when you create an account, upload documents, or use our translation and proofreading services. This information may include your name, email address, contact details, payment information, and any other information you provide to us.</p>
                    <p>b. Usage Information: We collect non-personal information about your interactions with our website and services. This may include your IP address, browser type, device information, operating system, browsing activities, and website usage statistics. We collect this information using cookies, web beacons, and similar technologies.</p>
                    <p>c. Third-Party Information: If you choose to use third-party services, such as social media platforms, to interact with our website or services, we may collect information from those services in accordance with their privacy policies and your privacy settings.</p>
                    <p><strong>2. Use of Collected Information</strong></p>
                    <p>a. Providing Services: We use the personal information you provide to process your translation and proofreading requests, communicate with you, deliver the services you requested, and provide customer support.</p>
                    <p>b. Communication: We may use your email address to send you updates, newsletters, promotional materials, and other communications related to our services. You can opt out of receiving these communications at any time by following the instructions provided in the email or by contacting us directly.</p>
                    <p>c. Website Improvement: We may use aggregated usage information to analyze trends, administer our website, improve our services, and gather demographic information for internal purposes. This helps us enhance the user experience and tailor our services to better meet your needs.</p>
                    <p>d. Legal Compliance: We may disclose your personal information if required by law, court order, or government regulation, or if we believe it is necessary to protect our rights, property, or safety, or the rights, property, or safety of others.</p>
                    <p><strong>3. Information Sharing and Disclosure</strong></p>
                    <p>a. Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you. These service providers are contractually obligated to keep your information confidential and use it only for the purposes of providing their services.</p>
                    <p>b. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity or third party involved. We will ensure that the recipient of your information agrees to safeguard it in a manner consistent with this Privacy Policy.</p>
                    <p>c. Legal Obligations: We may disclose your personal information if required to comply with applicable laws, regulations, legal processes, or enforceable governmental requests.</p>
                    <p><strong>4. Data Security</strong></p>
                    <p>a. We take reasonable and appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We implement industry-standard security measures to maintain the security and confidentiality of your information.</p>
                    <p>b. However, please note that no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                    <p><strong>5. Third-Party Links and Services</strong></p>
                    <p>a. Our website may contain links to third-party websites or services. These links are provided for your convenience and information. We are not responsible for the privacy practices or the content of these third-party websites. We recommend reviewing the privacy policies ofany third-party websites you visit.</p>
                    <p>b. We may also integrate third-party services or features into our website, such as social media plugins. These third-party services may collect information about your interactions with them, subject to their own privacy policies. We encourage you to review the privacy policies of these third-party services to understand how they collect, use, and share your information.</p>
                    <p><strong>6. Children's Privacy</strong></p>
                    <p>a. Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us immediately. We will take steps to remove that information from our records.</p>
                    <p><strong>7. International Data Transfers</strong></p>
                    <p>a. If you are accessing our website or using our services from outside the country where our servers are located, your information may be transferred to and processed in countries with different data protection laws. By using our website or services, you consent to the transfer of your information to countries outside your country of residence.</p>
                    <p><strong>8. Your Rights and Choices</strong></p>
                    <p>a. You have the right to access, correct, update, and delete your personal information. You can do this by logging into your account and Goto
                        <span style={{
                            fontWeight: "bold",
                            color: "#007bff",
                            textDecoration: "underline"
                        }}>{">"} Settings {">"} Delete my account</span> or by contacting us directly. We will respond to your request in accordance with applicable data protection laws.</p>
                    <p>b. You may also have the right to object to or restrict certain processing of your personal information or to request the portability of your information to another service provider.</p>
                    <p><strong>9. Changes to this Privacy Policy</strong></p>
                    <p>a. We reserve the right to update or modify this Privacy Policy at any time. Any changes we make will be effective immediately upon posting the revised Privacy Policy on our website. We will indicate the date of the last update at the top of the Privacy Policy. We encourage you to review this Privacy Policy periodically for any changes.</p>
                    <p><strong>10. Contact Us</strong></p>
                    <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the privacy practices of Lingvara, please contact us using the contact information provided on our website.</p>
                    <p><a name="_GoBack"></a></p>

                </div>

            </section >


        </>


    )
}

export default PrivacyPolicy