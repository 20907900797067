import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { createPayment } from '../../../services/api/jobPostApis'
import { useDispatch } from 'react-redux'

const SelectCurrenyModal = ({ handlePost, showModal, setShowModal, totalAmount, jobId }) => {
  const [cartCurrency, setCartCurrency] = useState()
  const [finalAmount, setFinalAmount] = useState(totalAmount)

  const changeAmount = (currency) => {
      
      setCartCurrency( currency )
      const jodValue = totalAmount * 0.7;
     
      const amount = currency == 'USD' ? totalAmount : jodValue.toFixed(2);
      setFinalAmount(amount);
  }
  const payForJob = async () => {
    let bodyData = {
        cartAmount: finalAmount,
        cartCurrency: cartCurrency,
        returnUrl: `${window.location.origin}`
    }
    
    let payment = await createPayment(bodyData)
    if (payment) {
        sessionStorage.setItem('transRef', payment?.result?.data?.tran_ref)
        sessionStorage.setItem('jobId', jobId)
        window.location.href = payment?.result?.data?.redirect_url;
    }
}
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Amount to pay
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="form-group">
            <div className={`currency-toggle`}>
              <label>
                USD
                <input type='radio' id="USD" name="currency" value={"USD"} checked={cartCurrency === 'USD'} onChange={(e) => { changeAmount( e.target.value ) }} />
              </label>
              <label>
                JOD
                <input type='radio' id="JOD" name='currency' value={"JOD"} checked={cartCurrency === 'JOD'} onChange={(e) => { changeAmount( e.target.value ) }} />
              </label>
            </div>
            <div className="tab-content">
              <div id="home" className={`tab-pane ${cartCurrency === "USD" && "active"}`}>
                <div className="row">

                  <div className="col-lg-12 mb-4 col-md-12">
                    <div className="tab-left-content">
                      <div className="tab-box-wrap">
                        ${totalAmount.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* tab1 */}
              <div id="home" className={`tab-pane ${cartCurrency === "JOD" && "active"}`}>
                <div className="row">

                  <div className="col-lg-12 mb-4 col-md-12">
                    <div className="tab-left-content">
                      <div className="tab-box-wrap">
                        {(totalAmount * 0.7).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* tab2 */}
            </div>

          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => payForJob()}>Pay</Button>
      </Modal.Footer>
    </Modal>

  )
}

export default SelectCurrenyModal