import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  assignUser,
  getAllJobs,
  getSingleJob,
  getUserList,
  unAssignUser,
} from "../../../services/api/jobApis";
import { setAssignUserList, setJobs, setSelectedJobDetails } from "../../../redux/reducers/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from 'lodash';
import { useLocation, useNavigate } from "react-router-dom";
import { chatAccess } from "../../../services/api/chatRoomApis";
import { getChatRoomMessage, joinChat } from "../../../services/socket";
import { setActiveroom } from "../../../redux/reducers/chatRoomSlice";

const ChatWithServiceProvider = ({ setShowManagers, showManagers, role }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate()
  const [userGetPage, setUserGetPage] = useState(0);
  const [userSearchinput, setUserSearchinput] = useState("");
  const { currentPage, assignUserList, selectedJobDetails } = useSelector((state) => state.job);
  const { user } = useSelector(state => state.auth)

  useEffect(() => {
    (async () => {
      let userList = await getUserList();
      dispatch(setAssignUserList({ users: userList?.data }));
      setUserGetPage(userList?.pageNumber);
    })();
    return () => {
      dispatch(setAssignUserList({ users: null }));
      setUserGetPage(0);
    };
  }, []);
  const handleSearch = debounce(async (input) => {
    setUserGetPage(0);
    setUserSearchinput(input.target.value);
    let userList = await getUserList(input.target.value);
    dispatch(setAssignUserList({ users: null }));
    dispatch(setAssignUserList({ users: userList?.data }));
  }, 500);

  const loadMoreData = async (page) => {
    let userList = await getUserList(userSearchinput, page);
    dispatch(setAssignUserList({ users: userList?.data }));
    setUserGetPage(userList?.pageNumber);
  };

  const gotoChat = async (id) => {
    let accessData = await chatAccess(id)
    if (accessData?.serverResponse?.code === 200) {
      joinChat([accessData?.result[0]?._id, id, user?._id])
      dispatch(setActiveroom({ activeRoom: accessData?.result[0] })) // will put this to socket event when roomid and userid will be send in an object
      getChatRoomMessage({ userId: user?._id, chatRoomId: accessData?.result[0]?._id, pageNumber: 1, dataPerPage: 10 })
      navigate(`/${role?.toLowerCase()}-manager-chat`)
      setShowManagers(false)
    }
  }
  return (
    <Modal
      className="modal order-d-popup fade"
      show={showManagers}
      onHide={() => setShowManagers(false)}
      centered
    >
      <ModalHeader closeButton>
        <div className="assign-modal">{role} Users</div>
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <input
            type="text"
            placeholder={`Search ${role} Users`}
            className="form-control assign-user-search"
            id="name"
            name="name"
            onChange={handleSearch}
          />
        </div>
        <div className="list-group assign-job-modal-body">
          {assignUserList && (
            <InfiniteScroll
              dataLength={assignUserList.length} //This is important field to render the next data
              next={() => loadMoreData(userGetPage + 1)}
              hasMore={true}
              height={500}
            >
              {assignUserList?.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="list-group-item list-group-item-action"
                    onClick={() => gotoChat(item?._id)}
                  >
                    {item?.name} ({item?.averageRating} Star)
                  </p>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChatWithServiceProvider;
