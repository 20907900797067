import React from 'react'
import ForgotPass from './ForgotPass'


const ForgotPassword = () => {
    return (
        <section className="user-dashbord sec_pad">

            <div className="container">
                <div className="signin-form-wrap">
                    <ForgotPass />
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword