import React, { useState, useEffect } from "react";
import DragDropUploadButton from "../../../components/common/DragDropUploadButton";
import { useDispatch, useSelector } from "react-redux";
import { createACompany } from "../../../redux/reducers/companySlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cropper from "../../../components/common/cropper/Cropper";
import { useNavigate } from 'react-router-dom'
import { createACompanySchema } from "../../../services/formValidator/companyValidator";
import { updateCompany } from "../../../redux/reducers/authSlice";
import { updateUser } from "../../../redux/reducers/authSlice";
import { countryCode } from "../../../constants/config";

export default function Company() {
  const dispatch = useDispatch();
  const { user, company } = useSelector((state) => state.auth);
  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [document, setDocument] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [showCropModal, setShowCropModal] = useState(false);
  const navigate = useNavigate()

  const initialValues = {
    name: "",
    authorizedSignator: "",
    phoneNumber: "",
    proofreaderRate: "",
    translationRate: "",
    laison: "",
    companyCode: "",
    address: "",
    agreement: null,
    logo: null,
  };

  const addCompanyFormik = useFormik({
    initialValues,
    validationSchema: createACompanySchema,
    onSubmit: async (values, action) => {
      console.log(values)
      const formData = new FormData();
      formData.append("guest", "guest");
      formData.append("name", values.name);
      formData.append("authorizedSignator", values.authorizedSignator);
      formData.append("countryCode", values.countryCode);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("laison", values.laison);
      formData.append("address", values.address);
      formData.append("agreement", values.agreement);
      formData.append("logo", values.logo);
      formData.append("user", user._id);

      const result = await dispatch(createACompany(formData));

      if (result.payload?.serverResponse.code === 201) {
        action.resetForm();
        toast.success(result.payload.serverResponse.message);
        dispatch(updateUser({user: result.payload?.result.user}))
        dispatch(updateCompany({company: result.payload?.result.company}))
        navigate('/business-dashboard');

      }
    },
  });

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    if (file) {
      const allowedFormats = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/webp",
      ];
      const isValidFormat = allowedFormats.includes(file.type);

      if (isValidFormat) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setLogoFile(file);
          setLogo(reader.result);
        };
        reader.readAsDataURL(file);
        setShowCropModal(true);
      } else {
        alert('Invalid file format. Please upload a .png, .jpg, .jpeg, or .webp file.');
      }
    }
  };


  const handleDocumentChange = (e) => {
    const file = e.currentTarget.files[0];
    console.log(file)

    if (file) {
      const allowedFormats = [".odt", ".doc", ".docx", ".pdf", ".txt"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isValidFormat = allowedFormats.includes(`.${fileExtension}`);

      if (isValidFormat) {
        addCompanyFormik.setFieldValue("agreement", file);
        setDocument(file);
      } else {
        alert(
          "Invalid file format. Please upload a .odt, .doc, .docx, .pdf, or .txt file."
        );
      }
    }
  };
  useEffect(() => {
    // if (user?.companyId) {
    //   navigate('/business-dashboard');
    // }
  }, []);

  return (
    <section className="user-dashbord sec_pad">
      <div className="tab-pane fade  active show">
        <div className="signin-form-wrap">
          <form onSubmit={addCompanyFormik.handleSubmit}>
            <h4>Company information</h4>

            <div className="row">
              <div className="col-lg-12 d-flex flex-column align-items-center">
                <div className=" col-lg-3 d-flex flex-column align-items-center company-logo">
                  <label
                    htmlFor="logo"
                    className="logo d-flex justify-content-center align-items-center overflow-hidden cursor-pointer bg-blue-gray-200 text-white p-3 mb-2"
                  >
                    {logo ? (
                      <img src={logo} alt="Uploaded" className="img-fluid" />
                    ) : (
                      <FontAwesomeIcon icon="fa-regular fa-image" size="3x" />


                    )}
                  </label>
                  <label htmlFor="name">Company Logo</label>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .webp"
                    name="logo"
                    id="logo"
                    onChange={handleLogoChange}
                    className="d-none"
                    onBlur={addCompanyFormik.handleBlur}
                  />
                  {addCompanyFormik.errors.logo &&
                    addCompanyFormik.touched.logo ? (
                    <p className="form-error-message">{`*${addCompanyFormik.errors.logo}`}</p>
                  ) : null}

                  <Cropper
                    logo={logo}
                    logoFile={logoFile}
                    showCropModal={showCropModal}
                    setShowCropModal={setShowCropModal}
                    addCompanyFormik={addCompanyFormik}
                    setLogo={setLogo} />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="name">Company Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    value={addCompanyFormik.values.name}
                    onChange={addCompanyFormik.handleChange}
                    onBlur={addCompanyFormik.handleBlur}
                    required
                  />
                  {addCompanyFormik.errors.name &&
                    addCompanyFormik.touched.name ? (
                    <p className="form-error-message">{`*${addCompanyFormik.errors.name}`}</p>
                  ) : null}
                </div>{" "}
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    className="form-control"
                    value={addCompanyFormik.values.address}
                    onChange={addCompanyFormik.handleChange}
                    onBlur={addCompanyFormik.handleBlur}
                    required
                  />
                  {addCompanyFormik.errors.address &&
                    addCompanyFormik.touched.address ? (
                    <p className="form-error-message">{`*${addCompanyFormik.errors.address}`}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <label
                  htmlFor="agreement"
                  className="text-center mb-2 block text-sm font-medium"
                >
                  Company Registration Certificate
                </label>
                <label
                  htmlFor="agreement"
                  className="py-2 px-6 block cursor-pointer w-full mb-3"
                >
                  {document ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-8 h-8 text-lingvara me-3 document-file"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1.5}
                          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                        />
                      </svg>
                      <div>
                        <strong className="text-lingvara">
                          {document.name}
                        </strong>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <span className="rounded btn btn-primary">
                        Upload Document
                      </span>
                      <span className="text-sm opacity-70">
                        Document must be .pdf, .doc, .docx, .txt, or .odt
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  name="agreement"
                  id="agreement"
                  className="d-none"
                  accept=".odt, .doc, .docx, .pdf, .txt"
                  onChange={handleDocumentChange}
                  onBlur={addCompanyFormik.handleBlur}
                />
                {addCompanyFormik.errors.agreement &&
                  addCompanyFormik.touched.agreement ? (
                  <p className="form-error-message">{`*${addCompanyFormik.errors.agreement}`}</p>
                ) : null}
              </div>

              <hr />
              <h3>Authorized Signatory Section:</h3>

              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="authorizedSignator">Name of Authorized Company Signator as per above documents</label>
                  <input
                    type="text"
                    name="authorizedSignator"
                    id="authorizedSignator"
                    className="form-control"
                    value={addCompanyFormik.values.authorizedSignator}
                    onChange={addCompanyFormik.handleChange}
                    onBlur={addCompanyFormik.handleBlur}
                    required
                  />
                  {addCompanyFormik.errors.authorizedSignator &&
                    addCompanyFormik.touched.authorizedSignator ? (
                    <p className="form-error-message">{`*${addCompanyFormik.errors.authorizedSignator}`}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="laison">Laison Name</label>
                  <input
                    type="text"
                    name="laison"
                    id="laison"
                    className="form-control"
                    value={addCompanyFormik.values.laison}
                    onChange={addCompanyFormik.handleChange}
                    onBlur={addCompanyFormik.handleBlur}
                    required
                  />
                  {addCompanyFormik.errors.laison &&
                    addCompanyFormik.touched.laison ? (
                    <p className="form-error-message">{`*${addCompanyFormik.errors.laison}`}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6">
              <div class="form-group with-selectbox">
                  <label>Phone</label>
                  <input type="text"
                    placeholder=""
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control"
                    onChange={addCompanyFormik.handleChange}
                    value={addCompanyFormik.values.phoneNumber}
                    onBlur={addCompanyFormik.handleBlur}
                    required />

                  <select className="form-select" onChange={addCompanyFormik.handleChange} value={addCompanyFormik.values.countryCode} name="countryCode">
                    {countryCode.map((country, idx) => {
                      return (
                        <option key={idx} value={country.dial_code}>{country.code}</option>
                      );
                    })}

                  </select>
                  {addCompanyFormik.errors.phoneNumber && addCompanyFormik.touched.phoneNumber ? (
                    <p className="form-error-message">{`*${addCompanyFormik.errors.phoneNumber}`}</p>
                  ) : null}
                </div>
              </div>

              <button
                className="btn w-100"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}







//             <div className="row">
//               <div className="col-lg-6">
//                 <div className="form-group">




//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Company Registration Certificate</label>
//                   <DragDropUploadButton
//                   btnCss={"upload-f"}
//                   multiple={false}
//                   btnName={"Your Certificate"}
//                   onChange={handleDocumentChange}
//                 />
//                 {certificate && <img src={certificate} alt="Certificate Preview" style={{ maxWidth: "100%" }} />}
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Company Registration Number</label>
//                   <input
//                     type="text"
//                     value={registrationNumber}
//                     onChange={(e) => setRegistrationNumber(e.target.value)}
//                     className="form-control"
//                   />
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Company House (For UK Businesses)</label>
//                   <input
//                     type="text"
//                     value={companyHouse}
//                     onChange={(e) => setCompanyHouse(e.target.value)}
//                     className="form-control"
//                   />
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Tax ID (optional)</label>
//                   <input
//                     type="text"
//                     value={authorizedSignator}
//                     onChange={(e) => setauthorizedSignator(e.target.value)}
//                     className="form-control"
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Authorized Signator Name</label>
//                   <input
//                     type="text"
//                     value={authorizedSignator}
//                     onChange={(e) => setAuthorizedSignator(e.target.value)}
//                     className="form-control"
//                   />
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Liaison Name</label>
//                   <input
//                     type="text"
//                     value={liaisonName}
//                     onChange={(e) => setLiaisonName(e.target.value)}
//                     className="form-control"
//                   />
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Liaison Email</label>
//                   <input
//                     type="email"
//                     value={liaisonEmail}
//                     onChange={(e) => setLiaisonEmail(e.target.value)}
//                     className="form-control"
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <label>Liaison Phone Number</label>
//                   <input
//                     type="text"
//                     value={liaisonPhoneNumber}
//                     onChange={(e) => setLiaisonPhoneNumber(e.target.value)}
//                     className="form-control"
//                   />
//                 </div>
//               </div>

//               <div className="col-lg-12">
//                 <div className="form-group">
//                   <button type="submit" className="btn w-100">
//                     CREATE ACCOUNT
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Company;
