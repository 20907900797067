import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import CallToSignup from '../../../components/common/callToSignup/CallToSignup';
import { Link, useNavigate } from 'react-router-dom';
import DragDropUploadButton from '../../../components/common/DragDropUploadButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateBlobFile } from '../../../redux/reducers/jobPostSlice';
import BusinessSolutionSec from '../Home/BusinessSolutionSec';

const ProofReading = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const uploadJobFiles = (data) => {
        dispatch(updateBlobFile({ blobFile: data }))
        if (user) {
            navigate('/job-post/upload-file')
        }
        else {
            navigate('/job-post')
        }
    }

    const staticSlider = [{ src: "/images/img8.jpg", titel: "Academic paper" },
    { src: "/images/img9.jpg", title: "Legal document" },
    { src: "/images/img10.jpg", title: "Short communication" },
    { src: "/images/img10.jpg", title: "Short communication" },

    ]
    const staticCarouselData = [{ headings: "Healthcare & Medicine", subHeadings1: "Medical document translations", subHeadings2: "Patient record proofreading", subHeadings3: "Medical journal article translations", subHeadings4: "Pharmaceutical product description translations" },
    { headings: "Law Firms", subHeadings1: "Contract translations", subHeadings2: "Legal document proofreading", subHeadings3: "Court transcript translations", subHeadings4: "Witness statement translations" },
    { headings: "Technology Sector", subHeadings1: "Software localization", subHeadings2: "Technical manual translations", subHeadings3: "Product description translations", subHeadings4: "User interface translations" },
    { headings: "Education & Academia", subHeadings1: "Academic paper translations", subHeadings2: "Dissertation proofreading", subHeadings3: "Textbook translations", subHeadings4: "Course material translations" },
    { headings: "Tourism & Hospitality", subHeadings1: "Website localization", subHeadings2: "Brochure translations", subHeadings3: "Menu translations", subHeadings4: "Guest communication translations" },
    { headings: "Finance & Banking", subHeadings1: "Financial statement translations", subHeadings2: "Investment portfolio translations", subHeadings4: "Market research report translations" }]
    const options = {
        items: 3,
        dots: false,
        loop: true,
        nav: true,
        margin: 10,
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true
            },
            600: {
                items: 2

            },
            1200: {
                items: 3
            }
        }
    }
    return (
        <>
            {/* <!-- banner section --> */}
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/Proofreading-banner.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Proofreading</h1>
                                    <p>Elevate your written content to a new level of professionalism and accuracy with Lingvara's expert proofreading services.</p>


                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </section>
            {/* banner end */}
            <section className="why-us-sec padding-bottom padding-top">
                <div className="container">
                    <div className="section-heading">
                        <h3>Why choose Lingvara?</h3>
                        <p>We pride ourselves in securing document confidentiality, adhering to strict quality assurance measures, and collaborating with our clients throughout the process.</p>
                    </div>
                    <div className="us-row-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-6" data-aos="fade-right" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/scale.png" alt="" />
                                        <img className="h-img" src="/images/scale1.png" alt="" />

                                    </div>
                                    <h4>Confidentiality</h4>
                                    <p>We prioritize the confidentiality of your documents and adhere to strict security measures to protect your sensitive information.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/icon51.png" alt="" />
                                        <img className="h-img" src="/images/icon5.png" alt="" />

                                    </div>
                                    <h4>Client Collaboration</h4>
                                    <p>We value your input and encourage you to communicate your specific requirements and preferences to ensure the final document aligns with your vision. Your proofreader may contact you to ensure a proper rendering of what you had in mind.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-left" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/value.png" alt="" />
                                        <img className="h-img" src="/images/value1.png" alt="" />

                                    </div>
                                    <h4>Quality Assurance</h4>
                                    <p>Before delivering the proofread document to you, we conduct a final quality check to ensure it meets our high standards of excellence.</p>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- why us end --> */}
            <section className="Services-Include">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="Services-Include-left">
                                <img src="/images/images.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="Services-Include-right">
                                <h3>What Our Proofreading Services Include</h3>
                                <p>At Lingvara, our proofreading service is designed to elevate the quality and professionalism of your written content, making it polished and error-free. Here are some details about what the proofreading service includes:</p>

                                <ul>
                                    <li><strong>Grammar and Syntax Check</strong>: Our experienced proofreaders meticulously review your content to correct grammatical errors, ensuring that every sentence flows smoothly and is free from structural issues.</li>
                                    <li><strong>Spelling and Punctuation</strong>: We pay close attention to spelling and punctuation, fixing any typos or inconsistencies to maintain the highest level of accuracy in your text.</li>
                                    <li><strong>Language and Style Consistency:</strong>: Our proofreaders ensure that your document adheres to a consistent writing style and maintains a uniform tone throughout.
                                    </li>
                                    <li><strong>Clarity and Coherence</strong>: We enhance the clarity and coherence of your text, making it easier for your audience to understand your message and navigate through the content effortlessly.</li>
                                    <li><strong>Vocabulary and Word Choice</strong>: We evaluate your choice of words and suggest alternatives to improve the overall impact of your writing, ensuring it resonates with your target audience.</li>
                                    <li><strong>Formatting and Layout</strong>: Our proofreading service also includes basic formatting checks, ensuring that your document is visually appealing and follows any specified guidelines.
                                    </li>
                                    <li><strong>Citation and References</strong>:  If your document includes references, citations, or a bibliography, we cross-check them to ensure they follow the relevant style guide (e.g., APA, MLA, Chicago) accurately.</li>
                                    <li><strong>Consistent Formatting Style</strong>: We ensure that headings, subheadings, fonts, and indentation are consistent throughout your document.</li>
                                    <li><strong>Attention to Detail</strong>: Our proofreaders have a keen eye for detail and review every element of your document, from headers and footers to page numbers and table of contents.</li>
                                    <li><strong>Customized Feedback</strong>: Along with corrections, we provide constructive feedback and suggestions to help you improve your writing skills and enhance the quality of future documents.</li>
                                </ul>
                                {/* <Link to="/" className="btn">Upload </Link> */}
                                <DragDropUploadButton onChange={uploadJobFiles} btnCss={"upload-f"} multiple={false} btnName={"Upload Document"} />

                            </div>
                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- Services Include --> */}
            {/* <section className="proofreading-services padding-bottom padding-top">
                <div className="container">

                    <div className="section-heading">
                        {/* <h3><span className="g-bg">Industries</span> where we provide proofreading <span className="g-bg">services</span>
                        </h3> */}
                        {/* <h4>Industries we have served</h4>
                    </div> */}

                    {/* <div className="proofreading-slider-wrap"> */}
                        {/* <OwlCarousel className='proofreading-slider owl-carousel owl-theme' items={3} dots={false} loop margin={10} nav> */}

                        {/* <OwlCarousel className='owl-carousel owl-theme proofreading-slider' {...options}> */}
                            {/* {staticSlider?.map((item, index) =>
                                <div key={index} className="item proofreading-slider-item">
                                    <div className="proofreading-slider-img-wrap">
                                        <img src={item?.src} alt="" />
                                    </div>
                                    <div className="proofreading-slider-content" data-aos="fade-up" data-aos-offset="300">
                                        <h4>{item?.title}</h4>
                                    </div>
                                </div>
                            )} */}
                            {/* {staticCarouselData?.map((val, index) => <div key={index} className="item soultion-b-item">
                                <h5>Ecommerce</h5>
                                <img src="/images/img6.png" alt="" />
                                <h6>{val?.headings}</h6>
                                <ul>
                                    <li>{val?.subHeadings1}</li>
                                    <li>{val?.subHeadings2}</li>
                                    <li>{val?.subHeadings3}</li>
                                    <li>{val?.subHeadings4}</li>

                                </ul>
                            </div>)}

                        </OwlCarousel>

                    </div>

                </div> */}

            {/* </section> */} 
            <BusinessSolutionSec/>
            <CallToSignup />

        </>
    )
}

export default ProofReading