import React from "react";
import { Route, Routes } from "react-router-dom";
import AddQualificationTrans from "./AddQualificationTrans";
import ChangePassTranslator from "./ChangePassTranslator";
import LeftSide from "./LeftSide";
import TranslatorEditProfile from "./TranslatorEditProfile";

const SettingsTranslator = () => {
  return (
    <>
      <section className="user-dashbord sec_pad">
        <div className="container">
          <div className="row">
            <LeftSide />
            <Routes>
              <Route
                path="/edit-profile"
                element={<TranslatorEditProfile />}
              ></Route>
              <Route
                path="/change-password"
                element={<ChangePassTranslator />}
              ></Route>
               <Route
                path="/add-qualifications"
                element={<AddQualificationTrans/>}
              ></Route>
            </Routes>
          </div>
        </div>
      </section>
    </>
  );
};

export default SettingsTranslator;
