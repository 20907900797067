import Pricing from "../pages/visitor/Pricing/Pricing";
import ProofReading from "../pages/visitor/ProofReading/ProofReading";
import Subscription from "../pages/visitor/Subscription/Subscription";
import Translation from "../pages/visitor/Translation/Translation";
import AuthLayout from "../layouts/AuthLayout";
import UserLayout from "../layouts/UserLayout";
import VisitorLayout from "../layouts/VisitorLayout";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ForgotPassword/ResetPassword";
import Login from "../pages/auth/Login";
import CustomerDashboard from "../pages/Customer/Dashboard";
import BusinessDashboard from "../pages/Business/Dashboard";
import ProofReaderDashboard from "../pages/ProofReader/Dashboard";
import TranslatorDashboard from "../pages/Translator/Dashboard";
import SignUp from "../pages/auth/SignUp";
import Home from "../pages/visitor/Home/Home";
import VerifyEmail from "../pages/auth/SignUp/VerifyEmail";
import NoContentPage from "../components/common/NoContentPage";
import JobPost from "../pages/Customer/job post/Index";
import ContactUs from "../pages/visitor/Contact/ContactUs";
import Job from "../pages/visitor/Jobs/Job";
import Affilate from "../pages/visitor/Affilate/Affilate";
import CustomerJobList from "../pages/Customer/jobListing/CustomerJobList";
import CustomerJobDetails from "../pages/Customer/jobDetails/CustomerJobDetails";
import SettingsUser from "../pages/Customer/Settings";
import SettingsTranslator from "../pages/Translator/Settings";
import SettingsProofreader from "../pages/ProofReader/Settings";
import TranslatorManagerDashboard from "../pages/TranslatorManager/Dashboard/Dashboard";
import ProofreaderManagerDashboard from "../pages/ProofreaderManager/Dashboard/Dashboard";
import Process from "../pages/visitor/Process";
import TransManagerSettings from "../pages/TranslatorManager/Settings";
import SettingsProofreaderManager from "../pages/ProofreaderManager/Settings";
import JobsList from "../pages/Translator/Jobs/JobsList";
import JobDetails from "../pages/Translator/Jobs/JobDetails";
import MyjobListing from "../pages/Translator/Myjobs/MyjobListing";
import MyJobDetails from "../pages/Translator/Myjobs/MyJobDetail";
import JobsListProofreader from "../pages/ProofReader/Jobs/JobListProofreader";
import JobDetailsProofreader from "../pages/ProofReader/Jobs/JobDetailsProofreader";
import MyjobListingProf from "../pages/ProofReader/Myjobs/MyjobListingProf";
import MyJobDetailsProf from "../pages/ProofReader/Myjobs/MyJobDetailsProf";
import ChatIndex from "../components/common/chatContainer/Index";
import ThankYouPage from "../pages/Customer/job post/ThankYouPage";
import MyjobListingProfManager from "../pages/ProofreaderManager/MyJobs/MyjobListingProfManager";
import MyJobDetailsProfManager from "../pages/ProofreaderManager/MyJobs/MyJobDetailsProfManager";
import MyJobDetailsTransManager from "../pages/TranslatorManager/Myjobs/MyJobDetailTransManager";
import MyjobListingTransManager from "../pages/TranslatorManager/Myjobs/MyjobListingManager";
import TranslatorList from "../pages/TranslatorManager/translatorList/TranslatorList";
import ProofreaderList from "../pages/ProofreaderManager/proofreaderList/ProofreaderList";
import Notification from "../components/common/notification/Notification";
import AboutUs from "../pages/visitor/About/AboutUs";
import Accessibility from "../pages/visitor/Accessibility/Accessibility";
import MoneyBackGurantee from "../pages/visitor/MoneyBackGurantee/MoneyBackGurantee";
import HelpSupport from "../pages/visitor/HelpSupport/HelpSupport";
import PrivacyPolicy from "../pages/visitor/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../pages/visitor/TermsConditions/TermsConditions";
import AffilateTermsConditions from "../pages/visitor/AffilateTermsConditions/AffilateTermsConditions";
import ReceiptDetailsCustomer from "../pages/Customer/ReceiptDetails/ReceiptDetailsCustomer";
import TranscationDetailsProofreader from "../pages/Customer/ReceiptDetails/TranscationDetailsProofreader";
import TranscationDetailsTranslator from "../pages/Customer/ReceiptDetails/TranscationDetailsTranslator";
import CustomerReceiptsList from "../pages/Customer/ReceiptsList/CustomerReceiptsList";
import TranslatorRankingSys from "../pages/visitor/RankingSystem/TranslatorRankingSys";
import WebsiteMaintain from "../components/WebsiteMaintain";
import ProofReaderRankingSys from "../pages/visitor/RankingSystem/ProofReaderRankingSys";
import AddQualification from "../pages/auth/SignUp/AddQualification";
import GeneralFaq from "../pages/visitor/Faq/GeneralFaq";
import ThankYouBusinessPage from "../pages/Customer/job post/ThankYouBusinessPage";
import BusinessTermsConditions from "../pages/visitor/TermsConditions/BusinessTermsConditions";
import Company from "../pages/auth/SignUp/Company";
import EnvironmentalPioneers from "../pages/visitor/EnvironmentalPioneers/EnvironmentalPioneers";
import ManageUsers from "../pages/Business/Users/Manage";
import Download from "../pages/visitor/Certificate/Download";

/**
 * *Import component and create a route by define a path.
 *
 */
const AuthLayoutFunc = (comp) => {
  return <AuthLayout>{comp}</AuthLayout>;
};
const UserLayoutFunc = (comp) => {
  return <UserLayout>{comp}</UserLayout>;
};
const VisitorLayoutFunc = (comp) => {
  return <VisitorLayout>{comp}</VisitorLayout>;
};
const routes = [
  // auth pages routes
  {
    path: "/login",
    element: AuthLayoutFunc(<Login />),
    type: "auth",
    role: [],
  },
  {
    path: "/website-maintain",
    element: <WebsiteMaintain />,
    type: "visitor",
    role: [],
  },
  {
    path: "/signup",
    element: AuthLayoutFunc(<SignUp />),
    type: "auth",
    role: [],
  },
  {
    path: "/forgot-password",
    element: AuthLayoutFunc(<ForgotPassword />),
    type: "auth",
    role: [],
  },
  {
    path: "/reset-password",
    element: AuthLayoutFunc(<ResetPassword />),
    type: "auth",
    role: [],
  },

  {
    path: "/verify-email",
    element: AuthLayoutFunc(<VerifyEmail />),
    type: "protected",
    role: ["user", "proofreader", "translator","company"],
  },
  {
    path: "/add-qualification",
    element: AuthLayoutFunc(<AddQualification />),
    type: "protected",
    role: ["proofreader", "translator"],
  },
  // visitor pages routes
  {
    path: "/",
    element: VisitorLayoutFunc(<Home />),
    type: "visitor",
    role: [],
  },
  {
    path: "/subscription",
    element: VisitorLayoutFunc(<Subscription />),
    type: "visitor",
    role: [],
  },
  {
    path: "/pricing",
    element: VisitorLayoutFunc(<Pricing />),
    type: "visitor",
    role: [],
  },
  {
    path: "/proofReading",
    element: VisitorLayoutFunc(<ProofReading />),
    type: "visitor",
    role: [],
  },
  {
    path: "/jobs",
    element: VisitorLayoutFunc(<Job />),
    type: "visitor",
    role: [],
  },
  {
    path: "/affilate",
    element: VisitorLayoutFunc(<Affilate />),
    type: "visitor",
    role: [],
  },
  {
    path: "/translation",
    element: VisitorLayoutFunc(<Translation />),
    type: "visitor",
    role: [],
  },
  {
    path: "/contact-us",
    element: VisitorLayoutFunc(<ContactUs />),
    type: "visitor",
    role: [],
  },
  {
    path: "/process",
    element: VisitorLayoutFunc(<Process />),
    type: "visitor",
    role: [],
  },
  {
    path: "/about-us",
    element: VisitorLayoutFunc(<AboutUs />),
    type: "visitor",
    role: [],
  },
  {
    path: "/accessibility",
    element: VisitorLayoutFunc(<Accessibility />),
    type: "visitor",
    role: [],
  },
  {
    path: "/money-back-guarantee",
    element: VisitorLayoutFunc(<MoneyBackGurantee />),
    type: "visitor",
    role: [],
  },
  {
    path: "/help-support",
    element: VisitorLayoutFunc(<HelpSupport />),
    type: "visitor",
    role: [],
  },
  {
    path: "/environmental-pioneers",
    element: VisitorLayoutFunc(<EnvironmentalPioneers />),
    type: "visitor",
    role: [],
  },
  {
    path: "/faq",
    element: VisitorLayoutFunc(<GeneralFaq />),
    type: "visitor",
    role: [],
  },
  {
    path: "/privacy-policy",
    element: VisitorLayoutFunc(<PrivacyPolicy />),
    type: "visitor",
    role: [],
  },
  {
    path: "/terms-conditions",
    element: VisitorLayoutFunc(<TermsConditions />),
    type: "visitor",
    role: [],
  },
  {
    path: "/business/terms-conditions",
    element: VisitorLayoutFunc(<BusinessTermsConditions />),
    type: "visitor",
    role: [],
  },
  {
    path: "/affilate-terms-conditions",
    element: VisitorLayoutFunc(<AffilateTermsConditions />),
    type: "visitor",
    role: [],
  },
  {
    path: "/translator-ranking-system",
    element: VisitorLayoutFunc(<TranslatorRankingSys />),
    type: "visitor",
    role: [],
  },
  {
    path: "/proofreader-ranking-system",
    element: VisitorLayoutFunc(<ProofReaderRankingSys />),
    type: "visitor",
    role: [],
  },
  {
    path: "/download-certificate",
    element: VisitorLayoutFunc(<Download />),
    type: "visitor",
    role: [],
  },
  // user pages routes
  //customer routes
  {
    path: "/business-dashboard",
    element: UserLayoutFunc(<BusinessDashboard />),
    type: "protected",
    role: ["company"],
    isEmailVerficationRequired: true,
    isCompanyProfileCompleted: true
  },
  {
    path: "/customer-dashboard",
    element: UserLayoutFunc(<CustomerDashboard />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/customer-contact-us",
    element: UserLayoutFunc(<ContactUs />),
    type: "protected",
    role: ["user","company"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/user-settings/*",
    element: UserLayoutFunc(<SettingsUser />),
    type: "protected",
    role: ["user","company"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/business-users/*",
    element: UserLayoutFunc(<ManageUsers />),
    type: "protected",
    role: ["company"],
    isEmailVerficationRequired: true,
    isCompanyProfileCompleted: true
  },
  {
    path: "/job-post/",
    element: UserLayoutFunc(<JobPost />),
    // type: "protected",
    // role: ["user"],
    // isEmailVerficationRequired: true
  },
  {
    path: "/job-post/*",
    element: UserLayoutFunc(<JobPost />),
    type: "protected",
    role: ["user","company"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/job-list",
    element: UserLayoutFunc(<CustomerJobList />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/job-details/*",
    element: UserLayoutFunc(<CustomerJobDetails />),
    type: "protected",
    role: ["user","company"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/customer/receipts-list",
    element: UserLayoutFunc(<CustomerReceiptsList />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/customer/receipt-details/*",
    element: UserLayoutFunc(<ReceiptDetailsCustomer />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader/transactions-details/*",
    element: UserLayoutFunc(<TranscationDetailsProofreader />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator/transactions-details/*",
    element: UserLayoutFunc(<TranscationDetailsTranslator />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/customer-chat",
    element: UserLayoutFunc(<ChatIndex />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/thank_you",
    element: UserLayoutFunc(<ThankYouPage />),
    type: "protected",
    role: ["user"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/thank_you_business",
    element: UserLayoutFunc(<ThankYouBusinessPage />),
    type: "protected",
    role: ["company"],
    isEmailVerficationRequired: true,
  },
  //proofReader routes
  {
    path: "/proofreader-dashboard",
    element: UserLayoutFunc(<ProofReaderDashboard />),
    type: "protected",
    role: ["proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-settings/*",
    element: UserLayoutFunc(<SettingsProofreader />),
    type: "protected",
    role: ["proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-jobs",
    element: UserLayoutFunc(<JobsListProofreader />),
    type: "protected",
    role: ["proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-jobs/job-details",
    element: UserLayoutFunc(<JobDetailsProofreader />),
    type: "protected",
    role: ["proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-my-jobs",
    element: UserLayoutFunc(<MyjobListingProf />),
    type: "protected",
    role: ["proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-my-jobs/details",
    element: UserLayoutFunc(<MyJobDetailsProf />),
    type: "protected",
    role: ["proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-chat",
    element: UserLayoutFunc(<ChatIndex />),
    type: "protected",
    role: ["proofreader"],
    isEmailVerficationRequired: true,
  },
  //translator routes
  {
    path: "/translator-dashboard",
    element: UserLayoutFunc(<TranslatorDashboard />),
    type: "protected",
    role: ["translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-settings/*",
    element: UserLayoutFunc(<SettingsTranslator />),
    type: "protected",
    role: ["translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-jobs",
    element: UserLayoutFunc(<JobsList />),
    type: "protected",
    role: ["translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-jobs/job-details",
    element: UserLayoutFunc(<JobDetails />),
    type: "protected",
    role: ["translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-my-jobs",
    element: UserLayoutFunc(<MyjobListing />),
    type: "protected",
    role: ["translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-my-jobs/details",
    element: UserLayoutFunc(<MyJobDetails />),
    type: "protected",
    role: ["translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-chat",
    element: UserLayoutFunc(<ChatIndex />),
    type: "protected",
    role: ["translator"],
    isEmailVerficationRequired: true,
  },
  // translator manager pages
  {
    path: "/translator-manager-dashboard",
    element: UserLayoutFunc(<TranslatorManagerDashboard />),
    type: "protected",
    role: ["project-manager-for-translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-manager-settings/*",
    element: UserLayoutFunc(<TransManagerSettings />),
    type: "protected",
    role: ["project-manager-for-translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-manager-my-jobs/details",
    element: UserLayoutFunc(<MyJobDetailsTransManager />),
    type: "protected",
    role: ["project-manager-for-translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-manager-my-jobs",
    element: UserLayoutFunc(<MyjobListingTransManager />),
    type: "protected",
    role: ["project-manager-for-translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-manager-chat",
    element: UserLayoutFunc(<ChatIndex />),
    type: "protected",
    role: ["project-manager-for-translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-manager-userlist",
    element: UserLayoutFunc(<TranslatorList />),
    type: "protected",
    role: ["project-manager-for-translator"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/translator-manager-notification",
    element: UserLayoutFunc(<Notification role={'translator'}/>),
    type: "protected",
    role: ["project-manager-for-translator"],
    isEmailVerficationRequired: true,
  },
  // proofreader manager pages
  {
    path: "/proofreader-manager-dashboard",
    element: UserLayoutFunc(<ProofreaderManagerDashboard />),
    type: "protected",
    role: ["project-manager-for-proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-manager-settings/*",
    element: UserLayoutFunc(<SettingsProofreaderManager />),
    type: "protected",
    role: ["project-manager-for-proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-manager-my-jobs/details",
    element: UserLayoutFunc(<MyJobDetailsProfManager />),
    type: "protected",
    role: ["project-manager-for-proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-manager-my-jobs",
    element: UserLayoutFunc(<MyjobListingProfManager />),
    type: "protected",
    role: ["project-manager-for-proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-manager-chat",
    element: UserLayoutFunc(<ChatIndex />),
    type: "protected",
    role: ["project-manager-for-proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-manager-userlist",
    element: UserLayoutFunc(<ProofreaderList />),
    type: "protected",
    role: ["project-manager-for-proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/proofreader-manager-notification",
    element: UserLayoutFunc(<Notification role={'proofreader'}/>),
    type: "protected",
    role: ["project-manager-for-proofreader"],
    isEmailVerficationRequired: true,
  },
  {
    path: "/company",
    element: VisitorLayoutFunc(<Company />),
    type: "protected",
    role: ["company"],
    isEmailVerficationRequired: true,
  },

  {
    path: "*",
    element: <NoContentPage />,
  },
];

export default routes;
