import * as Yup from "yup";

const validFileExtensions = {
  image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],
  document: ['pdf', 'doc', 'docx', 'txt', 'odt']
};

function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}

export const createACompanySchema = Yup.object({
  logo: Yup
    .mixed()
    .required("Required")
    .test("is-valid-type", "Not a valid image type",
      value => isValidFileType(value && value.name.toLowerCase(), "image")),
  agreement: Yup
    .mixed()
    .required("Required")
    .test("is-valid-type", "Not a valid file type",
      value => isValidFileType(value && value.name.toLowerCase(), "document")),
  name: Yup.string().required("Enter a Name."),
  address: Yup.string().required("Enter a Address."),
  countryCode: Yup.number().required("Choose a country code."),
  phoneNumber: Yup.string().min(9).max(10).matches(/^[1-9]\d{8,9}$/,"Enter a valid Phone Number.").required("Enter a valid Phone Number."),
  laison: Yup.string().required("Enter a Laison."),
});