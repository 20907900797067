import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { NavLink, useLocation } from 'react-router-dom';


const BusinessSolutionSec = () => {

    const staticCarouselData = [{ headings: "Healthcare & Medicine", subHeadings1: "Medical document translations", subHeadings2: "Patient record proofreading", subHeadings3: "Medical journal article translations", subHeadings4: "Pharmaceutical product description translations", paraText: "We provide specialized translation services tailored for the healthcare industry, ensuring medical accuracy and confidentiality. Our team of experts ensures meticulous proofreading for all healthcare-related documents, ensuring precision and clarity." },
    { headings: "Law Firms", subHeadings1: "Contract translations", subHeadings2: "Legal document proofreading", subHeadings3: "Court transcript translations", subHeadings4: "Witness statement translations", paraText: "Our translation services are tailored for law firms, ensuring legal terminology is accurately conveyed across languages. We offer diligent proofreading for legal documents, guaranteeing precision and adherence to legal standards." },
    { headings: "Technology Sector", subHeadings1: "Software localization", subHeadings2: "Technical manual translations", subHeadings3: "Product description translations", subHeadings4: "User interface translations", paraText: "For the technology sector, we offer translations that accurately capture the nuances of technical jargon and concepts. Our proofreading services ensure that tech-related documents are free of errors and convey information seamlessly." },
    { headings: "Education & Academia", subHeadings1: "Academic paper translations", subHeadings2: "Dissertation proofreading", subHeadings3: "Textbook translations", subHeadings4: "Course material translations", paraText: "We cater to the education and academia sector with translations that reflect academic rigor and clarity. Our proofreading services for educational content ensure that academic papers and articles meet the highest standards of quality." },
    { headings: "Tourism & Hospitality", subHeadings1: "Website localization", subHeadings2: "Brochure translations", subHeadings3: "Menu translations", subHeadings4: "Guest communication translations", paraText: "We provide translation services that resonate with the dynamic needs of the tourism and hospitality industry. Proofreading for travel brochures, websites, and promotional materials is executed with an eye for detail, ensuring they appeal to global audiences." },
    { headings: "Finance & Banking", subHeadings1: "Financial statement translations", subHeadings2: "Investment portfolio translations", subHeadings3: "Market research report translations",subHeadings4:"", paraText: "Our translation services for the finance and banking sector ensure that complex financial terms and data are communicated clearly across languages. We meticulously proofread financial documents to guarantee accuracy and compliance with international standards." }]
    const options = {
        items: 2,
        dots: false,
        loop: true,
        nav: true,
        margin: 10,
        responsive: {
            0: {
                items: 1,
                dots: true,
                nav: false,
            },
            600: {
                items: 2
            }
        }
    }

    return (
        <section className="B-Solutions  padding-bottom padding-top" id="scrollTarget">
            <div className="container" >
                <div className="section-heading text-center" >
                    <h3 >Business Solutions</h3>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <div className="B-Solutions-left">
                            <h4>Industries we have served</h4>
                            <OwlCarousel className='owl-carousel owl-theme soultion-b-slider' {...options}>
                                {/* <div
                                id="soultion-b-slider"
                                className="soultion-b-slider  owl-carousel owl-theme"
                            > */}
                                {staticCarouselData?.map((val, index) => <div key={index} className="item soultion-b-item">
                                    <h5>{val?.headings}:</h5>
                                    {/* <img src="/images/img6.png" alt="" /> */}
                                    {val?.paraText}
                                    {/* <h6>{val?.headings}</h6> */}
                                    <ul>
                                        <li>{val?.subHeadings1}</li>
                                        <li>{val?.subHeadings2}</li>
                                        <li>{val?.subHeadings3}</li>
                                        {val?.subHeadings4 && <li>{val?.subHeadings4}</li>}

                                    </ul>
                                </div>)}
                                {/* </div> */}
                            </OwlCarousel>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="B-Solutions-right">
                            <h2>Have a large project?</h2>
                            <p>Contact us for a tailored solution for your business.</p>
                            <NavLink to="/contact-us" className="btn btn-border">
                                Get in touch
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BusinessSolutionSec