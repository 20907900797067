import React, { useEffect, useState } from 'react'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer'

const ProjectSinglecard = ({ item, gotoSelectedProject, idx }) => {
    const [timeAgo, setTimeAgo] = useState("");
    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(item?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [item?.deadline]);
    return (
        <div className="col-lg-6" key={idx} onClick={() => gotoSelectedProject(item)}>
            <div className="project-with-progress-box">
                {item?.translatorSpecialisation !== '' && <div className="site-tag">Specialized</div>}
                <h5>
                    {item?.jobTitle}
                </h5>
                <ul className="tags-sec">
                    <li>
                        <span>
                            <img alt='icons' src="/images/icon54.svg" />
                        </span>{" "}
                        {item?.specificService?.toUpperCase()}
                    </li>
                    {item?.chooseTranslator !== "" && <li className="light-green-db">
                        <span>
                            <img alt='icons' src="/images/bag.svg" />
                        </span>{" "}
                        {item?.chooseTranslator}
                    </li>}
                    <li className="light-blue-db">
                        <span>
                            <img alt='icons' src="/images/icon60.svg" />
                        </span>
                        {timeAgo}
                    </li>
                </ul>
                <h6>Project Overview:</h6>
                <p>
                    {item?.comment}
                </p>
                <div className="listing-area">
                    <ul className="listing-sec">
                        <li>
                            <strong>Document type:</strong> {item?.jobDocumentType}
                        </li>
                        <li>
                            <strong>Language:</strong> {item?.requiredEnglishDialect !== "" ? item?.requiredEnglishDialect : item?.translateFrom}
                        </li>
                        <li>
                            <strong>Status:</strong> <span>{item?.jobStatus ?? 0}%</span>
                        </li>
                    </ul>
                </div>
                <div className="progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProjectSinglecard