import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobWordCount: 0,
  uploadFiles: null,
  fileDetails: null,
  blobFile: null,
  documentType: "",
  serviceType: "",
  deadlineOptions: null,
  deadlineTime: "",
  multipliedBy: 0,
  fixedRate: 0,
  perWordPrice: 0,
  jobPrice: 0,
  totalInitial: 0,
  selectedSpeedOption: null,
  rewardPointUsed: 0,
  promoCode: 0,
  walletFundUsed: 0,
  cartCurrency: "JOD",
  styleSheetLink:null,
  stepperArray: [
    {
      name: "Upload your file",
      value: "uploadFile",
      isComplete: false,
      isActive: false,
      route: "/job-post/upload-file",
      description: "Browse or drag & drop your file",
    },
    {
      name: "Type of document",
      value: "typeOfDoc",
      isComplete: false,
      isActive: false,
      route: "/job-post/document-type",
      description: "Which type of document did you upload?",
    },
    {
      name: "Service type",
      value: "serviceType",
      isComplete: false,
      isActive: false,
      route: "/job-post/service-type",
      description: "Choose General Service type",
    },
    {
      name: "Choose Deadline",
      value: "chooseDeadline",
      isComplete: false,
      isActive: false,
      route: "/job-post/choose-deadline",
      description: "Choose Deadline  according to word count",
    },
    {
      name: "Configure",
      value: "configure",
      isComplete: false,
      isActive: false,
      route: "/job-post/config",
      description: "Configure Details of service",
    },
    {
      name: "Pay",
      value: "pay",
      isComplete: false,
      isActive: false,
      route: "/job-post/payment",
      description: "Pay and Confirm your order",
    },
  ],
  configTranslation: {
    fromLang: "",
    toLang: "",
    showWatermark: false,
    addComments: "",
    chooseTranslator: "",
    specialisation: "",
  },
  configProofreading: {
    specService: "",
    citationstyle: "",
    dialect: "",
    styleOfWriting: "",
    showWatermark: false,
    addComments: "",
    
  },
};
export const businessJobPostSlice = createSlice({
  name: "businessJobPost",
  initialState,
  reducers: {
    updateJobPostFileWordCount: (state, action) => {
      const { count } = action.payload;
      state.jobWordCount = count;
    },
    updateJobPostFiles: (state, action) => {
      const { file } = action.payload;
      state.uploadFiles = file;
    },
    updateBlobFile: (state, action) => {
      const { blobFile } = action.payload;
      state.blobFile = blobFile;
    },
    updateJob: (state, action) => {
      const { type, value } = action.payload;
      switch (type) {
        case "UPDATE_FILE_DETAILS":
          state.fileDetails = value;
          state.stepperArray = state.stepperArray.map((item) =>
            item?.value === "uploadFile" ? { ...item, isComplete: true } : item
          );
          break;
        case "UPDATE_DOC_TYPE":
          state.documentType = value;
          state.stepperArray = state.stepperArray.map((item) =>
            item?.value === "typeOfDoc" ? { ...item, isComplete: true } : item
          );
          break;
        case "UPDATE_SERVICE_TYPE":
          state.serviceType = value;
          state.stepperArray = state.stepperArray.map((item) =>
            item?.value === "serviceType" ? { ...item, isComplete: true } : item
          );
          break;
        case "UPDATE_DEADLINE":
          state.deadlineTime = value?.deadlineTime;
          state.multipliedBy = value?.multipliedBy;
          state.fixedRate = value?.fixedRate;
          state.perWordPrice = value?.perWordPrice;
          state.stepperArray = state.stepperArray.map((item) =>
            item?.value === "chooseDeadline"
              ? { ...item, isComplete: true }
              : item
          );
          break;
        case "UPDATE_TRANSLATION_CONFIGURATION":
          state.configTranslation = value;
          state.stepperArray = state.stepperArray.map((item) =>
            item?.value === "configure" ? { ...item, isComplete: true } : item
          );
          break;
        case "UPDATE_PROOFREADING_CONFIGURATION":
          state.configProofreading = value;
          state.styleSheetLink=value.styleSheetLink
          state.stepperArray = state.stepperArray.map((item) =>
            item?.value === "configure" ? { ...item, isComplete: true } : item
          );
          break;
        default:
          break;
      }
    },
    setActiveStep: (state, action) => {
      const { activeStep } = action.payload;
      state.stepperArray = state.stepperArray.map((item) =>
        item?.value === activeStep
          ? { ...item, isActive: true }
          : { ...item, isActive: false }
      );
    },
    setDeadlineOptions: (state, action) => {
      const { deadlineOptions } = action.payload;
      state.deadlineOptions = deadlineOptions;
    },
    setJobPrice: (state, action) => {
      const { jobPrice } = action.payload;
      state.jobPrice = jobPrice;
    },
    setTotalInitial: (state, action) => {
      const { total } = action.payload;
      state.totalInitial = total;
    },
    setSelectedSpeedOption: (state, action) => {
      const { speedOption } = action.payload;
      state.selectedSpeedOption = speedOption;
    },
    setRewardPoint: (state, action) => {
      const { rewardPointUsed } = action.payload;
      state.rewardPointUsed = rewardPointUsed;
    },
    setPromoCode: (state, action) => {
      const { promoCode } = action.payload;
      state.promoCode = promoCode;
    },
    setWalletFund: (state, action) => {
      const { walletFundUsed } = action.payload;
      state.walletFundUsed = walletFundUsed;
    },
    setCartCurrency: (state, action) => {
      const { cartCurrency } = action.payload;
      state.cartCurrency = cartCurrency;
    },
    clearjobPost: (state) => {
      state.uploadFiles = null;
      state.fileDetails = null;
      state.blobFile = null;
      state.documentType = "";
      state.styleSheetLink=null;
      state.serviceType = "";
      state.deadlineTime = "";
      state.multipliedBy = 0;
      state.fixedRate = 0;
      state.perWordPrice = 0;
      state.jobWordCount = 0;
      state.deadlineOptions = null;
      state.jobPrice = 0;
      state.selectedSpeedOption = null;
      state.rewardPointUsed = 0;
      state.walletFundUsed = 0;
      state.promoCode = 0;
      state.cartCurrency = "JOD";
      state.totalInitial = 0;
      state.stepperArray = [
        {
          name: "Upload your file",
          value: "uploadFile",
          isComplete: false,
          isActive: false,
          route: "/job-post/upload-file",
          description: "Browse or drag & drop your file",
        },
        {
          name: "Type of document",
          value: "typeOfDoc",
          isComplete: false,
          isActive: false,
          route: "/job-post/document-type",
          description: "Which type of document did you upload?",
        },
        {
          name: "Service type",
          value: "serviceType",
          isComplete: false,
          isActive: false,
          route: "/job-post/service-type",
          description: "Choose General Service type",
        },
        {
          name: "Choose Deadline",
          value: "chooseDeadline",
          isComplete: false,
          isActive: false,
          route: "/job-post/choose-deadline",
          description: "Choose Deadline  according to word count",
        },
        {
          name: "Configure",
          value: "configure",
          isComplete: false,
          isActive: false,
          route: "/job-post/config",
          description: "Configure Details of service",
        },
        {
          name: "Pay",
          value: "pay",
          isComplete: false,
          isActive: false,
          route: "/job-post/",
          description: "Pay and Confirm your order",
        },
      ];
      state.configTranslation = {
        fromLang: "",
        toLang: "",
        showWatermark: false,
        addComments: "",
        chooseTranslator: "",
        specialisation: "",
      };
      state.configProofreading = {
        specService: "",
        citationstyle: "",
        dialect: "",
        styleOfWriting: "",
        showWatermark: false,
        addComments: "",
      };
    },
  },
});
// this is for dispatch
export const {
  updateJobPostFileWordCount,
  updateJobPostFiles,
  updateBlobFile,
  updateJob,
  clearjobPost,
  updateTranslationLang,
  setActiveStep,
  setDeadlineOptions,
  setJobPrice,
  setSelectedSpeedOption,
  setRewardPoint,
  setPromoCode,
  setWalletFund,
  setCartCurrency,
  setTotalInitial,
} = businessJobPostSlice.actions;

// this is for configureStore
export default businessJobPostSlice.reducer;
