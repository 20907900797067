import React from 'react'

const AboutUs = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <h1>About Lingvara</h1>
                                <p>Welcome to Lingvara! We are a leading online translation and proofreading business based in Amman, Jordan. With a team of skilled professionals and a commitment to excellence, we provide high-quality language services to clients worldwide.</p>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec common-btm-padd">

                <div className="container">

                    <p className='static-text'><strong>Our Mission</strong></p>
                    <p>At Lingvara, our mission is to bridge language barriers and facilitate effective communication across cultures. We believe that accurate and culturally sensitive translations are essential for global understanding and success. Our goal is to deliver exceptional translation and proofreading services that exceed our clients' expectations.</p>
                    <p><strong>Expert Translators and Proofreaders</strong></p>
                    <p>We take pride in our team of experienced translators and proofreaders who possess in-depth language proficiency and subject matter expertise. Our professionals are meticulously selected, ensuring that they meet the highest standards of accuracy, professionalism, and confidentiality. They have a deep understanding of cultural nuances, enabling them to deliver translations that capture the essence and tone of the source material.</p>
                    <p><strong>Comprehensive Language Solutions</strong></p>
                    <p>Whether you require document translation, website localization, or proofreading services, Lingvara offers a comprehensive range of language solutions tailored to meet your specific needs. We work with a wide range of industries, including legal, medical, technical, and business sectors, providing accurate translations that are industry-specific and cater to your target audience.</p>
                    <p><strong>Commitment to Quality</strong></p>
                    <p>Quality is at the heart of everything we do. We employ a rigorous quality assurance process to ensure that every translation and proofreading project meets the highest standards of accuracy and precision. Our team follows a meticulous review process, combining human expertise with cutting-edge translation technologies to deliver top-notch results.</p>
                    <p><strong>Confidentiality and Data Security</strong></p>
                    <p>At Lingvara, we understand the importance of confidentiality and data security. We adhere to strict confidentiality protocols to safeguard your sensitive information. All our translators and proofreaders are bound by strict non-disclosure agreements, and we employ secure technologies and encryption methods to protect your data throughout the translation process.</p>
                    <p><strong>Client Satisfaction</strong></p>
                    <p>We are dedicated to providing exceptional customer service and ensuring your complete satisfaction. We strive to build long-term relationships with our clients by delivering reliable and timely translations, maintaining open lines of communication, and accommodating specific project requirements. Your success is our success.</p>
                    <p><strong>Contact Us</strong></p>
                    <p>Are you ready to experience the difference that Lingvara can make for your translation and proofreading needs? Contact us today to discuss your project requirements or request a quote. Our friendly team is ready to assist you and provide you with language solutions that will take your business global.</p>
                </div>

            </section>


        </>
    )
}

export default AboutUs