import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

const StepsToFollow = () => {
    const { t } = useTranslation();
    const [slectedUserRole, setSelectedtUserRole] = useState(1)

    return (


        <section className="steps-sec padding-bottom padding-top">
            <div className="container">
                <div className="section-heading text-center">
                    <h3>{t("steps-follow-heading")}</h3>
                </div>
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" onClick={() => setSelectedtUserRole(1)}>
                        <a className={`nav-link  ${slectedUserRole === 1 && "active"} `} data-bs-toggle="tab" href="#home">
                            {t("translation")}
                        </a>
                    </li>
                    <li className="nav-item" onClick={() => setSelectedtUserRole(2)}>
                        <a className={`nav-link  ${slectedUserRole === 2 && "active"} `} data-bs-toggle="tab" href="#menu1">
                            {t("proofReader")}
                        </a>
                    </li>
                </ul>
                {/* Tab panes */}
                <div className="tab-content">
                    <div id="home" className={`tab-pane ${slectedUserRole === 1 && "active"}`}>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="tab-left-img">
                                    <img src="/images/tab-img.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="tab-left-content">
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">1</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon1.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-uploadYourFile")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">2</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon2.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-calculate-sign-in")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">3</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon3.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-documentType")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">4</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon4.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-choose-service-type")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">5</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon5.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-receive-notification")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">6</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon6.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-be-contacted-by-your-translator")}</h6>
                                    </div>

                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">7</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon7.png" alt="" />
                                        </div>
                                        <h6>
                                            {t("steps-follow-receive-translated-document")}
                                        </h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">8</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon8.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-rate-the-work-translator")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">9</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon9.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-earn-reward-points")}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* tab1 */}
                    <div id="home" className={`tab-pane ${slectedUserRole === 2 && "active"}`}>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="tab-left-img">
                                    <img src="/images/tab-img.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="tab-left-content">
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">1</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon1.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-uploadYourFile")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">2</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon2.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-calculate-sign-in")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">3</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon3.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-documentType")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">4</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon4.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-choose-service-type")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">5</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon5.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-receive-notification")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">6</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon6.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-be-contacted-by-your-translator")}</h6>
                                    </div>

                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">7</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon7.png" alt="" />
                                        </div>
                                        <h6>
                                            {t("steps-follow-receive-translated-document")}
                                        </h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">8</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon8.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-rate-the-work-translator")}</h6>
                                    </div>
                                    <div className="tab-box-wrap">
                                        <div className="tab-number">
                                            <span className="s-number">9</span>
                                        </div>
                                        <div className="tab-icon">
                                            <img src="/images/tab-icon9.png" alt="" />
                                        </div>
                                        <h6>{t("steps-follow-earn-reward-points")}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* tab2 */}
                </div>
            </div>
        </section>

    )
}

export default StepsToFollow