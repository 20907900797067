import * as Yup from "yup";

// upload job file form validator

export const uploadJobFileFormValidator = (prevData, type) => {
  if (type === "initialValues") {
    let initialValues = {
      name: prevData?.name ?? "",
      // description: prevData?.description ?? "",
    };
    return initialValues;
  } else if (type === "validationSchema") {
    let validationSchema = Yup.object().shape({
      name: Yup.string().required("Name field cannot be empty"),
      // description: Yup.string().required("Description field cannot be empty"),
    });
    return validationSchema;
  }
};
// job post validator for translation
export const jobPostTranslationVerification = {
  initialValues: {
    fromLang: "",
    toLang: "",
    showWatermark: false,
    addComments: "",
    chooseTranslator: "",
    specialisation: "",
  },
  validationSchema: Yup.object().shape({
    fromLang: Yup.string().required("Language field cannot be empty"),
    toLang: Yup.string().required("Language field cannot be empty"),
    addComments: Yup.string().required("Comments field cannot be empty"),
    chooseTranslator: Yup.string().required("Translator field cannot be empty"),
    specialisation: Yup.string().when(
      "chooseTranslator",
      (chooseTranslator, schema) => {
        if (chooseTranslator[0] === "General Translator") {
          return schema;
        } else {
          return schema.required("field required");
        }
      }
    ),
  }),
};
//job post validator for proofReader
export const jobPostProofReaderVerification = {
  initialValues: {
    specService: "",
    citationstyle: "",
    dialect: "American",
    styleOfWriting: "",
    showWatermark: false,
    addComments: "",
    styleSheetLink:null
  },
  validationSchema: Yup.object().shape({
    specService: Yup.string().required("Select specific service"),
    addComments: Yup.string().required("Comments field cannot be empty"),
    citationstyle: Yup.string().required("Select citation style"),
    dialect: Yup.string().required("Select dialect"),
    styleOfWriting: Yup.string().required("Select style of writing"),
  }),
};
