import React from 'react'
import { useFormik } from 'formik'
import { forgotPassFromValidator } from '../../../services/formValidator/authValidator'
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../../services/api/authApis'
import { toast } from 'react-toastify'


const ForgotPass = () => {
    const navigate = useNavigate()
    const forgotPassFormik = useFormik({
        initialValues: forgotPassFromValidator.initialValues,
        validationSchema: forgotPassFromValidator.validationSchema,
        onSubmit: async (values) => {
            let { serverResponse } = await forgotPassword(values)
            if (serverResponse.code === 200) {
                toast.success(serverResponse.message)
                navigate('/reset-password', { state: { email: values.email } })
            }
        }
    })

    return (
        <>
            <form onSubmit={forgotPassFormik.handleSubmit}>
                <h4>Reset Password</h4>
                <p className='reset-password-mail'>Enter your registered email to send an OTP.</p>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" placeholder="Enter Your Email" id='email' name='email' value={forgotPassFormik?.values.email} onChange={forgotPassFormik.handleChange} className="form-control" />
                    <p className='form-error-message'>{forgotPassFormik.touched.email &&
                        forgotPassFormik.errors.email}</p>
                </div>

                <div className="form-group">
                    <button type="submit" className="btn w-100">
                        Sent otp
                    </button>
                </div>

            </form>
        </>
    )
}

export default ForgotPass