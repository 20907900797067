import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login';
import { socialSignIn, socialSignUp } from '../../../services/api/authApis';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/reducers/authSlice';
import { FACEBOOK_APP_ID, GOOGLE_APP_ID } from '../../../constants/config';
import AddEmailToSocialLogin from './AddEmailToSocialLogin';
import { navigateTo } from '../../../services/appNavigator';

const SocialLoginSignup = ({ role, type }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [checkAvlMail, setCheckAvlmail] = useState(false)
    const [socialData, setSocialData] = useState(null)
    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };
    const handleCredentialResponse = (response) => {
        console.log(response,"responseId")
        socialLoginCallBackData(parseJwt(response.credential), response.clientId);
    }
    const socialLoginCallBackData = async (data, googleClientId) => {
        console.log(data,"dataIn")
        if (data.status !== "unknown" && !data.email && type === 'signup') {
            setSocialData({ data: data, googleClientId: googleClientId })
            setCheckAvlmail(true)
        }
        else if (data.status !== "unknown" && type === 'signup') {
            let values = {
                name: data.name,
                email: data.email,
                socialType: data?.graphDomain ?? 'google',
                socialId: data.id ?? data?.sub,
                role: role
            }
            if (data?.picture?.data?.url ?? data?.picture) {
                values.profilePicture = {
                    key: data?.picture?.data?.url ?? data?.picture,
                    name: '',
                    mimetype: '',
                    location: '',
                    size: 0
                }
            }
            let { result, serverResponse } = await socialSignUp(values)
            toast.success(serverResponse.message)
            dispatch(updateUser({ user: result.data }))
            setSocialData(null)
            setCheckAvlmail(false)
            navigateTo(result.data.role, navigate)
        }
        else if (type === 'login' && (data.id ?? googleClientId)) {
            let values = {
                socialType: data?.graphDomain ?? 'google',
                socialId: data.id ?? data?.sub,
            }
            let { result, serverResponse } = await socialSignIn(values)
            toast.success(serverResponse.message)
            dispatch(updateUser({ user: result.data }))
            navigateTo(result.data.role, navigate)
        }
    }
    // close mail dialog after mail added
    const closeMailModal = () => {
        setCheckAvlmail(false)
    }
    return (
        <div>
            <div className="signin-others text-center">
                <a>
                    <img src="/images/log1.svg" />
                    <div className='social-login-buttons'>
                        <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    handleCredentialResponse(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </GoogleOAuthProvider>
                    </div>
                </a>
                <a>
                    <img src="/images/log2.svg" />
                    <div className='social-login-buttons'>
                        <FacebookLogin
                            appId={FACEBOOK_APP_ID}
                            fields="name,email,picture"
                            callback={socialLoginCallBackData}
                            textButton={'Sign in with Facebook'}
                        />
                    </div>
                </a>
            </div>
            <AddEmailToSocialLogin
                checkAvlMail={checkAvlMail}
                closeMailModal={closeMailModal}
                socialData={socialData}
                socialLoginCallBackData={socialLoginCallBackData}
            />
        </div>
    )
}

export default SocialLoginSignup