import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { navigateTo } from '../../services/appNavigator'

const NoContentPage = () => {
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (user) {
      navigateTo(user?.role, navigate)
    }
    else {
      navigate('/')
    }
  }, [])
  return (
    <div>NoContentPage</div>
  )
}

export default NoContentPage