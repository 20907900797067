import React, { useEffect, useRef, useState } from 'react'
import { chooseTranslatorJson, specalisationJson } from '../../../constants/config'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer';
import useOutsideObserver from '../../../utils/outsideObserver/outsideObserver';
import JobAssignModal from '../../../components/common/assignJob/JobAssignModal';

const SplitedJobPost = ({ item, unassignJob, orderNumber }) => {
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [timeAgo, setTimeAgo] = useState("");
  const expandButtonRef = useRef()
  const [showAssignModal, setShowAssignModal] = useState(false)
  useOutsideObserver(expandButtonRef, () => setShowConfiguration(false))

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeAgo = getTimeAgo(item?.deadline);
      setTimeAgo(timeAgo);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [item?.deadline]);
  return (
    <div className="acc-wrap-pm"
      ref={expandButtonRef}>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              onClick={() => setShowConfiguration(!showConfiguration)}
            >
              <div className="button-table btn-table">
                <div className="button-table-head">
                  <div className="tab-2">#Order</div>
                  <div className="tab-2">Wordcount</div>
                  <div className="tab-2">Deadline</div>
                  <div className="tab-2">Type of doc</div>
                  <div className="tab-2">Language</div>
                  <div className="tab-2">Price/word</div>
                  <div>
                    {
                      showConfiguration ?
                        <div data-aos="fade-up"
                          data-aos-offset={100}
                          data-aos-easing="ease-in-sine">
                          <img src={'/images/acc-arrow1.png'} alt="arrow" height={40} />
                        </div>
                        :
                        <div data-aos="fade-up"
                          data-aos-offset={100}
                          data-aos-easing="ease-in-sine">
                          <img src={'/images/acc-arrow2.png'} alt='arrow' height={40} />
                        </div>
                    }
                  </div>
                </div>
                <div className="button-table-body">
                  <div className="tab-2">
                    {orderNumber?orderNumber:"N/N"}
                  </div>
                  <div className="tab-2">{item?.wordCount ?? '--'}</div>
                  <div className="tab-2">{timeAgo}</div>
                  <div className="tab-2">{item?.jobDocumentType}</div>
                  <div className="tab-2">{item?.translateFrom} to {item?.translateTo}</div>
                  <div className="tab-2">
                    <div className="a-border">{item.perWordPrice}</div>
                  </div>
                  <div></div>
                </div>
              </div>
            </button>
          </h2>
          {showConfiguration && <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-aos="fade-down"
            data-aos-offset={100}
            data-aos-easing="ease-in-sine"
          >
            <div className="accordion-body">
              <div className="acc-body-item">
                <div className="item-acc-1">
                  <div className="signin-form-wrap">
                    <div>
                      <div className="row">
                        {/* <div className="col-lg-4">
                          <div className="item-acc-wrap">
                            <div className="form-group">
                              <label htmlFor="">
                                Select deadline
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name='deadline' id='deadline' onChange={(e) => { selectDeadLine(e) }}
                                disabled={!jobFiles}
                              >
                                {
                                  deadlineStaticArray?.map((item) => <option selected={item?.value === splitFileFormik?.values?.deadline}>{item?.time}</option>)
                                }
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-lg-6">
                          <div className="item-acc-wrap">
                            <div className="form-group">
                              <label htmlFor="">
                                Type of document
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                disabled
                              >
                                <option selected="">
                                  {item?.jobDocumentType}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="item-acc-wrap">
                            <div className="form-group">
                              <label htmlFor="">Word count</label>
                              <input
                                type="text"
                                placeholder={500}
                                className="form-control"
                                disabled
                                value={item?.wordCount}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="file-list-table">
                          <div className="signin-form-wrap e-ch upload-file-section">
                            <div className="form-group">
                              <label>Name</label>
                              <input type="text" placeholder="" id='jobTitle' name='jobTitle' className="form-control" value={item?.jobTitle} disabled />
                            </div>
                            <div className="form-group">
                              <label htmlFor="">Additional Comments?</label>
                              <textarea name="addComments" id="addComments" cols="10" rows="7" className="form-control" value={item?.addComments} disabled></textarea>
                            </div>
                            <table className="table table-responsive">
                              <thead>
                                <tr>
                                  <th colSpan="2">File name</th>
                                  <th scope="col">Word Count</th>
                                  <th scope="col"></th>

                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan="2">
                                    <h6><span><i className="fas fa-file-alt"></i></span>{item?.jobFile?.name}</h6>
                                  </td>
                                  <td>{item?.wordCount}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row-section">
                          <h5>Choose Language Pair:</h5>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="item-acc-wrap">
                                <div className="form-group">
                                  <label htmlFor="">
                                    Translate From
                                  </label>
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    disabled
                                  >
                                    <option value={1}>{item?.translateFrom}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="item-acc-wrap">
                                <div className="form-group">
                                  <label htmlFor="">
                                    Translate to
                                  </label>
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    disabled
                                  >
                                    <option value={1}>{item?.translateTo}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row-section">
                          <h5>Choose your Translator</h5>
                          <div className="radio-li half-radio-li">
                            <ul>
                              {chooseTranslatorJson?.map((trans) => {
                                return (
                                  <li>
                                    <div className="radio-group">
                                      <input
                                        type="radio"
                                        name="plan"
                                        disabled
                                        value={trans.value}
                                        checked={item.chooseTranslator === trans?.value}
                                      />
                                      <label htmlFor="html">
                                        <h5>{trans?.name}</h5>
                                      </label>
                                    </div>
                                  </li>
                                )
                              }
                              )}
                            </ul>
                          </div>
                        </div>
                        {!!item.translatorSpecialisation && <div className="col-lg-12">
                          <div className="form-group">
                            <h5>
                              Which best describes the field of your
                              document?
                            </h5>
                            <div className="row">
                              {specalisationJson?.map((specs) => <div className="col-lg-4 mb-3">
                                <div className="rdio-btn-for-db-frm">
                                  <input
                                    type="radio"
                                    id="test4"
                                    name="radio-group2"
                                    value={specs.value}
                                    checked={item.translatorSpecialisation === specs?.value}
                                    disabled
                                  />
                                  <label htmlFor="test4">
                                    {specs?.name}
                                  </label>
                                </div>
                              </div>)}
                            </div>
                          </div>
                        </div>}
                        <div className="col-lg-12">
                          <div className="pm-tr-button">
                            {!item?.isAssignedJob && <button
                              type="submit"
                              className="btn btn-border-g"
                              onClick={() => setShowAssignModal(true)}
                            >
                              Assign
                            </button>}


                            {item?.isAssignedJob && (item?.jobStatus < 25) && <button
                              type="submit"
                              className="btn btn-border-g"
                              onClick={() => unassignJob(item?._id)}
                            >
                              Unassign
                            </button>}

                            {item?.isCompleted &&
                              <table className="table table-responsive">
                                <thead>
                                  <tr>
                                    <th colSpan="1">Completed Job File</th>

                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tr>
                                  <td colSpan="1">
                                    <h6>

                                      <a href={item?.jobCompletedFile?.location} download>
                                        <span>
                                          <img src="/images/icon55.svg" alt="icon" />
                                        </span>
                                        {item?.jobCompletedFile?.name}
                                      </a>
                                    </h6>
                                  </td>

                                </tr>

                              </table>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
      {showAssignModal && <JobAssignModal setShowAssignModal={setShowAssignModal} showAssignModal={showAssignModal} item={item} />}
    </div>
  )
}

export default SplitedJobPost