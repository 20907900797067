import React, { useEffect } from 'react'
import JobPostStapper from './JobPostStapper'
import BusinessJobPostStapper from './BusinessJobPostStapper'
import UploadFile from './UploadFile'
import { Routes, Route, useNavigate } from 'react-router-dom'
import Deadline from './Deadline'
import GeneralService from './GeneralService'
import SignUpType from './SignUpType'
import DocumentType from './DocumentType'
import Configure from './Configure'
import { useDispatch, useSelector } from 'react-redux'
import { clearjobPost } from '../../../redux/reducers/jobPostSlice'
import Payment from './Payment'


const JobPost = () => {
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { stepperArray } = useSelector((state) => state.jobPost)
    useEffect(() => {
        let data = stepperArray?.find((item) => item?.isActive)
        if (data) {
            navigate(`${data?.route}`)
        }
        return () => !localStorage.getItem('reDirect_url') && dispatch(clearjobPost())
    }, [])
    return (
        <>
            <section className="step-form-wrap padding-bottom padding-top">

                <div className="container">
                    <div className='row'>
                        {user?.role === "company" ? <BusinessJobPostStapper /> : <JobPostStapper />}
                        <div className="col-lg-8">
                            <div className="step-right">

                                <Routes>
                                    <Route path='/' element={<SignUpType />} />
                                    <Route path='/upload-file' element={<UploadFile />} />
                                    <Route path='/document-type' element={<DocumentType />} />
                                    <Route path='/service-type' element={<GeneralService />} />
                                    <Route path='/choose-deadline' element={<Deadline />}></Route>
                                    <Route path='/config' element={<Configure />}></Route>
                                    <Route path='/payment' element={<Payment />}></Route>

                                </Routes>
                            </div>

                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default JobPost