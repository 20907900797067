import * as Yup from "yup";


export const ResponseValidator = async ({ code, message }) => {
    const validator =
        Yup.object().shape({
            code: Yup.number(),
            message: Yup.string()
        })
    try {
        return await validator.isValid({
            code: code,
            message: message
        })
        // return validate
    }
    catch (err) {
        console.log(err)
    }
}