import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, Button } from 'react-bootstrap'
import { acceptRejectRecheckJobPost } from '../../../services/api/jobApis'

const RecheckCommentModal = ({ notificationHandler, selectedJobDetailsId, showRecheckCommentModel, setShowRecheckCommentModel }) => {
    const [recheckComments, setRecheckComments] = useState('')
    const [commentSection, setCommentSection] = useState(false)

    const addCommentFunc = async () => {
        let acceptRejectRecheckJobData = await acceptRejectRecheckJobPost({ jobId: selectedJobDetailsId, status: "recheck", recheckComments: recheckComments })
        if (acceptRejectRecheckJobData?.serverResponse?.code === 200 || acceptRejectRecheckJobData?.serverResponse?.code === 201) {
            notificationHandler("recheck")
            setShowRecheckCommentModel(false)
        }
    }

    return (
        <Modal
            show={showRecheckCommentModel}
            onHide={() => setShowRecheckCommentModel(false)}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Rechecking Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!commentSection && (
                    <>
                        <p>Corrections may take up to 72 hours. Do you wish to proceed with asking for corrections?</p>
                        <Button variant="primary" onClick={() => setCommentSection(!commentSection)}>Yes</Button>{' '}
                        <Button variant="danger" onClick={() => setShowRecheckCommentModel(false)}>No</Button>
                    </>
                )}
                {commentSection && (
                    <div>
                        <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Write your comments.."
                            onChange={(e) => setRecheckComments(e.target.value)}
                            value={recheckComments}
                        />
                        <Button variant="primary" onClick={addCommentFunc}>Submit</Button>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default RecheckCommentModal
