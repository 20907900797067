import React from 'react'


const ProcessBanner = ({ slectedUserRole }) => {

    return (
        <section className="home-banner inner-banner">
            <div className="container">
                <div className="home-banner-wrap">
                    <div className="home-banner-img">
                        <img src="/images/Proofreading-banner.jpg" alt="" />
                    </div>
                    <div className="banner-t-wrap white-text">
                        <div className="container">
                            <div
                                className="banner-text-wrap"
                                data-aos="fade-up"
                                data-aos-duration={3000}
                            >
                                <h1>{slectedUserRole === 1 ? "Translator" : "Proofreader"}</h1>
                                <p>
                                    {slectedUserRole === 1 ? "Join us as a translator and unlock the opportunity to work from anywhere in the world, gain access to our abundant job board, and enjoy some of the highest industry rates – it's a career move that opens doors to limitless possibilities." : "Join us as a proofreader and open the door to a world of remote work opportunities from anywhere on the globe. Gain exclusive access to our thriving job board, all while enjoying some of the most competitive rates in the industry. It's not just a career move; it's an invitation to limitless possibilities in the world of proofreading."}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ProcessBanner