import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/reducers/authSlice";
import { editProfileApi } from "../../../services/api/profileApi";
import { editProfileValidatorUser } from "../../../services/formValidator/customerValidator";
import Select from "react-select";
import countryList from 'react-select-country-list'
import { countryCode } from "../../../constants/config";
const CustomerEditProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectCountry, setSelectCountry] = useState({
    countryVal: user.country,

  })
  const [getPhoneNumber, setGetPhoneNumber] = useState(false);
  const [selectedDialCode, setSelectedDialCode] = useState(user?.countryCode);

  const editProfileFormik = useFormik({
    initialValues: {
      name: user.name,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
      country: user.country,
    },
    validationSchema: editProfileValidatorUser.validationSchema,
    onSubmit: async (values) => {
      let data = values;
      let editProfiledata = await editProfileApi(data);
      dispatch(updateUser({ user: editProfiledata?.result?.data }));
    },
  });
  const countrySelectHandler = (countryVal) => {
    editProfileFormik.setFieldValue("country", countryVal?.label)
    setSelectCountry({ countryVal });
    countryCode.forEach((item) => {
      if (item.name === countryVal?.label) {
        setGetPhoneNumber(true)
        setSelectedDialCode(item.dial_code);
        editProfileFormik.setFieldValue("countryCode", item.dial_code)

      }
    });
  };;
  const handleKeyDownForCountryCode = (e) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', "+"];

    // Allow backspace (keyCode 8)
    if (e.keyCode === 8) {
      return;
    }

    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  // const handleKeyDownForPhone = (e) => {
  //   const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  //   // Allow backspace (keyCode 8)
  //   if (e.keyCode === 8) {
  //     return;
  //   }

  //   if (!allowedKeys.includes(e.key)) {
  //     e.preventDefault();
  //   }
  // };
  useEffect(() => {


    if (selectedDialCode?.length === 3) {


      if (!getPhoneNumber) {

        editProfileFormik.setFieldValue("phoneNumber", user?.phoneNumber)
      }
      else {
        editProfileFormik.setFieldValue("phoneNumber", 0)

      }
    }
    if (selectedDialCode?.length === 4) {

      if (!getPhoneNumber) {

        editProfileFormik.setFieldValue("phoneNumber", user?.phoneNumber)
      }
      else {
        editProfileFormik.setFieldValue("phoneNumber", "")
      }


    }
  }, [selectedDialCode])
  const handleInputChangeForPhone = (e) => {
    e.target.value = e.target.value.replace(/\D/g, ''); // Filter out non-digit characters
    editProfileFormik.handleChange(e); // Call Formik's handleChange with the modified event
  };
  return (
    <>
      <div className="col-lg-10">
        <div className="translator-edit-profile-right">
          <div className="content common-btm-mrgn">
            <h4>Edit profile</h4>
          </div>
          <div className="signin-form-wrap">
            <form onSubmit={editProfileFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      onChange={editProfileFormik.handleChange}
                      type="text"
                      placeholder=""
                      id="name"
                      name="name"
                      className="form-control"
                      value={editProfileFormik.values.name}
                    />
                  </div>
                </div>
                <p className="form-error-message">
                  {editProfileFormik.touched.name &&
                    editProfileFormik.errors.name}
                </p>

                {/* <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Last name</label>
                                                <input type="text" placeholder="" className="form-control" />
                                            </div>
                                        </div> */}
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      disabled
                      id="email"
                      name="email"
                      type="email"
                      placeholder=""
                      className="form-control"
                      value={user.email}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Country</label>
                    {/* <select
                                            name="country"
                                            id="country"
                                            className="form-select form-control"
                                            onChange={editProfileFormik.handleChange}
                                        >
                                            {chooseCountryTranslator.map((item, id) => (
                                                <option key={id} value={item?.value} selected={item?.value === editProfileFormik?.values?.country}>
                                                    {item?.name}
                                                </option>
                                            ))}
                                        </select>
                                        <p className="form-error-message">
                                            {editProfileFormik.touched.country &&
                                                editProfileFormik.errors.country}
                                        </p> */}
                    <div
                      className='form-control select-country'
                    >
                      <Select
                        id='country' name='country' onChange={countrySelectHandler} value={{ label: editProfileFormik?.values.country }}
                        isSearchable={true}
                        options={countryList().getData()}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: "none",
                          }),
                        }}

                      />
                      <p className='form-error-message'>{editProfileFormik.touched.country && editProfileFormik.errors.country}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group with-selectbox">
                    <label>Phone</label>
                    <input type="text" placeholder="" className="form-control" id='phoneNumber' name='phoneNumber' onChange={handleInputChangeForPhone} value={editProfileFormik.values.phoneNumber} />
                    <select className="form-select" disabled>
                      <option>{selectedDialCode}</option>

                    </select>
                    <p className='form-error-message'>{editProfileFormik.touched.phoneNumber && editProfileFormik.errors.phoneNumber}</p>

                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>Country Code</label>
                    <input type="text" placeholder="" className="form-control" id="countryCode"
                      name="countryCode"
                      onChange={editProfileFormik.handleChange}
                      value={editProfileFormik.values.countryCode}
                      onKeyDown={handleKeyDownForCountryCode} />
                    <p className="form-error-message">
                      {editProfileFormik.touched.countryCode &&
                        editProfileFormik.errors.countryCode}
                    </p>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="form-group mb-0">
                    <button type="submit" className="btn w-100">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerEditProfile;
