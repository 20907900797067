import React from "react";
import ProofReaderManagerEditProfile from "./ProofReaderManagerEditProfile";
import LeftSide from "./LeftSide";
import { Route, Routes } from "react-router-dom";
import ChangePassProofReaderManager from "./ChangePassProofReaderManager";

const SettingsProofreaderManager = () => {
  return (
    <>
      <section className="user-dashbord sec_pad">
        <div className="container">
          <div className="row">
            <LeftSide />
            <Routes>
              <Route
                path="/edit-profile"
                element={<ProofReaderManagerEditProfile />}
              />
              <Route
                path="/change-password"
                element={<ChangePassProofReaderManager />}
              />
            </Routes>
          </div>
        </div>
      </section>
    </>
  );
};

export default SettingsProofreaderManager;
