import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userLogin } from "../../services/api/authApis";
import { toast } from "react-toastify";
const initialState = {
  user: null,
  company: [],
  isFetching: false,
  isError: false,
  isLogin: false,
  error: null,
  currentPageNotification: 1,
  notifications: [],
  dashBoarddata: {},
};
export const signIn = createAsyncThunk(
  "auth/signIn",
  async (values, { rejectWithValue }) => {
    try {
      const data = await userLogin(values);
      console.log('data-login api ===>', data);
      if (data?.serverResponse.code !== 200) {
        toast.error(data?.serverResponse.message);
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
    updateCompany: (state, action) => {
      
      const { company } = action.payload;
      state.company = company;
    },
    deleteUser: (state, action) => {
      const { userID } = action.payload;
      state.userID = userID;
    },
    updateNotification: (state, action) => {
      const { notifications, pageNumber } = action.payload;
      if (state.notifications?.length > 0 && notifications) {
        state.notifications = [...state.notifications, ...notifications];
      } else {
        state.notifications = notifications;
      }
      state.currentPageNotification = pageNumber;
    },
    updateNotificationSocket: (state, action) => {
      const { notifications } = action.payload;
      // if (state.notifications?.length > 0 && notifications) {
      //   state.notifications = [notifications, ...state.notifications];
      // } else {
        state.notifications = notifications;
      // }
    },
    logOutUser: (state) => {
      state.user = null;
      state.isFetching = false;
      state.isError = false;
      state.isLogin = false;
      state.error = null;
      state.notifications = [];
      state.currentPageNotification = 1;
    },
    updateDashboardData: (state, action) => {
      state.dashBoarddata = action.payload;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.isFetching = true;
      state.isLogin = false;
      state.isError = false;
      return state;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.isLogin = true;
      state.user = payload.result.data;
      state.company = payload.result.company;
      state.isFetching = false;
      state.isError = false;
      state.error = null;
      sessionStorage.removeItem("userType");
      return state;
    },
    [signIn.rejected]: (state, { payload }) => {
      state.isLogin = false;
      state.user = null;
      state.company = [];
      state.company = null;
      sessionStorage.removeItem("userType");
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
      return state;
    },
  },
});

// this is for dispatch
export const {
  updateUser,
  updateCompany,
  logOutUser,
  deleteUser,
  updateNotification,
  updateDashboardData,
  updateNotificationSocket,
} = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
