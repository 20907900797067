import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/reducers/authSlice";
import { updateUserPinCode } from "../../../services/api/profileApi";
import Form from 'react-bootstrap/Form';


const CertificatePinCode = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isPinCodeEnabled, setIsPinCodeEnabled] = useState(false);

  const certificatePinCodeFormik = useFormik({
    initialValues: {
      pincode: user.pinCode
    },
    onSubmit: async (values) => {
      values.pincode = !isPinCodeEnabled ? "" : values.pincode;
      let serverResponse = await updateUserPinCode(
        values
      );
      dispatch(updateUser({ user: serverResponse?.result?.data }));
    },
  });

  useEffect(() => {
    setIsPinCodeEnabled(user?.pinCode ? true : false)
}, [window.location.href]);

  const toggle = () => setIsPinCodeEnabled(o => !o);

  return (
    <>
      <div className="col-lg-10">
        <div className="translator-edit-profile-right">
          <div className="content common-btm-mrgn">
            <h4>Ceritificate PIN Code</h4>
          </div>
          <div className="signin-form-wrap">
            <form onSubmit={certificatePinCodeFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Enable?</label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      className="toggle-checkbox"
                      onChange={toggle}
                      checked={isPinCodeEnabled}
                    />
                  </div>
                </div>
                <p className="form-error-message">
                  {certificatePinCodeFormik.touched.name &&
                    certificatePinCodeFormik.errors.name}
                </p>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>PIN Code</label>
                    <input
                      disabled={isPinCodeEnabled === true ? false : true}
                      required={isPinCodeEnabled === true ? false : true}
                      id="pincode"
                      name="pincode"
                      type="text"
                      placeholder=""
                      className="form-control"
                      value={certificatePinCodeFormik.values.pincode}
                      onChange={certificatePinCodeFormik.handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-0">
                    <button type="submit" className="btn w-100">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificatePinCode;
