import * as Yup from "yup";
//translator
export const editProfileValidatorTranslator = {
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(20, "Max can be 20 characters"),
    phoneNumber: Yup.string()
      .test(
        "is-valid-phone-number",
        "Phone number is not valid",
        (value, { parent }) => {
          if (parent.countryCode && parent.countryCode.length === 3) {
            return /^\d{10}$/.test(value);
          } else if (parent.countryCode && parent.countryCode.length === 4) {
            return /^\d{9}$/.test(value);
          }
          return true; // Allow empty value if countryCode is not set
        }
      )
      .required("Phone number is required"),
    // collegeName: Yup.string().required("Please Select option"),
    country: Yup.string().required("Please Select option"),
    // timezone: Yup.string().required("Please Select option"),
    // schoolName: Yup.string()
    //   .required("School name can not be empty")
    //   .min(10, "Must be 10 characters")
    //   .max(20, "Max can be 20 characters"),
    // fieldOfStudy: Yup.string().required("Please Select option"),
    // levelOfStudy: Yup.string().required("Please Select option"),
    // expectedGraduationDate: Yup.string().required(
    //   "Graduation date can not be empty"
    // ),
    // .min(3, "Must be 3 characters")
    // .max(8, "Max can be 8 characters"),
  }),
};
//change password
export const changePassFormValidatorTranslator = {
  initialValues: {
    newPassword: "",
    confrmPassword: "",
  },
  validationSchema: Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(/\w/, "Please enter valid password"),
    confrmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), ""], "Password must match")
      .required("Confirm password field is required")
      .matches(/\w/, "Please enter valid password"),
  }),
};
//add qualifications
export const addQualiTranslatorValidator = {
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    // certificatePhoto: Yup.array().required("Please upload file"),
    resume: Yup.array().required("Please upload file"),
    // sampleWork: Yup.array().required("Please upload file"),
    // latestDegree: Yup.string().when("other", (other, schema) => {
    //   if (other[0]) {
    //     return schema;
    //   } else {
    //     return schema.required("Please select a value or add other value");
    //   }
    // }),
    // others: Yup.string().when("latestDegree", (latestDegree, schema) => {
    //   if (latestDegree[0]) {
    //     return schema;
    //   } else {
    //     return schema.required("field required");
    //   }
    // }),
    // specialization: Yup.string().when("additional", (additional, schema) => {
    //   if (additional[0]) {
    //     return schema;
    //   } else {
    //     return schema.required("Please select a value or add other value");
    //   }
    // }),
    // additional: Yup.string().when("latestDegree", (specialization, schema) => {
    //   if (specialization[0]) {
    //     return schema;
    //   } else {
    //     return schema.required("field required");
    //   }
    // }),
    translatingExperience: Yup.string().required("Please Select option"),
    videoWatched: Yup.bool().oneOf([true], 'Field must be checked'),
    nativeLanguage: Yup.string().required("Please Select option"),
    languageToTranslateFrom: Yup.string().required("Please Select option"),
    portfolioLink: Yup.string().matches(
      /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
      "Please give valid link"
    ),
    // .required("Please give required link")
    // pagesYouCanTranslatePerDay: Yup.string().required("Please Select option"),
    // timezone: Yup.string().required("Please Select option"),
    // country: Yup.string().required("Please Select option"),
    // activeHours: Yup.string().required("Please Select option"),
  }),
};
