import React from 'react'

const Accessibility = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <h1>Accessibility Statement</h1>
                                <p>At Lingvara, we are committed to ensuring digital accessibility for all individuals, including those with disabilities. We strive to provide an inclusive and user-friendly experience for all users of our website. We are continually improving the accessibility of our website to meet or exceed applicable accessibility standards and guidelines.</p>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec common-btm-padd">

                <div className="container">

                    <p className='static-text'><strong>Accessibility Features</strong></p>
                    <p>We have implemented the following features to enhance the accessibility of our website:</p>
                    <ol>
                        <li>
                            <p><strong>Alternative Text</strong>: We strive to ensure all images on our website have alternative text descriptions, which can be read by screen readers, providing a textual representation of the image.</p>
                        </li>
                        <li>
                            <p><strong>Keyboard Navigation</strong>: Our website supports keyboard navigation to allow users who cannot use a mouse or other pointing device to navigate through the site using keyboard controls.</p>
                        </li>
                        <li>
                            <p><strong>Clear Design and Layout</strong>: We aim to maintain a clear and consistent design and layout throughout our website, making it easier for users to navigate and locate information.</p>
                        </li>
                        <li>
                            <p><strong>Color Contrast</strong>: We have taken steps to ensure sufficient color contrast between text and background elements, making the content more readable for individuals with visual impairments.</p>
                        </li>
                        <li>
                            <p><strong>Resizable Text</strong>: Our website allows users to adjust the text size according to their preferences, using browser settings or built-in website controls.</p>
                        </li>
                        <li>
                            <p><strong>Forms and Input Fields</strong>: We have implemented accessible form structures and labels to assist users in completing online forms and input fields accurately.</p>
                        </li>
                    </ol>
                    <p><strong>Third-Party Content and Plugins</strong></p>
                    <p>While we strive to ensure accessibility on our website, we may occasionally feature third-party content or plugins that may not be fully accessible. We work with our third-party partners to encourage accessibility compliance, but we cannot guarantee the accessibility of their content or features.</p>
                    <p><strong>Feedback and Assistance</strong></p>
                    <p>We are dedicated to providing an accessible website experience. If you encounter any difficulties or have any suggestions to enhance accessibility on our website, please contact us. Your feedback is valuable to us as we continue to improve accessibility for all users.</p>
                    <p><strong>Accessibility Standards</strong></p>
                    <p>We aim to adhere to the Web Content Accessibility Guidelines (WCAG) 2.1 Level AA as the benchmark for accessibility standards. These guidelines are internationally recognized and provide a comprehensive set of recommendations for making web content more accessible.</p>
                    <p><strong>Continuous Improvement</strong></p>
                    <p>We are committed to ongoing efforts to improve the accessibility of our website. Our team regularly reviews and assesses our website's accessibility features, ensuring compliance with evolving standards and best practices.</p>
                    <p><strong>Contact Us</strong></p>
                    <p>If you have any questions, concerns, or need assistance with accessibility on our website, please contact us using the contact information provided on our website. We value your feedback and are dedicated to providing equal access to all users.</p>
                </div>

            </section>


        </>
    )
}

export default Accessibility