import { Navigate } from "react-router-dom";

export const navigateTo = (role, navigate, user, userType) => {
  if (role === "proofreader") {
    if (user?.isQualificationVerified) {
      navigate("/proofreader-dashboard");
    } else {
      navigate("/add-qualification");
    }
  }
  if (role === "translator") {
    if (user?.isQualificationVerified) {
      navigate("/translator-dashboard");
    } else {
      navigate("/add-qualification");
    }
  }

  if (role === "user") {
    navigate("/customer-dashboard");
  }
  if (role === "company") {
    navigate("/business-dashboard");
  }
  if (role === "project-manager-for-translator") {
    navigate("/translator-manager-dashboard");
  }
  if (role === "project-manager-for-proofreader") {
    navigate("/proofreader-manager-dashboard");
  }
};

export const NavigateTo = ({ role, isQualificationVerified, userType }) => {
  console.log(userType)
  if (role === "proofreader") {
    if (isQualificationVerified) {
      return <Navigate to="/proofreader-dashboard" replace />;
    } else {
      return <Navigate to="/add-qualification" replace />;
    }
  }
  if (role === "translator") {
    if (isQualificationVerified) {
      return <Navigate to="/translator-dashboard" replace />;
    } else {
      return <Navigate to="/add-qualification" replace />;
    }
  }
  if (role === "user") {
    return <Navigate to="/customer-dashboard" replace />;
  }
  if (role === "company") {
    if (!userType.name) {
      return <Navigate to="/company" replace />;
    }
    return <Navigate to="/business-dashboard" replace />;
  }
  if (role === "project-manager-for-translator") {
    return <Navigate to="/translator-manager-dashboard" replace />;
  }
  if (role === "project-manager-for-proofreader") {
    return <Navigate to="/proofreader-manager-dashboard" replace />;
  }
};
