import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  citationStyleJson,
  deadlineStaticArray,
  docTypesJsonData,
  proffReaderEngilshDialectJson,
  proffReaderWritingStyleJson,
  specificServiceProofreaderJson,
} from "../../../constants/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  removeFileFromS3,
  uploadJobFile,
  checkWordCount
} from "../../../services/api/jobPostApis";
import { splitProfJobValidatior } from "../../../services/formValidator/proofreaderManagerValidator";
import DragDropUploadButton from "../../../components/common/DragDropUploadButton";
import { toast } from "react-toastify";

const ConfigurationCard = ({ index, postSplit, setPostSplit, setJobArr }) => {
  const [showConfiguration, setShowConfiguration] = useState(false);
  const { selectedJobDetails } = useSelector((state) => state.job);

  const [jobFiles, setJobFiles] = useState(null);
  const [wordCount, setWordCount] = useState(null)


  const wordCountCheck = async (data) => {
    let uploadFileData = await checkWordCount(data[0])
    if (uploadFileData?.result) {
      setWordCount(uploadFileData?.result?.data?.wordCount)
      splitFileFormik?.setFieldValue('jobFile', data[0])
      splitFileFormik?.setFieldValue('wordCount', uploadFileData?.result?.data?.wordCount)
    }
    setJobFiles(data[0])
  }


  const uploadJobFiles = async (data) => {
    let resData = await uploadJobFile(data[0]);
    setJobFiles(resData?.result?.data);
    splitFileFormik?.setFieldValue("jobFile", resData?.result?.data?.responseData);
    splitFileFormik?.setFieldValue("wordCount", resData?.result?.data?.wordCount);
  };
  const removeItem = async () => {
    setJobFiles(null)
  };
  const splitFileFormik = useFormik({
    initialValues: splitProfJobValidatior(
      selectedJobDetails,
      index,
      "initialValues"
    ),
    validationSchema: splitProfJobValidatior(
      selectedJobDetails,
      index,
      "validationSchema"
    ),
    onSubmit: (values) => {
      if (jobFiles) {
        setJobArr((prev) => [...prev, values]);
      } else {
        toast.error(`Fill all fields in job ${index + 1}`);
      }
    },
  });
  const selectDeadLine = (e) => {
    let perWordPrice =
      deadlineStaticArray[
        deadlineStaticArray?.findIndex((item) => item?.value === e.target.value)
      ]?.priceValue;
    splitFileFormik?.setFieldValue("deadline", e.target.value);
    splitFileFormik?.setFieldValue(
      "totalJobPrice",
      perWordPrice * splitFileFormik?.values?.wordCount
    );
    splitFileFormik?.setFieldValue(
      "perWordPrice",
      Number(perWordPrice.toFixed(4))
    );
  };
  useEffect(() => {
    if (postSplit) {
      if (jobFiles && !splitFileFormik.isValid) {
        toast.error(`Fill all fields in job ${index + 1}`);
      }
      splitFileFormik.handleSubmit();
      setPostSplit(false);
    }
  }, [postSplit]);
  return (
    <div className="acc-wrap-pm">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              onClick={() => setShowConfiguration(!showConfiguration)}
            >
              <div className="button-table btn-table">
                <div className="button-table-head">
                  <div className="tab-2">#Order</div>
                  <div className="tab-2">Wordcount</div>
                  <div className="tab-2">Deadline</div>
                  <div className="tab-2">Type of doc</div>
                  <div className="tab-2">Language</div>
                  <div className="tab-2">Price/word</div>
                  <div>
                    {showConfiguration ? (
                      <div
                        data-aos="fade-up"
                        data-aos-offset={100}
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={"/images/acc-arrow1.png"} height={40} />
                      </div>
                    ) : (
                      <div
                        data-aos="fade-up"
                        data-aos-offset={100}
                        data-aos-easing="ease-in-sine"
                      >
                        <img src={"/images/acc-arrow2.png"} height={40} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="button-table-body">
                  <div className="tab-2">
                    <div
                      className="p-a"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      7/4/23/1
                    </div>
                  </div>
                  <div className="tab-2">{jobFiles?.wordCount ?? "--"}</div>
                  <div className="tab-2">{splitFileFormik?.values?.deadline} : 00min : 00sec</div>
                  <div className="tab-2">
                    {selectedJobDetails?.jobDocumentType}
                  </div>
                  <div className="tab-2">
                    {selectedJobDetails?.requiredEnglishDialect}
                  </div>
                  <div className="tab-2">
                    <div>
                      <input type='number' className="a-border" name='perWordPrice' id='perWordPrice' value={splitFileFormik?.values?.perWordPrice} onChange={(e) => splitFileFormik?.setFieldValue('perWordPrice', (e.target.value > selectedJobDetails?.perWordPrice) ? selectedJobDetails?.perWordPrice : (e.target.value < 0) ? 0 : e.target.value)} style={{ textAlign: 'center' }} />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </button>
          </h2>
          {showConfiguration && (
            <div id="collapseOne" className="accordion-collapse collapse show">
              <div className="accordion-body">
                <div className="acc-body-item">
                  <div className="item-acc-1">
                    <div className="signin-form-wrap">
                      <form onSubmit={splitFileFormik?.handleSubmit}>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="item-acc-wrap">
                              <div className="form-group">
                                <label for="">Select deadline</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="deadline"
                                  id="deadline"
                                  onChange={(e) => {
                                    selectDeadLine(e);
                                  }}
                                  disabled={!jobFiles}
                                >
                                  {deadlineStaticArray.map((val, idx) => (
                                    <option
                                      selected={
                                        val?.value ===
                                        splitFileFormik?.values?.deadline
                                      }
                                    >
                                      {val.time}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="item-acc-wrap">
                              <div className="form-group">
                                <label for="">Type of document</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  disabled
                                >
                                  {docTypesJsonData.map((val, idx) => (
                                    <option
                                      selected={
                                        selectedJobDetails?.jobDocumentType
                                      }
                                      value={val.value}
                                    >
                                      {val.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="item-acc-wrap">
                              <div className="form-group">
                                <label for="">Word count</label>
                                <input
                                  type="text"
                                  placeholder={500}
                                  className="form-control"
                                  value={wordCount}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {jobFiles ? (
                            <div className="file-list-table">
                              <div className="signin-form-wrap e-ch upload-file-section">
                                <div className="form-group">
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    placeholder=""
                                    id="jobTitle"
                                    name="jobTitle"
                                    className="form-control"
                                    value={splitFileFormik?.values?.jobTitle}
                                    onChange={splitFileFormik?.handleChange}
                                  />
                                  <p className="form-error-message">
                                    {splitFileFormik.touched.jobTitle &&
                                      splitFileFormik.errors.jobTitle}
                                  </p>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="">Additonal Comments?</label>
                                  <textarea
                                    name="addComments"
                                    id="addComments"
                                    cols="10"
                                    rows="7"
                                    className="form-control"
                                    value={
                                      splitFileFormik?.values?.comment
                                    }
                                    onChange={splitFileFormik?.handleChange}
                                  ></textarea>
                                  <p className="form-error-message">
                                    {splitFileFormik.touched.addComments &&
                                      splitFileFormik.errors.addComments}
                                  </p>
                                </div>
                                <table className="table table-responsive">
                                  <thead>
                                    <tr>
                                      <th colSpan="2">File name</th>
                                      <th scope="col">Word Count</th>
                                      <th scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td colSpan="2">
                                        <h6>
                                          <span>
                                            <i className="fas fa-file-alt"></i>
                                          </span>
                                          {jobFiles?.name}
                                        </h6>
                                      </td>
                                      <td>{wordCount}</td>
                                      <td>
                                        <p onClick={removeItem}>
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            <div className="col-lg-12">
                              <div className="form-group file-upload">
                                <label htmlFor="myfile">
                                  <span>Upload</span>
                                  or Drag &amp; drop your entire document here
                                </label>
                                <DragDropUploadButton
                                  multiple={true}
                                  onChange={wordCountCheck}
                                />
                              </div>
                            </div>
                          )}

                          <div className="row-section">
                            <h5>Choose specific service:</h5>
                            <div className="radio-li half-radio-li">
                              <ul>
                                {specificServiceProofreaderJson.map(
                                  (val, idx) => (
                                    <li>
                                      <div className="radio-group">
                                        <input
                                          type="radio"
                                          name="radio-group3"
                                          id="test4"
                                          value={val.value}
                                          disabled
                                          checked={
                                            selectedJobDetails?.proofReadingService ===
                                            val?.value
                                          }
                                        />
                                        <label for="test4">
                                          <h5>{val.name}</h5>
                                        </label>
                                      </div>
                                    </li>
                                  )
                                )}
                                
                              </ul>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <h5>Which Citation style do you prefer?</h5>
                              <div className="row">
                                {citationStyleJson.map((val, idx) => (
                                  <div className="col-lg-4 mb-3">
                                    <div className="rdio-btn-for-db-frm">
                                      <input
                                        type="radio"
                                        id="test4"
                                        disabled
                                        name="radio-group2"
                                        value={val.value}
                                        checked={
                                          selectedJobDetails?.citationStyle
                                        }
                                      />
                                      <label for="test4">{val.name}</label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="item-acc-wrap">
                              <div className="form-group">
                                <label htmlFor="">
                                  <h5>Choose the required English Dialect</h5>
                                </label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                >
                                  {proffReaderEngilshDialectJson.map(
                                    (val, idx) => (
                                      <option
                                        disabled
                                        selected={
                                          selectedJobDetails?.requiredEnglishDialect
                                        }
                                        value={val.value}
                                      >
                                        {val.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row-section">
                            <h5>
                              Which of the following best describes the intended
                              style of writing?
                            </h5>
                            <div className="col-lg-12">
                              <div className="radio-li half-radio-li">
                                <ul>
                                  {proffReaderWritingStyleJson.map(
                                    (val, idx) => (
                                      <li>
                                        <div className="radio-group">
                                          <input
                                            disabled
                                            type="radio"
                                            name="plan"
                                            checked={
                                              selectedJobDetails?.intendedStyle
                                            }
                                            value={val.value}
                                          />
                                          <label for="html">
                                            <h5>{val.name}</h5>
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-lg-12">
                            <div className="pm-tr-button">
                              <button type="submit" className="btn">
                                Post
                              </button>
                              <button
                                type="submit"
                                className="btn btn-border-g"
                              >
                                Assign
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationCard;
