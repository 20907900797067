import React, { useEffect, useState } from 'react'
import Profreader from './Profreader'
import Translator from './Translator'
import UserSwitch from '../../../components/common/UserSwitch'

import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import User from './User'
import { useSearchParams } from 'react-router-dom'
import Business from './Business'

const SignUp = () => {
    const [searchParams] = useSearchParams()

    const [slectedUserRole, setSelectedtUserRole] = useState(Number(searchParams.get('usertype')) ?? 0)
    const [termCondition, settermsCondition] = useState(false)
    const [code,setCode]=useState(null)
    const userType = (role) => {
        settermsCondition(false)
        setSelectedtUserRole(role)
    }
    useEffect(()=>{
       let findCode=searchParams.get('code')
       if(findCode){
        setCode(findCode)
       }
       

    },[window.location.href])


    return (
        <section className="user-dashbord sec_pad db-user-signup">
            <div className="container">
                <div className="content text-center common-btm-mrgn">
                    {/* <h4>Become a Translator or Proofreader or User</h4> */}
                </div>
                <div className="ongong-and-post-project-sec translator-signup-wrap">
                    <div className="card">
                        {(slectedUserRole === 0 || slectedUserRole === 3) && <UserSwitch onToggle={userType} slectedUserRole={slectedUserRole} />}
                        <div className="tab-content" id="nav-tabContent">
                            {slectedUserRole === 0 && <User findCode={code} setSelectedtUserRole={setSelectedtUserRole} slectedUserRole={slectedUserRole} settermsCondition={settermsCondition} termCondition={termCondition} />}
                            {slectedUserRole === 3 && <Business findCode={code} setSelectedtUserRole={setSelectedtUserRole} slectedUserRole={slectedUserRole} settermsCondition={settermsCondition} termCondition={termCondition} />}
                            {slectedUserRole === 1 && <Translator slectedUserRole={slectedUserRole} settermsCondition={settermsCondition} termCondition={termCondition} />}
                            {slectedUserRole === 2 && <Profreader slectedUserRole={slectedUserRole} settermsCondition={settermsCondition} termCondition={termCondition} />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignUp