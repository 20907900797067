import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
// import ProjectSinglecard from './SingleRecentProject';
import { getAllJobs } from '../../../services/api/jobApis';
import { clearJobs, setJobs, setMyReceipts } from '../../../redux/reducers/jobSlice';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';
import { NavLink } from 'react-router-dom';
import SingleTransactionCard from './SingleTransactionCard';
import { getAllReceipts } from '../../../services/api/transactionsApis';

const RecentTranscationSlider = ({ gotoSelectedReceipt }) => {
    const dispatch = useDispatch()
    const { myReceipts } = useSelector((state) => state.job)
    const options = {
        items: 2,
        dots: false,
        nav: true,
        loop: false,
        margin: 10,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            }
        }
    }
    const allReceipts = async () => {
        dispatch(startLoading())
        let data = await getAllReceipts({ pageNumber: 1, dataPerPage: 10 })
        if (data?.data?.length > 0) {
            dispatch(setMyReceipts({ receipts: data?.data, pageNumber: data?.pageNumber, dataPerPage: data?.dataPerPage, totalReceiptCount: data?.dataCount }))
        }

        dispatch(stopLoading())
    }
    // useEffect(() => {
    //     if (!myJobs?.length) {
    //         getOngoingProjects()
    //     }
    // }, [myJobs])
    // useEffect(() => {
    //     return () => {
    //         dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
    //     }
    // }, [])
    useEffect(() => {
        allReceipts()
        return () => {
            dispatch(setMyReceipts({ receipts: null, pageNumber: 1, dataPerPage: 0, totalReceiptCount: 0 }));
        }
    }, [])

    return (
        <div className="dashbord-recent-project common-btm-mrgn-big">
            {(myReceipts && myReceipts?.length > 0) && <div className="container">
                <div className="section-head-two common-btm-mrgn">
                    <h4>Recent Receipts (<NavLink to='/customer/receipts-list'>All Receipts</NavLink>)</h4>
                </div>
                <div className="recnt-pro-wraper">
                    <OwlCarousel className='owl-carousel owl-theme' {...options}>
                        {
                            myReceipts?.map((item, idx) => {
                                return (
                                    <SingleTransactionCard item={item} idx={idx} gotoSelectedReceipt={gotoSelectedReceipt} />
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
            </div>}
        </div>

    )
}

export default RecentTranscationSlider