import { toast } from "react-toastify";
import http from "./http";

export const editProfileApi = async (values) => {
  let editProfileData = await http.put("/profile/edit/profile", values);
  if (editProfileData?.serverResponse.code === 200) {
    toast.success(editProfileData?.serverResponse.message);
    return editProfileData;
  } else {
    toast.error(editProfileData?.serverResponse.message);
  }
};
export const changePassword = async (newPassword, confrmPassword) => {
  let bodyData = {
    newPassword: newPassword,
    confirmPassword: confrmPassword,
  };
  let changePassResult = await http.patch("/profile/change/password", bodyData);
  if (changePassResult?.serverResponse.code === 201) {
    return changePassResult;
  } else {
    toast.error(changePassResult?.serverResponse.message);
  }
};
//uploads api for proofReader and translator
export const uploadCertificate = async (values) => {
  let fd = new FormData();

  for (let i = 0; i < values.length; i++) {
    fd.append("file", values[i]);
  }
  // let formdata = new FormData();
  // formdata.append("file", [values]);
  let certificateFileUploadData = await http.post(
    "/uploads/file/upload/certificate",
    fd,
    {
      "Content-Type": "multipart/form-data",
    }
  );
  if (certificateFileUploadData?.serverResponse.code === 200) {
    return certificateFileUploadData;
  } else {
    toast.error(certificateFileUploadData?.serverResponse.message);
  }
};
export const uploadResume = async (values) => {
  // let formdata = new FormData();
  // formdata.append("file", [values]);
  let fd = new FormData();

  for (let i = 0; i < values.length; i++) {
    fd.append("file", values[i]);
  }
  let resumeFileUploadData = await http.post(
    "/uploads/file/upload/resume",
    fd,
    {
      "Content-Type": "multipart/form-data",
    }
  );
  if (resumeFileUploadData?.serverResponse.code === 200) {
    return resumeFileUploadData;
  } else {
    toast.error(resumeFileUploadData?.serverResponse.message);
  }
};
export const uploadSampleWork = async (values) => {
  // let formdata = new FormData();
  // formdata.append("file", values);
  let fd = new FormData();

  for (let i = 0; i < values.length; i++) {
    fd.append("file", values[i]);
  }
  let sampleWorkFileUploadData = await http.post(
    "/uploads/file/upload/sample-work",
    fd,
    {
      "Content-Type": "multipart/form-data",
    }
  );
  if (sampleWorkFileUploadData?.serverResponse.code === 200) {
    return sampleWorkFileUploadData;
  } else {
    toast.error(sampleWorkFileUploadData?.serverResponse.message);
  }
};
export const uploadDegreeCertificate = async (values) => {

  let fd = new FormData();
  for (let i = 0; i < values.length; i++) {
    fd.append("file", values[i]);
  }
  let sampleWorkFileUploadData = await http.post(
    "/uploads/file/upload/degree-certificate",
    fd,
    {
      "Content-Type": "multipart/form-data",
    }
  );
  if (sampleWorkFileUploadData?.serverResponse.code === 200) {
    return sampleWorkFileUploadData;
  } else {
    toast.error(sampleWorkFileUploadData?.serverResponse.message);
  }
};

//add qualifications for proofreader
export const addQualiProofReaderApi = async (values, id) => {
  let apiData = {
    email: values.email,
    certificatePhoto: values.certificatePhoto,
    resume: values.resume,
    sampleWork: values.sampleWork,
    degreeCertificate: values.degreeCertificate,
    latestDegree: values.latestDegree,
    other: values.other,
    specialization: values.specialization,
    additional: values.additional,
    translatingExperience: values.translatingExperience,
    nativeLanguage: values.nativeLanguage,
    languagesToProofread: values.languagesToProofread,
    abroadExperienceLong: values.abroadExperienceLong,
    abroadExperienceWhere: values.abroadExperienceWhere,
    citationConventions: values.citationConventions,
    portfolioLink: values.portfolioLink,
    // pagesYouCanTranslatePerDay: values.pagesYouCanTranslatePerDay,
    // activeHours: values.activeHours,
  };

  console.log(apiData);
  if (apiData.other) {
    delete apiData.latestDegree;
  }
  if (apiData.latestDegree) {
    delete apiData.other;
  }
  if (apiData.specialization) {
    delete apiData.additional;
  }
  if (apiData.additional) {
    delete apiData.specialization;
  }
  let addQualiData = id
    ? await http.put("/proofreaders/update-qualification", apiData)
    : await http.post("/proofreaders/add-qualification", apiData);
  if (addQualiData?.serverResponse.code === 201) {
    // toast.success(addQualiData?.serverResponse.message);
    return addQualiData;
  } else {
    toast.error(addQualiData?.serverResponse.message);
  }
};
//add qualifications for translator
export const addQualiTranslatorApi = async (values, id) => {
  let apiData = {
    email: values.email,
    certificatePhoto: values.certificatePhoto,
    resume: values.resume,
    sampleWork: values.sampleWork,
    degreeCertificate: values.degreeCertificate,
    latestDegree: values.latestDegree,
    other: values.other,
    specialization: values.specialization,
    additional: values.additional,
    translatingExperience: values.translatingExperience,
    nativeLanguage: values.nativeLanguage,
    languageToTranslateFrom: values.languageToTranslateFrom,
    portfolioLink: values.portfolioLink,
    // pagesYouCanTranslatePerDay: values.pagesYouCanTranslatePerDay,
    // activeHours: values.activeHours,
  };
  if (apiData.other) {
    delete apiData.latestDegree;
  }
  if (apiData.latestDegree) {
    delete apiData.other;
  }
  if (apiData.specialization) {
    delete apiData.additional;
  }
  if (apiData.additional) {
    delete apiData.specialization;
  }
  let addQualiData = await (id
    ? http.put("/translators/update-qualification", apiData)
    : http.post("/translators/add-qualification", apiData));
  if (addQualiData?.serverResponse.code === 201) {
    // toast.success(addQualiData?.serverResponse.message);
    return addQualiData;
  } else {
    toast.error(addQualiData?.serverResponse.message);
  }
};
/**
 * get proofreader qualification
 */

export const getProofReaderQualification = async () => {
  let proofreaderQualification = await http.get(
    "/proofreaders/get-qualification"
  );
  if (proofreaderQualification?.serverResponse?.code === 200) {
    return proofreaderQualification;
  }
};
/**
 * get translator qualification
 */

export const getTranslatorQualification = async () => {
  let translatorQualification = await http.get(
    "/translators/get-qualification"
  );
  if (translatorQualification?.serverResponse?.code === 200) {
    return translatorQualification;
  }
};
/**
 * Get Dashboard data
 */
export const getDashboardData = async () => {
  let dashboardData = await http.get("/profile/dashboard");
  if (dashboardData?.serverResponse?.code === 200) {
    return dashboardData;
  }
};


export const getProfile = async () => {
  let getProfileDetails = await http.get("/profile/view/profile");
  if (getProfileDetails?.serverResponse.code === 200) {
    return getProfileDetails;
  } else {
    toast.error(getProfileDetails?.serverResponse.message);
  }
};

export const updateRating = async (values, type) => {
  console.log(type, "type");
  let ratingData = await http.patch(`/update-rank/${type}`, values);
  console.log(
    ratingData?.serverResponse.code,
    "ratingData?.serverResponse.code"
  );
  if (ratingData?.serverResponse.code === 201) {
    return ratingData;
  }
};

export const getSingleApiForServiceProvider = async (values) => {
  let ratingData = await http.get(`/update-rank/get-rank-data/${values}`, {});
  console.log(
    ratingData?.serverResponse.code,
    "ratingData?.serverResponse.code"
  );
  if (ratingData?.serverResponse.code === 200) {
    return ratingData;
  }
};
export const getAllNotifications = async (values) => {
  let notifications = await http.get(
    `/notifications/list?dataPerPage=${values?.dataPerPage}&pageNumber=${values?.pageNumber}`
  );
  if (notifications?.serverResponse.code === 200) {
    return notifications;
  }
};

export const getallManagers = async (role) => {
  let managers = await http.get(`/${role}s/manager/list `);
  if (managers?.serverResponse.code === 200) {
    return managers;
  }
};

export const downloadAllPaidReceiptsReq = async () => {
  let result = await http.get(`/jobs/reciept/download?jobStatus=all`);
  // if (result?.serverResponse.code === 200) {
  //   return true;
  // }
};

export const downloadAllUnPaidIvoicesReq = async () => {
  let result = await http.get(`/jobs/invoice/download?jobStatus=all`);
  // if (result?.serverResponse.code === 200) {
  //   return true;
  // }
};

/**
 * Get businessUsers list
 */
export const getBusinessUsers = async (values) => {
  let businessUsers = await http.get(`/company-users/${values?.id}?dataPerPage=10&pageNumber=${values?.pageNumber}`);
  
  if (businessUsers?.serverResponse?.code === 200) {
    return businessUsers;
  }
};

/**
 * Update business User block status
 */
export const updateBusinessUserBlockStatus = async (values) => {
  let businessUser = await http.post(`/company-users/block/unblock/?userId=${values?.idReq}&isBlocked=${values?.isBlocked}`);
  
  if (businessUser?.serverResponse?.code === 200) {
    return businessUser;
  }
};

/**
 * Update pincode
 */
export const updateUserPinCode = async (values) => {
  let userProfile = await http.put(`/profile/update-pincode`, values);
  
  if (userProfile?.serverResponse.code === 200) {
    toast.success(userProfile?.serverResponse.message);
    return userProfile;
  } else {
    toast.error("PIN Code not changed");
  }

};
