import React from 'react'
import { useSelector } from 'react-redux'

const BusinessJobPostStapper = () => {
    const { user } = useSelector((state) => state.auth)
    const { stepperArray } = useSelector((state) => state.jobPost)
    console.log(stepperArray, "cus");
    return (
        <>
            {window.innerWidth > 991 && <div className="col-lg-4 job-post-stepper">
                <div id="progressbar" className="progressbar">
                    {(!user || !user.isVerified) && <div className="active step-list step0">
                        <h6>Sign In</h6>
                        <p>Create your account
                        </p>
                    </div>}
                    {
                        stepperArray && stepperArray.map((item, index) => {
                            return (
                                <div className={`step-list ${item?.isComplete && 'active'} ${item?.isActive && 'active-now'} step0`} key={index}>
                                <h6>{item?.name}</h6>
                                <p>
                                    {item?.description}
                                </p>
                            </div>
                              
                            )
                        })
                    }
                </div>
            </div>}
        </>
    )
}
export default BusinessJobPostStapper