import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RecentProjects from '../../components/common/recentProjectSlider/RecentProjectsSlider'
import { setJobCounts } from '../../redux/reducers/dashBoardSlice'
import { getDashboardData, getProfile, getallManagers } from '../../services/api/profileApi'
import { Link, useNavigate } from 'react-router-dom'
import { setSelectedJobDetails, setSelectedReceiptDetails } from '../../redux/reducers/jobSlice'
import { updateUser } from '../../redux/reducers/authSlice'
import RecentTranscationSlider from '../../components/common/TransactionSlider/RecentTranscationSlider'
import { userLogout } from '../../services/api/authApis'
import WithDrawModel from '../../components/common/WithdrawModel/WithDrawModelCustomer'
import WithDrawModelTranslator from '../../components/common/WithdrawModel/WithDrawModelTranslator'
import UpdateRankModal from '../../components/common/updateRank/UpdateRankModal'
import { sendNotification, sendNotificationUpdateRank } from '../../services/socket'
import { toast } from 'react-toastify'

const TranslatorDashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const [showWithDrawModel, setShowWithDrawModel] = useState(false)
    const [showRankModel, setShowRankModel] = useState(false)

    const { cancelJobs, completedProjects, ongoingProjects, correctionAskedByClient, lateSubmission, rejectedByClient } = useSelector((state) => state.dashBoard)
    useEffect(() => {
        (async () => {
            let dashbordData = await getDashboardData()
            let payload = {
                cancelJobs: dashbordData?.result?.data?.cancelJobs,
                completedProjects: dashbordData?.result?.data?.completedProjects,
                ongoingProjects: dashbordData?.result?.data?.ongoingProjects,
                correctionAskedByClient: dashbordData?.result?.data?.correctionAskedByClient,
                lateSubmission: dashbordData?.result?.data?.lateSubmission,
                rejectedByClient: dashbordData?.result?.data?.rejectedByClient,
            }
            dispatch(setJobCounts(payload))
        })()
    }, [])
    const gotoSelectedProject = (data) => {
        dispatch(setSelectedJobDetails({ selectedJob: data }))
        navigate(`/translator-my-jobs/details`, { state: { id: data._id } })
    }

    const gotoSelectedReceipt = (data) => {
        dispatch(setSelectedReceiptDetails({ selectedReceipt: data }))
        navigate(`/translator/transactions-details`, { state: { id: data._id } })
    }
    const getUserDetails = async () => {
        let resultData = await getProfile()
        dispatch(updateUser({ user: resultData?.result?.data }));
    }

    useEffect(() => {
        getUserDetails()
    }, [])
    const becomeProofreader = () => {
        userLogout(dispatch)
        navigate('/signup?usertype=2')
    }
    const updateRankRequest = () => {
        setShowRankModel(true)
    }
    const confirmUpdate = async () => {
        let allManagers = await getallManagers('translator')
        for (let manager of allManagers?.result?.data) {
            let body = {
                senderId: user?._id,
                receiverId: manager?._id,
                notificationMessage: `Hi , ${user?.name} wants Update Rank, Please contact with this Translator`,
            }
            sendNotificationUpdateRank(body)
        }
        setShowRankModel(false)
    }
    const withDrawModelOpen = () => {
        if (user?.withdrawRequested == 1) {
            toast.error("Withdraw requested previously")
        } else {
            if (user.country !== "") {
                setShowWithDrawModel(true)
            }
            else {
                toast.error("Please Select Country From the Edit Profile")
            }
        }

    }
    return (
        <>
            <div className="user-dashbord">
                <div className="level-beginer-sec">
                    <div className="container">
                        <div className="level-beginer-wrap common-btm-mrgn">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="left-side-beginer">
                                        {/* <figure>
                                            <img src="/images/rnk1.png" alt="" />
                                        </figure> */}
                                        <figcaption>
                                            <h3 className="mb-2">Hi! {user.name}</h3>
                                            <h5>{user.email}</h5>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-4">
                                    <div className="graphic-text-beginer text-center bt-2">
                                        <h6 className="mb-2">My level</h6>
                                        <h4>{user?.isQualificationVerified && user?.averageRating} Star</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="level-beginer-btn-wrap text-center">
                            <Link to={user?.isQualificationVerified && "/translator-jobs" } className="btn">Go to job board</Link>
                            <p className="btn btn btn-border" onClick={updateRankRequest}>Upgrade Rank</p>
                        </div>
                    </div>
                </div>
                <div className="dashbord-aflit-link common-btm-mrgn-big">
                    <div className="container">
                        <div className="content light-green-bg">
                            <h4>Wallet</h4>
                            {<div className="ext-content">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="sngl-box">
                                                    <h5>Total earnings</h5>
                                                    <h2>${user?.isQualificationVerified ? user?.walletBalance ?? 0 : null}</h2>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                {/* <div className="sngl-box">
                          <h5>Total withdraw</h5>
                          <h4>$200</h4>
                        </div> */}
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="sngl-box">
                                                    <h5>Total balance</h5>
                                                    <h4>${user?.isQualificationVerified ? user?.walletBalance ?? 0 : null}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="right-content">
                                            <ul className="btn-box">
                                                {/* <li><a href="" className="btn">Change PayPal Email</a></li> */}
                                                <li><p
                                                    style={{
                                                        cursor: user?.walletBalance > 50
                                                            ? ""
                                                            : "not-allowed",
                                                        backgroundColor: user?.walletBalance < 50 && "gray"
                                                    }}

                                                    onClick={() =>
                                                        user?.walletBalance > 50 &&
                                                        withDrawModelOpen()} className="btn">Withdraw</p></li>
                                            </ul>
                                            <p>Balance should be minimum of $50 to withdraw</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="dashbord-three-box common-btm-mrgn">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 mb-4">
                                <div className="sngl-box blue-box">
                                    <h3>
                                        <span><img src="/images/tickdb.svg" alt="" /></span> {user?.isQualificationVerified && completedProjects}
                                    </h3>
                                    <h5>Projects completed</h5>
                                    <p>{user?.isQualificationVerified && completedProjects}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="sngl-box green-box">
                                    <h3>
                                        <span><img src="/images/icon71.svg" alt="" /></span> {user?.isQualificationVerified && ongoingProjects}
                                    </h3>
                                    <h5>Ongoing Projects</h5>
                                    <p>{user?.isQualificationVerified && ongoingProjects}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="sngl-box blue-box">
                                    <h3>
                                        <span><img src="/images/icon72.svg" alt="" /></span> {user?.isQualificationVerified && correctionAskedByClient}
                                    </h3>
                                    <h5>Correction asked by client</h5>
                                    <p>{user?.isQualificationVerified && correctionAskedByClient}</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="sngl-box green-box">
                                    <h3>
                                        <span><img src="/images/icon73.svg" alt="" /></span> {user?.isQualificationVerified && lateSubmission}
                                    </h3>
                                    <h5>Late Submission</h5>
                                    <p>{user?.isQualificationVerified && lateSubmission}</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="sngl-box red-box">
                                    <h3>
                                        <span><img src="/images/icon71.svg" alt="" /></span> {user?.isQualificationVerified && cancelJobs}
                                    </h3>
                                    <h5>Cancelled Projects</h5>
                                    <p>{user?.isQualificationVerified && cancelJobs}</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="sngl-box green-box">
                                    <h3>
                                        <span><img src="/images/icon74.svg" alt="" /></span> {user?.isQualificationVerified && rejectedByClient}
                                    </h3>
                                    <h5>Rejection by client</h5>
                                    <p>{user?.isQualificationVerified && rejectedByClient}</p>
                                </div>
                            </div>
                        </div>
                        <div className="end-btn-sec mt-5">
                            <div className="row justify-content-center">
                                <div className="col-lg-4">
                                    <p onClick={() => becomeProofreader()} className="btn">Apply to become a Proofreader</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* <RecentProjects gotoSelectedProject={gotoSelectedProject} /> */}
            {/* <RecentTranscationSlider gotoSelectedReceipt={gotoSelectedReceipt} /> */}
            {showWithDrawModel && <WithDrawModelTranslator showWithDrawModel={showWithDrawModel} setShowWithDrawModel={setShowWithDrawModel} />}
            {showRankModel && <UpdateRankModal showRankModel={showRankModel} setShowRankModel={setShowRankModel} confirmUpdate={confirmUpdate} />}

        </>
    )
}

export default TranslatorDashboard