import React from 'react'
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const DragDropUploadButton = ({ onChange, multiple, btnName, disabled, btnCss }) => {
    const { t } = useTranslation();
    return (
        <Dropzone onDrop={onChange} multiple={multiple} disabled={disabled}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()} className={`${disabled && 'dropzone-disabled'} dropzone-cls ${btnCss}`}>
                        <input  {...getInputProps()} />
                        <div>
                            <p>{btnName}</p>
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    )
}

export default DragDropUploadButton