import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const ChangePriceConfirmModal = ({ handlePost, showModal, setShowModal, item, setItemData, itemData }) => {
  const [price, setPrice] = useState(item?.perWordPrice)
  const changePrice = (e) => {
    if (e.target.value <= item?.perWordPrice && e.target.value >= 0) {
      setPrice(e.target.value)
      setItemData({
        ...itemData,
        perWordPrice: e.target.value
      })
    }
    else {
      setPrice(item?.perWordPrice)
    }
  }
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change Job price
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Price per word</label>
            <input type="number" placeholder="" className="form-control" id='name' name='name' value={price} onChange={changePrice} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handlePost(price)}>Post</Button>
      </Modal.Footer>
    </Modal>

  )
}

export default ChangePriceConfirmModal