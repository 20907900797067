import React from 'react'
import VisitorHeader from '../components/common/headers/VisitorHeader'
import VisitorFooter from '../components/common/footers/VisitorFooter'

const VisitorLayout = ({ children }) => {
    window.scrollTo(0, 0);
    return (
        <div>
            <VisitorHeader />
            <div>
                {children}
            </div>
            <VisitorFooter />
        </div>
    )
}

export default VisitorLayout