import React, { useEffect, useState } from 'react'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer'
import { timeAgoFunc } from '../../../utils/timeAgoFunc';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cancleJob } from '../../../services/api/jobApis';
import CancelConfirmModel from '../../../components/common/cancelConfirmModel/CancelConfirmModel';

const ProjectSinglecard = ({ item, gotoSelectedProject, idx }) => {
    const [timeAgo, setTimeAgo] = useState("");
    const [showCancelModel, setShowCancelModel] = useState(false)
    const [jobId, setJobId] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(item?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [item?.deadline]);
    const jobCancelFunc = (id) => {
        setJobId(id)
        setShowCancelModel(true)

    }
    const jobCancelFuncForNo = (id) => {
        setShowCancelModel(false)
        setJobId(null)


    }
    const jobCancelConfirm = async () => {
        let jobCancleData = await cancleJob(jobId)
        if (jobCancleData?.serverResponse.code === 201) {
            toast.success(jobCancleData?.serverResponse.message)
            navigate('/translator-my-jobs')
        }
        setShowCancelModel(false)
    }
    const isCancelableJob = (updatedAt) => {
        const unixTimestamp = Date.parse(updatedAt);
        const unixTimestamp2 = Date.parse(new Date().toISOString());
        const timeAgo = unixTimestamp2 - unixTimestamp;
        const hours = Math.floor(timeAgo / (1000 * 60 * 60));
        if (hours < 1) {
            return true
        }
        else {
            return false
        }
    }
    return (
        <div className="col-lg-6" key={idx} >
            <div className="project-with-progress-box">
                {item?.translatorSpecialisation !== '' && <div className="site-tag">Specialized</div>}
                <h5 onClick={() => gotoSelectedProject(item)}>
                    {item?.jobTitle}
                </h5>
                <ul className="tags-sec">
                    <li className="light-blue-db">
                        <span><img src="/images/icon52.svg" /></span> {timeAgoFunc(item?.createdAt)}
                    </li>
                    <li className="light-green-db">
                        <span><img src="/images/gn-ic.svg" /></span> {item?.chooseTranslator}
                    </li>
                    <li className="light-blue-db">
                        <span><img src="/images/icon62.svg" /></span> {item?.wordCount}
                    </li>
                    <li className="light-green-db">
                        <span><img src="/images/icon61.svg" /></span> {item?.perWordPrice}/word
                    </li>
                    <li className="light-blue-db">
                        <span><img src="/images/icon60.svg" /></span> {timeAgo}
                    </li>

                    {(item?.jobStatusByUser && item?.jobStatusByUser == "correction") &&
                        <li className="light-red-db">
                            <span><img src="/images/icon60.svg" /></span> {item?.jobStatusByUser}
                        </li>}
                </ul>
                <h6>Project Overview:</h6>
                <p>
                    {item?.comment}
                </p>
                <div className="listing-area">
                    <ul className="listing-sec">
                        <li>
                            <strong>Document type:</strong> {item?.jobDocumentType}
                        </li>
                        <li>
                            <strong>Language:</strong> {item?.translateFrom} to {item?.translateTo}
                        </li>
                        <li>
                            <strong>Status:</strong> <span>{item?.jobStatus ?? 0}%</span>
                        </li>
                    </ul>
                    <div className="sidt-btn">
                        <ul>
                            <li><a href={item?.jobFile?.location} download className="btn btn-border">Download file</a></li>
                            {item?.isStarted && isCancelableJob(item?.updatedAt) && <li onClick={() => jobCancelFunc(item?._id)}><span className="btn">Cancel</span></li>}
                        </ul>
                    </div>
                </div>
                <div className="progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    />
                </div>
            </div>
            <CancelConfirmModel showCancelModel={showCancelModel} setShowCancelModel={setShowCancelModel} message="If you cancel, your rank will be greatly affected. Are you sure you want to cancel?" confirmCancel={jobCancelConfirm} jobCancelFuncForNo={jobCancelFuncForNo} />
        </div>
    )
}

export default ProjectSinglecard