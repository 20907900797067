import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const UpdateRankModal = ({ showRankModel, setShowRankModel, confirmUpdate }) => {
    return (
        <Modal show={showRankModel} onHide={() => setShowRankModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Do you want to update your rank?</Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="alert alert-success">This will send a notification to all managers. After that one of them will contact you soon.</div></Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={confirmUpdate}>
                    Yes
                </Button>
                <Button variant="danger" onClick={() => setShowRankModel(false)}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateRankModal