// import { toast } from "react-toastify";
import http from "./http";
export const getAllReceipts = async (values) => {
  let allReceipts = await http.get(
    `/profile/receipts?pageNumber=${values.pageNumber}&dataPerPage=${values.dataPerPage}`
  );
  if (allReceipts?.serverResponse?.code === 200) {
    return allReceipts?.result;
  }
};
export const getSingleReceiptCustomer = async (jobId) => {
  let jobsGetData = await http.get(`/profile/receipt/${jobId}`);
  if (jobsGetData?.serverResponse?.code === 200) {
    return jobsGetData?.result;
  }
};
