import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import persistStore from "reduxjs-toolkit-persist/es/persistStore";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "reduxjs-toolkit-persist/es/constants";

// ReucerSlices are imported here
import authSlice from "./reducers/authSlice";
import loadingSlice from "./reducers/loadingSlice";
import jobPostSlice from "./reducers/jobPostSlice";
import businessJobPostSlice from "./reducers/businessJobPostSlice";
import jobSlice from "./reducers/jobSlice";
import qualificationSlice from "./reducers/qualificationSlice";
import dashBoardSlice from "./reducers/dashBoardSlice";
import chartRoomSlice from "./reducers/chatRoomSlice";
import companyUserSlice from "./reducers/companySlice";

import { REDUX_PERSIST_KEY } from "../constants/config";

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ["auth", "jobPost", "businessJobPost"],
};
const combinedReducers = persistReducer(
  persistConfig,
  combineReducers({
    auth: authSlice,
    loading: loadingSlice,
    jobPost: jobPostSlice,
    businessJobPost: businessJobPostSlice,
    job: jobSlice,
    qualification: qualificationSlice,
    dashBoard: dashBoardSlice,
    chatRoom: chartRoomSlice,
    companyUser: companyUserSlice,
  })
);
const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
