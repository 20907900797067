import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { seenMsg } from '../../../services/socket'
import { timeAgoFunc } from '../../../utils/timeAgoFunc'

const Message = ({ item, id }) => {
    const { user } = useSelector((state) => state.auth)
    useEffect(() => {

        if (!item?.messageSeenBy?.includes(user?._id) && (item?.receiverId === user?._id || item?.receiverId?._id === user?._id)) {
            seenMsg({ userId: user?._id, chatRoomId: item?.chatRoomId, msgId: item?._id })
        }
    }, [item])
    return (
        <li key={id} id={`item${id}`}>
            <p>
                <div className="sngl-text-box">
                    <div className="image-box">
                        {/* <img src={`/images/user-two.png`} alt="" /> */}
                    </div>
                    <div className="content-box">
                        <h5>
                            {item?.senderId?.name} <span>{item?.senderId?.role === 'project-manager-for-translator' ? 'Translator Manager' : item?.senderId?.role === 'project-manager-for-proofreader' ? 'Proofreader Manager' : item?.senderId?.role === 'translator' ? 'Translator' : item?.senderId?.role === 'proofreader' ? 'Proofreader' : 'Customer'}</span>
                        </h5>
                        <h6>{timeAgoFunc(item?.createdAt)}</h6>
                        <div className='message-content'>
                            <h3>{item?.messageText}</h3>
                            {((user?._id === item?.senderId?._id) && (item?.messageSeenBy?.includes(item?.receiverId?._id))) ? <img src='/images/seenMsg.svg' /> : (user?._id === item?.senderId?._id) && <img src='/images/sendMsg.svg' />}
                        </div>
                    </div>
                </div>
            </p>
        </li>
    )
}

export default Message