import React, { useEffect } from 'react'
import UserHeader from '../components/common/headers/UserHeader'
import UserFooter from '../components/common/footers/UserFooter'
import SocketEvents from '../services/socket/socketEvents';
import { io } from '../constants/config';
import ConnectionStatus from '../components/common/ConnectionStatus';
// import { Route, Routes } from 'react-router-dom'

const UserLayout = ({ children }) => {
    return (
        <div>
            {io ? <SocketEvents io={io} /> : null}
            <UserHeader />
            <div>
                {children}
            </div>
            <UserFooter />
            <ConnectionStatus io={io} />
        </div>
    )
}

export default UserLayout