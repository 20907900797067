import React from 'react'

const UserSwitch = ({ onToggle, slectedUserRole }) => {
    return (
        <>
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    {/* <button onClick={() => onToggle(0)} className={`${slectedUserRole === 0 && 'active'} nav-link`} >User</button> */}
                    <button onClick={() => onToggle(0)} className={`${slectedUserRole === 0 && 'active'} nav-link`} >Individual</button>
                    <button onClick={() => onToggle(3)} className={`${slectedUserRole === 3 && 'active'} nav-link`} >Business</button>
                </div>
            </nav>
        </>
    )
}

export default UserSwitch