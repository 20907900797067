import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChatRoomMessage, sendMessage, typing } from '../../../services/socket'
import InfiniteScroll from 'react-infinite-scroll-component'
import { setMessages } from '../../../redux/reducers/chatRoomSlice'
import Message from './Message'


const RighSideChatSection = ({ setShowChat, innerWidth }) => {
    const dispatch = useDispatch()
    const { activeRoom, messages, typingData } = useSelector((state) => state.chatRoom)
    const { user } = useSelector((state) => state.auth)
    const [pageNumber, setPageNumber] = useState(1)
    const [message, setMessage] = useState('')
    const [isControlKey, setIsControlKey] = useState(false)


    const sendTextMessage = () => {
        let payload = {
            messageText: message,
            senderId: user?._id,
            receiverId: activeRoom?.members?.find((usr) => usr?._id !== user?._id)?._id,
            chatRoomId: activeRoom?._id
        }

        setMessage('')
        sendMessage(payload)
    }
    const typingMsg = (text) => {
        setMessage(text)
        typing({ userId: user?._id, chatRoomId: activeRoom?._id })
    }
    useEffect(() => {
        if (messages?.length) {
            document.getElementById(`item0`).scrollIntoView()
        }
    }, [])
    const loadMoreChatRoomMsg = () => {
        getChatRoomMessage({ userId: user?._id, chatRoomId: activeRoom?._id, pageNumber: pageNumber + 1, dataPerPage: 10 })
        setPageNumber(pageNumber + 1)
    }
    useEffect(() => {
        setPageNumber(1)
        return () => {
            setMessage('')
            dispatch(setMessages({ messages: null }))
        }
    }, [activeRoom?._id])

    const keyDownHandeler = (event) => {
        if (event.key === "Shift") {
            setIsControlKey(true)
        }
        if (event.key === "Enter" && !isControlKey) {
            event.preventDefault()
            event.stopPropagation()
            sendTextMessage()
        }
    };
    const keyUpHandeler = (event) => {
        if (event.key === "Shift") {
            setIsControlKey(false)
        }
    };
    return (
        <div className="chat-right-content">
            {innerWidth <= 991 &&
                <div onClick={() => setShowChat(false)}>
                    <img src={`/images/black-left-arrow.svg`} width={40} />
                </div>
            }
            <div className="all-chat-dtls">
                <div className={`typing-message`}>
                    {(typingData?.userId !== user?._id) && (typingData?.chatRoomId === activeRoom?._id) && <p>Typing...</p>}
                </div>
                <ul>
                    {(messages && messages?.length) ? <InfiniteScroll
                        dataLength={messages.length} //This is important field to render the next data
                        next={() => { loadMoreChatRoomMsg() }}
                        hasMore={true}
                        style={{ display: 'flex', flexDirection: 'column-reverse' }}
                        height={500}
                        inverse={true}
                    >
                        {messages?.map((item, id) => (
                            <Message item={item} id={id} />
                        ))
                        }
                    </InfiniteScroll> :
                        <div>
                            <p>No messages</p>
                        </div>}


                </ul>
            </div>
            <div className="reply-chat-box">
                <form>
                    <div className="mb-3">
                        <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows={3}
                            placeholder="Reply"
                            onChange={(e) => typingMsg(e.target.value)}
                            value={message}
                            onKeyDown={keyDownHandeler}
                            onKeyUp={keyUpHandeler}
                        />
                    </div>
                    <ul>
                        {/* <li>
                            <label className="filelabel">
                                <img src="/images/attch.png" alt="" />
                                <input
                                    className="FileUpload1"
                                    id="FileInput"
                                    name="booking_attachment"
                                    type="file"
                                />
                            </label>
                        </li> */}
                        <li>
                            <button type="button" className="btn" onClick={sendTextMessage}>
                                {/* Submit */}
                            </button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    )
}

export default RighSideChatSection