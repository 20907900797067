import React from 'react'
import { useSelector } from 'react-redux'
import { Rating } from 'react-simple-star-rating'

const ReviewRating = ({ ratingReview, setShowReviewModal }) => {
    const { user } = useSelector((state) => state.auth)

    return (
        <div className="review-reting-box common-top-mrgn">
            <h3>Review and Rating:</h3>
            <div className="sngl-box">
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <p>{ratingReview?.review}</p>
                    {user?.role === "user" && <button onClick={() => setShowReviewModal(true)}>Edit</button>}
                </div>

                <Rating readonly={true} value={ratingReview?.rating} initialValue={ratingReview?.rating} allowFraction />

                <div className="user">
                    <div className="image-box">
                        <img src="/images/user-two.png" alt="" />
                    </div>
                    <div className="contnt">
                        <h4>{ratingReview?.userId?.name}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewRating