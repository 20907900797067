import React, { useEffect } from 'react'
import WhyUsSec from './WhyUsSec'
import Services from './Services'
import StepsToFollow from './StepsToFollow'
import { NavLink, useLocation } from 'react-router-dom'
import BecomeAMember from './BecomeAMember'
import CustomerReview from './CustomerReview'
import TranslationSolution from './TranslationSolution'
import BusinessSolutionSec from './BusinessSolutionSec'
import CallToSignup from '../../../components/common/callToSignup/CallToSignup'
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import { getDashboardData } from '../../../services/api/authApis'
import { updateDashboardData } from '../../../redux/reducers/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import numeral from 'numeral'

const Home = () => {
    const { t } = useTranslation();
    const location = useLocation()
    const dispatch = useDispatch()
    const { dashBoarddata } = useSelector((state) => state.auth)
    const options = {
        items: 2,
        dots: false,
        nav: false,
        loop: true,
        margin: 20,
        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 4,
            },
            1000: {
                items: 6,
            }
        }
    }
    useEffect(() => {
        getAllData()
    }, [])
    useEffect(() => {
        if (location?.state?.businessText === "businessText") {
            const element = document.getElementById('scrollTarget');
            element.scrollIntoView({ behavior: 'smooth' });
        }



    }, [location?.state?.businessText])
    const getAllData = async () => {
        const gettingAllDetails = await getDashboardData()

        dispatch(updateDashboardData(gettingAllDetails))
    }
    const formatCount = (count) => {
        if (count >= 1000000) {
            return numeral(count).format('0.000a').toUpperCase(); // Formats numbers >= 1 million as "1.000M"
        }
        else {
            return count?.toString(); // No formatting needed for numbers < 1000000
        }
    };

    return (
        <>
            {/* banner section */}
            <section className="home-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/home-banner.jpg" alt="" />
                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div
                                    className="banner-text-wrap"
                                    data-aos="fade-up"
                                    data-aos-duration={3000}
                                >
                                    <h1>{t("home-banner-heading")}</h1>

                                    <p>
                                        {t("home-banner-paragraph")}
                                    </p>
                                    <div className="banner-counter for-desktop">
                                        <div className="counter-col">
                                            <span id="count1" className="count-num" >{dashBoarddata && formatCount(dashBoarddata?.totalTranslationWords)}</span>
                                            <p>{t("home-banner-counter-count1-paragraph")}</p>
                                        </div>
                                        <div className="counter-col">
                                            <span id="count1" className="count-num" >{dashBoarddata && formatCount(dashBoarddata?.totalProofreadingWords)}</span>
                                            <p>{t("home-banner-counter-count2-paragraph")}</p>
                                        </div>
                                        <div className="counter-col">
                                            <span id="count1" className="count-num" >{dashBoarddata && formatCount(dashBoarddata?.totalUsers)}</span>
                                            <p>{t("home-banner-counter-count3-paragraph")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* banner end */}

            <section className="logo-slider-sec padding-top padding-bottom">
                <div className="container">
                    {/* <div id="logo-slider" className="logo-slider owl-carousel owl-theme"> */}
                    <OwlCarousel className='logo-slider owl-carousel owl-theme' {...options}>
                        {dashBoarddata && dashBoarddata.totalCompanies && dashBoarddata.totalCompanies.length > 0 ? (
                            dashBoarddata.totalCompanies.map((company, index) => (
                                <div className="logo-item item" key={index}>
                                    <img src={company.logo} alt={company.name} width={200} />
                                </div>
                            ))
                        ) : (
                            <>
                                <div className="logo-item item">
                                    <img src="/images/buzzfeed.png" alt="" />
                                </div>
                                <div className="logo-item item">
                                    <img src="/images/bloomberg.png" alt="" />
                                </div>
                                <div className="logo-item item">
                                    <img src="/images/bitcoin.png" alt="" />
                                </div>
                                <div className="logo-item item">
                                    <img src="/images/youtube.png" alt="" />
                                </div>
                                <div className="logo-item item">
                                    <img src="/images/zendesk.png" alt="" />
                                </div>
                                <div className="logo-item item">
                                    <img src="/images/buzzfeed.png" alt="" />
                                </div>
                            </>
                        )}

                    </OwlCarousel>
                </div>
            </section>
            {/* logo section */}
            <TranslationSolution />
            <WhyUsSec />
            <Services />
            {/* home service */}
            <StepsToFollow />
            {/* steps end */}
            <BecomeAMember />
            {/* Translators section end */}
            <CustomerReview />
            {/* customer end */}
            <BusinessSolutionSec />
            {/* sign up */}
            <CallToSignup />
        </>

    )
}

export default Home