import React, { useEffect, useState } from 'react'
import ConfigurationCard from './ConfigurationCard'
import { jobPostSplitJob, uploadJobFile } from '../../../services/api/jobPostApis'
import { useDispatch, useSelector } from 'react-redux'
import { setJobs } from '../../../redux/reducers/jobSlice'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'
import { getAllJobs } from '../../../services/api/jobApis'

const TranslatorManagerConfig = ({ configurationRef }) => {
    const dispatch = useDispatch()
    const { currentPage } = useSelector(
        (state) => state.job
    );
    const [split, setSplit] = useState(0)
    const splitConfig = ['2', '3', '4', '5']
    const [postSplit, setPostSplit] = useState(false)
    const [jobArr, setJobArr] = useState([])
    const handleSubmitBtn = () => {
        setJobArr([])
        setPostSplit(true)
    }
    useEffect(() => {
        (async () => {
            if (split > 0 && (jobArr?.length === split)) {
                dispatch(startLoading());
                await Promise.all(jobArr?.map(async (item) => {
                    await uploadJobFile(item?.jobFile).then(async (res) => {
                        item = { ...item, jobFile: res?.result?.data?.responseData }
                        await jobPostSplitJob(item)
                    })
                })).then(async () => {
                    dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: currentPage ?? 0 }));
                    let data = await getAllJobs("all", 1);
                    if (data?.data?.length > 0) {
                        dispatch(
                            setJobs({
                                jobs: data?.data,
                                pageNumber: data?.pageNumber,
                                totalJobCount: data?.dataCount,
                            })
                        );
                    }
                });
                dispatch(stopLoading());
            }
        })()
    }, [jobArr])
    return (
        <div className="split-info-wrap" ref={configurationRef}>
            <div className="split-info-flex job-splitter-section">
                <div className="split-info-text">
                    <h4>How many jobs would you like to split this into?</h4>
                </div>
                <div className="split-info-list">
                    <ul>
                        {splitConfig?.map((item) =>
                            <li onClick={() => setSplit(Number(item))} className={`${split === Number(item) && 'activesplit-number'}`}>
                                <p>{item}</p>
                            </li>)}
                    </ul>
                    {!!split && <button type="submit" className="btn" onClick={handleSubmitBtn}>
                        Post
                    </button>}
                </div>
            </div>
            {Array(split).fill("split").map((i, index) => {
                return (
                    <ConfigurationCard index={index} postSplit={postSplit} setPostSplit={setPostSplit} setJobArr={setJobArr} />
                )
            })}
        </div>
    )
}

export default TranslatorManagerConfig