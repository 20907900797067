import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer';

const OrderDetailsModal = ({ item, showOrderModal, setShowOrderModal, whoAssigned }) => {
    const [timeAgo, setTimeAgo] = useState("");
    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeAgo = getTimeAgo(item?.deadline);
            setTimeAgo(timeAgo);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [item?.deadline]);

    return (
        <Modal
            className="modal order-d-popup fade"
            show={showOrderModal?.open}
            onHide={() => setShowOrderModal({ open: false })} centered
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <ModalHeader closeButton>
                    </ModalHeader>
                    <ModalBody>
                        <div className="order-d-popup-body">
                            <div className="light-h">Order no: {item?.orderNumber}</div>
                            <div className="order-d-popu-flex">
                                <div className="flex-1">
                                    <div className="flex-2">
                                        <p>
                                            <strong>Doc:</strong>
                                        </p>
                                        <p>.docx</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Finished Document:</strong>
                                        </p>
                                        <p className='orderDetailsFileName'>
                                            <img src="/images/pdf.png" alt="" /> {item?.jobFile?.name}
                                        </p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Word count:</strong>
                                        </p>
                                        <p>{item?.wordCount}</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Deadline:</strong>
                                        </p>
                                        <p>{timeAgo}</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Milestone status:</strong>
                                        </p>
                                        <p>{item?.jobStatus}%</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Type of doc:</strong>
                                        </p>
                                        <p>{item?.jobDocumentType}</p>
                                    </div>
                                    {/* <div className="flex-2">
                                        <p>
                                            <strong>Field of doc:</strong>
                                        </p>
                                        <p>{item?.translatorSpecialisation ?? '--'}</p>
                                    </div> */}
                                    <div className="flex-2">
                                        <p>
                                            <strong>Language pair:</strong>
                                        </p>
                                        <p>{item?.translateFrom} to {item?.translateTo}</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Show Lingvara watermark?</strong>
                                        </p>
                                        <p>{item?.lingVaraWaterMark ? 'Yes' : 'No'}</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Rank required</strong>
                                        </p>
                                        <p>{item?.chooseTranslator}</p>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    {/* <div className="flex-2">
                                        <p>
                                            <strong>Price paid by client</strong>/word :
                                        </p>
                                        <p>0.06</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Price for translator:</strong>
                                        </p>
                                        <p>0.06</p>
                                    </div> */}
                                    <div className="flex-2">
                                        <p>
                                            <strong>Additional comments from client:</strong>
                                        </p>
                                        <p>{item?.comment}</p>
                                    </div>
                                    {/* <div className="flex-2">
                                        <p>
                                            <strong>Client info:</strong>
                                        </p>
                                        <p>John doe</p>
                                    </div> */}
                                    <div className="flex-2">
                                        <p>
                                            <strong>Job status:</strong>
                                        </p>
                                        {whoAssigned ? whoAssigned : "Unassign" }     
                                    </div>
                                    {/* <div className="flex-2">
                                        <p>
                                            <strong>Client approval status:</strong>
                                        </p>
                                        <p>Approved</p>
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Client Reviews:</strong>
                                        </p>
                                        <p />
                                    </div>
                                    <div className="flex-2">
                                        <p>
                                            <strong>Overall score:</strong>
                                        </p>
                                        <p>8.0/10</p>
                                    </div> */}
                                    {/* <div className="flex-2">
                                        <p>
                                            <strong>Comments:</strong>
                                        </p>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </div>
        </Modal>
    )
}

export default OrderDetailsModal