import React from 'react'

const TermsConditions = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Terms and Conditions</h1>
                                    <p>Welcome to Lingvara! These Terms and Conditions ("Agreement") govern your use of our translation and proofreading services website. By accessing or using our website, you agree to be bound by these terms. If you disagree with any part of these terms, please refrain from using our services.</p>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec common-btm-padd">

                <div className="container">

                    <p className='static-text'><strong>1. Parties Involved</strong></p>
                    <p>a. Lingvara: "Lingvara" refers to our company, including its owners, employees, and authorized representatives.</p>
                    <p>b. Translator: "Translator" refers to independent contractors who provide translation services through our platform.</p>
                    <p>c. Proofreader: "Proofreader" refers to independent contractors who provide proofreading services through our platform.</p>
                    <p>d. Client: "Client" refers to individuals or entities who create an account on our website, upload documents for translation or proofreading, and may become an affiliate of the website.</p>
                    <p>e. Translation Project Manager: "Translation Project Manager" refers to our staff member responsible for monitoring the job board, assigning translations, coordinating translation tasks, and resolving disputes.</p>
                    <p><strong>2. Client Responsibilities</strong></p>
                    <p>a. By using our website, you agree to provide accurate and complete information for all translation and proofreading requests.</p>
                    <p>b. Clients have the right to receive accurate and timely translations and proofreadings as requested.</p>
                    <p>c. If a client is dissatisfied with the quality or speed of service, they can dispute the matter. Lingvara, along with the Translation Project Manager, will conduct an investigation and determine appropriate action, including a refund if necessary.</p>
                    <p>d. Clients may receive reward points for purchases made on the website, which can be used towards future purchases. However, reward points cannot be transferred to anyone else.</p>
                    <p>e. Clients may become affiliates of the website and share their affiliate link. They will be credited with 5% of the amount paid by another person who uses their affiliate link. The credited amount will be added to the client's website wallet and can be used towards future purchases or withdrawn to PayPal, provided the minimum withdrawal amount is $50 USD.</p>
                    <p><strong>3. Translator and Proofreader Responsibilities</strong></p>
                    <p>a. Translators and proofreaders are independent contractors responsible for providing accurate and high-quality translations or proofreadings within the agreed-upon timeframe.</p>
                    <p>b. The Translation Project Manager may assign translations, split translation tasks, recompile them upon completion, and report on low-ranked translators or proofreaders.</p>
                    <p><strong>4. Confidentiality and Data Protection</strong></p>
                    <p>a. Lingvara and all parties involved shall maintain strict confidentiality regarding client information and any confidential content provided for translation or proofreading.</p>
                    <p>b. Lingvara will take reasonable measures to protect client data and comply with applicable data protection laws. Please refer to our Privacy Policy for more information.</p>
                    <p><strong>5. Ownership of Intellectual Property</strong></p>
                    <p>a. Upon completion of the translation or proofreading services and full payment, the intellectual property rights of the translated or proofread content shall belong to the client.</p>
                    <p><strong>6. Limitations and Dispute Resolution</strong></p>
                    <p>a. Lingvara and all parties involved shall not be held liable for any direct, indirect, incidental, or consequential damages arising from the use of our services.</p>
                    <p>b. Any dispute arising from the use of our services shall be resolved through negotiation and, if necessary, through arbitration or mediation, in accordance with applicable laws.</p>
                    <p><strong>7. Termination</strong></p>
                    <p>a. Lingvara reserves the right to terminate or suspend the account of any user found to be in violation of these Terms and Conditions or engaging in fraudulent or unethical behavior.</p>
                    <p><strong>8. Applicable Law and Jurisdiction</strong></p>
                    <p>a. These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction where Lingvara is based. Any legal disputes arising from the use of our services shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.</p>
                </div>

            </section>


        </>
    )
}

export default TermsConditions