import React from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { NavLink, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SocialLogin from '../../../components/common/socialLogin/SocialLoginSignup'

const LoginComp = ({ loginFromik }) => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const passwordHandler = () => {
        setShowPassword((prev) => !prev)
    }
    return (
        <section className={`translator-signin-sec`} id="translator" role="tabpanel" aria-labelledby="translator-tab">
            <div className="container">
                <div className="signin-form-wrap">
                    <form onSubmit={loginFromik.handleSubmit}>
                        <h4>Sign in</h4>
                        <p>Let's break language barriers together!</p>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" placeholder="" id='email' name='email' value={loginFromik?.values.email} onChange={loginFromik.handleChange} className="form-control" />
                            <p className='form-error-message'>{loginFromik.touched.email &&
                                loginFromik.errors.email}</p>
                        </div>
                        <div className="form-group password">
                            <label>Password</label>
                            <input type={showPassword ? "text" : "password"} placeholder="" id='password' name='password' value={loginFromik?.values.password} onChange={loginFromik.handleChange} className="form-control" />
                            <p className='form-error-message'>{loginFromik.touched.password &&
                                loginFromik.errors.password}</p>

                            <span onClick={passwordHandler} className="forgot-password-eye-toggle "> <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></span>


                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn w-100">
                                Sign in
                            </button>

                        </div>
                        <div className="form-group">

                            <label htmlFor="createaccount"> Forgot Password? <NavLink to='/forgot-password'>Reset</NavLink></label>

                        </div>

                        <div className="or-divider text-center">


                            <span>Or</span>
                        </div>
                        <SocialLogin type='login' />
                        <div className="form-group mb-0 text-center">
                            <p>
                                Don’t have an account? <a style={{ color: "blue" }} onClick={() => navigate('/signup')}>Sign up</a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </section>

    )
}

export default LoginComp