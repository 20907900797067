import { useDispatch, useSelector } from "react-redux";
import Loading from "./components/common/Loading";
import RouteIdentifier from "./routing/RouteIdentifier";
import routes from "./routing/router";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import i18 from "./config/i18";
import { userLogout, webMaintainApi } from "./services/api/authApis";
import { Route, Routes, useNavigate } from "react-router-dom";
import WebsiteMaintain from "./components/WebsiteMaintain";
import { logOutUser } from "./redux/reducers/authSlice";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
i18.init();

function App() {
  const { loading } = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkWebsiteActive, setCheckWebsiteActive] = useState(false);
  useEffect(() => {
    AOS.init();
  }, []);
  const refreshToken = sessionStorage.getItem("refreshToken");
  useEffect(() => {
    if (!refreshToken) {
      userLogout(dispatch);
    }
  }, []);
  useEffect(() => {
    checkWebsite();
  }, []);
  const checkWebsite = async () => {
    let data = await webMaintainApi();
    if (data?.maintenance === true) {
      setCheckWebsiteActive(true);
      navigate("/website-maintain");
    } else {
      if (window.location.href.split("/").includes("website-maintain")) {
        navigate("/");
      } else {
        const currentPath = new URL(window.location.href).pathname;
        navigate(currentPath);
      }
    }
  };

  return (
    <>
      {loading && <Loading />}

      <RouteIdentifier routes={routes} />
      <FloatingWhatsApp phoneNumber={+962792901589} accountName="Lingvara" />
    </>
  );
}

export default App;
