import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimeAgo } from "../../../utils/getTimeAgoTimer";
import { timeAgoFunc } from "../../../utils/timeAgoFunc";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import { cancleJob, getReview, getSingleJob, updateJobMilestoneStatus, uploadCompletedJob } from "../../../services/api/jobApis";
import { setReviewRating, setSelectedJobDetails } from "../../../redux/reducers/jobSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadJobFile } from "../../../services/api/jobPostApis";
import DragDropUploadButton from "../../../components/common/DragDropUploadButton";
import { chatAccess } from "../../../services/api/chatRoomApis";
import { getChatRoomMessage, joinChat } from "../../../services/socket";
import { setActiveroom } from "../../../redux/reducers/chatRoomSlice";
import CancelConfirmModel from "../../../components/common/cancelConfirmModel/CancelConfirmModel";

const MyJobDetailsProf = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()
  const { selectedJobDetails } = useSelector((state) => state.job);
  const { ratingReview } = useSelector((state) => state.job);
  const { user } = useSelector((state) => state.auth)
  const [timeAgo, setTimeAgo] = useState("");
  const [showCancelModel, setShowCancelModel] = useState(false)
  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeAgo = getTimeAgo(selectedJobDetails?.deadline);
      setTimeAgo(timeAgo);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [selectedJobDetails?.deadline]);

  useEffect(() => {
    (async () => {
      if (!selectedJobDetails && location?.state?.id) {
        dispatch(startLoading());
        let { data } = await getSingleJob(location?.state?.id);
        if (data) {
          dispatch(setSelectedJobDetails({ selectedJob: data }));
        }
        dispatch(stopLoading());
      }
    })();
    return () => {
      dispatch(setSelectedJobDetails({ selectedJob: null }));
    };
  }, []);
  //   useEffect(() => {
  //     (async () => {
  //       if(selectedJobDetails?._id){
  //         let jobReviewData = await getReview(selectedJobDetails?._id)
  //         if (jobReviewData?.serverResponse?.code === 200) {
  //             dispatch(setReviewRating({reviewRating:jobReviewData.result?.data}))

  //         }
  //       }

  //     })()
  // }, [selectedJobDetails?._id])
  const jobCancelFunc = () => {
    setShowCancelModel(true)
  }
  const jobCancelFuncForNo = () => {
    setShowCancelModel(false)
  }
  const jobCancelConfirm = async () => {
    let jobCancleData = await cancleJob(selectedJobDetails?._id)
    if (jobCancleData?.serverResponse.code === 201) {
      toast.success(jobCancleData?.serverResponse.message)
      navigate('/proofreader-my-jobs')
    }
  }
  const isCancelableJob = () => {
    const unixTimestamp = Date.parse(selectedJobDetails?.updatedAt);

    const unixTimestamp2 = Date.parse(new Date().toISOString());
    const timeAgo = unixTimestamp2 - unixTimestamp;

    const hours = Math.floor(timeAgo / (1000 * 60 * 60));
    if (hours < 1 && !selectedJobDetails?.isCanceled && !selectedJobDetails?.jobStatus) {
      return true
    }
    else {
      return false
    }
  }
  const updateJobMilestone = async (status) => {
    let bodyData = {
      jobId: selectedJobDetails?._id,
      jobStatus: status,
      isTimeExpired: timeAgo === "Expired" ? true : false

    }
    let updatedJobData = await updateJobMilestoneStatus(bodyData)
    if (updatedJobData?.serverResponse?.code === 200) {
      dispatch(setSelectedJobDetails({ selectedJob: updatedJobData?.result?.data }))
    }
  }
  const isComplitedJob = () => {
    if (selectedJobDetails?.jobStatus === 100) {
      if (selectedJobDetails?.jobCompletedFile && selectedJobDetails?.jobStatusByUser !== "correction") {
        return false
      }
      return true
    }

  }
  const uploadJobFiles = async (data) => {
    let uploadJobData = await uploadJobFile(data[0])
    if (uploadJobData?.serverResponse?.code === 200) {
      let apiData = {
        jobFile: uploadJobData?.result?.data?.responseData
      }
      let completeJobData = await uploadCompletedJob(apiData, selectedJobDetails?._id)
      if (completeJobData?.serverResponse?.code === 200) {
        toast.success(completeJobData?.serverResponse?.message)
        dispatch(setSelectedJobDetails({ selectedJob: completeJobData?.result?.data }))
      }
    }
  }
  const gotoChat = async () => {
    let accessData = await chatAccess(selectedJobDetails?.assignedProjectManagerId)
    if (accessData?.serverResponse?.code === 200) {
      joinChat([accessData?.result[0]?._id, selectedJobDetails?.assignedProjectManagerId, user?._id])
      dispatch(setActiveroom({ activeRoom: accessData?.result[0] })) // will put this to socket event when roomid and userid will be send in an object
      getChatRoomMessage({ userId: user?._id, chatRoomId: accessData?.result[0]?._id, pageNumber: 1, dataPerPage: 10 })
    }
    navigate('/proofreader-chat')
  }
  return (
    <div className="user-dashbord">
      <div className="user-dtls-jb-pst-page common-padd">
        <div className="container">
          <div className="user-dtls-jb-pst-wraper">
            <div className="user-dtls-jb-pst-top">
              <h4>
                {selectedJobDetails?.jobTitle}
                <span className="chat" onClick={gotoChat}>
                  <p>
                    <img src="/images/chat.svg" alt="" />
                  </p>
                </span>
              </h4>
              <ul className="tags-sec">
                <li className="light-blue-db">
                  <span>
                    <img alt='icons' src="/images/icon52.svg" />
                  </span>{" "}
                  {timeAgoFunc(selectedJobDetails?.createdAt)}
                </li>
                <li className="light-green-db">
                  <span>
                    <img alt='icons' src="/images/icon62.svg" />
                  </span>{" "}
                  {selectedJobDetails?.wordCount}
                </li>
                <li className="light-blue-db">
                  <span>
                    <img alt='icons' src="/images/icon61.svg" />
                  </span>{" "}
                  {selectedJobDetails?.perWordPrice}
                </li>
                <li className="light-green-db">
                  <span>
                    <img alt='icons' src="/images/icon60.svg" />
                  </span>{" "}
                  {timeAgo}
                </li>
                {(selectedJobDetails?.jobStatusByUser && selectedJobDetails?.jobStatusByUser == "correction") &&
                  <li className="light-red-db">
                    <span><img src="/images/icon60.svg" /></span> {selectedJobDetails?.jobStatusByUser}
                  </li>}
              </ul>

              <h5>Project Overview:</h5>
              <p>{selectedJobDetails?.comment}</p>
            </div>
            <div className="user-dtls-jb-pst-mdl common-btm-mrgn">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="sngl-box">
                        <h6>Document type:</h6>
                        <p>{selectedJobDetails?.jobDocumentType}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="sngl-box">
                        <h6>Language:</h6>
                        <p>{selectedJobDetails?.requiredEnglishDialect}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="sngl-box">
                        <h6>Citation style:</h6>
                        <p>{selectedJobDetails?.citationStyle}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="sngl-box">
                        <h6>Specific service:</h6>
                        <p>{selectedJobDetails?.specificService}</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="sngl-box">
                        <h6>Intended style:</h6>
                        <p>{selectedJobDetails?.intendedStyle}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="sngl-box">
                        <h6>Word count:</h6>
                        <p>{selectedJobDetails?.wordCount}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="right-box">
                        <h5>Status:</h5>
                        <div className="progress-bar-sec">
                          <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow={selectedJobDetails?.jobStatus} aria-valuemin={0} aria-valuemax={100}>
                            <div className="progress-bar bg-success" style={{ width: `${selectedJobDetails?.jobStatus}%` }}><span>{selectedJobDetails?.jobStatus}% </span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-dtls-jb-pst-end">
              <h5>Job file:</h5>
              <div className="row">
                <div className="col-lg-5">
                  <div className="download-file-box">
                    <a href={selectedJobDetails?.jobFile?.location} download>
                      <span>
                        <img src="/images/icon55.svg" alt="" />
                      </span>
                      {selectedJobDetails?.jobFile?.name}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="end-dwnload-two-btn end-dwnload-two-btn-inline">
              <ul>
                {/* <li>
                  <a href={selectedJobDetails?.jobFile?.location} download className="btn btn-border">
                    <span>
                      <img src="/images/download.svg" alt="" />
                    </span>{" "}
                    Download certificate
                  </a>
                </li> */}
                {isCancelableJob() && <li>
                  <p onClick={jobCancelFunc} className="btn btn-red">
                    Cancel project
                  </p>
                </li>}
                {/* <li>
                  <a href="" className="btn">
                    Start Job
                  </a>
                </li> */}
              </ul>
              <div className="msg-txt">
                {isCancelableJob() && <p className="light-red-db">
                  N.B: You have to cancel the project within 1 hour
                </p>}
              </div>
            </div>
            {(isComplitedJob() || selectedJobDetails?.jobStatusByUser === "correction") &&
              <div className='user-dtls-jb-pst-end'>
                {selectedJobDetails?.recheckComments &&
                  <>
                    <h4>Recheck comment by customer</h4>
                    <div className='text-container'>{selectedJobDetails?.recheckComments}</div>
                  </>}
              </div>
            }
            <div className="mile-stone-check-sec">
              <h4>
                Milestones:{" "}
                {<span onClick={() => updateJobMilestone(100)}>
                  <p className="btn btn-invld">
                    Mark as complete
                  </p>
                </span>}
              </h4>
              <div className="mile-stone-wraper">
                <form>
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <input type="checkbox" id="milestone1" onClick={() => updateJobMilestone(25)} checked={selectedJobDetails?.jobStatus >= 25} disabled={selectedJobDetails?.jobStatus >= 25} />
                        <label htmlFor="milestone1">25%</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <input type="checkbox" onClick={() => updateJobMilestone(50)} id="milestone2" checked={selectedJobDetails?.jobStatus >= 50} disabled={selectedJobDetails?.jobStatus >= 50} />
                        <label htmlFor="milestone2">50%</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <input type="checkbox" onClick={() => updateJobMilestone(75)} id="milestone3" checked={selectedJobDetails?.jobStatus >= 75} disabled={selectedJobDetails?.jobStatus >= 75} />
                        <label htmlFor="milestone3">75%</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <input type="checkbox" onClick={() => updateJobMilestone(100)} id="milestone4" checked={selectedJobDetails?.jobStatus >= 100} disabled={selectedJobDetails?.jobStatus >= 100} />
                        <label htmlFor="milestone4">100%</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {(isComplitedJob() || selectedJobDetails?.jobStatusByUser === "correction") &&
              <div className="end-dwnload-two-btn end-dwnload-two-btn-inline common-btm-mrgn">
                <ul>
                  <li>
                    <DragDropUploadButton btnCss={"upload-f"} onChange={uploadJobFiles} multiple={false} btnName="Upload File" />
                  </li>
                </ul>
              </div>
            }
            {selectedJobDetails?.jobCompletedFile && <div className="user-dtls-jb-pst-end">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-lg-5">
                  <div className="download-file-box">
                    <a href={selectedJobDetails?.jobCompletedFile?.location} download>
                      <span>
                        <img src="/images/icon55.svg" alt="" />
                      </span>
                      {selectedJobDetails?.jobCompletedFile?.name}
                    </a>
                  </div>
                </div>
              </div>
            </div>}
            {/* <div className="review-reting-box common-top-mrgn">
        <h3>Review and Rating:</h3>
        <div className="sngl-box">
          <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim .”</p>
          <ul className="star-rating">
            <li><i className="fas fa-star"></i></li>
            <li><i className="fas fa-star"></i></li>
            <li><i className="fas fa-star"></i></li>
            <li><i className="fas fa-star"></i></li>
            <li><i className="fas fa-star"></i></li>
          </ul>
          <div className="user">
            <div className="image-box">
              <img src="images/user-two.png" alt=""/>
            </div>
            <div className="contnt">
              <h4>Shamraiz Gul</h4>
            </div>
          </div>
        </div>
      </div> */}
            <CancelConfirmModel showCancelModel={showCancelModel} setShowCancelModel={setShowCancelModel} message="If you cancel, your rank will be greatly affected. Are you sure you want to cancel?" confirmCancel={jobCancelConfirm} jobCancelFuncForNo={jobCancelFuncForNo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyJobDetailsProf;
