import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { Rating } from 'react-simple-star-rating'
import { addReview, getReview, updateReview } from '../../../services/api/jobApis'
import { toast } from 'react-toastify'
import { setReviewRating } from '../../../redux/reducers/jobSlice'
import { useDispatch, useSelector } from 'react-redux'

const ReviewModal = ({ jobId, showReviewModal, setShowReviewModal }) => {
    const dispatch = useDispatch()
    const [rating, setRating] = useState(0)
    const [ratingText, setRatingText] = useState('')
    const [prevData, setPrevData] = useState(null)
    const { ratingReview } = useSelector((state) => state.job);

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
    }

    const addReviewFunc = async () => {
        let apiData = {
            review: ratingText,
            rating: rating,
            jobId: jobId
        }
        let reviewAddResponse = ratingReview?._id ? await updateReview(apiData) : await addReview(apiData)
        if (reviewAddResponse?.serverResponse?.code === 200 || reviewAddResponse?.serverResponse?.code === 201) {
            dispatch(setReviewRating({ reviewRating: reviewAddResponse.result?.data }))

            toast.success(reviewAddResponse?.serverResponse?.message)
            setShowReviewModal(false)
        }
        else {
            toast.error(reviewAddResponse?.serverResponse?.message)
        }
    }

    useEffect(() => {
        (async () => {
            let jobReviewData = await getReview(jobId)
            if (jobReviewData?.serverResponse?.code === 200) {
                dispatch(setReviewRating({ reviewRating: jobReviewData.result?.data }))
                setRatingText(jobReviewData?.result?.data?.review ?? '')
                setRating(jobReviewData?.result?.data?.rating ?? '')
                setPrevData(jobReviewData?.result?.data)
            }
        })()
    }, [])
    return (
        <Modal
            className="modal order-d-popup fade"
            show={showReviewModal}
            onHide={() => setShowReviewModal(false)}
            centered
        >
            <div className="review-modal">
                <ModalHeader closeButton>
                    <ModalBody>
                        <div className="review-modal-wraper">
                            <h4>Submit a review</h4>
                            <div className={`review-stars-section`}>
                                <Rating onClick={handleRating} value={rating} initialValue={rating} allowFraction />
                            </div>
                            <p>{rating} star out of 5 stars</p>
                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows={3}
                                    placeholder="Write your review"
                                    onChange={(e) => setRatingText(e.target.value)}
                                    value={ratingText}
                                />
                            </div>
                            <button className="btn" onClick={addReviewFunc}>
                                Submit review
                            </button>
                        </div>
                    </ModalBody>
                </ModalHeader>
            </div>
        </Modal>
    )
}

export default ReviewModal