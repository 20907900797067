import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getAllJobs } from "../../../services/api/jobApis";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import { setJobs } from "../../../redux/reducers/jobSlice";
import NoContentAvailable from "../../../components/common/NoContentAvailable";
import SingleJobCard from "./SingleJobCard";
import JobDetailsProofreader from "./JobDetailsProofreader";

const JobsListProofreader = () => {
  const dispatch = useDispatch();
  const { currentPage, myJobs, totalJobCount } = useSelector(
    (state) => state.job
  );
  const getOngoingProjects = async (page) => {
    dispatch(startLoading());
    let data = await getAllJobs("not-assigned", page ?? 1);
    if (data?.data?.length > 0) {
      dispatch(
        setJobs({
          jobs: data?.data,
          pageNumber: data?.pageNumber,
          totalJobCount: data?.dataCount,
        })
      );
    }
    dispatch(stopLoading());
  };
  useEffect(() => {
    if (!myJobs || myJobs?.length === 0) {
      getOngoingProjects();
    }
  }, [myJobs]);
  useEffect(() => {
    return () => {
      dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
    };
  }, []);
  const loadMoreData = () => {
    getOngoingProjects(currentPage + 1);
  };
  return (
    <div className="user-dashbord">
      <div className="section-head-two common-btm-mrgn common-top-mrgn">
        <div className="container">
          <div className="content">
            <h3>
              Jobs <span>({totalJobCount ?? 0})</span>
            </h3>
          </div>
        </div>
      </div>
      <div className="ongong-and-post-project-sec common-btm-padd ongong-proof-post-project-sec">
        <div className="container">
          <div className="card">
            <div className="ongong-post-wraper">
              <div className="row">
                {myJobs ? (
                  myJobs.map((item, idx) => {
                    return (
                      <SingleJobCard
                        item={item}
                        id={idx}
                        getOngoingProjects={getOngoingProjects}
                      />
                    );
                  })
                ) : (
                  <NoContentAvailable title={"No jobs available"} />
                )}
              </div>
            </div>
          </div>
        </div>
        {totalJobCount - myJobs?.length > 0 && (
          <div className="end-btn" onClick={loadMoreData}>
            <p className="btn light-btn">Load more</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobsListProofreader;
