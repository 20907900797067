import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { drawMoneyFormikAlawnevalidator, drawMoneyFormikCLIQvalidator, drawMoneyFormikNotJordanvalidator } from '../../../services/formValidator/withdrawFormValidator';
import { useFormik } from 'formik';
import { withDrawMoneyAlawnehDataApi, withDrawMoneyCLIQDataApi, withDrawMoneyNotJordanDataApi } from '../../../services/api/withDrawApis';
import { updateUser } from '../../../redux/reducers/authSlice';

const WithDrawModelCustomer = ({ showWithDrawModel, setShowWithDrawModel }) => {
    const { user } = useSelector((state) => state.auth);
    const [choosenOption, setChooseOption] = useState('');
    const dispatch = useDispatch()
    const withDrawMoneyFormikCLIQ = useFormik({
        initialValues: {
            alias: user?.withdraw.alias,
            phoneNumber: user?.withdraw.phoneNumber ? user?.withdraw.phoneNumber : user?.phoneNumber,


        },
        validationSchema: drawMoneyFormikCLIQvalidator.validationSchema,
        onSubmit: async (values) => {
            let data = values;
            let withDrawMoneyCLIQData = await withDrawMoneyCLIQDataApi(data);
            if (withDrawMoneyCLIQData?.serverResponse?.code === 200) {
                dispatch(updateUser({ user: withDrawMoneyCLIQData?.result?.data }))
                setShowWithDrawModel(false)

            }


        },
    });
    const withDrawMoneyFormikAlawneh = useFormik({
        initialValues: {
            name: user?.withdraw.name ? user?.withdraw.name : user?.name,
            city: user?.withdraw?.city,


        },
        validationSchema: drawMoneyFormikAlawnevalidator.validationSchema,
        onSubmit: async (values) => {
            let data = values;
            let withDrawMoneyAlawnehData = await withDrawMoneyCLIQDataApi(data);
            if (withDrawMoneyAlawnehData?.serverResponse?.code === 200) {
                dispatch(updateUser({ user: withDrawMoneyAlawnehData?.result?.data }))
                setShowWithDrawModel(false)

            }

        },
    });
    const withDrawMoneyFormikNotJordan = useFormik({
        initialValues: {
            name: user?.withdraw.name ? user?.withdraw.name : user?.name,
            city: user?.withdraw?.city,


        },
        validationSchema: drawMoneyFormikNotJordanvalidator.validationSchema,
        onSubmit: async (values) => {
            let data = values;
            let withDrawMoneyNotJordanData = await withDrawMoneyCLIQDataApi(data);
            if (withDrawMoneyNotJordanData?.serverResponse?.code === 200) {
                dispatch(updateUser({ user: withDrawMoneyNotJordanData?.result?.data }))
                setShowWithDrawModel(false)

            }


        },
    });
    const handleSubmit = (e) => {
        e.preventDefault()
        if (choosenOption === 'CLIQ') {
            withDrawMoneyFormikCLIQ.handleSubmit()
        }
        if (choosenOption === 'Alawneh') {
            withDrawMoneyFormikAlawneh.handleSubmit()
        }
        if (user?.country !== 'Jordan') {
            withDrawMoneyFormikNotJordan.handleSubmit()
        }
    }
    // const handleKeyDownForPhone = (e) => {
    //     const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    //     // Allow backspace (keyCode 8)
    //     if (e.keyCode === 8) {
    //         return;
    //     }

    //     if (!allowedKeys.includes(e.key)) {
    //         e.preventDefault();
    //     }
    // };
    const handleInputChangeForPhone = (e) => {
        e.target.value = e.target.value.replace(/\D/g, ''); // Filter out non-digit characters
        withDrawMoneyFormikCLIQ.handleChange(e); // Call Formik's handleChange with the modified event
    };

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showWithDrawModel}
            onHide={() => setShowWithDrawModel(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Withdraw Money
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    {user?.country === 'Jordan' && (
                        <div className={`currency-toggle`}>
                            <label>
                                CLIQ Transfer
                                <input
                                    type="radio"
                                    id="CLIQ"
                                    name="money"
                                    value={'CLIQ'}
                                    checked={choosenOption === 'CLIQ'}
                                    onChange={() => setChooseOption('CLIQ')}
                                />
                            </label>
                            <label>
                                Alawneh Exchange
                                <input
                                    type="radio"
                                    id="Alawneh"
                                    name="money"
                                    value={'Alawneh'}
                                    checked={choosenOption === 'Alawneh'}
                                    onChange={() => setChooseOption('Alawneh')}
                                />
                            </label>
                        </div>
                    )}
                    {user?.country !== 'Jordan' && (
                        <div className={`currency-toggle`}>
                            <label>Western Union Details</label>
                        </div>
                    )}
                </div>

                {choosenOption === 'CLIQ' && (
                    <form>
                        <div className="form-group">
                            <label>CLIQ Alias</label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                id="alias"
                                name="alias"
                                onChange={withDrawMoneyFormikCLIQ.handleChange}
                                value={withDrawMoneyFormikCLIQ.values.alias}
                            />
                            <p className="form-error-message">
                                {withDrawMoneyFormikCLIQ.touched.countryCode &&
                                    withDrawMoneyFormikCLIQ.errors.alias}
                            </p>
                        </div>
                        <div className="form-group">
                            <label>CLIQ Phone Number</label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                onChange={handleInputChangeForPhone}

                                value={withDrawMoneyFormikCLIQ.values.phoneNumber}
                            />
                            <p className="form-error-message">
                                {withDrawMoneyFormikCLIQ.touched.phoneNumber &&
                                    withDrawMoneyFormikCLIQ.errors.phoneNumber}
                            </p>
                        </div>
                    </form>
                )}

                {choosenOption === 'Alawneh' && (
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={withDrawMoneyFormikAlawneh.handleChange}
                                value={withDrawMoneyFormikAlawneh.values.name}
                            />
                            <p className="form-error-message">
                                {withDrawMoneyFormikAlawneh.touched.name &&
                                    withDrawMoneyFormikAlawneh.errors.name}
                            </p>
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                id="city"
                                name="city"
                                onChange={withDrawMoneyFormikAlawneh.handleChange}
                                value={withDrawMoneyFormikAlawneh.values.city}
                            />
                            <p className="form-error-message">
                                {withDrawMoneyFormikAlawneh.touched.city &&
                                    withDrawMoneyFormikAlawneh.errors.city}
                            </p>
                        </div>
                    </form>
                )}

                {user?.country !== 'Jordan' && (
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={withDrawMoneyFormikNotJordan.handleChange}
                                value={withDrawMoneyFormikNotJordan.values.name}
                            />
                            <p className="form-error-message">
                                {withDrawMoneyFormikNotJordan.touched.name &&
                                    withDrawMoneyFormikNotJordan.errors.name}
                            </p>
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                id="city"
                                name="city"
                                onChange={withDrawMoneyFormikNotJordan.handleChange}
                                value={withDrawMoneyFormikNotJordan.values.city}
                            />
                            <p className="form-error-message">
                                {withDrawMoneyFormikNotJordan.touched.city &&
                                    withDrawMoneyFormikNotJordan.errors.city}
                            </p>
                        </div>
                    </form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn w-100"
                    onClick={(e) => handleSubmit(e)}
                >
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default WithDrawModelCustomer;
