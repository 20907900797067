import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DragDropUploadButton from "../../../components/common/DragDropUploadButton";
import {
  latestDegreeTranslator,
  specilizationsTranslator,
  transExpTranslator,
  nativeLangTranslator,
  languageToTranslateFromTranslator,
  pagesYouCanTranslatePerDayTranslator,
  activeHoursTranslator,
  translationExperience,
} from "../../../constants/config";
import { setAddQualificationsTrans } from "../../../redux/reducers/qualificationSlice";
import {
  addQualiProofReaderApi,
  addQualiTranslatorApi,
  getProfile,
  getProofReaderQualification,
  getTranslatorQualification,
  uploadCertificate,
  uploadResume,
  uploadSampleWork,
  uploadDegreeCertificate,
} from "../../../services/api/profileApi";
import { addQualiTranslatorValidator } from "../../../services/formValidator/translatorValidator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { removeFileFromS3 } from "../../../services/api/jobPostApis";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import { updateUser } from "../../../redux/reducers/authSlice";
import { useNavigate, Link } from "react-router-dom";
import { getAllLanguages } from "../../../services/api/languagesApis";

const AddQualification = () => {
  const dispatch = useDispatch();
  const [uploadCertificatedata, setUploadCertificateData] = useState([]);
  // const [watchedVideo, setwatchedVideo] = useState(false)
  const [uploadResumeData, setUploadResumeData] = useState([]);
  const [uploadSampleWorkData, setUploadSampleWorkData] = useState([]);
  const [uploadDegreeCertificateData, setUploadDegreeCertificateData] =
    useState([]);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [langArray, setLangArray] = useState([]);

  const [specialized, setSpecialized] = useState(false);

  const addQualiFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email ?? "",
      certificatePhoto: [],
      resume: [],
      sampleWork: [],
      degreeCertificate: [],
      latestDegree: "",
      other: "",
      specialization: "",
      additional: null,
      translatingExperience: "",
      nativeLanguage:
        langArray[0]?.languageName !== undefined
          ? langArray[0]?.languageName
          : "",
      languageToTranslateFrom:
        langArray[0]?.languageName !== undefined
          ? langArray[0]?.languageName
          : "",
      languagesToProofread: [],
      hasAbroadExperience: false,
      abroadExperienceLong: "",
      abroadExperienceWhere: "",
      citationConventions: [],
      portfolioLink: "",
      // pagesYouCanTranslatePerDay:
      //     "",
      videoWatched: false,
      // activeHours: "",
    },
    validationSchema: addQualiTranslatorValidator.validationSchema,
    onSubmit: async (values) => {
      let { serverResponse, result } =
        user?.role === "translator"
          ? await addQualiTranslatorApi(values)
          : await addQualiProofReaderApi(values);

      if (serverResponse.code === 201) {
        toast.success(
          "Please allow 48 hours for us to review your qualifications. If all is good, you will then be able to access the job board."
        );
        // getUserDetails()
        dispatch(setAddQualificationsTrans({ qualifications: result.data }));
        navigate(`/verify-email`);
      } else {
        toast.error("Qualification not added");
      }
    },
  });

  const uploadCertificateFile = async (data) => {
    let resData = await uploadCertificate(data);
    if (resData?.result) {
      setUploadCertificateData(
        uploadCertificatedata?.concat(resData?.result.data)
      );
      addQualiFormik.setFieldValue(
        "certificatePhoto",
        addQualiFormik?.values.certificatePhoto.concat(resData?.result.data)
      );
    }
  };
  const uploadResumeFile = async (data) => {
    let resData = await uploadResume(data);
    if (resData?.result) {
      setUploadResumeData(uploadResumeData?.concat(resData?.result.data));
      addQualiFormik.setFieldValue(
        "resume",
        addQualiFormik?.values.resume.concat(resData?.result.data)
      );
    }
  };
  const uploadSampleWorkFile = async (data) => {
    let resData = await uploadSampleWork(data);
    if (resData?.result) {
      setUploadSampleWorkData(
        uploadSampleWorkData?.concat(resData?.result.data)
      );
      addQualiFormik.setFieldValue(
        "sampleWork",
        addQualiFormik?.values.sampleWork.concat(resData?.result.data)
      );
    }
  };
  const uploadDegreeCertificateFile = async (data) => {
    let resData = await uploadDegreeCertificate(data); // Assume you have a similar API endpoint for degree certificates
    if (resData?.result) {
      setUploadDegreeCertificateData(
        uploadDegreeCertificateData?.concat(resData?.result.data)
      );
      console.log(addQualiFormik);
      // You might also want to update the form field if necessary
      addQualiFormik.setFieldValue(
        "degreeCertificate",
        addQualiFormik?.values.degreeCertificate.concat(resData?.result.data)
      );
    }
  };

  const removeItem = async (item, type) => {
    let data = {
      key: item?.key,
    };
    dispatch(startLoading());
    let { serverResponse } = await removeFileFromS3(data);
    if (serverResponse?.code === 200) {
      addQualiFormik.setFieldValue(
        `${type}`,
        addQualiFormik?.values[type].filter((val) => val?.key !== item?.key)
      );
    }
    dispatch(stopLoading());
  };
  const getUserDetails = async () => {
    let resultData = await getProfile();
    dispatch(updateUser({ user: resultData?.result?.data }));
  };
  useEffect(() => {
    if (user?.role === "proofreader") {
      getProfQuali();
    }
    if (user?.role === "translator") {
      getTransQuali();
    }
  }, []);
  const getTransQuali = async () => {
    let resData = await getTranslatorQualification();
    if (resData?.result?.data) {
      navigate("/translator-dashboard");
    }
  };
  const getProfQuali = async () => {
    let resData = await getProofReaderQualification();
    if (resData?.result?.data) {
      navigate("/proofreader-dashboard");
    }
  };
  useEffect(() => {
    // setwatchedVideo(false);
    (async () => {
      let languageJson = await getAllLanguages()
      setLangArray(languageJson.data)
    })()
  })

  useEffect(() => {
    console.log(addQualiFormik.errors)
  }, [addQualiFormik])


  // useEffect(() => {
  //     console.log(addQualiFormik.values)
  // }, [addQualiFormik])

  return (
    <>
      <section className="user-dashbord sec_pad">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="translator-edit-profile-right qualification-wrap">
                <div className="content common-btm-mrgn">
                  <h4>Add Qualification</h4>
                </div>
                <div className="signin-form-wrap">
                  <form onSubmit={addQualiFormik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group file-upload">
                          <label htmlFor="myfile">
                            Drag to upload photo or you can&nbsp;
                            <span>browse</span>
                          </label>
                          <DragDropUploadButton
                            multiple={true}
                            onChange={uploadCertificateFile}
                          />
                          {/* <p className="form-error-message">
                                                        {
                                                            addQualiFormik.errors.certificatePhoto}
                                                    </p> */}
                        </div>
                        {addQualiFormik.values.certificatePhoto &&
                          addQualiFormik.values.certificatePhoto?.length >
                          0 && (
                            <div className="uploaded-file-show show-file-wrap">
                              {addQualiFormik.values.certificatePhoto.map(
                                (item, index) => {
                                  return (
                                    <div className="show-file">
                                      <div
                                        onClick={() =>
                                          removeItem(item, "certificatePhoto")
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </div>
                                      <p className="extenstionText">
                                        {item?.name}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>1. Email</label>
                          <input
                            type="email"
                            placeholder="example@gmail.com"
                            className="form-control"
                            name="email"
                            id="email"
                            value={addQualiFormik.values.email}
                            onChange={addQualiFormik.handleChange}
                          />
                        </div>
                        <p className="form-error-message">
                          {addQualiFormik.touched.email &&
                            addQualiFormik.errors.email}
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>2. Latest degree</label>
                          <div className="row">
                            {latestDegreeTranslator.map((item, id) => (
                              <div key={id} className="col-lg-4">
                                <div className="rdio-btn-for-db-frm">
                                  <input
                                    type="radio"
                                    id={`latestDegree${id}`}
                                    name="latestDegree"
                                    value={item?.value}
                                    onClick={() =>
                                      addQualiFormik.setFieldValue("other", "")
                                    }
                                    checked={
                                      addQualiFormik.values.latestDegree ===
                                      item?.value
                                    }
                                    onChange={addQualiFormik.handleChange}
                                  />
                                  <label htmlFor={`latestDegree${id}`}>
                                    {item?.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                            <p className="form-error-message">
                              {addQualiFormik.touched.latestDegree &&
                                addQualiFormik.touched.other !== "" &&
                                addQualiFormik.errors.latestDegree}
                            </p>
                            <div className="col-lg-12">
                              <label className="mt-3">
                                Other (Please specify in the box below)
                              </label>
                              <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                name="other"
                                id="other"
                                onChange={addQualiFormik.handleChange}
                                onClick={() =>
                                  addQualiFormik.setFieldValue(
                                    "latestDegree",
                                    ""
                                  )
                                }
                                value={addQualiFormik.values.other}
                              />
                              <p className="form-error-message">
                                {addQualiFormik.touched.other &&
                                  addQualiFormik.errors.other}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <label>Upload certificate/diploma</label>
                            <div className="form-group file-upload">
                              <label htmlFor="myfile">
                                Drag to upload photo or you can&nbsp;
                                <span>browse</span>
                              </label>
                              <DragDropUploadButton
                                multiple={true}
                                onChange={uploadDegreeCertificateFile}
                              />
                            </div>
                            {addQualiFormik.values.degreeCertificate &&
                              addQualiFormik.values.degreeCertificate?.length >
                              0 && (
                                <div className="uploaded-file-show show-file-wrap">
                                  {addQualiFormik.values.degreeCertificate.map(
                                    (item, index) => {
                                      return (
                                        <div className="show-file">
                                          kkk
                                          <div
                                            onClick={() =>
                                              removeItem(
                                                item,
                                                "degreeCertificate"
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                            />
                                          </div>
                                          <p className="extenstionText">
                                            {item?.name}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                          </div>
                          <p className="form-error-message">
                            {addQualiFormik.touched.degreeCertificate &&
                              addQualiFormik.errors.degreeCertificate}
                          </p>
                        </div>
                      </div>

                      {user?.role !== "proofreader" && (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              3. Which one of the following best describes your
                              translation experience?
                            </label>
                            <div className="row">
                              <div className="col-lg-4 mb-3">
                                <div className="rdio-btn-for-db-frm">
                                  <input
                                    type="radio"
                                    id="General"
                                    name="experience"
                                    value="General"
                                    onClick={() =>
                                      setSpecialized(false) &&
                                      addQualiFormik.setFieldValue(
                                        "additional",
                                        ""
                                      )
                                    }
                                    onChange={addQualiFormik.handleChange}
                                  />
                                  <label htmlFor="General">
                                    A. I am a General Translator
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-5 mb-3">
                                <div className="rdio-btn-for-db-frm">
                                  <input
                                    type="radio"
                                    id="Specialized"
                                    name="experience"
                                    value="Specialized"
                                    onClick={() => setSpecialized(true)}
                                    onChange={addQualiFormik.handleChange}
                                  />
                                  <label
                                    htmlFor="Specialized"
                                    className="justify-content-center"
                                  >
                                    B. I am a Specialized Translator
                                  </label>
                                </div>
                              </div>
                              <p className="form-error-message">
                                {addQualiFormik.touched.specialization &&
                                  addQualiFormik.errors.specialization}
                              </p>
                            </div>
                            <div
                              className={`${specialized ? "d-block" : "d-none"
                                } `}
                            >
                              <label>
                                Which field are you a specialized translator in?
                              </label>
                              <div className="row">
                                {specilizationsTranslator.map((item, id) => (
                                  <div key={id} className="col-lg-4 mb-3">
                                    <div className="rdio-btn-for-db-frm">
                                      <input
                                        type="radio"
                                        id={`specialization${id}`}
                                        name="specialization"
                                        value={item?.value}
                                        onClick={() =>
                                          addQualiFormik.setFieldValue(
                                            "additional",
                                            ""
                                          )
                                        }
                                        checked={
                                          addQualiFormik.values
                                            .specialization === item?.value
                                        }
                                        onChange={addQualiFormik.handleChange}
                                      />
                                      <label htmlFor={`specialization${id}`}>
                                        {item?.name}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                                <p className="form-error-message">
                                  {addQualiFormik.touched.specialization &&
                                    addQualiFormik.errors.specialization}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>
                            {user?.role === "proofreader"
                              ? "3. How many years of proofreading experience do you have?"
                              : "4. How many years of translating experience?"}
                          </label>
                          <div className="row">
                            {transExpTranslator.map((item, id) => (
                              <div key={id} className="col-lg-4">
                                <div className="rdio-btn-for-db-frm">
                                  <input
                                    type="radio"
                                    id={`translatingExperience${id}`}
                                    name="translatingExperience"
                                    value={item?.value}
                                    checked={
                                      addQualiFormik.values
                                        .translatingExperience === item?.value
                                    }
                                    onChange={addQualiFormik.handleChange}
                                  />
                                  <label htmlFor={`translatingExperience${id}`}>
                                    {item?.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                            <p className="form-error-message">
                              {addQualiFormik.touched.translatingExperience &&
                                addQualiFormik.errors.translatingExperience}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>
                            {user?.role === "proofreader" ? "4. " : "5. "}What
                            is your native language?
                          </label>

                          <select
                            name="nativeLanguage"
                            id="nativeLanguage"
                            className="form-select form-control"
                            onChange={addQualiFormik.handleChange}
                            value={addQualiFormik?.nativeLanguage}
                          >
                            {langArray &&
                              langArray.length > 0 &&
                              langArray.map((item, id) => (
                                <option key={id} value={item?.languageName}>
                                  {item?.languageName}
                                </option>
                              ))}
                          </select>
                          <p className="form-error-message">
                            {addQualiFormik.touched.nativeLanguage &&
                              addQualiFormik.errors.nativeLanguage}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        {user?.role === "proofreader" ? (
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label>
                                5. Which language(s) do you proofread? (use
                                control to choose mor than one)
                              </label>
                              <select
                                name="languagesToProofread"
                                id="languagesToProofread"
                                className="form-select form-control"
                                onChange={addQualiFormik.handleChange}
                                value={addQualiFormik?.languagesToProofread}
                                multiple
                              >
                                {langArray &&
                                  langArray.length > 0 &&
                                  langArray.map((item, id) => (
                                    <option
                                      key={id}
                                      value={item?.languageName}
                                      selected={
                                        item?.languageName ===
                                        addQualiFormik.values
                                          .languagesToProofread
                                      }
                                    >
                                      {item?.languageName}
                                    </option>
                                  ))}
                              </select>
                              <p className="form-error-message">
                                {addQualiFormik.touched.languagesToProofread &&
                                  addQualiFormik.errors.languagesToProofread}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group">
                            <label>
                              6. Which language do you translate to/from?
                            </label>
                            <select
                              name="languageToTranslateFrom"
                              id="languageToTranslateFrom"
                              className="form-select form-control"
                              onChange={addQualiFormik.handleChange}
                              value={addQualiFormik?.languageToTranslateFrom}
                            >
                              {langArray &&
                                langArray.length > 0 &&
                                langArray.map((item, id) => (
                                  <option key={id} value={item?.languageName}>
                                    {item?.languageName}
                                  </option>
                                ))}
                            </select>
                            <p className="form-error-message">
                              {addQualiFormik.touched.languageToTranslateFrom &&
                                addQualiFormik.errors.languageToTranslateFrom}
                            </p>
                          </div>
                        )}
                      </div>

                      {user?.role === "proofreader" && (
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              6. Which citation conventions are you familiar
                              with? (select all that apply)
                            </label>
                            <select
                              name="citationConventions"
                              id="citationConventions"
                              className="form-select form-control"
                              onChange={(event) => {
                                const { options } = event.target;
                                const value = [];
                                for (
                                  let i = 0, l = options.length;
                                  i < l;
                                  i++
                                ) {
                                  if (options[i].selected) {
                                    value.push(options[i].value);
                                  }
                                }
                                addQualiFormik.setFieldValue(
                                  "citationConventions",
                                  value
                                );
                              }}
                              value={addQualiFormik.values.citationConventions}
                              multiple
                            >
                              <option value="APA">APA</option>
                              <option value="MLA">MLA</option>
                              <option value="Harvard">Harvard</option>
                              <option value="Chicago">Chicago</option>
                              <option value="IEEE">IEEE</option>
                            </select>
                          </div>
                        </div>
                      )}

                      {user?.role === "proofreader" && (
                        <div className="col-lg-12">
                          <div className="form-group custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              id="hasAbroadExperience"
                              name="hasAbroadExperience"
                              onChange={(e) => {
                                addQualiFormik.setFieldValue(
                                  "hasAbroadExperience",
                                  e.target.checked
                                );
                                // Optionally clear the details field if the user unchecks the box
                                if (!e.target.checked) {
                                  addQualiFormik.setFieldValue(
                                    "abroadExperienceDetails",
                                    ""
                                  );
                                }
                              }}
                              checked={
                                addQualiFormik.values.hasAbroadExperience
                              }
                            />
                            <label
                              className="ml-2"
                              htmlFor="hasAbroadExperience"
                            >
                              7. Have you lived/worked/studied abroad?
                            </label>
                          </div>
                        </div>
                      )}

                      {user?.role === "proofreader" &&
                        addQualiFormik.values.hasAbroadExperience && (
                          <div className="col-lg-12">
                            <div className="form-group">
                              <label htmlFor="abroadExperienceWhere">
                                If yes, where?
                              </label>
                              <textarea
                                className="form-control"
                                id="abroadExperienceWhere"
                                name="abroadExperienceWhere"
                                placeholder="Enter details where..."
                                value={
                                  addQualiFormik.values.abroadExperienceWhere
                                }
                                onChange={addQualiFormik.handleChange}
                              ></textarea>

                              <label htmlFor="abroadExperienceLong">
                                If yes, how long?
                              </label>
                              <textarea
                                className="form-control"
                                id="abroadExperienceLong"
                                name="abroadExperienceLong"
                                placeholder="Enter details how long..."
                                value={
                                  addQualiFormik.values.abroadExperienceLong
                                }
                                onChange={addQualiFormik.handleChange}
                              ></textarea>
                            </div>
                          </div>
                        )}

                      <div className="col-lg-12">
                        <label>
                          {user?.role === "proofreader" ? "8. " : "7. "}Upload
                          resume / CV
                        </label>
                        <div className="form-group file-upload">
                          <label htmlFor="myfile">
                            Drag to upload photo or you can&nbsp;
                            <span>browse</span>
                          </label>
                          <DragDropUploadButton
                            multiple={true}
                            onChange={uploadResumeFile}
                          />
                        </div>
                        {addQualiFormik.values.resume &&
                          addQualiFormik.values.resume?.length > 0 && (
                            <div className="uploaded-file-show show-file-wrap">
                              {addQualiFormik.values.resume.map(
                                (item, index) => {
                                  return (
                                    <div className="show-file">
                                      <div
                                        onClick={() =>
                                          removeItem(item, "resume")
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </div>
                                      <p className="extenstionText">
                                        {item?.name}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}

                        <p className="form-error-message">
                          {addQualiFormik.touched.resume &&
                            addQualiFormik.errors.resume}
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <label>
                          {user?.role === "proofreader" ? "9. " : "8. "}Sample
                          of your work
                        </label>

                        <div className="form-group file-upload">
                          <label htmlFor="myfile">
                            Drag to upload photo or you can&nbsp;
                            <span>browse</span>
                          </label>
                          <DragDropUploadButton
                            multiple={true}
                            onChange={uploadSampleWorkFile}
                          />
                        </div>
                        {addQualiFormik.values.sampleWork &&
                          addQualiFormik.values.sampleWork?.length > 0 && (
                            <div className="uploaded-file-show show-file-wrap">
                              {addQualiFormik.values.sampleWork.map(
                                (item, index) => {
                                  return (
                                    <div className="show-file">
                                      <div
                                        onClick={() =>
                                          removeItem(item, "sampleWork")
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </div>
                                      <p className="extenstionText">
                                        {item?.name}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}

                        {/* <p className="form-error-message">
                                                    {addQualiFormik.touched.sampleWork &&
                                                        addQualiFormik.errors.sampleWork}
                                                </p> */}
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>
                            {user?.role === "proofreader" ? "10. " : "9. "}Link
                            to portfolio
                          </label>

                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="portfolioLink"
                            id="portfolioLink"
                            value={addQualiFormik.values.portfolioLink}
                            onChange={addQualiFormik.handleChange}
                          />
                          <p className="form-error-message">
                            {addQualiFormik.touched.portfolioLink &&
                              addQualiFormik.errors.portfolioLink}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label></label>
                          <div className="row">
                            {/* <iframe
                              width="100%"
                              height="415"
                              src="https://www.youtube.com/embed/LZz03myFuWA?si=P5aFMXv0LriBr7XG"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe> */}

                            <div className="form-group custom-checkbox mb-0 mt-4">
                              <input
                                type="checkbox"
                                id="videoWatched"
                                name="videoWatched"
                                onChange={(e) => {
                                  addQualiFormik.setFieldValue(
                                    "videoWatched",
                                    e.target.checked
                                  );
                                }}
                                checked={addQualiFormik.values.videoWatched}
                              />
                              <label htmlFor="videoWatched">
                                I acknowledge that the information provided is accurate, I know my responsibilities, and I fully understand the process. (<Link target='_blank' to='/process'>Learn more</Link>)

                              </label>
                            </div>
                            <p className="form-error-message">
                              {addQualiFormik.touched.videoWatched &&
                                addQualiFormik.errors.videoWatched}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label>10. Choose Timezone</label>
                    <select
                      name="timezone"
                      id="timezone"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                    >
                      {chooseTimezoneTranslator.map((item, id) => (
                        <option key={id} value={item?.value}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.timezone &&
                        addQualiFormik.errors.timezone}
                    </p>
                  </div>
                </div> */}
                      {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label>11. Choose Country</label>
                    <select
                      name="country"
                      id="country"
                      onChange={addQualiFormik.handleChange}
                      className="form-select form-control"
                    >
                      {chooseCountryTranslator.map((item, id) => (
                        <option key={id} value={item?.value}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.country &&
                        addQualiFormik.errors.country}
                    </p>
                  </div>
                </div> */}

                      {/* <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>11. Choose your active hours</label>
                                                    <select
                                                        name="activeHours"
                                                        id="activeHours"
                                                        className="form-select form-control"
                                                        onChange={addQualiFormik.handleChange}
                                                    >
                                                        {activeHoursTranslator.map((item, id) => (
                                                            <option
                                                                key={id}
                                                                value={item?.value}
                                                                selected={

                                                                    item?.value
                                                                }
                                                            >
                                                                {item?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <p className="form-error-message">
                                                        {addQualiFormik.touched.activeHours &&
                                                            addQualiFormik.errors.activeHours}
                                                    </p>
                                                </div>
                                            </div> */}

                      <div className="col-lg-12">
                        <div className="form-group mb-0">
                          <button type="submit" className="btn w-100">
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddQualification;
