import React from 'react'

const ProofReaderRankingSys = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h4>Proofreader Ranking System: Encouragement and Consequences</h4>
                                    <p>At Lingvara, we value the contributions of our proofreaders and strive to create a supportive environment that rewards excellence. Our ranking system plays an essential role in determining payout and acknowledging the exceptional work of our proofreaders. It is designed to encourage high performance while addressing any concerns or issues that may arise. Here's how our ranking system works:</p>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec">

                <div className="container">

                    <p><strong>Payout Differentiation</strong></p>
                    <p>There are two ways in which Proofreaders may earn more than their peers:</p>
                    <ol type="A">
                        <li>
                            <p><em><strong>Stylesheet Adherence</strong></em></p>
                            <ol type="a">
                                <li>
                                    <p>If a client has uploaded a specific stylesheet that a proofreading task must adhere to (as for a specific journal or university), then it is a given that more work will be required from the proofreader. In this case, jobs labeled with &ldquo;Stylesheet Adherence&rdquo; will reward 20% extra on Base payout. Note that if a client simply asks for stylesheet adherence without the job being labelled as such and without paying for the additional service, then the proofreader should notify the project manager ASAP and must not comply with such demand unless asked to by the project manager. In this case, the project manager will contact the client letting the client know that stylesheet adherence requires an extra charge.</p>
                                </li>
                            </ol></li>
                        <li>
                            <p><em><strong>Proofreader Performance Ranking System</strong></em></p>
                        </li>
                    </ol>
                    <p>The ranking you achieve as a proofreader directly affects your payout. We believe in recognizing and rewarding outstanding performance. Here's how the payout ranking system works:</p>
                    <ol>
                        <li>
                            <p><strong>5-Star Ranking</strong>: Achieving a 5-star ranking earns you an additional 10% bonus on top of base payout price.</p>
                        </li>
                        <li>
                            <p><strong>4-Star Ranking</strong>: Attaining a 4-star ranking earns you a 5% extra bonus on top of base payout price.</p>
                        </li>
                        <li>
                            <p><strong>3-Star Ranking and Below</strong>: Translators with a 3-star ranking and below receive the regular payout without any additional bonus.</p>
                        </li>
                    </ol>
                    <p><a name="_GoBack"></a><strong>Determining Factors for Proofreader Rankings</strong></p>
                    <p>The payout ranking is based on a combination of the average customer star review scores and the performance scores on our website. The performance scores take into account various factors that can raise or decrease your ranking. Here's an overview:</p>
                    <ol>
                        <li>
                            <p><strong>Positive Factors</strong>: Factors that can raise your performance score include the number of successfully completed jobs. Consistently delivering high-quality work and meeting client expectations contribute to a positive performance score.</p>
                        </li>
                        <li>
                            <p><strong>Negative Factors</strong>: The following factors can decrease your performance score significantly: a. Number of times corrections are requested by clients. b. Number of jobs you accepted but later cancelled. c. Number of late job submissions. d. Number of rejections by clients.</p>
                        </li>
                    </ol>
                    <p>Please note that factors b, c, and d are weighed heavily and can have a substantial impact on your performance score.</p>
                    <p><strong>Consequences and Conflict Resolution</strong></p>
                    <p>We understand that misunderstandings or unjustified client reviews can sometimes impact your ranking unfairly. In such cases, we are here to assist you. Our goal is to ensure fairness and address any conflicts that may arise. Here's how we handle different scenarios:</p>
                    <ol>
                        <li>
                            <p><strong>Total Payout Score Less Than 3 Stars</strong>: Proofreaders with a total payout score of less than 3 stars will have their access to the job board declined. They will be asked to resolve the issue with the project manager. The project manager will determine the appropriate manner to address the conflicts, which may include but is not limited to:</p>
                            <ul>
                                <li>
                                    <p>Termination of the account with Lingvara and blacklisting.</p>
                                </li>
                                <li>
                                    <p>Opening an investigation in cases where unjustified false reviews from clients are suspected.</p>
                                </li>
                                <li>
                                    <p>Referring the proofreader to a quick online course and reinstating access with a grace period.</p>
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>Our aim is to maintain a high standard of quality and professionalism while supporting our proofreaders in their journey with Lingvara. We are committed to ensuring fair rankings and providing opportunities for growth and improvement.</p>
                    <p>Remember, your hard work and dedication contribute to your success as a proofreader. Keep striving for excellence, delivering outstanding results, and maintaining a positive ranking. We're here to support you along the way.</p>
                    <p><br /><br /></p>
                </div>

            </section>


        </>
    )
}

export default ProofReaderRankingSys