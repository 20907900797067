import React, { useEffect } from 'react'
import DetailsProofreading from './DetailsProofreading'
import { useDispatch, useSelector } from 'react-redux'
import DetailsTranslation from './DetailsTranslation'
import { useLocation } from 'react-router-dom'
import { setSelectedJobDetails } from '../../../redux/reducers/jobSlice'
import { getSingleJob } from '../../../services/api/jobApis'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'

const CustomerJobDetails = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { selectedJobDetails } = useSelector((state) => state.job)
    useEffect(() => {
        (async () => {
            if (!selectedJobDetails && location?.state?.id) {
                dispatch(startLoading())
                let { data } = await getSingleJob(location?.state?.id)
                if (data) {
                    dispatch(setSelectedJobDetails({ selectedJob: data }))
                }
                dispatch(stopLoading())
            }
        })()
        return () => {
            dispatch(setSelectedJobDetails({ selectedJob: null }))
        }
    }, [])

    return (
        <div>
            {selectedJobDetails?.specificService === 'proofreading' && <DetailsProofreading />}
            {selectedJobDetails?.specificService === 'translation' && <DetailsTranslation />}
        </div>
    )
}

export default CustomerJobDetails