import { useFormik } from 'formik'
import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { addEmailVerification } from '../../../services/formValidator/authValidator'

const AddEmailToSocialLogin = ({ checkAvlMail, closeMailModal, socialData, socialLoginCallBackData }) => {

    const emailAddFromik = useFormik({
        initialValues: addEmailVerification.initialValues,
        validationSchema: addEmailVerification.validationSchema,
        onSubmit: (values) => {
            let newData = socialData
            newData.data.email = values.email
            socialLoginCallBackData(newData.data,newData.googleClientId)
        }
    })
    return (
        <div>
            <Modal show={checkAvlMail} onHide={closeMailModal} centered className="uploadModal modal-ls">
                <ModalHeader closeButton>
                    <ModalTitle>Add email</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={emailAddFromik.handleSubmit}>
                        <div className="signin-form-wrap">
                            <h6>Add a mail to your account</h6>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" placeholder="" id='email' name='email' value={emailAddFromik.values.email} onChange={emailAddFromik.handleChange} className="form-control" />
                                <p className='form-error-message'>{emailAddFromik.touched.email &&
                                    emailAddFromik.errors.email}</p>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn w-100">
                                    Add mail
                                </button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default AddEmailToSocialLogin