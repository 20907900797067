import React from 'react'

const HelpSupport = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <h1>Help & Support</h1>
                                <p>Welcome to the Help & Support page of Lingvara. We are dedicated to providing exceptional assistance and resolving any queries or concerns you may have regarding our translation and proofreading services. Here you will find useful information and resources to help you navigate our website and make the most of your experience.</p>

                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec common-btm-padd">

                <div className="container">

                    <p className='static-text'><strong>Frequently Asked Questions (FAQ)</strong></p>
                    <p>Our Frequently Asked Questions section is designed to address common inquiries. It covers a wide range of topics, including account registration, service pricing, order process, and more. Before reaching out to our support team, we recommend visiting the FAQ page to see if your question has already been answered.</p>
                    <p><strong>Knowledge Base</strong></p>
                    <p>Our Knowledge Base contains articles and guides that provide detailed information on various aspects of our services. Whether you are looking for specific instructions on how to place an order, understand our quality assurance process, or manage your account settings, our Knowledge Base is a valuable resource to assist you.</p>
                    <p><strong>Contacting Support</strong></p>
                    <p>If you are unable to find the information you need through our FAQ or Knowledge Base, our support team is here to help. To contact us, please use one of the following methods:</p>
                    <ol>
                        <li>
                            <p><strong>Support Ticket</strong>: Submit a support ticket through our website by filling out the support form. Please provide as much detail as possible about your inquiry or issue, allowing us to assist you more efficiently.</p>
                        </li>
                        <li>
                            <p><strong>Email</strong>: Send us an email at [support email address]. Our support team will respond to your inquiry within [response time frame].</p>
                        </li>
                    </ol>
                    <p><strong>Tips for Efficient Support</strong></p>
                    <p>To help us provide you with the most effective support, please consider the following tips:</p>
                    <ol>
                        <li>
                            <p><strong>Be Specific</strong>: When submitting a support ticket or sending an email, provide clear and detailed information about your question or issue. Include relevant order numbers, account details, and any error messages you may have encountered.</p>
                        </li>
                        <li>
                            <p><strong>Attach Screenshots</strong>: If applicable, attach screenshots or files that can help us understand and resolve your inquiry faster.</p>
                        </li>
                        <li>
                            <p><strong>Include Relevant Information</strong>: When contacting us, provide any relevant context or additional information that may assist us in better understanding your situation.</p>
                        </li>
                    </ol>
                    <p><strong>Service Status and Updates</strong></p>
                    <p>For service-related announcements, updates, or maintenance notifications, please visit our Service Status page. We strive to keep you informed about any temporary disruptions or changes that may impact our services.</p>
                    <p><strong>Data Privacy and Security</strong></p>
                    <p>We take your privacy and the security of your information seriously. For more details about how we handle your personal data, please refer to our Privacy Policy.</p>
                    <p>Thank you for choosing Lingvara. We are here to provide you with the help and support you need to make your experience with our translation and proofreading services as seamless as possible.</p>
                </div>

            </section>


        </>
    )
}

export default HelpSupport