import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { contactFormValidatorCustomer } from '../../../services/formValidator/contactFormValidator';
import { useFormik } from 'formik';
import { contactUsApi } from '../../../services/api/contactApi';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';

const ContactForm = () => {
    const { user } = useSelector((state) => state.auth);
const dispatch=useDispatch()
    const contactFormik = useFormik({
        initialValues: contactFormValidatorCustomer(user, 'initialValues'),
        validationSchema: contactFormValidatorCustomer(user, 'validationSchema'),
        onSubmit: async (values) => {
            let data = values
            let contactUsData = await contactUsApi(data);
            dispatch(startLoading())
            if(contactUsData?.serverResponse.code === 201){
                contactFormik.resetForm()
                dispatch(stopLoading())
            }
        }
    })
    return (
        <>
            <form onSubmit={contactFormik.handleSubmit} >
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <input type="text" name="name" id="name" placeholder=" Name" className="form-control" onChange={contactFormik.handleChange} value={contactFormik.values.name} />
                            <p className='form-error-message'>{contactFormik.touched.name && contactFormik.errors.name}</p>

                        </div>

                    </div>
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <input type="text" name="lastName" id="lastName" placeholder="Last Name" className="form-control" onChange={contactFormik.handleChange} value={contactFormik.values.lastName} />
                            <p className='form-error-message'>{contactFormik.touched.lastName && contactFormik.errors.lastName}</p>

                        </div>

                    </div> */}
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <input type="tel" name="phoneNumber" id="phoneNumber" placeholder="Phone" className="form-control" onChange={contactFormik.handleChange} value={contactFormik.values.phoneNumber} />
                            <p className='form-error-message'>{contactFormik.touched.phoneNumber && contactFormik.errors.phoneNumber}</p>

                        </div>

                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <input type="email" name="email" id="email" placeholder="Email" className="form-control" onChange={contactFormik.handleChange} value={contactFormik.values.email} />
                            <p className='form-error-message'>{contactFormik.touched.email && contactFormik.errors.email}</p>

                        </div>

                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <textarea name="message" id="message" cols="30" rows="10" placeholder="Message"
                                className="form-control" onChange={contactFormik.handleChange} value={contactFormik.values.message}></textarea>
                            <p className='form-error-message'>{contactFormik.touched.message && contactFormik.errors.message}</p>

                        </div>

                    </div>
                    <div className="text-center">
                        <input type="submit" value="submit" className="btn" />
                    </div>

                </div>
            </form>
        </>
    )
}

export default ContactForm