import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const CancelConfirmModel = ({ showCancelModel, setShowCancelModel, message, confirmCancel, jobCancelFuncForNo }) => {
    return (
        <Modal show={showCancelModel} onHide={() => setShowCancelModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={confirmCancel}>
                    Yes
                </Button>
                <Button variant="danger" onClick={jobCancelFuncForNo}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelConfirmModel