import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { userLogout } from '../../../services/api/authApis'
import { useDispatch } from 'react-redux'

const CallToSignup = () => {
    const dipatch = useDispatch()
    const navigate = useNavigate()
    const joinHandler = () => {
        userLogout(dipatch)
        // navigate("/signup?usertype=1")
    }
    return (
        <section className="home-sign-sec padding-bottom padding-top">
            <div className="container">
                <div className="section-heading">
                    <h3 style={{ textAlign: "center", marginTop: "5vh" }}>Want to make money with Lingvara? </h3>
                    <h4 style={{ textAlign: "center" }}>Here are two ways you can earn!</h4>
                </div>
                <div className="row">
                    {!window.location.href.split("/").includes("affilate") && <div className="col-lg-6 col-md-12">
                        <div className="sign-box" >
                            <h4>Sign up to become a translator or proofreader</h4>
                            <p>
                                Join our dedicated team and experience the most transparent work environment in the industry. Work from anywhere in the world! We offer one of the highest starting rates and reward outstanding performance with increasing compensation. Join and let your skills finally payoff.
                            </p>
                            <div className="btn-inline">
                                <NavLink to='/signup?usertype=1' className="btn btn-border">Sign up</NavLink>
                                <NavLink to='/process' className="btn btn-blue-solid">Learn about the process</NavLink>
                            </div>


                        </div>
                    </div>}
                    <div className={`col-lg-${window.location.href.split("/").includes("affilate") ? "12" : "6"} col-md-12`}>
                        <div className="sign-box" >
                            <h4>Join our affiliate program</h4>
                            <p>
                                By promoting our top-tier services and sharing your affiliate link, or even by securing just one service agreement like with a publishing company, you can earn up to 5% of the total price. To begin, create a user account and receive your unique affiliate link. All your earnings are conveniently sent via Western Union.
                            </p>
                            <div className="btn-inline">
                                <NavLink to={`${window.location.href.split("/").includes("affilate") ? "/signup" : "/signup"}`} onClick={joinHandler} className="btn btn-border">Join now!</NavLink>
                                <NavLink to='/affilate' className="btn btn-blue-solid">Learn about the process</NavLink>
                            </div>
                        </div>
                        {/* /signup?usertype=1 */}
                    </div>
                </div>
            </div>
        </section>

    )
}

export default CallToSignup