import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'
import { setSelectedReceiptDetails } from '../../../redux/reducers/jobSlice'

const TranscationDetailsProofreader = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { selectedReceiptDetails } = useSelector((state) => state.job)
    // useEffect(() => {
    //     (async () => {
    //         if (!selectedReceiptDetails && location?.state?.id) {
    //             dispatch(startLoading())
    //             let { data } = await getSingleJob(location?.state?.id)
    //             if (data) {
    //                 dispatch(setSelectedReceiptDetails({ selectedReceipt: data }))
    //             }
    //             dispatch(stopLoading())
    //         }
    //     })()
    //     return () => {
    //         dispatch(setSelectedReceiptDetails({ selectedReceipt: null }))
    //     }
    // }, [])
    return (
        <div>
            Transcations
        </div>
    )
}

export default TranscationDetailsProofreader