import { useState } from 'react';
import { deleteAccount } from '../../../services/api/authApis';
import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux';

import { userLogout}  from "../../../services/api/authApis";


const CustomerDeleteProfile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { user } = useSelector((state) => state.auth);

    const deletAccountHandler = (e) => {
        e.preventDefault();

        // dispatch(deleteAccount(user._id));

        deleteAccount(user._id)

        userLogout(dispatch)
        navigate("/");
    }

    return (
        <>
            <div className="col-lg-10">
                <div className="translator-edit-profile-right">
                    <div className="content common-btm-mrgn">
                        <h4>Delete Account</h4>
                    </div>
                    <div className="signin-form-wrap">
                        <form >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder=""
                                            id="name"
                                            name="name"
                                            className="form-control"
                                            value={user.name}
                                        />
                                    </div>
                                </div>

                                {/* <div className="col-lg-12">
                                            <div className="form-group">
                                                <label>Paypal email</label>
                                                <input type="email" placeholder="example@gmail.com" className="form-control" />
                                            </div>
                                        </div> */}
                                <div className="col-lg-12">
                                    <div className="form-group mb-0">
                                        <button type="button" onClick={handleShow} className="btn w-100">
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Modal 
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered                         
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Account?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete your account!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary bg-secondary py-2 px-4" onClick={handleClose}>cancel</Button>
                    <Button variant="danger bg-danger py-2 px-4" onClick={deletAccountHandler}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomerDeleteProfile;
