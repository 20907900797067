import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createChatRooms } from '../../../redux/reducers/chatRoomSlice'
import { chatAccess } from '../../../services/api/chatRoomApis'
import { gettingRoom } from '../../../services/socket'
import LeftSideChatSection from './LeftSideChatSection'
import RighSideChatSection from './RighSideChatSection'

const ChatIndex = () => {
    const [showChat, setShowChat] = useState(false)
    const [innerWidth, setInnerWidth] = useState(0)

    useEffect(() => {
        setInnerWidth(window.innerWidth)
        window.addEventListener('resize', () => setInnerWidth(window.innerWidth));
    }, [window.innerWidth])
    return (
        <div className="user-dashbord">
            <div className="container">
                <div className="user-chat-box">
                    <div className={`chat-lft ${innerWidth <= 991 && (showChat && 'none')}`}>
                        <LeftSideChatSection setShowChat={setShowChat} />
                    </div>
                    {<div className={`chat-right ${innerWidth <= 991 && (!showChat && 'none')}`}>
                        <RighSideChatSection setShowChat={setShowChat} innerWidth={innerWidth} />
                    </div>}
                </div>
            </div>
        </div>

    )
}

export default ChatIndex