import React from 'react'

export const SpecializedEarnMore = ({ slectedUserRole }) => {
    return (
        <section className="after-process-sec padding-bottom">
            <div className="container">
                <div className="gray-box-pro">

                    {slectedUserRole === 1 && <section className="Pricing-sec pb-5 padding-top">
                        <div className="container">
                            <div className="section-heading text-center">

                            </div>
                            <div className="row">
                                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300} >
                                    <div className="Pricing-col-wrap process-specs-wrap" style={{
                                        // backgroundColor: serviceType === item?.name && "#9EB212",
                                        // border: serviceType === item?.name && "1px solid #9EB212"
                                    }} >
                                        <h3>Specialize and Earn More</h3>

                                        <p>
                                            Are you a subject-specialized translator? Great news – you automatically earn 30% more on any subject-specific translation job you choose. When you sign up, simply upload your qualifications to showcase your subject specialty, whether it's in legal translation, medical translation, or any other field. Once verified, we'll label you as a subject-specialized translator.
                                            When you access our job board, you'll notice some jobs labeled as subject-specialized and others as general. While you're welcome to work on either, the subject-specialized assignments come with the extra 30% earnings boost. If you're a general translator, your job board will exclusively display general translation opportunities tailored to your expertise. It's all about making the most of your unique skills and maximizing your earning potential.

                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300} >
                                    <div className="Pricing-col-wrap process-specs-wrap" style={{
                                        // backgroundColor: serviceType === item?.name && "#9EB212",
                                        // border: serviceType === item?.name && "1px solid #9EB212"
                                    }} >
                                        <h3>Rewarding Your Growth</h3>

                                        <p>
                                            At Lingvara, we believe in recognizing and rewarding your talent as it evolves. Our unique ranking system is designed to not only guarantee the highest quality but also to ensure your skills are rightfully compensated. As you successfully complete jobs, your rank ascends, resulting in increased pay for those same tasks. Achieve a 5-star rank to enjoy an additional 10% bonus on every order you complete. And the best part? As long as you maintain quality work, those rates are securely locked in, giving you the stability and financial rewards you deserve. Want even more? How about combining your extra 10% with the extra 30% you gain from being a specialized translator. That’s 40% extra income for the same exact job.

                                        </p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </section>}
                    {slectedUserRole === 2 && <section className="Pricing-sec pb-5 padding-top">
                        <div className="container">
                            <div className="section-heading text-center">

                            </div>
                            <div className="row">
                                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300} >
                                    <div className="Pricing-col-wrap" style={{
                                        // backgroundColor: serviceType === item?.name && "#9EB212",
                                        // border: serviceType === item?.name && "1px solid #9EB212"
                                    }} >
                                        <h3> Boost Your Earnings with Stylesheet Adherence</h3>

                                        <p>
                                            Are you a meticulous proofreader? Here's some exciting news – you can earn a 20% bonus on any work that requires strict adherence to style sheets, recognizing the meticulous effort it takes on your part.

                                            As you explore our job board, you'll come across assignments labeled as style-sheet adherence projects. By taking on these tasks, you not only contribute to flawless content but also enjoy a 20% bonus, making your proofreading endeavors even more rewarding. If you prefer more general proofreading work, your job board will feature opportunities suited to your expertise. Join us and enhance your earning potential while ensuring polished and consistent content for our clients.


                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-6" data-aos="fade-right" data-aos-offset={300} >
                                    <div className="Pricing-col-wrap" style={{
                                        // backgroundColor: serviceType === item?.name && "#9EB212",
                                        // border: serviceType === item?.name && "1px solid #9EB212"
                                    }} >
                                        <h3>Rewarding Your Growth</h3>

                                        <p>
                                            At Lingvara, we believe in recognizing and rewarding your talent as it evolves. Our unique ranking system is designed to not only guarantee the highest quality but also to ensure your skills are rightfully compensated. As you successfully complete jobs, your rank ascends, resulting in increased pay for those same tasks. Achieve a 5-star rank to enjoy an additional 10% bonus on every order you complete. And the best part? As long as you maintain quality work, those rates are securely locked in, giving you the stability and financial rewards you deserve. Want even more? How about combining your extra 10% with the extra 20% you gain from taking on stylesheet adherence jobs. That’s 30% extra income for the same exact job.

                                        </p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </section>}
                </div>
            </div>
        </section>

    )
}
export default SpecializedEarnMore
