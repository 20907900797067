import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

const LeftSide = () => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  return (
    <>
      <div className="col-lg-2">
        <div className="translator-left-custom-tabs">
          <ul>
            <li
              className={
                location.pathname ===
                  "/proofreader-manager-settings/edit-profile" && "active"
              }
            >
              <NavLink to="/proofreader-manager-settings/edit-profile">
                Edit profile
              </NavLink>
            </li>
            {!(user?.facebookId || user?.googleId) && (
              <li
                className={
                  location.pathname ===
                    "/proofreader-manager-settings/change-password" && "active"
                }
              >
                <NavLink to="/proofreader-manager-settings/change-password">
                  Change Password
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LeftSide;
