import React from 'react'

export const ProcessFaq = ({ slectedUserRole, setSelectedtUserRole }) => {
  return (
    <section className="steps-sec sssp-faqisec-sec padding-bottom" id="faqTarget">
      <div className="container">
        <div className="section-heading text-center">
          <h3>Frequently asked questions</h3>
        </div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" onClick={() => setSelectedtUserRole(1)}>
            <a className={`nav-link  ${slectedUserRole === 1 && "active"} `} data-bs-toggle="tab" href="#home">
              Translator
            </a>
          </li>
          <li className="nav-item" onClick={() => setSelectedtUserRole(2)}>
            <a className={`nav-link  ${slectedUserRole === 2 && "active"} `} data-bs-toggle="tab" href="#menu1">
              Proofreader
            </a>
          </li>
        </ul>
        {/* Tab panes */}
        <div className="tab-content">
          <div id="home" className="container tab-pane active">
            <div className="accordion faq-wrapper" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    How often do I get paid, and what is the payment method?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      As a translator or proofreader, you will receive payments once a month. We offer multiple payment methods, including Western Union or similar venues, to ensure you receive your earnings conveniently and efficiently.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What factors determine my rank as a translator or proofreader?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Your rank is influenced by a multitude of factors, primarily based on customer reviews on one hand and performance on the other. Performance factors include, but are not limited to, the number of successfully completed jobs, adherence to deadlines,avoiding job cancellations, receiving correction requests from clients, and the acceptance or rejection of your work by clients. It's essential to maintain a high level of professionalism and consistently deliver quality work to maintain and improve your rank.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    What happens if I accept a job but then realize I cannot complete it?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      If you accept a job but find that you cannot complete it, you have a one-hour window to cancel the job after it has been awarded to you. However, please exercise caution when using this option, as frequent cancellations can negatively impact your ranking. It's important to thoroughly review the job description and ensure it aligns with your capabilities before accepting it.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    What happens if my rank is very low?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      If your rank falls to a very low level, you may lose access to the job board. Maintaining a good rank is crucial to continue accessing job opportunities on our platform, so it's important to consistently deliver quality work and address any performance issues to ensure continued access to job assignments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    What should I do if my rank has been severely damaged due to a false claim made by a client?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      If you believe your rank has been unfairly affected by a false claim from a client, please promptly contact the project manager to address the situation. They will work with you to investigate the issue and take appropriate steps to rectify any inaccuracies in your performance record. Your satisfaction and reputation are important to us, and we are here to help resolve any disputes or concerns you may have.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab1 */}
          <div id="menu1" className="container tab-pane fade">
            <div className="accordion faq-wrapper" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Quisque sapien augue, ornare id leo a, tristique elementum
                      justo. Praesent non nulla sagittis, sollicitudin justo id,
                      varius erat. Nunc sed pharetra nisl. Cras et suscipit felis,
                      in lacinia sapien. Integer venenatis sagittis massa, eu
                      eleifend nibh venenatis in. Pellentesque a aliquet urna.
                      Curabitur tortor metus, ultrices sed mi at, sagittis imperdiet
                      turpis. Suspendisse nec luctus nunc. Fusce in arcu quis lacus
                      mollis ultrices.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                      laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                      irure dolor in reprehenderit in voluptate velit esse cillum
                      dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Praesent nec ipsum scelerisque dui condimentum pellentesque eu
                      at lectus. Vivamus purus purus, bibendum in vestibulum ac,
                      pharetra sit amet sapien. Nunc luctus, orci vel luctus cursus,
                      nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi.
                      In auctor mi ac ante tincidunt tincidunt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tab2 */}
        </div>
      </div>
    </section>

  )
}
export default ProcessFaq
