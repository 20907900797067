import React, { useEffect, useState } from "react";
import PastProjectsProf from "./PastProjectsProf";
import OngoingProjectsProf from "./OngoingProjectsProf";
import { useDispatch } from "react-redux";
import { setJobs } from "../../../redux/reducers/jobSlice";

const MyjobListingProf = () => {
  const dispatch = useDispatch();
  const [selectedProjectType, setSelectedProjectType] = useState(1);
  const changeProjectType = (value) => {
    setSelectedProjectType(value);
    dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
  };
  useEffect(() => {
    return () => {
      dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
    };
  }, []);
  return (
    <div className="user-dashbord">
      <div className="section-head-two common-btm-mrgn common-top-mrgn">
        <div className="container">
          <div className="content">
            <h3>My Jobs</h3>
          </div>
        </div>
      </div>
      <div className="ongong-and-post-project-sec common-btm-padd">
        <div className="container">
          <div className="card">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className={`nav-link ${
                    selectedProjectType === 1 && "active"
                  }`}
                  onClick={() => changeProjectType(1)}
                >
                  Ongoing projects
                </button>
                <button
                  className={`nav-link ${
                    selectedProjectType === 2 && "active"
                  }`}
                  onClick={() => changeProjectType(2)}
                >
                  Past Projects
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              {selectedProjectType === 1 && <OngoingProjectsProf />}
              {selectedProjectType === 2 && <PastProjectsProf />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyjobListingProf;
