import React from "react";
import { Navigate, Outlet } from "react-router-dom";
function Protected({ route, user }) {
  console.log(user)
  let isAuthinticated = user;
  let isAuthorized = route.role.includes(user?.role);
  const reDirect = localStorage.getItem("reDirect_url");
  if (!isAuthinticated || !isAuthorized) {
    return <Navigate to="/login" replace />;
  } else if (user && route?.isEmailVerficationRequired && !user?.isVerified) {
    return <Navigate to="/verify-email" replace />;
  } else if (reDirect && route.path !== "/verify-email") {
    localStorage.removeItem("reDirect_url");
    return <Navigate to={reDirect} replace />;
  } else if (user.role == "company" && route?.isCompanyProfileCompleted && !user?.companyId) {
    return <Navigate to='/company' replace />;
  }

  // else if (reDirect && route.path !== '/add-aualification') {
  //     localStorage.removeItem('reDirect_url')
  //     return <Navigate to={reDirect} replace />
  // }
  else {
    return <Outlet />;
  }
}
export default Protected;
