import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DragDropUploadButton from "../../../components/common/DragDropUploadButton";
import {
  latestDegreeTranslator,
  specilizationsTranslator,
  transExpTranslator,
  nativeLangTranslator,
  languageToTranslateFromTranslator,
  pagesYouCanTranslatePerDayTranslator,
  activeHoursTranslator,
} from "../../../constants/config";
import { setAddQualificationsTrans } from "../../../redux/reducers/qualificationSlice";
import {
  addQualiTranslatorApi,
  getTranslatorQualification,
  uploadCertificate,
  uploadDegreeCertificate,
  uploadResume,
  uploadSampleWork,
} from "../../../services/api/profileApi";
import { addQualiTranslatorValidator } from "../../../services/formValidator/translatorValidator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { removeFileFromS3 } from "../../../services/api/jobPostApis";
import { startLoading, stopLoading } from "../../../redux/reducers/loadingSlice";
import { getAllLanguages } from "../../../services/api/languagesApis";

const AddQualificationTrans = () => {
  const { transQualificationDetails } = useSelector(
    (state) => state.qualification
  );
  const dispatch = useDispatch();
  const [uploadCertificatedata, setUploadCertificateData] = useState([]);
  const [uploadResumeData, setUploadResumeData] = useState([]);
  const [uploadSampleWorkData, setUploadSampleWorkData] = useState([]);
  const [uploadDegreeCertificateData, setUploadDegreeCertificateData] = useState([]);
  const [langArray, setLangArray] = useState([])
  const addQualiFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: transQualificationDetails?.email ?? "",
      certificatePhoto:
        !!transQualificationDetails?.certificatePhoto ? transQualificationDetails?.certificatePhoto : uploadCertificatedata,
      resume: !!transQualificationDetails?.resume ? transQualificationDetails?.resume : uploadResumeData,
      sampleWork: !!transQualificationDetails?.sampleWork ? transQualificationDetails?.sampleWork : uploadSampleWorkData,
      degreeCertificate: !!transQualificationDetails?.degreeCertificate ? transQualificationDetails?.degreeCertificate : uploadDegreeCertificateData , 
      latestDegree: transQualificationDetails?.latestDegree ?? "",
      other: transQualificationDetails?.other ?? "",
      specialization: transQualificationDetails?.specialization ?? "",
      additional: transQualificationDetails?.additional ?? null,
      translatingExperience:
        transQualificationDetails?.translatingExperience ?? "",
      nativeLanguage: transQualificationDetails?.nativeLanguage ?? "",
      languageToTranslateFrom:
        transQualificationDetails?.languageToTranslateFrom ?? "",
      portfolioLink: transQualificationDetails?.portfolioLink ?? "",
      // pagesYouCanTranslatePerDay:
      //   transQualificationDetails?.pagesYouCanTranslatePerDay ?? "",
      activeHours: transQualificationDetails?.activeHours ?? "",
    },
    validationSchema: addQualiTranslatorValidator.validationSchema,
    onSubmit: async (values) => {
      let { serverResponse, result } = (await transQualificationDetails?._id
        ? addQualiTranslatorApi(values, transQualificationDetails?._id)
        : addQualiTranslatorApi(values));
      if (serverResponse.code === 201) {
        toast.success("Qualification added successfully");
        dispatch(setAddQualificationsTrans({ qualifications: result.data }));
      } else {
        toast.error("Qualification not added");
      }
    },
  });

  const uploadCertificateFile = async (data) => {
    let resData = await uploadCertificate(data);
    if (resData?.result) {
      setUploadCertificateData(uploadCertificatedata?.concat(resData?.result.data));
      addQualiFormik.setFieldValue("certificatePhoto", addQualiFormik?.values.certificatePhoto.concat(resData?.result.data))
    }
  };
  const uploadResumeFile = async (data) => {
    let resData = await uploadResume(data);
    if (resData?.result) {
      setUploadResumeData(uploadResumeData?.concat(resData?.result.data));
      addQualiFormik.setFieldValue("resume", addQualiFormik?.values.resume.concat(resData?.result.data))
    }
  };
  const uploadSampleWorkFile = async (data) => {
    let resData = await uploadSampleWork(data);
    if (resData?.result) {
      setUploadSampleWorkData(uploadSampleWorkData?.concat(resData?.result.data));
      addQualiFormik.setFieldValue("sampleWork", addQualiFormik?.values.sampleWork.concat(resData?.result.data))
    }
  };
  const uploadDegreeCertificateFile = async (data) => {
    let resData = await uploadDegreeCertificate(data);
    if (resData?.result) {
      setUploadDegreeCertificateData(uploadDegreeCertificateData?.concat(resData?.result.data));
      addQualiFormik.setFieldValue("degreeCertificate", addQualiFormik?.values.degreeCertificate.concat(resData?.result.data))
    }
  };
  useEffect(() => {
    (async () => {
      let resData = await getTranslatorQualification();
      if (resData?.result?.data) {
        dispatch(setAddQualificationsTrans({ qualifications: resData?.result.data }));
      }
    })();
  }, []);
  const removeItem = async (item, type) => {
    let data = {
      key: item?.key
    }
    dispatch(startLoading())
    let { serverResponse } = await removeFileFromS3(data)
    if (serverResponse?.code === 200) {
      addQualiFormik.setFieldValue(`${type}`, addQualiFormik?.values[type].filter((val) => val?.key !== item?.key))
    }
    dispatch(stopLoading())
  }
  useEffect(() => {
    (async () => {
      let languageJson = await getAllLanguages()
      setLangArray(languageJson.data)
    })()
  }, [])

  return (
    <>
      <div className="col-lg-10">
        <div className="translator-edit-profile-right qualification-wrap">
          <div className="content common-btm-mrgn">
            <h4>Add Qualification</h4>
          </div>
          <div className="signin-form-wrap">
            <form onSubmit={addQualiFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadCertificateFile}
                    />
                    {/* <p className="form-error-message">
                      {
                        addQualiFormik.errors.certificatePhoto}
                    </p> */}
                  </div>
                  {addQualiFormik.values.certificatePhoto &&
                    addQualiFormik.values.certificatePhoto?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.certificatePhoto.map(
                          (item, index) => {
                            return (
                              <div className="show-file" key={index}>
                                <div onClick={() => removeItem(item, 'certificatePhoto')}><FontAwesomeIcon icon={faTrashAlt} /></div>
                                <p className="extenstionText">{item?.name}</p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>1. Email</label>
                    <input
                      type="email"
                      placeholder="example@gmail.com"
                      className="form-control"
                      name="email"
                      id="email"
                      value={addQualiFormik.values.email}
                      onChange={addQualiFormik.handleChange}
                    />
                  </div>
                  <p className="form-error-message">
                    {addQualiFormik.touched.email &&
                      addQualiFormik.errors.email}
                  </p>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>2. Latest degree</label>
                    <div className="row">
                      {latestDegreeTranslator.map((item, id) => (
                        <div key={id} className="col-lg-4">
                          <div className="rdio-btn-for-db-frm">
                            <input
                              type="radio"
                              id={`latestDegree${id}`}
                              name="latestDegree"
                              value={item?.value}
                              onClick={() =>
                                addQualiFormik.setFieldValue("other", "")
                              }
                              checked={
                                addQualiFormik.values.latestDegree ===
                                item?.value
                              }
                              onChange={addQualiFormik.handleChange}
                            />
                            <label htmlFor={`latestDegree${id}`}>
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p className="form-error-message">
                        {addQualiFormik.touched.latestDegree &&
                          addQualiFormik.touched.other !== "" &&
                          addQualiFormik.errors.latestDegree}
                      </p>
                      <div className="col-lg-12">
                        <label className="mt-3">
                          Other (Please specify in the box below)
                        </label>
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="other"
                          id="other"
                          onChange={addQualiFormik.handleChange}
                          onClick={() =>
                            addQualiFormik.setFieldValue("latestDegree", "")
                          }
                          value={addQualiFormik.values.other}
                        />
                        <p className="form-error-message">
                          {addQualiFormik.touched.other &&
                            addQualiFormik.errors.other}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Upload certificate/diploma</label>
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadDegreeCertificateFile}
                    />
                  </div>
                  {addQualiFormik.values.degreeCertificate &&
                    addQualiFormik.values.degreeCertificate?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.degreeCertificate.map((item, index) => {
                          return (
                            <div className="show-file" key={index}>
                              <div onClick={() => removeItem(item, 'degreeCertificate')}><FontAwesomeIcon icon={faTrashAlt} /></div>
                              <p className="extenstionText">{item?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      3. Which specialization/field of study best describes
                      yours?
                    </label>
                    <div className="row">
                      {specilizationsTranslator.map((item, id) => (
                        <div key={id} className="col-lg-4 mb-3">
                          <div className="rdio-btn-for-db-frm">
                            <input
                              type="radio"
                              id={`specialization${id}`}
                              name="specialization"
                              value={item?.value}
                              onClick={() =>
                                addQualiFormik.setFieldValue("additional", "")
                              }
                              checked={
                                addQualiFormik.values.specialization ===
                                item?.value
                              }
                              onChange={addQualiFormik.handleChange}
                            />
                            <label htmlFor={`specialization${id}`}>
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p className="form-error-message">
                        {addQualiFormik.touched.specialization &&
                          addQualiFormik.errors.specialization}
                      </p>
                      <div className="col-lg-12">
                        <label>
                          None of the above. Please specify in the box below.
                        </label>
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          name="additional"
                          id="additional"
                          onClick={() =>
                            addQualiFormik.setFieldValue("specialization", "")
                          }
                          onChange={addQualiFormik.handleChange}
                          value={addQualiFormik.values.additional}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>4. How many years of translating experience?</label>
                    <div className="row">
                      {transExpTranslator.map((item, id) => (
                        <div key={id} className="col-lg-4">
                          <div className="rdio-btn-for-db-frm">
                            <input
                              type="radio"
                              id={`translatingExperience${id}`}
                              name="translatingExperience"
                              value={item?.value}
                              checked={
                                addQualiFormik.values.translatingExperience ===
                                item?.value
                              }
                              onChange={addQualiFormik.handleChange}
                            />
                            <label htmlFor={`translatingExperience${id}`}>
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p className="form-error-message">
                        {addQualiFormik.touched.translatingExperience &&
                          addQualiFormik.errors.translatingExperience}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>5. What is your native language?</label>
                    <select
                      name="nativeLanguage"
                      id="nativeLanguage"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                      value={addQualiFormik?.nativeLanguage}
                      
                    >
                      {langArray && langArray.length > 0 && langArray.map((item, id) => (
                        <option
                          key={id}
                          value={item?.languageName}
                          selected={
                            transQualificationDetails?.nativeLanguage ===
                            item?.languageName
                          }
                        >
                          {item?.languageName}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.nativeLanguage &&
                        addQualiFormik.errors.nativeLanguage}
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>6. Which language do you translate to/from?</label>
                    <select
                      name="languageToTranslateFrom"
                      id="languageToTranslateFrom"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                      value={addQualiFormik?.languageToTranslateFrom}
                    >
                      {langArray && langArray.length > 0 && langArray.map((item, id) => (
                        <option
                          key={id}
                          value={item?.languageName}
                          selected={
                            transQualificationDetails?.languageToTranslateFrom ===
                            item?.languageName
                          }
                        >
                          {item?.languageName}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.languageToTranslateFrom &&
                        addQualiFormik.errors.languageToTranslateFrom}
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>7. Upload resume / CV</label>
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadResumeFile}
                    />
                  </div>
                  {addQualiFormik.values.resume &&
                    addQualiFormik.values.resume?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.resume.map((item, index) => {
                          return (
                            <div className="show-file" key={index}>
                              <div onClick={() => removeItem(item, 'resume')}><FontAwesomeIcon icon={faTrashAlt} /></div>
                              <p className="extenstionText">{item?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}

                  <p className="form-error-message">
                    {addQualiFormik.touched.resume &&
                      addQualiFormik.errors.resume}
                  </p>
                </div>
                <div className="col-lg-12">
                  <label>8. Sample of your work</label>
                  <div className="form-group file-upload">
                    <label htmlFor="myfile">
                      Drag to upload photo or you can&nbsp;<span>browse</span>
                    </label>
                    <DragDropUploadButton
                      multiple={true}
                      onChange={uploadSampleWorkFile}
                    />
                  </div>
                  {addQualiFormik.values.sampleWork &&
                    addQualiFormik.values.sampleWork?.length > 0 && (
                      <div className="uploaded-file-show show-file-wrap">
                        {addQualiFormik.values.sampleWork.map((item, index) => {
                          return (
                            <div className="show-file" key={index}>
                              <div onClick={() => removeItem(item, 'sampleWork')}><FontAwesomeIcon icon={faTrashAlt} /></div>
                              <p className="extenstionText">{item?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}

                  {/* <p className="form-error-message">
                    {addQualiFormik.touched.sampleWork &&
                      addQualiFormik.errors.sampleWork}
                  </p> */}
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>9. Link to portfolio</label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control"
                      name="portfolioLink"
                      id="portfolioLink"
                      value={addQualiFormik.values.portfolioLink}
                      onChange={addQualiFormik.handleChange}
                    />
                    <p className="form-error-message">
                      {addQualiFormik.touched.portfolioLink &&
                        addQualiFormik.errors.portfolioLink}
                    </p>
                  </div>
                </div>

                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      10. How many pages (page = 250 words) can you translate
                      per day?
                    </label>
                    <div className="row">
                      {pagesYouCanTranslatePerDayTranslator.map((item, id) => (
                        <div key={id} className="col-lg-4 mb-3">
                          <div className="rdio-btn-for-db-frm">
                            <input
                              type="radio"
                              id={`pagesYouCanTranslatePerDay${id}`}
                              name="pagesYouCanTranslatePerDay"
                              value={item?.value}
                              checked={
                                addQualiFormik.values
                                  .pagesYouCanTranslatePerDay === item?.value
                              }
                              onChange={addQualiFormik.handleChange}
                            />

                            <label htmlFor={`pagesYouCanTranslatePerDay${id}`}>
                              {item?.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p className="form-error-message">
                        {addQualiFormik.touched.pagesYouCanTranslatePerDay &&
                          addQualiFormik.errors.pagesYouCanTranslatePerDay}
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label>10. Choose Timezone</label>
                    <select
                      name="timezone"
                      id="timezone"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                    >
                      {chooseTimezoneTranslator.map((item, id) => (
                        <option key={id} value={item?.value}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.timezone &&
                        addQualiFormik.errors.timezone}
                    </p>
                  </div>
                </div> */}
                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label>11. Choose Country</label>
                    <select
                      name="country"
                      id="country"
                      onChange={addQualiFormik.handleChange}
                      className="form-select form-control"
                    >
                      {chooseCountryTranslator.map((item, id) => (
                        <option key={id} value={item?.value}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.country &&
                        addQualiFormik.errors.country}
                    </p>
                  </div>
                </div> */}

                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <label>11. Choose your active hours</label>
                    <select
                      name="activeHours"
                      id="activeHours"
                      className="form-select form-control"
                      onChange={addQualiFormik.handleChange}
                    >
                      {activeHoursTranslator.map((item, id) => (
                        <option
                          key={id}
                          value={item?.value}
                          selected={
                            transQualificationDetails?.activeHours ===
                            item?.value
                          }
                        >
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <p className="form-error-message">
                      {addQualiFormik.touched.activeHours &&
                        addQualiFormik.errors.activeHours}
                    </p>
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="form-group mb-0">
                    <button type="submit" className="btn w-100">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQualificationTrans;
