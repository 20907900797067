import React, { useEffect } from 'react'
import LoginComp from './LoginComp'
import { useFormik } from 'formik'
import { loginFromValidator } from '../../../services/formValidator/authValidator'
import { signIn } from '../../../redux/reducers/authSlice'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { navigateTo } from '../../../services/appNavigator'


const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLogin, user } = useSelector((state) => state.auth);
    const loginFromik = useFormik({
        initialValues: loginFromValidator.initialValues,
        validationSchema: loginFromValidator.validationSchema,
        onSubmit: (values) => {
            dispatch(signIn(values))

        }
    })
    useEffect(() => {
        navigateTo(user?.role, navigate, user)
    }, [user])

    return (
        <section className="user-dashbord sec_pad">
            <div className="container">
                <div className="ongong-and-post-project-sec translator-signup-wrap">
                    <div className="card">
                        <div className="tab-content" id="nav-tabContent">
                            <LoginComp
                                loginFromik={loginFromik}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login