import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useState, useRef } from 'react';
import Slider from 'react-rangeslider'
import { Modal, ModalBody, Button, ModalTitle } from 'react-bootstrap'

// To include the default styles
import 'react-rangeslider/lib/index.css'

const Cropper = ({ logo, logoFile, showCropModal, setShowCropModal, addCompanyFormik, setLogo }) => {

    const [filePreview, setFilePreview] = useState(null);
    const [crop, setCrop] = useState();
    const [scale, setScale] = useState(.5);
    const cropRef = useRef(null);


    const closeModal = () => {
        setShowCropModal(false);
    };
    const handleChangeStart = () => {
        console.log('Change event started')
    };

    const handleChange = (value) => {
        setScale(value)
    };

    const handleChangeComplete = () => {
        console.log((scale * 100))
        console.log('Change event completed')
    };

    useEffect(() => {
        if (logoFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                // console.log(base64String)
                setFilePreview(base64String); // Set the base64 string to state
            };
            reader.readAsDataURL(logoFile); // Convert file to base64
        }
    }, [logoFile]);

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget
        console.log(width, height)
        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                16 / 9,
                width,
                height
            ),
            width,
            height
        )

        setCrop(crop)
    }

    const getCroppedImg = () => {
        // 
        const image = cropRef.current;
        const cropWidth = image.naturalWidth * (scale)
        const cropHeight = image.naturalHeight * (crop.height / 100)
        const startX = image.naturalWidth / image.width;
        const startY = image.naturalHeight * (crop.y / 100)
        console.log(crop.x)
        const canvas = document.createElement('canvas')
        
        canvas.width = cropWidth
        canvas.height = cropHeight
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            startX,
            startY,
            cropWidth,
            cropHeight,
            0,
            0,
            cropWidth,
            cropHeight
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                setFilePreview(reader.result);
                dataURLtoFile(reader.result, logoFile.name);
                closeModal();

            }
        }, logoFile.type, 1)
    }

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, { type: mime });
        setLogo(dataurl);
        addCompanyFormik.setFieldValue('logo', croppedImage);
    }
    return (

        showCropModal ? (
            <>
                <Modal show={true} centered className="modal order-d-popup fade">
                    <ModalBody>
                        <ModalTitle><div className="font-semibold">Crop Image</div></ModalTitle>
                      
                        <div className="relative flex-auto">
                            <ReactCrop
                                crop={crop}
                                locked={true}
                                onChange={(c, percentCrop) => setCrop(percentCrop)}
                                style={{ width: `100%` }}
                            >
                                <img
                                    src={filePreview}
                                    ref={cropRef}
                                    onLoad={onImageLoad}
                                    alt="Your uploaded image"
                                    style={{ transform: `scale(${scale})`, margin: `0 auto` }}
                                />
                            </ReactCrop>
                            <Slider
                                min={0}
                                max={1}
                                step={0.1}
                                tooltip={false}
                                value={scale}
                                onChangeStart={handleChangeStart}
                                onChange={handleChange}
                                onChangeComplete={handleChangeComplete}
                            />

                            <div><Button className="full-right" onClick={getCroppedImg}>Save</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        ) : null
    )
}

export default Cropper;