import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  assignUser,
  getAllJobs,
  getSingleJob,
  getUserList,
  unAssignUser,
} from "../../../services/api/jobApis";
import { setAssignUserList, setJobs, setSelectedJobDetails } from "../../../redux/reducers/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from 'lodash';
import { useLocation } from "react-router-dom";

const JobAssignModal = ({ setShowAssignModal, showAssignModal, item }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const [userGetPage, setUserGetPage] = useState(0);
  const [userSearchinput, setUserSearchinput] = useState("");
  const { currentPage, assignUserList, selectedJobDetails } = useSelector((state) => state.job);

  useEffect(() => {
    (async () => {
      let userList = await getUserList();
      dispatch(setAssignUserList({ users: userList?.data }));
      setUserGetPage(userList?.pageNumber);
    })();
    return () => {
      dispatch(setAssignUserList({ users: null }));
      setUserGetPage(0);
    };
  }, []);
  const handleSearch = debounce(async (input) => {
    setUserGetPage(0);
    setUserSearchinput(input.target.value);
    let userList = await getUserList(input.target.value);
    dispatch(setAssignUserList({ users: null }));
    dispatch(setAssignUserList({ users: userList?.data }));
  }, 500);
  const assignJobToUser = async (userId) => {
    if (!item?.isAssignedJob) {
      let bodyData = {
        jobId: item?._id,
        userId: userId,
      };
      let assignData = await assignUser(bodyData);
      toast.success(assignData?.serverResponse?.message);
      dispatch(
        setJobs({
          jobs: null,
          pageNumber: currentPage,
          totalJobCount: currentPage ?? 0,
        })
      );
      dispatch(startLoading());
      let data = await getAllJobs("all", currentPage);
      if (data?.data?.length > 0) {
        dispatch(
          setJobs({
            jobs: data?.data,
            pageNumber: data?.pageNumber,
            totalJobCount: data?.dataCount,
          })
        );
      }
      if (location?.state?.id) {
        let { data } = await getSingleJob(location?.state?.id)
        if (data) {
          dispatch(setSelectedJobDetails({ selectedJob: data }))
        }
      }
      dispatch(stopLoading());
      setShowAssignModal(false);
    } else {
      let unAssignData = await unAssignUser(item?._id);
      toast.success(unAssignData?.serverResponse?.message);
      dispatch(
        setJobs({
          jobs: null,
          pageNumber: currentPage,
          totalJobCount: currentPage ?? 0,
        })
      );
      dispatch(startLoading());
      let data = await getAllJobs("all", currentPage);
      if (data?.data?.length > 0) {
        dispatch(
          setJobs({
            jobs: data?.data,
            pageNumber: data?.pageNumber,
            totalJobCount: data?.dataCount,
          })
        );
      }
      dispatch(stopLoading());
      setShowAssignModal(false);
    }
  };
  const loadMoreData = async (page) => {
    let userList = await getUserList(userSearchinput, page);
    dispatch(setAssignUserList({ users: userList?.data }));
    setUserGetPage(userList?.pageNumber);
  };
  return (
    <Modal
      className="modal order-d-popup fade"
      show={showAssignModal}
      onHide={() => setShowAssignModal(false)}
      centered
    >
      <ModalHeader closeButton>
        <div className="assign-modal">{item?.specificService} Users</div>
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <input
            type="text"
            placeholder={`Search ${item?.specificService} Users`}
            className="form-control assign-user-search"
            id="name"
            name="name"
            onChange={handleSearch}
          />
        </div>
        <div className="list-group assign-job-modal-body">
          {assignUserList && (
            <InfiniteScroll
              dataLength={assignUserList.length} //This is important field to render the next data
              next={() => loadMoreData(userGetPage + 1)}
              hasMore={true}
              height={500}
            >
              {assignUserList?.map((item, index) => {
                return (
                  <p
                    key={index}
                    className="list-group-item list-group-item-action"
                    onClick={() => assignJobToUser(item?._id)}
                  >
                    {item?.name} ({item?.averageRating} Star)
                  </p>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default JobAssignModal;
