import { toast } from "react-toastify";
import http from "./http";

/**
 * this api will get all jobs data
 */
export const getAllJobs = async (values, page, sortBy, sortType, search = '') => {
  let jobsGetData;
  if (sortBy && sortType) {
    jobsGetData = await http.get(
      `/jobs/list?jobStatus=${values}&pageNumber=${page}&sortBy=${sortBy}&sortType=${sortType}&search=${search}`
    );
  } else {
    jobsGetData = await http.get(
      `/jobs/list?jobStatus=${values}&pageNumber=${page}&search=${search}`
    );
  }

  if (jobsGetData?.serverResponse?.code === 200) {
    return jobsGetData?.result;
  }
};

/**
 * Single job get api
 */
export const getSingleJob = async (jobId) => {
  let jobsGetData = await http.get(`/jobs/${jobId}`);
  if (jobsGetData?.serverResponse?.code === 200) {
    return jobsGetData?.result;
  }
};

/**
 * Job assign user list get api
 */
export const getUserList = async (input, page) => {
  let userListGet = !!input
    ? await http.get(
        `/users/project-manager/list?name=${input}&pageNumber=${page ?? 1}`
      )
    : await http.get(`/users/project-manager/list?pageNumber=${page ?? 1}`);
  if (userListGet?.serverResponse?.code === 200) {
    return userListGet?.result;
  }
};

/**
 * Job to a user
 */
export const assignUser = async (apiData) => {
  let assignToUser = await http.post(`/jobs/job-assign`, apiData);
  if (assignToUser?.serverResponse?.code === 200) {
    return assignToUser;
  }
};
export const unAssignUser = async (jobId) => {
  let unAssignToUser = await http.patch(`/jobs/job-unassign/${jobId}`);
  if (unAssignToUser?.serverResponse?.code === 200) {
    return unAssignToUser;
  }
};
/**
 * Apply for job
 */
export const applyJob = async (apiData) => {
  let applyJobData = await http.patch(`/jobs/apply`, apiData);
  if (applyJobData?.serverResponse?.code === 201) {
    toast.success(applyJobData?.serverResponse?.message);
    return applyJobData;
  }
};
/**
 * Cancel a applied job
 */
export const cancleJob = async (jobId) => {
  let applyJobData = await http.patch(`/jobs/cancel/${jobId}`);
  if (applyJobData?.serverResponse?.code === 201) {
    return applyJobData;
  }
};
/**
 * Update job milestone status
 */
export const updateJobMilestoneStatus = async (apiData) => {
  let updateMilestone = await http.patch(`/jobs/job-milestone-update`, apiData);
  if (updateMilestone?.serverResponse?.code === 200) {
    return updateMilestone;
  }
};

/**
 * Upload updated final job
 */
export const uploadCompletedJob = async (apiData, jobId) => {
  let completeJobApiData = {
    jobId: jobId,
    jobCompletedFile: apiData.jobFile,
  };
  let completeJobJobData = await http.post(
    `/jobs/complete-job-post`,
    completeJobApiData
  );
  if (completeJobJobData?.serverResponse?.code === 200) {
    return completeJobJobData;
  }
};
/**
 * Add review for a job
 */
export const addReview = async (apiData) => {
  let addReview = await http.post(`/review-rating/create`, apiData);
  if (addReview?.serverResponse?.code === 201) {
    return addReview;
  }
};
/**
 * Update review for a job
 */
export const updateReview = async (apiData) => {
  let updateReview = await http.patch(`/review-rating/update`, apiData);
  if (updateReview?.serverResponse?.code === 200) {
    return updateReview;
  }
};

/**
 * Get review for a job
 */
export const getReview = async (jobId) => {
  let getReview = await http.get(`/review-rating/list/${jobId}`);
  if (getReview?.serverResponse?.code === 200) {
    return getReview;
  }
};

/**
 * Get Promo code from admin
 */

export const getPromoCode = async () => {
  let getPromo = await http.get(`/users/promo-codes/list`);
  if (getPromo?.serverResponse?.code === 200) {
    return getPromo;
  }
};

//Jo accept post api
export const acceptRejectRecheckJobPost = async (data) => {
  let val = {
    jobId: data?.jobId,
  };
  if (data?.status === "recheck") {
    val.recheckComments = data?.recheckComments;
  }
  let acceptRejectRecheckJobDetails = await http.post(
    `/jobs/${data?.status}`,
    val
  );
  if (acceptRejectRecheckJobDetails?.serverResponse?.code === 200) {
    toast.success(acceptRejectRecheckJobDetails?.serverResponse?.message);
    return acceptRejectRecheckJobDetails;
  }
};
export const deliverApi = async (id) => {
  let deliverApiData = await http.post(`/jobs/deliver/${id}`, {});
  if (deliverApiData?.serverResponse?.code === 200) {
    toast.success(deliverApiData?.serverResponse?.message);
    return deliverApiData;
  }
};
