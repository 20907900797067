import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { downloadCertificateFromValidator } from '../../../services/formValidator/downloadCertificateValidator';
import { checkCertificate, downloadCertificate } from '../../../services/api/downloadCertificateApi'
import { save } from 'save-file'

const Download = () => {
    const navigate = useNavigate();
    let currentUrl = window.location.href;
    const [searchParams] = useSearchParams();
    const [certificateLink, setCertificateLink] = useState(null)
    const [requiredPinCode, setRequiredPinCode] = useState(false)

    const downloadCertificateFormik = useFormik({
        initialValues: {
            pincode: "",
            certificateLink: certificateLink,
        },
        validationSchema: downloadCertificateFromValidator.validationSchema,
        onSubmit: async (values) => {
            values.certificateLink = downloadCertificateFormik.initialValues.certificateLink;
            let serverResponseDownload = await downloadCertificate(values)
            toast.success(serverResponseDownload.message);

            const body = serverResponseDownload?.result.data.body;
            const fileName = serverResponseDownload?.result.data.certificateFileName;
            await save(body, fileName)
        }
    })

    const checkCertificateAccess = async (data) => {
        let serverResponse = await checkCertificate(data)
        if (serverResponse == 'redirect') {
            toast.error('Certificate is not exist');
            navigate('/');
        }else if (!serverResponse) {
            setRequiredPinCode(true);
        } else {

            if (serverResponse?.result.data) {
                return serverResponse?.result.data.downloadLink;
            }
        }

    }

    useEffect(() => {

        let findCertificateLink = searchParams.get('link')
        window.history.pushState({}, '', '/download-certificate?link=' + certificateLink);
        const data = { "certificateLink": findCertificateLink };

        if (findCertificateLink != null) {

            (async () => {
                downloadCertificateFormik.values.certificateLink = findCertificateLink;
                setCertificateLink(findCertificateLink)
                try {
                    let downloadLink = await checkCertificateAccess(data)
                    if (downloadLink) {
                        window.location.href = downloadLink;
                        return null;
                    }
                } catch (err) {
                    toast.error('Error occured when fetching file');
                }
            })();
        }

    }, [currentUrl]);

    return (
        <>
            <section className="user-dashbord sec_pad">
                <div className="container">
                    <div className="signin-form-wrap">
                        {requiredPinCode ?
                            <>
                                <h4>Please enter the certificate PIN code to download</h4>
                                <form onSubmit={downloadCertificateFormik.handleSubmit}>
                                    <input type="hidden" name="certificateLink" value={downloadCertificateFormik?.values.certificateLink} />
                                    <div className="form-group">
                                        <label>PIN code</label>
                                        <input type="text" name="pincode" id="pincode" className="form-control"
                                            value={downloadCertificateFormik?.values.pincode} onChange={downloadCertificateFormik.handleChange} />
                                        <p className='form-error-message'>{downloadCertificateFormik.touched.pincode &&
                                            downloadCertificateFormik.errors.pincode}</p>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn w-100">Download</button>
                                    </div>
                                </form>
                            </>
                            : <h4>Downloading...</h4>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Download;