import React from 'react'
import LeftSide from './LeftSide'
import { Route, Routes } from 'react-router'
import TransManagerEditProfile from './TransManagerEditProfile'
import TransManagerChangePassword from './TransManagerChangePassword'

const TransManagerSettings = () => {
    return (
        <>
            <section className="user-dashbord sec_pad">
                <div className="container">
                    <div className="row">
                        <LeftSide />
                        <Routes>
                            <Route
                                path="/edit-profile"
                                element={<TransManagerEditProfile />}
                            />
                            <Route
                                path="/change-password"
                                element={<TransManagerChangePassword />}
                            />
                        </Routes>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TransManagerSettings