import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
const BecomeAMember = () => {
    const { t } = useTranslation();

    return (
        <section className="Translators-sec padding-bottom">
            <div className="container">
                <div className="row gx-0">
                    <div className="col-lg-6">
                        <div className="Translators-col-wrap">
                            <div className="Translators-col-img">
                                <img src="/images/Translators.jpg" alt="" />
                            </div>
                            <div
                                className="Translators-content"
                                data-aos="fade-up"
                                data-aos-offset={100}
                                data-aos-easing="ease-in-sine"
                            >
                                <h2>{t("become-member-translator-header")}</h2>
                                <p>
                                    {t("become-member-translator-paragraph")}
                                </p>
                                <NavLink to='/process?membertype=1' className="btn">
                                    {t("become-a-translator")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="Translators-col-wrap">
                            <div className="Translators-col-img">
                                <img src="/images/Proofreading.jpg" alt="" />
                            </div>
                            <div
                                className="Translators-content"
                                data-aos="fade-up"
                                data-aos-offset={100}
                                data-aos-easing="ease-in-sine"
                            >
                                <h2>{t("become-member-proofReader-header")}</h2>
                                <p>
                                    {t("become-member-proofReader-paragraph")}
                                </p>
                                <NavLink to='/process?membertype=2' className="btn">
                                    {t("become-a-proofReader")}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BecomeAMember