import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  profQualificationDetails: {
    email: "",
    certificatePhoto: null,
    resume: "",
    sampleWork: "",
    latestDegree: "",
    other: "",
    specialization: "",
    additional: "",
    translatingExperience: "",
    nativeLanguage: "",
    languageToTranslateFrom: "",
    portfolioLink: "",
    // pagesYouCanTranslatePerDay: "",
    activeHours: "",
  },
  transQualificationDetails: {
    email: "",
    certificatePhoto: null,
    resume: "",
    sampleWork: "",
    latestDegree: "",
    other: "",
    specialization: "",
    additional: "",
    translatingExperience: "",
    nativeLanguage: "",
    languageToTranslateFrom: "",
    portfolioLink: "",
    // pagesYouCanTranslatePerDay: "",
    activeHours: "",
  },
};
export const qualificationSlice = createSlice({
  name: "qualification",
  initialState,
  reducers: {
    setAddQualificationsProf: (state, actions) => {
      const { qualifications } = actions.payload;
      state.profQualificationDetails = qualifications;
    },
    setAddQualificationsTrans: (state, actions) => {
      const { qualifications } = actions.payload;
      state.transQualificationDetails = qualifications;
    },
  },
});
export const { setAddQualificationsProf, setAddQualificationsTrans} = qualificationSlice.actions;
export default qualificationSlice.reducer;
