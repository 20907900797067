export function timeAgoFunc(timestamp) {
  const unixTimestamp = Date.parse(timestamp);
  const timeAgo = Date.now() - unixTimestamp;

  // Convert time to seconds, minutes, hours, or days
  const seconds = Math.round(timeAgo / 1000);
  const minutes = Math.round(timeAgo / (1000 * 60));
  const hours = Math.round(timeAgo / (1000 * 60 * 60));
  const days = Math.round(timeAgo / (1000 * 60 * 60 * 24));

  // Return appropriate string based on the time elapsed
  if (seconds < 60) {
    return seconds + " seconds ago";
  } else if (minutes < 60) {
    return minutes + " minutes ago";
  } else if (hours < 24) {
    return hours + " hours ago";
  } else {
    return days + " days ago";
  }
}