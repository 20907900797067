import socketClient from "socket.io-client";

export const REDUX_PERSIST_KEY = "lingvara";

export const NODE_ENV = process.env.NODE_ENV;
export const API_URL =
  NODE_ENV === "production"
    ? "https://api.lingvara.com/api"
    : "http://localhost:4000/api";
export const SOCKET_URL =
  NODE_ENV === "production"
    ? "https://api.lingvara.com"
    : "http://localhost:4000";

export const FACEBOOK_APP_ID =
  NODE_ENV === "production" ? "24106242538974401" : "1398414080720429"; //this is nimar fb ID 1398414080720429

export const GOOGLE_APP_ID =
  NODE_ENV === "production"
    ? "507324321920-4rv1u651mjkkd9ijaeil3n7rodaaevoc.apps.googleusercontent.com"
    : "674081728017-1ji0bm5uf485jtv1r05sa22tbrna574p.apps.googleusercontent.com";

export const BITLY_TOKEN =
  NODE_ENV === "production"
    ? "7e88de0d161f8e8bee7a566dcb0d2e5e71c2c9b3"
    : "7e88de0d161f8e8bee7a566dcb0d2e5e71c2c9b3";
// job create doc type statioc array
export const docTypesJsonData = [
  {
    name: "Academic paper",
    value: "Academic paper",
    image: "/images/img15.png",
  },
  {
    name: "Legal document",
    value: "Legal document",
    image: "/images/img16.png",
  },
  {
    name: "Short communication",
    value: "Short communication",
    image: "/images/img17.png",
  },
  {
    name: "Book",
    value: "Book",
    image: "/images/img19.png",
  },
  {
    name: "Report or Transcript",
    value: "Report or Transcript",
    image: "/images/img18.png",
  },
  {
    name: "Other",
    value: "Other",
    image: "/images/img14.png",
  },
];
// job create service type statioc array
export const serviceTypeStaticArray = [
  {
    name: "Translation",
    value: "translation",
    image: "/images/img20.png",
  },
  {
    name: "Proofreading",
    value: "proofreading",
    image: "/images/img21.png",
  },
];

// job create deadline statioc array

export const deadlineStaticArray = [
  {
    time: "24 hr",
    price: "0.02 USD/Word",
    value: "24 hr",
    priceValue: 0.02,
  },
  {
    time: "12 hr",
    price: "0.02 USD/Word * 1.35",
    value: "12 hr",
    priceValue: 0.02 * 1.35,
  },
  {
    time: "4 hr",
    price: "0.02 USD/Word * 2x",
    value: "4 hr",
    priceValue: 0.02 * 2,
  },
];
export const userType = [
  {
    name: "Student",
    value: "student",
    image: "/images/img11.png",
  },
  {
    name: "Professor",
    value: "professor",
    image: "/images/img12.png",
  },
  {
    name: "Business",
    value: "business",
    image: "/images/img13.png",
  },
  {
    name: "Other",
    value: "other",
    image: "/images/img14.png",
  },
];

// language pair static json
export const languageArray = [
  {
    language: "English",
    subLang: ["English (US)", "English (UK)"],
  },
  {
    language: "Arabic",
    subLang: [
      "Arabic (Standard)",
      "Arabic (Gulf)",
      "Arabic (Levant)",
      "Arabic (Egyptian)",
      "Arabic (Western North Africa)",
    ],
  },
  {
    language: "Turkish",
  },
  {
    language: "Russian",
  },
  {
    language: "German",
  },
  {
    language: "French",
  },
  {
    language: "Spanish",
    subLang: ["Spanish (Spain)", "Spanish (Latin America)", "Spanish (Mexico)"],
  },
  {
    language: "Italian",
  },
  {
    language: "Korean",
  },
  {
    language: "Japanese",
  },
  {
    language: "Chinese",
    subLang: [
      "Chinese (Simplified)",
      "Chinese (Traditional)",
      "Chinese (Hong Kong)",
    ],
  },
];
export const chooseTranslatorJson = [
  {
    name: "A. Translation (No Certificate)",
    value: "General Translator",
  },
  {
    name: "B. Certified Specialized Translator",
    value: "Subject Specialized Translator",
  },
];
export const specalisationJson = [
  {
    name: "A. Legal",
    value: "Legal",
  },
  {
    name: "B. Medical Sciences",
    value: "Medical Sciences",
  },
  {
    name: "C. Engineering & IT",
    value: "Engineering & IT",
  },
  {
    name: "D. Business & Economics",
    value: "Business & Economics",
  },
  {
    name: "F. Arts and Humanities",
    value: "Arts and Humanities",
  },
  {
    name: "G. Literature & Poetry",
    value: "Literature & Poetry",
  },
];
export const specificServiceProofreaderJson = [
  {
    name: "A. Proofreading",
    value: "Proofreading",
  },
  {
    name: "B. Proofreading + Stylesheet Adherence",
    value: "Proofreading + Stylesheet Adherence",
  },
];
export const citationStyleJson = [
  {
    name: "A. APA",
    value: "APA",
  },
  {
    name: "B. MLA",
    value: "MLA",
  },
  {
    name: "C. Chicago",
    value: "Chicago",
  },
  {
    name: "D. Harvard",
    value: "Harvard",
  },
  {
    name: "E. No particular preference",
    value: "No particular preference",
  },
  // {
  //   name: "F. “I uploaded a stylesheet“",
  //   value: "I uploaded a stylesheet",
  // },
  ,
];
export const proffReaderEngilshDialectJson = [
  {
    name: "American",
    value: "American",
    images: "/images/footer-logo.png",
  },
  {
    name: "British",
    value: "British",
    images: "/images/footer-logo.png",
  },
  {
    name: "Australian",
    value: "Australian",
    images: "/images/footer-logo.png",
  },
];
export const proffReaderWritingStyleJson = [
  {
    name: "A. “In this paper, we will prove the theory”",
    value: "In this paper, we will prove the theory",
  },
  {
    name: "B. “In this paper, the researcher will prove the theory”",
    value: "In this paper, the researcher will prove the theory",
  },
];

export const ongoingProjects = [
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
];

export const pastProjects = [
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
  {
    name: "Academic document translation from English to German",
    type: "Proofreading",
    projectOverView:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    documentType: "Document type",
    language: "English",
    status: "25%",
  },
];
//editprofile for translator
export const iAmATranslator = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Student of University",
    value: "collegeStudent",
  },
  {
    name: "Student of School",
    value: "schoolStudent",
  },
  {
    name: "Student of High School",
    value: "highSchoolStudent",
  },
];
export const chooseCountryTranslator = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Germany",
    value: "Germany",
  },
  {
    name: "Canada",
    value: "Canada",
  },
  {
    name: "Australia",
    value: "Australia",
  },
];
export const chooseTimezoneTranslator = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Berlin, Germany(GMT+1:00)",
    value: "Berlin, Germany(GMT+1:00)",
  },
  {
    name: "Belgrade,Serbia(GMT+1:00)",
    value: "Belgrade,Serbia(GMT+1:00)",
  },
  {
    name: "Belize City,Belize(GMT+1:00)",
    value: "Belize City,Belize(GMT+1:00)",
  },
];
export const fieldOfStudyTranslator = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Science",
    value: "Science",
  },
  {
    name: "Commerce",
    value: "Commerce",
  },
  {
    name: "Arts",
    value: "Arts",
  },
];
export const levelOfStudyTranslator = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Graduation",
    value: "Graduation",
  },
  {
    name: "Post graduation",
    value: "Post Graduation",
  },
  {
    name: "Msc",
    value: "Msc",
  },
];
//edit profile proofreader
export const iAmAProofreader = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Student of University",
    value: "collegeStudent",
  },
  {
    name: "Student of School",
    value: "schoolStudent",
  },
  {
    name: "Student of High School",
    value: "highSchoolStudent",
  },
];
export const chooseCountryProofreader = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Germany",
    value: "Germany",
  },
  {
    name: "Canada",
    value: "Canada",
  },
  {
    name: "Australia",
    value: "Australia",
  },
];
export const chooseTimezoneProofreader = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Berlin, Germany(GMT+1:00)",
    value: "Berlin, Germany(GMT+1:00)",
  },
  {
    name: "Belgrade,Serbia(GMT+1:00)",
    value: "Belgrade,Serbia(GMT+1:00)",
  },
  {
    name: "Belize City,Belize(GMT+1:00)",
    value: "Belize City,Belize(GMT+1:00)",
  },
];
export const fieldOfStudyProofreader = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Science",
    value: "Science",
  },
  {
    name: "Commerce",
    value: "Commerce",
  },
  {
    name: "Arts",
    value: "Arts",
  },
];
export const levelOfStudyProofreader = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "Graduation",
    value: "Graduation",
  },
  {
    name: "Post graduation",
    value: "Post Graduation",
  },
  {
    name: "Msc",
    value: "Msc",
  },
];
// add qualification for proofReader
export const latestDegreeProofReader = [
  {
    name: "A. Bachelor’s",
    value: "Bachelor's",
  },
  {
    name: "B. Master’s",
    value: "Master's",
  },
  {
    name: "C. PhD",
    value: "PhD",
  },
];
export const specilizationsProofReader = [
  {
    name: "A. Legal",
    value: "Legal",
  },
  {
    name: "B. Medical Sciences",
    value: "Medical Sciences",
  },
  {
    name: "C. Engineering and IT",
    value: "Engineering and IT",
  },
  {
    name: "D. Business & Economics",
    value: "Business & Economics",
  },
  {
    name: "E. Arts and Humanities",
    value: "Arts and Humanities",
  },
];
export const transExpProofReader = [
  {
    name: "A. 1-3",
    value: "1-3",
  },
  {
    name: "B. 4-8",
    value: "4-8",
  },
  {
    name: "C. More than 8",
    value: "More than 8",
  },
];
export const nativeLangProofReader = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "English",
    value: "English",
  },
  {
    name: "British",
    value: "British",
  },
  {
    name: "German",
    value: "German",
  },
];
export const languageToTranslateFromProofReader = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "English",
    value: "English",
  },
  {
    name: "British",
    value: "British",
  },
  {
    name: "German",
    value: "German",
  },
];
export const pagesYouCanTranslatePerDayProofReader = [
  {
    name: "A. 1-5",
    value: "1-5",
  },
  {
    name: "B. 6-10",
    value: "6-10",
  },
  {
    name: "C. 11-15",
    value: "11-15",
  },
  {
    name: "D. 16-20",
    value: "16-20",
  },
  {
    name: "E. 21-25",
    value: "21-25",
  },
];
export const activeHoursProofReader = [
  {
    name: "01",
    value: "01",
  },
  {
    name: "02",
    value: "02",
  },
  {
    name: "03",
    value: "03",
  },
];
// add qualification for translator
export const latestDegreeTranslator = [
  {
    name: "A. Bachelor’s",
    value: "Bachelor's",
  },
  {
    name: "B. Master’s",
    value: "Master's",
  },
  {
    name: "C. PhD",
    value: "PhD",
  },
];
export const translationExperience = [
  {
    name: "A. I am a General Translator",
    value: "General",
  },
  {
    name: "B. I am a Specialized Translator",
    value: "Specialized",
  }
];

export const specilizationsTranslator = [
  {
    name: "A. Legal",
    value: "Legal",
  },
  {
    name: "B. Medical Sciences",
    value: "Medical Sciences",
  },
  {
    name: "C. Engineering and IT",
    value: "Engineering and IT",
  },
  {
    name: "D. Business & Economics",
    value: "Business & Economics",
  },
  {
    name: "E. Arts and Humanities",
    value: "Arts and Humanities",
  },
  {
    name: "F. Literature & Poetry",
    value: "Literature & Poetry",
  }
];
export const transExpTranslator = [
  {
    name: "A. 1-3",
    value: "1-3",
  },
  {
    name: "B. 4-8",
    value: "4-8",
  },
  {
    name: "C. More than 8",
    value: "More than 8",
  },
];
export const nativeLangTranslator = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "English",
    value: "English",
  },
  {
    name: "British",
    value: "British",
  },
  {
    name: "German",
    value: "German",
  },
];
export const languageToTranslateFromTranslator = [
  { name: "Select", value: "" },
  {
    name: "English",
    value: "English",
  },
  {
    name: "British",
    value: "British",
  },
  {
    name: "German",
    value: "German",
  },
];
export const pagesYouCanTranslatePerDayTranslator = [
  {
    name: "A. 1-5",
    value: "1-5",
  },
  {
    name: "B. 6-10",
    value: "6-10",
  },
  {
    name: "C. 11-15",
    value: "11-15",
  },
  {
    name: "D. 16-20",
    value: "16-20",
  },
  {
    name: "E. 21-25",
    value: "21-25",
  },
];

export const activeHoursTranslator = [
  {
    name: "Select",
    value: "",
  },
  {
    name: "01",
    value: "01",
  },
  {
    name: "02",
    value: "02",
  },
  {
    name: "03",
    value: "03",
  },
];
//Chat Section
//Left side chat section
export const leftSideChat = [
  {
    name: "Client name 1",
    time: "04.22 PM",
    desgination: "Translator",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    image: "/images/user-one.png",
  },
  {
    name: "Client name 2",
    time: "04.23 PM",
    desgination: "Translator",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    image: "/images/user-two.png",
  },
  {
    name: "Client name 3",
    time: "04.24 PM",
    desgination: "Translator",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    image: "/images/user-three.png",
  },
  {
    name: "Client name 4",
    time: "04.25 PM",
    desgination: "Translator",
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
    image: "/images/user-four.png",
  },
];
//Right side chat section
export const rightSideChat = [
  {
    name: "Client name 1",
    time: "Sun, Mar 12,2023, 04.22 PM",
    description: "Lorem ipsum dolor sit amet",
    desgination: "Translator",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat.",
    image: "/images/user-one.png",
  },
  {
    name: "Client name 2",
    time: "Sun, Mar 12,2023, 04.23 PM",
    description: "Lorem ipsum dolor sit amet",
    desgination: "Translator",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip ex eacommodo consequat.",
    image: "/images/user-two.png",
  },
];
/**
 * *Initialize Socket
 */
export const io = socketClient.io(SOCKET_URL, {
  closeOnBeforeunload: false,
});
/**
 * *Country code
 */
export const countryCode = [
  {name: "Afghanistan", code: "AFG", dial_code: "+93"},
  {name: "Albania", code: "ALB", dial_code: "+355"},
  {name: "Algeria", code: "DZA", dial_code: "+213"},
  {name: "Andorra", code: "AND", dial_code: "+376"},
  {name: "Angola", code: "AGO", dial_code: "+244"},
  {name: "Antigua and Barbuda", code: "ATG", dial_code: "+1"},
  {name: "Argentina", code: "ARG", dial_code: "+54"},
  {name: "Armenia", code: "ARM", dial_code: "+374"},
  {name: "Australia", code: "AUS", dial_code: "+61"},
  {name: "Austria", code: "AUT", dial_code: "+43"},
  {name: "Azerbaijan", code: "AZE", dial_code: "+994"},
  {name: "Bahamas", code: "BHS", dial_code: "+1"},
  {name: "Bahrain", code: "BHR", dial_code: "+973"},
  {name: "Bangladesh", code: "BGD", dial_code: "+880"},
  {name: "Barbados", code: "BRB", dial_code: "+1"},
  {name: "Belarus", code: "BLR", dial_code: "+375"},
  {name: "Belgium", code: "BEL", dial_code: "+32"},
  {name: "Belize", code: "BLZ", dial_code: "+501"},
  {name: "Benin", code: "BEN", dial_code: "+229"},
  {name: "Bhutan", code: "BTN", dial_code: "+975"},
  {name: "Bolivia", code: "BOL", dial_code: "+591"},
  {name: "Bosnia and Herzegovina", code: "BIH", dial_code: "+387"},
  {name: "Botswana", code: "BWA", dial_code: "+267"},
  {name: "Brazil", code: "BRA", dial_code: "+55"},
  {name: "Brunei", code: "BRN", dial_code: "+673"},
  {name: "Bulgaria", code: "BGR", dial_code: "+359"},
  {name: "Burkina Faso", code: "BFA", dial_code: "+226"},
  {name: "Burundi", code: "BDI", dial_code: "+257"},
  {name: "Cabo Verde", code: "CPV", dial_code: "+238"},
  {name: "Cambodia", code: "KHM", dial_code: "+855"},
  {name: "Cameroon", code: "CMR", dial_code: "+237"},
  {name: "Canada", code: "CAN", dial_code: "+1"},
  {name: "Central African Republic", code: "CAF", dial_code: "+236"},
  {name: "Chad", code: "TCD", dial_code: "+235"},
  {name: "Chile", code: "CHL", dial_code: "+56"},
  {name: "China", code: "CHN", dial_code: "+86"},
  {name: "Colombia", code: "COL", dial_code: "+57"},
  {name: "Comoros", code: "COM", dial_code: "+269"},
  {name: "Congo (Congo-Brazzaville)", code: "COG", dial_code: "+242"},
  {name: "Costa Rica", code: "CRI", dial_code: "+506"},
  {name: "Croatia", code: "HRV", dial_code: "+385"},
  {name: "Cuba", code: "CUB", dial_code: "+53"},
  {name: "Cyprus", code: "CYP", dial_code: "+357"},
  {name: "Czech Republic", code: "CZE", dial_code: "+420"},
  {name: "Democratic Republic of the Congo", code: "COD", dial_code: "+243"},
  {name: "Denmark", code: "DNK", dial_code: "+45"},
  {name: "Djibouti", code: "DJI", dial_code: "+253"},
  {name: "Dominica", code: "DMA", dial_code: "+1"},
  {name: "Dominican Republic", code: "DOM", dial_code: "+1"},
  {name: "Ecuador", code: "ECU", dial_code: "+593"},
  {name: "Egypt", code: "EGY", dial_code: "+20"},
  {name: "El Salvador", code: "SLV", dial_code: "+503"},
  {name: "Equatorial Guinea", code: "GNQ", dial_code: "+240"},
  {name: "Eritrea", code: "ERI", dial_code: "+291"},
  {name: "Estonia", code: "EST", dial_code: "+372"},
  {name: "Eswatini fmr. Swaziland", code: "SWZ", dial_code: "+268"},
  {name: "Ethiopia", code: "ETH", dial_code: "+251"},
  {name: "Fiji", code: "FJI", dial_code: "+679"},
  {name: "Finland", code: "FIN", dial_code: "+358"},
  {name: "France", code: "FRA", dial_code: "+33"},
  {name: "Gabon", code: "GAB", dial_code: "+241"},
  {name: "Gambia", code: "GMB", dial_code: "+220"},
  {name: "Georgia", code: "GEO", dial_code: "+995"},
  {name: "Germany", code: "DEU", dial_code: "+49"},
  {name: "Ghana", code: "GHA", dial_code: "+233"},
  {name: "Greece", code: "GRC", dial_code: "+30"},
  {name: "Grenada", code: "GRD", dial_code: "+1"},
  {name: "Guatemala", code: "GTM", dial_code: "+502"},
  {name: "Guinea", code: "GIN", dial_code: "+224"},
  {name: "Guinea-Bissau", code: "GNB", dial_code: "+245"},
  {name: "Guyana", code: "GUY", dial_code: "+592"},
  {name: "Haiti", code: "HTI", dial_code: "+509"},
  {name: "Honduras", code: "HND", dial_code: "+504"},
  {name: "Hungary", code: "HUN", dial_code: "+36"},
  {name: "Iceland", code: "ISL", dial_code: "+354"},
  {name: "India", code: "IND", dial_code: "+91"},
  {name: "Indonesia", code: "IDN", dial_code: "+62"},
  {name: "Iran", code: "IRN", dial_code: "+98"},
  {name: "Iraq", code: "IRQ", dial_code: "+964"},
  {name: "Ireland", code: "IRL", dial_code: "+353"},
  {name: "Israel", code: "ISR", dial_code: "+972"},
  {name: "Italy", code: "ITA", dial_code: "+39"},
  {name: "Jamaica", code: "JAM", dial_code: "+1"},
  {name: "Japan", code: "JPN", dial_code: "+81"},
  {name: "Jordan", code: "JOR", dial_code: "+962"},
  {name: "Kazakhstan", code: "KAZ", dial_code: "+7"},
  {name: "Kenya", code: "KEN", dial_code: "+254"},
  {name: "Kiribati", code: "KIR", dial_code: "+686"},
  {name: "Kuwait", code: "KWT", dial_code: "+965"},
  {name: "Kyrgyzstan", code: "KGZ", dial_code: "+996"},
  {name: "Laos", code: "LAO", dial_code: "+856"},
  {name: "Latvia", code: "LVA", dial_code: "+371"},
  {name: "Lebanon", code: "LBN", dial_code: "+961"},
  {name: "Lesotho", code: "LSO", dial_code: "+266"},
  {name: "Liberia", code: "LBR", dial_code: "+231"},
  {name: "Libya", code: "LBY", dial_code: "+218"},
  {name: "Liechtenstein", code: "LIE", dial_code: "+423"},
  {name: "Lithuania", code: "LTU", dial_code: "+370"},
  {name: "Luxembourg", code: "LUX", dial_code: "+352"},
  {name: "Madagascar", code: "MDG", dial_code: "+261"},
  {name: "Malawi", code: "MWI", dial_code: "+265"},
  {name: "Malaysia", code: "MYS", dial_code: "+60"},
  {name: "Maldives", code: "MDV", dial_code: "+960"},
  {name: "Mali", code: "MLI", dial_code: "+223"},
  {name: "Malta", code: "MLT", dial_code: "+356"},
  {name: "Marshall Islands", code: "MHL", dial_code: "+692"},
  {name: "Mauritania", code: "MRT", dial_code: "+222"},
  {name: "Mauritius", code: "MUS", dial_code: "+230"},
  {name: "Mexico", code: "MEX", dial_code: "+52"},
  {name: "Micronesia", code: "FSM", dial_code: "+691"},
  {name: "Moldova", code: "MDA", dial_code: "+373"},
  {name: "Monaco", code: "MCO", dial_code: "+377"},
  {name: "Mongolia", code: "MNG", dial_code: "+976"},
  {name: "Montenegro", code: "MNE", dial_code: "+382"},
  {name: "Morocco", code: "MAR", dial_code: "+212"},
  {name: "Mozambique", code: "MOZ", dial_code: "+258"},
  {name: "Myanmar", code: "MMR", dial_code: "+95"},
  {name: "Namibia", code: "NAM", dial_code: "+264"},
  {name: "Nauru", code: "NRU", dial_code: "+674"},
  {name: "Nepal", code: "NPL", dial_code: "+977"},
  {name: "Netherlands", code: "NLD", dial_code: "+31"},
  {name: "New Zealand", code: "NZL", dial_code: "+64"},
  {name: "Nicaragua", code: "NIC", dial_code: "+505"},
  {name: "Niger", code: "NER", dial_code: "+227"},
  {name: "Nigeria", code: "NGA", dial_code: "+234"},
  {name: "North Korea", code: "PRK", dial_code: "+850"},
  {name: "North Macedonia", code: "MKD", dial_code: "+389"},
  {name: "Norway", code: "NOR", dial_code: "+47"},
  {name: "Oman", code: "OMN", dial_code: "+968"},
  {name: "Pakistan", code: "PAK", dial_code: "+92"},
  {name: "Palau", code: "PLW", dial_code: "+680"},
  {name: "Palestine State", code: "PSE", dial_code: "+970"},
  {name: "Panama", code: "PAN", dial_code: "+507"},
  {name: "Papua New Guinea", code: "PNG", dial_code: "+675"},
  {name: "Paraguay", code: "PRY", dial_code: "+595"},
  {name: "Peru", code: "PER", dial_code: "+51"},
  {name: "Philippines", code: "PHL", dial_code: "+63"},
  {name: "Poland", code: "POL", dial_code: "+48"},
  {name: "Portugal", code: "PRT", dial_code: "+351"},
  {name: "Qatar", code: "QAT", dial_code: "+974"},
  {name: "Romania", code: "ROU", dial_code: "+40"},
  {name: "Russia", code: "RUS", dial_code: "+7"},
  {name: "Rwanda", code: "RWA", dial_code: "+250"},
  {name: "Saint Kitts and Nevis", code: "KNA", dial_code: "+1"},
  {name: "Saint Lucia", code: "LCA", dial_code: "+1"},
  {name: "Saint Vincent and the Grenadines", code: "VCT", dial_code: "+1"},
  {name: "Samoa", code: "WSM", dial_code: "+685"},
  {name: "San Marino", code: "SMR", dial_code: "+378"},
  {name: "Sao Tome and Principe", code: "STP", dial_code: "+239"},
  {name: "Saudi Arabia", code: "SAU", dial_code: "+966"},
  {name: "Senegal", code: "SEN", dial_code: "+221"},
  {name: "Serbia", code: "SRB", dial_code: "+381"},
  {name: "Seychelles", code: "SYC", dial_code: "+248"},
  {name: "Sierra Leone", code: "SLE", dial_code: "+232"},
  {name: "Singapore", code: "SGP", dial_code: "+65"},
  {name: "Slovakia", code: "SVK", dial_code: "+421"},
  {name: "Slovenia", code: "SVN", dial_code: "+386"},
  {name: "Solomon Islands", code: "SLB", dial_code: "+677"},
  {name: "Somalia", code: "SOM", dial_code: "+252"},
  {name: "South Africa", code: "ZAF", dial_code: "+27"},
  {name: "South Korea", code: "KOR", dial_code: "+82"},
  {name: "South Sudan", code: "SSD", dial_code: "+211"},
  {name: "Spain", code: "ESP", dial_code: "+34"},
  {name: "Sri Lanka", code: "LKA", dial_code: "+94"},
  {name: "Sudan", code: "SDN", dial_code: "+249"},
  {name: "Suriname", code: "SUR", dial_code: "+597"},
  {name: "Sweden", code: "SWE", dial_code: "+46"},
  {name: "Switzerland", code: "CHE", dial_code: "+41"},
  {name: "Syria", code: "SYR", dial_code: "+963"},
  {name: "Tajikistan", code: "TJK", dial_code: "+992"},
  {name: "Tanzania", code: "TZA", dial_code: "+255"},
  {name: "Thailand", code: "THA", dial_code: "+66"},
  {name: "Timor-Leste", code: "TLS", dial_code: "+670"},
  {name: "Togo", code: "TGO", dial_code: "+228"},
  {name: "Tonga", code: "TON", dial_code: "+676"},
  {name: "Trinidad and Tobago", code: "TTO", dial_code: "+1"},
  {name: "Tunisia", code: "TUN", dial_code: "+216"},
  {name: "Turkey", code: "TUR", dial_code: "+90"},
  {name: "Turkmenistan", code: "TKM", dial_code: "+993"},
  {name: "Tuvalu", code: "TUV", dial_code: "+688"},
  {name: "Uganda", code: "UGA", dial_code: "+256"},
  {name: "Ukraine", code: "UKR", dial_code: "+380"},
  {name: "United Arab Emirates", code: "ARE", dial_code: "+971"},
  {name: "United Kingdom", code: "GBR", dial_code: "+44"},
  {name: "United States of America", code: "USA", dial_code: "+1"},
  {name: "Uruguay", code: "URY", dial_code: "+598"},
  {name: "Uzbekistan", code: "UZB", dial_code: "+998"},
  {name: "Vanuatu", code: "VUT", dial_code: "+678"},
  {name: "Venezuela", code: "VEN", dial_code: "+58"},
  {name: "Vietnam", code: "VNM", dial_code: "+84"},
  {name: "Yemen", code: "YEM", dial_code: "+967"},
  {name: "Zambia", code: "ZMB", dial_code: "+260"},
  {name: "Zimbabwe", code: "ZWE", dial_code: "+263"}
]
;
