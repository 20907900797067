import React from 'react'
import { useSelector } from 'react-redux'

const ConnectionStatus = ({ io }) => {
    const { user }=useSelector((state) => state.auth)
    window.scrollTo(0, 0);
    // var socket = io.connect();
    console.log('Socket connection status 1', io.connected);
    io.on('connect', function () {
        console.log('Socket connection status 2', io.connected);
    });
    return (
        <div></div>
    )
}

export default ConnectionStatus