import React, { useEffect, useState } from 'react'
import RecentProjects from '../../components/common/recentProjectSlider/RecentProjectsSlider'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardData, getProfile } from '../../services/api/profileApi'
import { setJobCounts } from '../../redux/reducers/dashBoardSlice'
import { toast } from 'react-toastify'
import SocialShare from '../../components/common/socialShare/SocialShare'
import { setSelectedJobDetails, setSelectedReceiptDetails } from '../../redux/reducers/jobSlice'
import { useNavigate } from 'react-router-dom'
import { updateUser } from '../../redux/reducers/authSlice'
import RecentTranscationSlider from '../../components/common/TransactionSlider/RecentTranscationSlider'
import WithDrawModelCustomer from '../../components/common/WithdrawModel/WithDrawModelCustomer'
import { checkWordCount } from '../../services/api/jobPostApis'
import { setActiveStep, updateBlobFile, updateJobPostFileWordCount } from '../../redux/reducers/jobPostSlice'
import DragDropUploadButton from '../../components/common/DragDropUploadButton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";

const CustomerDashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showWithDrawModel, setShowWithDrawModel] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { cancelJobs, completedProjects, ongoingProjects } = useSelector((state) => state.dashBoard)
  const [showSocialShare, setShowSocialShare] = useState(false)
  const { jobWordCount, uploadFiles, blobFile, fileDetails } = useSelector((state) => state.jobPost)

  const copyFunc = async () => {
    await navigator.clipboard.writeText(`${window?.location?.origin}/signup?code=${user?._id}`);
    toast.success('Affiliate link copied successfully')
  }
  const downloadQR = () => {
    const svg = document.getElementById("qrcode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
  
      const downloadLink = document.createElement("a");
      downloadLink.download = "qrcode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
  
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  useEffect(() => {
    (async () => {
      let dashbordData = await getDashboardData()
      let payload = {
        cancelJobs: dashbordData?.result?.data?.cancelJobs,
        completedProjects: dashbordData?.result?.data?.completedProjects,
        ongoingProjects: dashbordData?.result?.data?.ongoingProjects
      }
      dispatch(setJobCounts(payload))
    })()
  }, [])
  const gotoSelectedProject = (data) => {
    dispatch(setSelectedJobDetails({ selectedJob: data }))
    navigate(`/job-details`, { state: { id: data._id } })
  }
  const gotoSelectedReceipt = (data) => {
    dispatch(setSelectedReceiptDetails({ selectedReceipt: data }))
    navigate(`/customer/receipt-details`, { state: { id: data._id } })
  }
  const getUserDetails = async () => {
    let resultData = await getProfile()
    dispatch(updateUser({ user: resultData?.result?.data }));
  }

  useEffect(() => {
    getUserDetails()
  }, [])
  const wordCountCheck = async (data) => {
    let uploadFileData = await checkWordCount(data[0])
    if (uploadFileData?.result) {


      dispatch(updateJobPostFileWordCount({ count: uploadFileData?.result?.data?.wordCount }))
      blobToBase64(data[0])
        .then(base64String => {
          dispatch(updateBlobFile({ blobFile: { b64: base64String, name: data[0]?.path } }))
          dispatch(setActiveStep({ activeStep: 'uploadFile' }))
          navigate('/job-post/upload-file')
        })
        .catch(error => {
          console.error('Error converting blob to base64:', error);
        });


    }
  }
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  const withDrawModelOpen = () => {
    if (user.country !== "") {
      setShowWithDrawModel(true)
    }
    else {
      toast.error("Please Select Country From the Edit Profile")
    }


  }
  return (
    <>
      <div className="user-dashbord">
        <div className="section-header-dashbord common-btm-mrgn">
          <div className="container">
            <div className="content">
              <h2>Welcome! {user.name}.</h2>
            </div>
          </div>
        </div>

        <div className="dashbord-three-box common-btm-mrgn">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="sngl-box blue-box">
                  <h3><span><img src="/images/tickdb.svg" alt="" /></span> {ongoingProjects}</h3>
                  <h5>Ongoing Projects</h5>
                  <p>{ongoingProjects}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sngl-box green-box">
                  <h3><span><img src="/images/tickdb.svg" alt="" /></span> {completedProjects}</h3>
                  <h5>Completed Projects</h5>
                  <p>{completedProjects}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sngl-box green-box">
                  <div className="zone">
                    <div id="dropZ">
                      <img src="/images/file.png" alt="" />
                      <div className="drag-text">Drag & drop your entire document here.</div>
                      <div className="pro-or">
                        <span> OR</span>
                      </div>

                      <DragDropUploadButton btnCss={"upload-f"} btnName={"Browse File"} multiple={false} disabled={!!blobFile} />
                      <DragDropUploadButton btnName={""} multiple={false} onChange={wordCountCheck} disabled={!!blobFile} />

                    </div>

                  </div>
                </div>
                {/* <div className="col-lg-4">
                <div className="sngl-box red-box">
                  <h3><span><img src="/images/tickdb.svg" alt="" /></span> {cancelJobs}</h3>
                  <h5>Projects cancelled</h5>
                  <p>{cancelJobs}</p>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="dashbord-aflit-link common-btm-mrgn-big">
          <div className="container">
            <div className="content light-green-bg">
              {<div className="ext-content">
                <h5>Reward Points</h5>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="sngl-box">
                          <h5>Points</h5>
                          <h2>{user?.rewardPoint}(${user?.rewardPoint})</h2>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/* <div className="sngl-box">
                          <h5>Total withdraw</h5>
                          <h4>$200</h4>
                        </div> */}
                      </div>
                      {/* <div className="col-lg-4">
                        <div className="sngl-box">
                          <h5>Total Points</h5>
                          <h4>{user?.rewardPoint}</h4>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-lg-5">
                    <div className="right-content">
                      <ul className="btn-box">
                        <li><a href="" className="btn">Change PayPal Email</a></li>
                        <li><a href="" className="btn">Withdraw</a></li>
                      </ul>
                      <p>Get $50 to withdraw</p>
                    </div>
                  </div> */}
                </div>
              </div>}
              <div className="ext-content">
                <h4>Affiliate Link</h4>
                <form>
                  <div className="frm-box-wrap">


                    <div className="frm-box">
                      <div className="row">
                        <div className="col-lg-8">
                          <div class="custom-search">
                            <input type="text" className="form-control custom-search-input" id="" aria-describedby="" placeholder="" value={`${window?.location?.origin}/signup?code=${user?._id}`} />

                            <button type="button" className="copy-link custom-search-botton" onClick={copyFunc}>
                              <FontAwesomeIcon icon={faCopy} />
                            </button>
                          </div>
                          <button type="button" className="btn" onClick={() => setShowSocialShare(true)} ><span><img src="/images/sear.svg" /></span> Share</button>
                        </div>
                        <div className="col-lg-4">
                          <div style={{ margin: "0", "border-left": "1px solid #c9c9c9", "padding-left": "30px" }}>
                            <QRCode
                              size={128}
                              value={`${window?.location?.origin}/signup?code=${user?._id}`}
                              viewBox={`0 0 128 128`}
                              id="qrcode"
                            />
                            <button onClick={() => downloadQR()} className="btn" style={{ "margin-left": "30px", "verticalAlign": "top" }}>
                              <FontAwesomeIcon icon={faDownload} /> Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              {<div className="ext-content">
                <h5>Affiliate Earnings</h5>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="sngl-box">
                          <h5>Total earnings</h5>
                          <h2>${user?.affiliatedAmount}</h2>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/* <div className="sngl-box">
                          <h5>Total withdraw</h5>
                          <h4>$200</h4>
                        </div> */}
                      </div>
                      <div className="col-lg-4">
                        <div className="sngl-box">
                          <h5>Total balance</h5>
                          <h4>${user?.affiliatedAmount}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="right-content">
                      <ul className="btn-box">
                        {/* <li><a href="" className="btn">Change PayPal Email</a></li> */}
                        <li ><p
                          style={{
                            cursor: user?.affiliatedAmount > 50
                              ? ""
                              : "not-allowed",
                            backgroundColor: user?.affiliatedAmount < 50 && "gray"
                          }}


                          onClick={() => user?.affiliatedAmount > 50 && withDrawModelOpen()} className="btn">Withdraw</p></li>
                      </ul>
                      <p>Balance should be minimum of $50 to withdraw</p>
                    </div>
                  </div>
                </div>
              </div>}

            </div>
          </div>
        </div>



      </div>
      <RecentProjects gotoSelectedProject={gotoSelectedProject} />
      <RecentTranscationSlider gotoSelectedReceipt={gotoSelectedReceipt} />

      <SocialShare setShowSocialShare={setShowSocialShare} showSocialShare={showSocialShare} />
      {showWithDrawModel && <WithDrawModelCustomer showWithDrawModel={showWithDrawModel} setShowWithDrawModel={setShowWithDrawModel} />}
    </>

  )
}

export default CustomerDashboard