import { io } from "../../constants/config";

export const timerGet = (jobId) => {
  io?.emit("timer", jobId);
};
export const joinChat = (id) => {
  // TODO: will improve this section after BE change to {userId, RoomId}
  io?.emit("join-chat", id);
};

export const gettingAllChatRooms = async ({ userId, pageNumber, dataPerPage }) => {
  await io?.emit("join-all-chatrooms", `${userId}`);
  await io?.emit("all-chatrooms", { userId, pageNumber, dataPerPage });
};
export const sendMessage = ({
  messageText,
  senderId,
  receiverId,
  chatRoomId,
}) => {
  if (
    messageText.length > 0 &&
    messageText.replace(/<[^>]*>/g, "").length > 0 &&
    messageText.replace(/<[^>]*>/g, "").trim().length > 0
  ) {
    io?.emit("send-message", { messageText, senderId, receiverId, chatRoomId });
  }
};
export const getChatRoomMessage = ({
  userId,
  chatRoomId,
  pageNumber,
  dataPerPage,
}) => {
  io?.emit("all-chatroom-messages", {
    userId,
    chatRoomId,
    pageNumber,
    dataPerPage,
  });
};
export const typing = ({ userId, chatRoomId }) => {
  io.emit("typing", { userId, chatRoomId });
};
export const seenMsg = ({ userId, chatRoomId, msgId }) => {
  io.emit("seen", { userId, chatRoomId, msgId });
};
export const sendNotification = (data) => {
  io.emit("notification", data);
}
export const sendNotificationUpdateRank = (data) => {
  io.emit("chat-notification", data);
}