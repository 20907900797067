export function getTimeAgo(timestamp) {
    const unixTimestamp = Date.parse(timestamp);
    const timeAgo = unixTimestamp - Date.now();
    if (timeAgo > 0) {
        // Convert time to seconds, minutes, hours, or days
        const seconds = Math.floor(timeAgo / 1000) % 60;
        const minutes = Math.floor(timeAgo / (1000 * 60)) % 60;
        const hours = Math.floor(timeAgo / (1000 * 60 * 60)) % 24;
        const days = Math.floor(timeAgo / (24 * 60 * 60 * 1000));

        // Format the time as a string
        const timeString =
            (days < 10 ? "0" + days : days) + "d: " +
            (hours < 10 ? "0" + hours : hours) +
            "h: " +
            (minutes < 10 ? "0" + minutes : minutes) +
            "m: " +
            (seconds < 10 ? "0" + seconds : seconds) +
            "s";

        return timeString;
    }
    else {
        return "Expired";
    }
}