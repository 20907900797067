import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18Lang from "../../constants/i18Lang";
const init = (language = "en") => {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            // the translations
            // (tip move them in a JSON file and import them,
            // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
            resources: {
                en: {
                    translation: i18Lang.en
                },
                test: {
                    translation: i18Lang.test
                }
            },
            lng: language, // if you're using a language detector, do not define the lng option
            fallbackLng: language,

            interpolation: {
                escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            }
        });
}

const changeLanguage = (language = "en") => {
    i18n.changeLanguage(language);
}

const i18 = {
    init,
    changeLanguage,
}
export default i18;