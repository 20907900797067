import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/reducers/authSlice";
import { editProfileApi, getBusinessUsers } from "../../../services/api/profileApi";
import { editProfileValidatorUser } from "../../../services/formValidator/customerValidator";
import Select from "react-select";
import countryList from 'react-select-country-list'
import { countryCode } from "../../../constants/config";
import BusinessAccountTable from "./BusinessAccountTable";
import { startLoading, stopLoading } from "../../../redux/reducers/loadingSlice";
import { createACompanyUser, setUsers } from "../../../redux/reducers/companySlice";
import { createACompanyUserSchema } from "../../../services/formValidator/companyUserValidator";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ManageUsers = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectCountry, setSelectCountry] = useState({
    countryVal: user.country,

  })
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { myUsers, pageNumber, dataPerPage, totalJobCount } = useSelector((state) => state.companyUser)
  const [getPhoneNumber, setGetPhoneNumber] = useState(false);
  const [selectedDialCode, setSelectedDialCode] = useState(user?.countryCode);
  let currentUrl = window.location.href;
  const editProfileFormik = useFormik({
    initialValues: {
      name: user.name,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
      country: user.country,
    },
    validationSchema: editProfileValidatorUser.validationSchema,
    onSubmit: async (values) => {
      let data = values;
      let editProfiledata = await editProfileApi(data);
      dispatch(updateUser({ user: editProfiledata?.result?.data }));
    },
  });
  const countrySelectHandler = (countryVal) => {
    editProfileFormik.setFieldValue("country", countryVal?.label)
    setSelectCountry({ countryVal });
    countryCode.forEach((item) => {
      if (item.name === countryVal?.label) {
        setGetPhoneNumber(true)
        setSelectedDialCode(item.dial_code);
        editProfileFormik.setFieldValue("countryCode", item.dial_code)

      }
    });
  };
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        username: "",
        email: "",
        countryCode: "",
        password: "",
        phoneNumber: "",
        companyId: user?.companyId
      },
      validationSchema: createACompanyUserSchema,
      onSubmit: async (values, action) => {
        values.companyId = user?.companyId;
        const result = await dispatch(createACompanyUser(values));
        if (result.payload.serverResponse.code === 201) {
          setShowCreateModal(false);
          action.resetForm();
          toast.success(result.payload.serverResponse.message);

          const data = await dispatch(
            getCompanyUsersList(user?.companyId)
          );
          dispatch(setUsers({ users: data.result.data, pageNumber: data?.pageNumber, totalUsersCount: data?.dataCount }))

        }
      },
    });
  const getCompanyUsersList = async (page) => {
    dispatch(startLoading())
    let data = await getBusinessUsers({
      id: user?.companyId,
      pageNumber: page ? page : 1,
    });
    console.log(data?.result?.data)
    if (data?.result?.data.length > 0) {
      dispatch(setUsers({ users: data.result.data, pageNumber: data?.pageNumber, totalUsersCount: data?.dataCount }))
    }
    dispatch(stopLoading())
  }

  useEffect(() => {
    if (!myUsers?.length) {
      getCompanyUsersList()
    }
  }, [myUsers])
  
  useEffect(() => {
    return () => {
      dispatch(setUsers({ users: null, pageNumber: 1, totalUsersCount: 0 }));
    }
  }, [])
  useEffect(() => {


    if (selectedDialCode?.length === 3) {


      if (!getPhoneNumber) {

        editProfileFormik.setFieldValue("phoneNumber", user?.phoneNumber)
      }
      else {
        editProfileFormik.setFieldValue("phoneNumber", 0)

      }
    }
    if (selectedDialCode?.length === 4) {

      if (!getPhoneNumber) {

        editProfileFormik.setFieldValue("phoneNumber", user?.phoneNumber)
      }
      else {
        editProfileFormik.setFieldValue("phoneNumber", "")
      }


    }
  }, [selectedDialCode])

  return (
    <>
      <section className="user-dashbord sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="translator-edit-profile-right">
                <div className="content common-btm-mrgn">
                  <h4>Manage Users</h4>
                </div>
                <div className="">
                  <BusinessAccountTable data={myUsers} setUsers={setUsers} getCompanyUsersList={getCompanyUsersList} />
                  <div className="flex gap-x-4 justify-between mt-12">
                    <div className="flex gap-x-3">
                      <button onClick={() => setShowCreateModal(() => true)} className='btn btn-primary'>Create User</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Dialog Modal */}
      {showCreateModal ? (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Create Business User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="signin-form-wrap">
              <form className="grid grid-cols-2 gap-6" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control"
                    placeholder="Name"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {errors.username && touched.username ? (
                    <p className="form-error-message">{`*${errors.username}`}</p>
                  ) : null}

                </div>
                <div className="form-group">
                  <label
                    htmlFor="email"

                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {errors.email && touched.email ? (
                    <p className="form-error-message">{`*${errors.email}`}</p>
                  ) : null}
                </div>
                <div class="form-group with-selectbox">
                  <label>Phone</label>
                  <input type="text"
                    placeholder=""
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control"
                    onChange={handleChange}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    required />

                  <select className="form-select" onChange={handleChange} value={values.countryCode} name="countryCode">
                    {countryCode.map((country, idx) => {
                      return (
                        <option key={idx} value={country.dial_code}>{country.code}</option>
                      );
                    })}

                  </select>
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <p className="form-error-message">{`*${errors.phoneNumber}`}</p>
                  ) : null}
                </div>

                <div className="form-group">
                  <label
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {errors.password && touched.password ? (
                    <p className="form-error-message">{`*${errors.password}`}</p>
                  ) : null}
                </div>

              </form>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn w-100"
              onClick={(e) => handleSubmit(e)}
            // disabled={!checkBox}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default ManageUsers;
