import React, { useEffect } from "react";
import { getAllJobs } from "../../../services/api/jobApis";
import { useDispatch, useSelector } from "react-redux";
import {
  setJobs,
  setSelectedJobDetails,
} from "../../../redux/reducers/jobSlice";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import NoContentAvailable from "../../../components/common/NoContentAvailable";
import { getTimeAgo } from "../../../utils/getTimeAgoTimer";
import ProjectSingleCardProf from "./ProjectSingleCardProf";
import { useNavigate } from "react-router";

const PastProjectsProf = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentPage, myJobs, totalJobCount } = useSelector(
    (state) => state.job
  );

  const getPastProjects = async (page) => {
    dispatch(startLoading());
    let data = await getAllJobs("past-projects", page ?? 1);
    if (data?.data?.length > 0) {
      dispatch(
        setJobs({
          jobs: data?.data,
          pageNumber: data?.pageNumber,
          totalJobCount: data?.dataCount,
        })
      );
    }
    dispatch(stopLoading());
  };
  useEffect(() => {
    if (!myJobs?.length) {
      getPastProjects();
    }
  }, [myJobs]);
  const loadMoreData = () => {
    getPastProjects(currentPage + 1);
  };
  const gotoSelectedProject = (data) => {
    dispatch(setSelectedJobDetails({ selectedJob: data }));
    navigate(`/proofreader-my-jobs/details`, { state: { id: data._id } });
  };
  return (
    <>
      <div
        className="tab-pane fade active show"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div className="ongong-post-wraper">
          <div className="row">
            {myJobs ? (
              myJobs.map((item, idx) => {
                return (
                  <ProjectSingleCardProf
                    item={item}
                    gotoSelectedProject={gotoSelectedProject}
                    id={idx}
                  />
                );
              })
            ) : (
              <NoContentAvailable title={"No past jobs available"} />
            )}
          </div>
        </div>
      </div>
      {totalJobCount - myJobs?.length > 0 && (
        <div className="end-btn" onClick={loadMoreData}>
          <p className="btn light-btn">Load more</p>
        </div>
      )}
    </>
  );
};

export default PastProjectsProf;
