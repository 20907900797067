import React, { useEffect, useState } from 'react'
import { getTimeAgo } from '../../../utils/getTimeAgoTimer'

const SingleTransactionCard = ({ item, gotoSelectedReceipt, idx }) => {

    return (
        <div className="col-lg-12" key={idx}>
            <div className="project-with-progress-box" style={{ minHeight: "41vh" }}>

                <h5 onClick={() => gotoSelectedReceipt(item)} style={{ cursor: "pointer" }}>
                    {item?.jobId?.jobTitle}
                </h5>

                <h6>Total Amount: <span>
                    {item?.subtotal.toFixed(2)}
                </span></h6>

                <div className="listing-area">
                    <ul className="listing-sec">
                        <li>
                            <strong>Currency Type:</strong> {item?.currency}
                        </li>
                        <li>
                            <strong>Service Type:</strong> {item?.serviceType.toUpperCase()}
                        </li>
                        <li>
                            <strong>Receipt:</strong> <span><a download href={item?.recieptLink}>Download</a></span>
                        </li>
                        <li>
                            <strong>Tax:</strong> 0%
                        </li>
                    </ul>
                </div>

            </div>


        </div>

    )
}

export default SingleTransactionCard