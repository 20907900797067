
import { useDispatch, useSelector } from 'react-redux'
import LanguageSelector from '../../../components/common/LanguageSelector'
import { setJobPrice, setTotalInitial, updateJob, clearjobPost, updateBlobFile, updateJobPostFiles } from '../../../redux/reducers/jobPostSlice'
import { chooseTranslatorJson, specalisationJson } from '../../../constants/config'
import { jobPostTranslationVerification } from '../../../services/formValidator/jobValidator'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import ResponsiveStepper from './ResponsiveStepper'
import { createJob, uploadJobFile } from '../../../services/api/jobPostApis'
import { useEffect, useState } from 'react'
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice'
import { toast } from 'react-toastify'
const TranslationConfigure = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        jobWordCount,
        uploadFiles,
        fileDetails,
        blobFile,
        documentType,
        serviceType,
        deadlineTime,
        configTranslation,
        configProofreading,
        perWordPrice,
        jobPrice,
        cartCurrency,
        totalInitial,
        multipliedBy,
        fixedRate,
        styleSheetLink,
        selectedSpeedOption
    } = useSelector((state) => state.jobPost);
    let jobPostApi = {
        jobWordCount,
        uploadFiles,
        fileDetails,
        blobFile,
        documentType,
        serviceType,
        deadlineTime,
        configTranslation,
        configProofreading,
        perWordPrice,
        jobPrice,
        cartCurrency,
        totalInitial,
        multipliedBy,
        fixedRate,
        styleSheetLink
    }
    const { user } = useSelector((state) => state.auth)
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const jobPostTranslationFormik = useFormik({
        initialValues: jobPostTranslationVerification.initialValues,
        validationSchema: jobPostTranslationVerification.validationSchema,
        onSubmit: async (values) => {
            dispatch(
                (dispatch) =>
                  new Promise((resolve) => {
                    dispatch(
                      updateJob({
                        type: 'UPDATE_TRANSLATION_CONFIGURATION',
                        value: { ...values },
                      })
                    );
                    resolve();
                  })
              );
          
          

            // let bodyData = {
            //     cart_amount: jobPrice
            // }
            // let payment = await createPayment(bodyData)
            // if (payment) {
            //     sessionStorage.setItem('transRef', payment?.result?.data?.tran_ref)
            //     window.location.href = payment?.result?.data?.redirect_url;
            // }
            navigate('/job-post/payment');
        }
    })
    useEffect(() => {
        if (jobPostTranslationFormik.values.chooseTranslator === 'Subject Specialized Translator') {
            dispatch(setJobPrice({
                jobPrice: Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1.5) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy - 1)) + ((selectedSpeedOption?.perWordPrice * jobWordCount) * 1.5)).toFixed(4)))
            }))
        }
        else {
            dispatch(setJobPrice({
                jobPrice: Number(selectedSpeedOption?.fixedRate ? (selectedSpeedOption?.fixedRate * 1) : ((((selectedSpeedOption?.perWordPrice * jobWordCount) * (selectedSpeedOption?.multipliedBy))).toFixed(4)))
            }))
        }
        return (() => {
            dispatch(setTotalInitial({ total: jobPrice }))
        })
    }, [jobPostTranslationFormik])


    return (
        <>
            <ResponsiveStepper goNext={jobPostTranslationFormik.handleSubmit} />
            <div className="Tran-sec">
                <h3>Translation</h3>
                <form onSubmit={jobPostTranslationFormik.handleSubmit}>
                    <div className="tran-card-sec">
                        <h4>1. Choose Language Pair</h4>
                        <LanguageSelector
                            title={'Translate from'}
                            getLanguage={(value) => {
                                jobPostTranslationFormik.handleChange({
                                    target: { name: 'fromLang', value: value }
                                });
                                
                            }}
                            jobPostTranslationFormik={jobPostTranslationFormik}
                        />
                        <p className='form-error-message'>{jobPostTranslationFormik.touched.fromLang && jobPostTranslationFormik.errors.fromLang}</p>

                        <LanguageSelector
                            title={'Translate to'}
                            getLanguage={(value) => {
                                jobPostTranslationFormik.handleChange({
                                    target: { name: 'toLang', value: value }
                                });
                            }}
                            jobPostTranslationFormik={jobPostTranslationFormik}

                        />
                        <p className='form-error-message'>{jobPostTranslationFormik.touched.toLang && jobPostTranslationFormik.errors.toLang}</p>
                    </div>
                    <div className="tran-card-sec">
                        <h4>2. Choose your Translator</h4>

                        <div className="radio-li widht-half">

                            <ul>
                                {chooseTranslatorJson.map((item) => (
                                    <li>
                                        <div className="radio-group">
                                            <input onChange={jobPostTranslationFormik.handleChange} type="radio" name="chooseTranslator" id="chooseTranslator" value={item.value} checked={jobPostTranslationFormik.values.chooseTranslator === item?.value} />
                                            <label htmlFor="html">

                                                <h5>{item.name}</h5>
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <p> *(Price 1.5x)</p>
                        </div>
                        <p className='form-error-message'>{jobPostTranslationFormik.touched.chooseTranslator && jobPostTranslationFormik.errors.chooseTranslator}</p>

                        {jobPostTranslationFormik.values.chooseTranslator === 'Subject Specialized Translator' &&
                            <>
                                <div className="radio-li">
                                    <div className="Details-card-box">
                                        <h6>Which best describes the field of your document?</h6>
                                    </div>
                                    <ul>
                                        {
                                            specalisationJson.map((item) => (
                                                <li>
                                                    <div className="radio-group">
                                                        <input onChange={jobPostTranslationFormik.handleChange} type="radio" name="specialisation" id="specialisation" value={item.value} checked={jobPostTranslationFormik.values.specialisation === item?.value} />
                                                        <label htmlFor="html">

                                                            <h5>{item.name}</h5>
                                                        </label>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <p className='form-error-message'>{jobPostTranslationFormik.touched.specialisation && jobPostTranslationFormik.errors.specialisation}</p>
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            name="showWatermark"
                                            onChange={jobPostTranslationFormik.handleChange}
                                            id="showWatermark" checked={jobPostTranslationFormik.showWatermark} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            <strong>Show Lingvara certified translation watermark on all pages? <a download target='_blank' href="/js/Translation Watermark.pdf">See Example.</a></strong>
                                        </label>
                                    </div>

                                </div>
                            </>
                        }

                    </div>
                    <div className="e-ch">
                        <div className="form-group">
                            <label htmlFor="">Any additional comments to the translator?</label>
                            <textarea onChange={jobPostTranslationFormik.handleChange} value={jobPostTranslationFormik.values.addComments} name="addComments" id="addComments" cols="10" rows="7" className="form-control"></textarea>
                            <p className='form-error-message'>{jobPostTranslationFormik.touched.addComments && jobPostTranslationFormik.errors.addComments}</p>
                        </div>

                    </div>
                    {/* {!!user?.rewardPoint && <div className="form-group">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                name="showWatermark"
                                onClick={handleReward}
                                checked={useReward}
                                id="showWatermark" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                <strong>Use reward points?( {user?.rewardPoint} USD )</strong>
                            </label>
                        </div>
                    </div>} */}
                    <div className="button-set">

                        <button type='submit' className="next-button text-center mt-1 ml-2 btn">Submit</button>
                    </div>
                </form>
            </div>


        </>
    )
}

export default TranslationConfigure