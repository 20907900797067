import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatRooms: null,
  limit: 10,
  page: 1,
  activeRoom: null,
  typingData: {},
  messages: [],
  chatPageNumber: 1,
  messagePageNumber: 1,
  updateMsg: "",
};
export const chartRoomSlice = createSlice({
  name: "chatRoom",
  initialState,
  reducers: {
    createChatRooms: (state, { payload }) => {
      const { chatRoom } = payload;
      if (state.chatRooms?.length > 0 && chatRoom) {
        let arr = [];
        let newArr = state.chatRooms.concat(chatRoom);
        newArr.forEach((object) => {
          const found = arr.some((item) => item["_id"] === object["_id"]);

          if (!found) {
            arr.push(object);
          }
        });
        state.chatRooms = arr;
      } else {
        state.chatRooms = chatRoom;
      }
    },
    setActiveroom: (state, { payload }) => {
      const { activeRoom } = payload;
      state.activeRoom = activeRoom;
    },
    updateChatRoom: (state, { payload }) => {
      const { updateRoom } = payload;
      if (state.chatRooms && state.chatRooms?.length) {
        let filteredChatRoom = state.chatRooms?.filter(
          (item) => item?._id !== updateRoom?._id
        );
        state.chatRooms = [updateRoom, ...filteredChatRoom];
      }
    },
    setMessages: (state, { payload }) => {
      const { messages } = payload;
      if (state.messages && state.messages?.length && messages) {
        state.messages = state.messages.concat(messages);
      } else {
        state.messages = messages;
      }
    },
    addMessages: (state, { payload }) => {
      const { message } = payload;
      if (state.messages && message?.chatRoomId === state.activeRoom?._id) {
        state.messages = [message, ...state.messages];
      }
    },
    setTyping: (state, { payload }) => {
      state.typingData = payload;
    },
    setUpdateMsg: (state, { payload }) => {
      const { message } = payload;
      if (state.messages && state.messages.length) {
        const updatedMessages = state.messages.map((val) =>
          val?._id === message?._id ? message : val
        );
        state.messages = updatedMessages;
      }
    },
    clearChat: (state, actions) => {
      state.chatRooms = null;
      state.limit = 10;
      state.page = 1;
      state.activeRoom = null;
      state.typingData = "";
      state.messages = [];
      state.chatPageNumber = 1;
      state.messagePageNumber = 1;
      state.updateMsg = "";
    },
  },
});
export const {
  createChatRooms,
  setActiveroom,
  updateChatRoom,
  addMessages,
  setMessages,
  setTyping,
  setUpdateMsg,
  clearChat,
} = chartRoomSlice.actions;
export default chartRoomSlice.reducer;
