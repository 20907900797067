import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { removeFileFromS3, uploadJobFile } from '../../../services/api/jobPostApis';
import DragDropUploadButton from '../DragDropUploadButton';
import { getAllJobs, uploadCompletedJob } from '../../../services/api/jobApis';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setJobs } from '../../../redux/reducers/jobSlice';
import { startLoading, stopLoading } from '../../../redux/reducers/loadingSlice';
import { t } from 'i18next';

const FileUploadModal = ({ showUploadedFileModal, setShowUploadedFileModal, item }) => {
    const dispatch = useDispatch()
    const { currentPage } = useSelector((state) => state.job);
    const [jobFiles, setJobFiles] = useState(null);
    const uploadJobFiles = async (data) => {
        let uploadJobData = await uploadJobFile(data[0]);
        if (uploadJobData?.result) {
            setJobFiles(uploadJobData?.result?.data);
        }

    };
    const removeItem = async () => {
        let data = {
            key: jobFiles?.responseData?.key,
        };
        if (data) {
            let { serverResponse } = await removeFileFromS3(data);
            if (serverResponse?.code === 200) {
                setJobFiles(null);

            }
        }

    };
    const uploadJobToParent = async () => {
        let apiData = {
            jobFile: jobFiles?.responseData
        }
        let jobUploadData = await uploadCompletedJob(apiData, item?._id)
        toast.success(jobUploadData?.serverResponse?.message);
        dispatch(
            setJobs({
                jobs: null,
                pageNumber: currentPage,
                totalJobCount: currentPage ?? 0,
            })
        );
        dispatch(startLoading());
        let data = await getAllJobs("all", currentPage);
        if (data?.data?.length > 0) {
            dispatch(
                setJobs({
                    jobs: data?.data,
                    pageNumber: data?.pageNumber,
                    totalJobCount: data?.dataCount,
                })
            );
        }
        dispatch(stopLoading());
        setShowUploadedFileModal(false)
    }
    const cancelAction = () => {
        if(jobFiles !==null){
            removeItem()
        }
        
        setShowUploadedFileModal(false)

    }
    return (
        <Modal className="modal order-d-popup fade"
            show={showUploadedFileModal}
            onHide={() => cancelAction()}
            centered>
            <ModalHeader closeButton>
                <div className="assign-modal">Upload Your File Here</div>
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    {jobFiles ? (<table className="table table-responsive">
                        <thead>
                            <tr>
                                <th colSpan="2">File name</th>
                                <th scope="col">Word Count</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="2">
                                    <h6>
                                        <span>
                                            <i className="fas fa-file-alt"></i>
                                        </span>
                                        {jobFiles?.responseData?.name}
                                    </h6>
                                </td>
                                <td>{jobFiles?.wordCount}</td>
                                <td>
                                    <p onClick={removeItem}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>) : (<div className="col">
                        <div className="form-group file-upload">
                            <DragDropUploadButton
                                multiple={false}
                                onChange={uploadJobFiles}
                                btnCss={"upload-f"}
                                btnName={t("quick-solution-sec-upload")}
                            />
                        </div>
                    </div>)}
                    <div className={`upload-job-modal-btns`}>
                        <p className="btn btn-red" onClick={cancelAction}>
                            Cancel
                        </p>
                        <p className="btn" style={{
                            cursor: jobFiles !==null
                              ? ""
                              : "not-allowed",
                            backgroundColor: jobFiles===null && "gray"
                          }} onClick={jobFiles && uploadJobToParent}>
                            Upload File
                        </p>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default FileUploadModal