import { toast } from "react-toastify";
import http from "./http";

export const contactUsApi = async (values) => {
  let contactUsData = await http.post("/contactus", values);
  if (contactUsData?.serverResponse.code === 201) {
    toast.success(contactUsData?.serverResponse.message);
    return contactUsData;
  } else {
    toast.error(contactUsData?.serverResponse.message);
  }
};
