import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'

const ResponsiveStepper = ({ goPrevious, goNext }) => {
    const { stepperArray } = useSelector((state) => state.jobPost)
    return (
        <>
            {window.innerWidth <= 991 && stepperArray && stepperArray.map((item, index) => {
                return (
                    item?.isActive &&
                    <div key={index} className="col-lg-4 job-post-stepper-responsive">
                        {goPrevious ? <FontAwesomeIcon icon={faArrowLeft} onClick={goPrevious} /> : <div></div>}
                        <div>
                            <h6>{item?.name}</h6>
                            <h6>
                                {`${index + 1}/${stepperArray?.length}`}
                            </h6>
                        </div>
                        <FontAwesomeIcon icon={faArrowRight} onClick={goNext} />
                    </div>
                )
            })}
        </>
    )
}

export default ResponsiveStepper