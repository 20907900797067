import React from 'react'

const TranslatorRankingSys = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h4>Translator Ranking System: Encouragement and Consequences</h4>
                                    <p>At Lingvara, we value the contributions of our translators and strive to create a supportive environment that rewards excellence. Our ranking system plays an essential role in determining payout and acknowledging the exceptional work of our translators. It is designed to encourage high performance while addressing any concerns or issues that may arise. Here's how our ranking system works:</p>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec">

                <div className="container">

                    <p><strong>Payout Differentiation</strong></p>
                    <p>There are two ways in which translators may earn more than their peers:</p>
                    <ol type="A">
                        <li>
                            <p><strong>Subject Specialized Translators</strong></p>
                        </li>
                    </ol>
                    <p>We believe in rewarding specialized knowledge and expertise. As a translator at Lingvara, you have the opportunity to indicate your subject specialization. Here's how the payout differentiation works:</p>
                    <ol>
                        <li>
                            <p>Subject-Specialized Translator: Translators designated as subject-specialized receive a 30% increase in payout for projects within their specific domain of expertise when specifically asked for by the client. Examples include Medical translators, Legal translators, Literary translators, etc. This acknowledges the added value they bring to the translation process and their specialized knowledge. Note that Subject-Specialized Translators may also take on jobs ranked as &ldquo;general translator&rdquo; if not certified-specialized translations are asked for. The payout will be determined as per the type of translations asked for (i.e. Subject-Specialized or General).</p>
                        </li>
                        <li>
                            <p>General Translator: Translators who handle projects across multiple domains are classified as general translators and receive the regular payout based on the standard rates for translation services.</p>
                        </li>
                    </ol>
                    <p><br /><br /></p>
                    <ol type="A" start="2">
                        <li>
                            <p><strong>Translator Performance Ranking System</strong></p>
                        </li>
                    </ol>
                    <p>The ranking you achieve as a translator directly affects your payout. We believe in recognizing and rewarding outstanding performance. Here's how the payout ranking system works:</p>
                    <ol>
                        <li>
                            <p><strong>5-Star Ranking</strong>: Achieving a 5-star ranking earns you an additional 10% bonus on top of base payout price.</p>
                        </li>
                        <li>
                            <p><strong>4-Star Ranking</strong>: Attaining a 4-star ranking earns you a 5% extra bonus on top of base payout price.</p>
                        </li>
                        <li>
                            <p><strong>3-Star Ranking and Below</strong>: Translators with a 3-star ranking and below receive the regular payout without any additional bonus.</p>
                        </li>
                    </ol>
                    <p><a name="_GoBack"></a><strong>Determining Factors for Translator Rankings</strong></p>
                    <p>The payout ranking is based on a combination of the average customer star review scores and the performance scores on our website. The performance scores take into account various factors that can raise or decrease your ranking. Here's an overview:</p>
                    <ol>
                        <li>
                            <p><strong>Positive Factors</strong>: Factors that can raise your performance score include the number of successfully completed jobs. Consistently delivering high-quality work and meeting client expectations contribute to a positive performance score.</p>
                        </li>
                        <li>
                            <p><strong>Negative Factors</strong>: The following factors can decrease your performance score significantly: a. Number of times corrections are requested by clients. b. Number of jobs you accepted but later cancelled. c. Number of late job submissions. d. Number of rejections by clients.</p>
                        </li>
                    </ol>
                    <p>Please note that factors b, c, and d are weighed heavily and can have a substantial impact on your performance score.</p>
                    <p><strong>Consequences and Conflict Resolution</strong></p>
                    <p>We understand that misunderstandings or unjustified client reviews can sometimes impact your ranking unfairly. In such cases, we are here to assist you. Our goal is to ensure fairness and address any conflicts that may arise. Here's how we handle different scenarios:</p>
                    <ol>
                        <li>
                            <p><strong>Total Payout Score Less Than 3 Stars</strong>: Translators with a total payout score of less than 3 stars will have their access to the job board declined. They will be asked to resolve the issue with the project manager. The project manager will determine the appropriate manner to address the conflicts, which may include but is not limited to:</p>
                            <ul>
                                <li>
                                    <p>Termination of the account with Lingvara and blacklisting.</p>
                                </li>
                                <li>
                                    <p>Opening an investigation in cases where unjustified false reviews from clients are suspected.</p>
                                </li>
                                <li>
                                    <p>Referring the translator to a quick online course and reinstating access with a grace period.</p>
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>Our aim is to maintain a high standard of quality and professionalism while supporting our translators in their journey with Lingvara. We are committed to ensuring fair rankings and providing opportunities for growth and improvement.</p>
                    <p>Remember, your hard work and dedication contribute to your success as a translator. Keep striving for excellence, delivering outstanding results, and maintaining a positive ranking. We're here to support you along the way.</p>
                </div>

            </section>


        </>
    )
}

export default TranslatorRankingSys