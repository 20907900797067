import React from 'react'
import CallToSignup from '../../../components/common/callToSignup/CallToSignup'
import { NavLink } from 'react-router-dom'

const Affilate = () => {
    return (
        <>

            <section className="home-banner inner-banner affiliate-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/jobs-banner.png" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Join Our Affiliate Marketing Program and Start Earning Today!</h1>
                                    <p>Welcome to Lingvara's Affiliate Marketing Program, where your referrals can turn into substantial earnings. We believe in the power of partnerships and want to reward you for spreading the word about our exceptional translation and proofreading services. Join our program and unlock a world of exciting opportunities to earn while helping others</p>


                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </section>


            <section className="affiliate-cnt padding-bottom padding-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-heading">
                                <h3>Supercharge your income by promoting our premium services and earning up to 5% of the total price with your unique affiliate link!</h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="affiliate-paragraph">
                                <p>Elevate your earnings significantly! Promote our premium services and unlock the potential to earn up to 5% of the total price. It all begins with a simple step – create your user account to obtain your exclusive affiliate link. We make it easy and convenient by sending your well-deserved earnings via Western Union. There's no time to waste; seize this opportunity now and watch your income soar!</p>

                                {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="steps-sec padding-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="step-left-video">
                                <div className="video-playbtn"><a href="#"><img src="/images/play_icon.png" alt="" /></a></div>

                                <div className="video-mask">
                                    <video autoplay="autoplay" muted="muted" playsinline="playsinline" preload="auto" className="video-splash" id="video-desktop">
                                        <source src="videos/banner-video.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="tab-left-content">
                                <div className="section-heading text-start mb-0">
                                    <h3>Our process</h3>
                                    <p>Just follow these simple steps to start earning!</p>
                                </div>
                                <div className="tab-box-wrap">
                                    <div className="tab-number">
                                        <span className="s-number">1</span>
                                    </div>
                                    <div className="tab-icon">
                                        <img src="/images/process_one.png" alt="" />
                                    </div>
                                    <h6>Signup as a user</h6>
                                </div>
                                <div className="tab-box-wrap">
                                    <div className="tab-number">
                                        <span className="s-number">2</span>
                                    </div>
                                    <div className="tab-icon">
                                        <img src="/images/process_one.png" alt="" />
                                    </div>
                                    <h6>Enter your correct details</h6>
                                </div>

                                <div className="tab-box-wrap">
                                    <div className="tab-number">
                                        <span className="s-number">3</span>
                                    </div>
                                    <div className="tab-icon">
                                        <img src="/images/process_two.png" alt="" />
                                    </div>
                                    <h6>Receive your affiliate link</h6>
                                </div>

                                <div className="tab-box-wrap">
                                    <div className="tab-number">
                                        <span className="s-number">4</span>
                                    </div>
                                    <div className="tab-icon">
                                        <img src="/images/process_three.png" alt="" />
                                    </div>
                                    <h6>Promote or share with others</h6>
                                </div>

                                <div className="tab-box-wrap">
                                    <div className="tab-number">
                                        <span className="s-number">5</span>
                                    </div>
                                    <div className="tab-icon">
                                        <img src="/images/process_four.png" alt="" />
                                    </div>
                                    <h6>Get paid when they make an order using your link</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-us-sec padding-bottom">
                <div className="container">
                    <div className="section-heading">
                        <h3>How It Works:</h3>
                        <p><strong>&nbsp;</strong></p>
                        <ol>
                            <li>
                                <p><strong>Create Your Affiliate Account</strong>: Once you create an account, you will find your affilate link on your dashboard. This link is your key to success, allowing you to track referrals and earn commissions on every qualified purchase made through your link.</p>
                            </li>
                            <li>
                                <p><strong>Share Your Affiliate Link</strong>: Start sharing your affiliate link with friends, colleagues, and anyone who may benefit from our top-notch language services. Spread the word through social media, email, or other marketing channels. The more you promote, the greater your earning potential.</p>
                            </li>
                            <li>
                                <p><strong>Earn Generous Commissions</strong>: As an affiliate, you will earn a fantastic 5% commission on every regular website order made using your affiliate link. The more successful referrals you bring in, the more you'll earn.</p>
                            </li>
                            <li>
                                <p><strong>Flexible Earnings and Rewards</strong>: Your hard work will be rewarded through our flexible earnings system. All affiliate earnings will be credited to your wallet on our website. You can use these earnings towards future purchases, ensuring that you can enjoy our services while reaping the financial benefits.</p>
                            </li>
                            <li>
                                <p><strong>Withdraw or Reinvest</strong>: When your earnings reach a minimum of $50 USD, you can choose to withdraw the funds. We believe in giving you the freedom to enjoy your earnings as you see fit. Whether you choose to use them for personal purposes or reinvest them back into your affiliate marketing efforts, the choice is yours.</p>
                            </li>
                        </ol>
                    </div>
                    <div className="section-heading">
                        <h3>Join our Affiliate Marketing Program today and unlock your earning potential.</h3>
                        <p>Start leveraging your network and connections to earn passive income with Lingvara. Our Affiliate Marketing Program provides you with the tools, support, and rewards you need to succeed. Sign up now and embark on an exciting journey of financial growth while helping others access our exceptional translation and proofreading services.</p>
                        <p>Remember, the more you promote, the more you earn. Together, let's make a difference and create a thriving community of affiliates who benefit from our partnership. Join us today and start earning with Lingvara's Affiliate Marketing Program.</p>
                        <p><a name="_GoBack"></a><em>Note: Please review the <strong>
                            <NavLink to='/affilate-terms-conditions' >Affiliate Marketing Program Terms and Conditions</NavLink></strong> for full details on eligibility, commission rates, and guidelines for participation.</em></p>

                    </div>
                    {/* <div className="us-row-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-6" data-aos="fade-right" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/scale.png" alt="" />
                                        <img className="h-img" src="/images/scale1.png" alt="" />

                                    </div>
                                    <h4>Earn extra cash</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                        ut labore et
                                        dolore magna aliqua.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/icon51.png" alt="" />
                                        <img className="h-img" src="/images/icon5.png" alt="" />

                                    </div>
                                    <h4>Get paid via Paypal</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-left" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/value.png" alt="" />
                                        <img className="h-img" src="/images/value1.png" alt="" />

                                    </div>
                                    <h4>Let your link work for you</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                </div>

                            </div>

                        </div>

                    </div> */}


                </div>

            </section>







            <section className="sssp-faqisec-sec padding-bottom">
                <div className="container">
                    <div className="sec-head text-center mb-5">
                        <h3>Frequently asked questions</h3>
                    </div>
                    <div className="accordion faq-wrapper" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    How are affiliate earnings managed, and how can I use them?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        All affiliate earnings are credited to your wallet on our website once a client’s order is completed. You can use these earnings towards future purchases of our services or you can request to withdraw them as cash.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    When can I withdraw my earnings, and how is it done?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        You can withdraw your earnings once they reach a minimum of $50 USD. Withdrawals are processed via Western Union or similar routes, giving you flexibility in how you receive your earnings. We believe in giving you the freedom to use your earnings as you see fit, whether for personal purposes or reinvesting them in your affiliate marketing efforts.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    How often are affiliate commissions paid out?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        Affiliate commissions are typically paid out on a monthly basis. Once your earnings reach the minimum withdrawal threshold of $50 USD, you can request a payout through Western Union or similar routes, ensuring you receive your earnings promptly.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Are there any marketing materials or resources available to help me promote Lingvara's services?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        Yes, we offer a range of marketing materials and resources to assist you in promoting Lingvara's services effectively. Contact us to learn more.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Can I promote Lingvara's services internationally as an affiliate?

                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        Absolutely! Our Affiliate Marketing Program is open to international affiliates. You can promote Lingvara's services globally and earn commissions on qualified purchases from around the world.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    Are there any restrictions or guidelines I should be aware of as an affiliate?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        Yes. Please review our <NavLink to='/affilate-terms-conditions' >Affiliate Marketing Program Terms and Conditions</NavLink> for full details on eligibility, commission rates, and guidelines for participation.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Can you provide an example of how much I could earn as an affiliate with a specific scenario?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        Certainly! Let's consider a scenario where you, as an affiliate, secure an agreement with a publishing house for one year of translation services offered by Lingvara. In this scenario, the publishing house sends one 100-page book per week to be translated by us at a rate of $20 USD per page.Here's the breakdown of potential earnings:

                                        Each book translation: 100 pages x $20 per page = $2,000 USD
                                        Weekly translations for one year: 52 weeks x $2,000 = $104,000 USD
                                        As an affiliate earning a 5% commission on each regular website order, your potential earnings for this scenario would be:

                                        Affiliate commission per book translation: 5% of $2,000 = $100 USD
                                        Weekly commission for one year: 52 weeks x $100 = $5,200 USD
                                        So, by securing this agreement with the publishing house, you could earn a substantial $5,200 USD in commissions for the year. Please note that this is just an example, and your actual earnings may vary depending on the agreements you secure and the referrals you bring in.


                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section className="subscribe_newsletter_row  padding-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="subscribe-newsletter">
                                <div className="section-heading text-center">
                                    <h3>Signup for affiliate program</h3>
                                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                                </div>
                                <form action="">
                                    <div className="formgrp">
                                        <input type="text" placeholder="Enter email" />
                                        <input type="submit" className="subscribe-btn" value="Sign Up" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <CallToSignup />

        </>
    )
}

export default Affilate