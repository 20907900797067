import * as Yup from "yup";

export const splitProfJobValidatior = (selectedJobDetails, index, type) => {
  if (type === "initialValues") {
    let initialValues = {
      jobTitle: `${selectedJobDetails?.jobTitle}${index + 1}` ?? "",
      // jobDescription: `${selectedJobDetails?.jobDescription}${index + 1}` ?? "",
      jobDocumentType: selectedJobDetails?.jobDocumentType ?? "",
      totalJobPrice: selectedJobDetails?.totalJobPrice ?? "",
      perWordPrice: selectedJobDetails?.perWordPrice ?? 0,
      specificService: "proofreading",
      jobId: selectedJobDetails?._id ?? "",
      wordCount: 0,
      deadline: "24 hr",
      jobFile: null,
      config: {
        proofReadingService: selectedJobDetails?.specificService ?? "",
        intendedStyle: selectedJobDetails?.intendedStyle ?? "",
        comment: selectedJobDetails?.comment ?? "",
        citationStyle: selectedJobDetails?.citationStyle ?? "",
        lingVaraWaterMark: selectedJobDetails?.lingVaraWaterMark,
        requiredEnglishDialect:
          selectedJobDetails?.requiredEnglishDialect ?? "",
      },
    };
    return initialValues;
  }
  if (type === "validationSchema") {
    let validationSchema = Yup.object().shape({
      jobTitle: Yup.string().required("Name field cannot be empty"),
      addComments: Yup.string().required("Comment field cannot be empty"),
    });
    return validationSchema;
  }
};
const phoneRegExpEditprofile = "^[0-9]{10}$";
const countryCodeRegExp = /^(\+?\d{1,3}|\d{1,4})$/;

export const editProfileValidatorManagerProofreader = {
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(20, "Max can be 20 characters"),
    // collegeName: Yup.string().required("Please Select option"),
    country: Yup.string().required("Please Select option"),
    // timezone: Yup.string().required("Please Select option"),
    phoneNumber: Yup.string()
      .test(
        "is-valid-phone-number",
        "Phone number is not valid",
        (value, { parent }) => {
          if (parent.countryCode && parent.countryCode.length === 3) {
            return /^\d{10}$/.test(value);
          } else if (parent.countryCode && parent.countryCode.length === 4) {
            return /^\d{9}$/.test(value);
          }
          return true; // Allow empty value if countryCode is not set
        }
      )
      .required("Phone number is required"),

    // schoolName: Yup.string()
    //     .required("School name can not be empty")
    //     .min(10, "Must be 10 characters")
    //     .max(20, "Max can be 20 characters"),
    // fieldOfStudy: Yup.string().required("Please Select option"),
    // levelOfStudy: Yup.string().required("Please Select option"),
    // expectedGraduationDate: Yup.string()
    //     .required("Graduation date can not be empty")
    // .min(3, "Must be 3 characters")
    // .max(8, "Max can be 8 characters"),
  }),
};

//change password
export const changePassFormValidatorProofreaderManager = {
  initialValues: {
    newPassword: "",
    confrmPassword: "",
  },
  validationSchema: Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Must be 8 or more than 8 characters")
      .required("Password field is Required")
      .matches(/\w/, "Please enter valid password"),
    confrmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), ""], "Password must match")
      .required("Confirm password field is required")
      .matches(/\w/, "Please enter valid password"),
  }),
};
