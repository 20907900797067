import * as Yup from "yup";

/**
 * Dowonload certificate form validator
 */
export const downloadCertificateFromValidator = {
  validationSchema: Yup.object().shape({
    pincode: Yup.string()
      .required("PIN code field cannot be empty"),
  }),
};
