import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getSingleApiForServiceProvider, updateRating } from '../../../services/api/profileApi'
import { useDispatch } from 'react-redux'
import { setAssignUserList } from '../../../redux/reducers/jobSlice'

const ChangeRatingModal = ({ ratingModal, setRatingModal, selectedItem, getUserListData }) => {
    const dispatch = useDispatch()
    const [totalCompletedJobs, setTotalCompletedJobs] = useState(0)
    const [totalCorrectionReqJobs, setTotalCorrectionReqJobs] = useState(0)
    const [totalLateSubmissionJobs, setTotalLateSubmissionJobs] = useState(0)
    const [takenButCancelledJobs, setTakenButCancelledJobs] = useState(0)
    const [totalRejectedJobs, setTotalRejectedJobs] = useState(0)

    const changeRating = (type) => {
        if (type === "totalCompletedJobs") {
            callTotalCompleted(totalCompletedJobs, type)
        }
        if (type === "totalCorrectionReqJobs") {
            callTotalCompleted(totalCorrectionReqJobs, type)
        }
        if (type === "totalLateSubmissionJobs") {
            callTotalCompleted(totalLateSubmissionJobs, type)
        }
        if (type === "takenButCancelledJobs") {
            callTotalCompleted(takenButCancelledJobs, type)
        }
        if (type === "totalRejectedJobs") {
            callTotalCompleted(totalRejectedJobs, type)
        }

    }
    const callTotalCompleted = async (val, type) => {
        console.log(typeof (val), "vallldl")
        if (val === "") {
            toast.error('cant be empty')
        }
        else {

            let value = {
                userId: selectedItem?._id,
                value: Number(val)

            }
            let updateRankData = await updateRating(value, type)
            if (updateRankData?.serverResponse?.code === 201) {
                dispatch(setAssignUserList({ users: null }));
                getUserListData()
                toast.success("Rank Updated Successfully")
                setRatingModal(false)
            }


        }
    }
    useEffect(() => {
        getSingleApi()
    }, [])
    const getSingleApi = async () => {
        const comingData = await getSingleApiForServiceProvider(selectedItem?._id)
        setTotalCompletedJobs(comingData?.result?.data?.completedJob ?? 0)
        setTakenButCancelledJobs(comingData?.result?.data?.jobsTakenThenCancelled ?? 0)
        setTotalCorrectionReqJobs(comingData?.result?.data?.correctionAskedByClient ?? 0)
        setTotalLateSubmissionJobs(comingData?.result?.data?.lateSubmisstion ?? 0)
        setTotalRejectedJobs(comingData?.result?.data?.rejectedByClient ?? 0)
        return (() => {
            setTotalCompletedJobs("")
            setTakenButCancelledJobs("")
            setTotalCorrectionReqJobs("")
            setTotalLateSubmissionJobs("")
            setTotalRejectedJobs("")
        })
    }
    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={ratingModal}
            onHide={() => setRatingModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Change Ratings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Total Completed Jobs</label>
                        <input
                            type="number"
                            placeholder=""
                            className="form-control"
                            id='totalCompletedJobs'
                            name='totalCompletedJobs'
                            value={totalCompletedJobs}
                            onChange={(e) => setTotalCompletedJobs((e.target.value >= 0 && e.target.value !== "") ? e.target.value : 0)}
                        />
                        <div><Button onClick={() => changeRating("totalCompletedJobs")}>Submit</Button></div>

                    </div>

                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Total Correction Request Jobs</label>
                        <input
                            type="number"
                            placeholder=""
                            className="form-control"
                            id='totalCorrectionReqJobs'
                            name='totalCorrectionReqJobs'
                            value={totalCorrectionReqJobs}
                            onChange={(e) => setTotalCorrectionReqJobs((e.target.value >= 0 && e.target.value !== "") ? e.target.value : 0)}
                        />
                        <div><Button onClick={() => changeRating("totalCorrectionReqJobs")}>Submit</Button></div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Total Taken But Cancelled Jobs</label>
                        <input
                            type="number"
                            placeholder=""
                            className="form-control"
                            id='takenButCancelledJobs'
                            name='takenButCancelledJobs'
                            value={takenButCancelledJobs}
                            onChange={(e) => setTakenButCancelledJobs((e.target.value >= 0 && e.target.value !== "") ? e.target.value : 0)}
                        />
                        <div><Button onClick={() => changeRating("takenButCancelledJobs")}>Submit</Button></div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Total Rejected Jobs</label>
                        <input
                            type="number"
                            placeholder=""
                            className="form-control"
                            id='totalRejectedJobs'
                            name='totalRejectedJobs'
                            value={totalRejectedJobs}
                            onChange={(e) => setTotalRejectedJobs((e.target.value >= 0 && e.target.value !== "") ? e.target.value : 0)}
                        />
                        <div><Button onClick={() => changeRating("totalRejectedJobs")}>Submit</Button></div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Total Late Submission Jobs</label>
                        <input
                            type="number"
                            placeholder=""
                            className="form-control"
                            id='totalLateSubmissionJobs'
                            name='totalLateSubmissionJobs'
                            value={totalLateSubmissionJobs}
                            onChange={(e) => setTotalLateSubmissionJobs((e.target.value >= 0 && e.target.value !== "") ? e.target.value : 0)}
                        />
                        <div><Button onClick={() => changeRating("totalLateSubmissionJobs")}>Submit</Button></div>

                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}

export default ChangeRatingModal