import React from 'react'
import CallToSignup from '../../../components/common/callToSignup/CallToSignup'

const Subscription = () => {
    // These are static properties
    // TODO: Need to remove these once we have data from backend
    const statiscPlans = [1, 2, 3]
    return (
        <div>
            {/* banner section */}
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/Subscribe.jpg" alt="" />
                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div
                                    className="banner-text-wrap"
                                    data-aos="fade-up"
                                    data-aos-duration={3000}
                                >
                                    <h1>Subscribe</h1>
                                    <p>
                                        Et assumenda voluptates ex explicabo voluptatem non earum dolor
                                        ea velit consectetur At reiciendis aliquam est dolores tempora.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* banner end */}
            <section className="sub-text-sec padding-bottom padding-top">
                <div className="container">
                    <h2>
                        Are you a business or a publishing house and have large amounts of
                        constant documents to translate?
                    </h2>
                    <p>
                        If yes, you can choose one of the plans below and save. After you
                        confirm a plan, all translations you ask for will be paid for with your
                        purchased points at a discount.
                    </p>
                    <p>
                        <strong>
                            Note: Each word is with 1 point deducted from their total number of
                            points they have left.
                        </strong>
                    </p>
                    <div className="row">
                        {statiscPlans.map((val,index) => <div key={index} className="col-lg-4">
                            <div className="Pricing-col-wrap sub-price-col">
                                <p>Plan A</p>
                                <div className="d-flex p-flex">
                                    <div className="p-h2">
                                        <h2>.057</h2>
                                        <p>word – translation</p>
                                    </div>
                                    <div className="p-h2">
                                        <h2>.057</h2>
                                        <p>word – translation</p>
                                    </div>
                                </div>
                                <ul>
                                    <li> 5% Discount</li>
                                    <li>125,000 Words (500 Pages)</li>
                                    <li>~ 3-5 Academic Theses OR ~ 16 Journal Articles</li>
                                </ul>
                                <a href="#" className="btn btn-blue">
                                    Buy Now
                                </a>
                            </div>
                        </div>)}
                    </div>
                </div>
            </section>
            <section className="more-custom padding-bottom">
                <div className="container">
                    <div className="more-custom-heading">
                        <h2>Would you like a more custom plan?</h2>
                        <p>
                            Perhaps you would like unlimited word count and the ability to pay at
                            the end of each month. If yes,<a href="#"> contact us</a> to sign a
                            formal agreement.
                        </p>
                    </div>
                </div>
            </section>
            <CallToSignup />

        </div>
    )
}

export default Subscription