import React, { useEffect, useState } from 'react'
import CustomerReview from '../Home/CustomerReview'
import CallToSignup from '../../../components/common/callToSignup/CallToSignup'
import { getAllLanguages } from '../../../services/api/languagesApis'
import { NavLink, useNavigate } from 'react-router-dom'
import DragDropUploadButton from '../../../components/common/DragDropUploadButton'
import { useDispatch, useSelector } from 'react-redux'
import { updateBlobFile } from '../../../redux/reducers/jobPostSlice'
import OwlCarousel from 'react-owl-carousel';
import BusinessSolutionSec from '../Home/BusinessSolutionSec'

const Translation = () => {
    const [languages, setLanguages] = useState([])
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        (async () => {
            let languageJson = await getAllLanguages()
            setLanguages(languageJson.data)
        })()
    }, [])
    const uploadJobFiles = (data) => {
        dispatch(updateBlobFile({ blobFile: data }))
        if (user) {
            navigate('/job-post/upload-file')
        }
        else {
            navigate('/job-post')
        }
    }
    const options = {
        items: 3,
        dots: false,
        loop: true,
        nav: true,
        margin: 10,
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true
            },
            600: {
                items: 2

            },
            1200: {
                items: 3
            }
        }
    }
    const staticCarouselData = [{ headings: "Healthcare & Medicine", subHeadings1: "Medical document translations", subHeadings2: "Patient record proofreading", subHeadings3: "Medical journal article translations", subHeadings4: "Pharmaceutical product description translations" },
    { headings: "Law Firms", subHeadings1: "Contract translations", subHeadings2: "Legal document proofreading", subHeadings3: "Court transcript translations", subHeadings4: "Witness statement translations" },
    { headings: "Technology Sector", subHeadings1: "Software localization", subHeadings2: "Technical manual translations", subHeadings3: "Product description translations", subHeadings4: "User interface translations" },
    { headings: "Education & Academia", subHeadings1: "Academic paper translations", subHeadings2: "Dissertation proofreading", subHeadings3: "Textbook translations", subHeadings4: "Course material translations" },
    { headings: "Tourism & Hospitality", subHeadings1: "Website localization", subHeadings2: "Brochure translations", subHeadings3: "Menu translations", subHeadings4: "Guest communication translations" },
    { headings: "Finance & Banking", subHeadings1: "Financial statement translations", subHeadings2: "Investment portfolio translations", subHeadings4: "Market research report translations" }]
    return (
        <>
            {/* <!-- banner section --> */}
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/russian-english.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Translation</h1>
                                    <p>Unlock a world of precise and fluent communication across languages with Lingvara's top-tier translation services.</p>


                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </section>
            {/* <!-- banner end --> */}



            <section className="why-us-sec padding-bottom padding-top">
                <div className="container">
                    <div className="section-heading">
                        <h3>Why choose Lingvara?</h3>
                        <p>We pride ourselves in securing document confidentiality, adhering to strict quality assurance measures, and collaborating with our clients throughout the process.</p>
                    </div>
                    <div className="us-row-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-6" data-aos="fade-right" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/translate.png" alt="" />
                                        <img className="h-img" src="/images/translate1.png" alt="" />

                                    </div>
                                    <h4>Confidentiality</h4>
                                    <p>We prioritize the confidentiality of your documents and adhere to strict security measures to protect your sensitive information.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/quality1.png" alt="" />
                                        <img className="h-img" src="/images/quality.png" alt="" />

                                    </div>
                                    <h4>Satisfaction Guarantee</h4>
                                    <p>We are committed to delivering translations that meet or exceed your expectations, backed by our <NavLink to='/money-back-guarantee'>money-back guarantee.</NavLink></p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-left" data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="us-col">
                                    <div className="us-i">
                                        <img className="n-img" src="/images/project.png" alt="" />
                                        <img className="h-img" src="/images/project1.png" alt="" />

                                    </div>
                                    <h4>Authenticity Assurance</h4>
                                    <p>When you upload your document for translation, be sure to request our translation certificate, equipped with an exclusive QR code for authentication, offering peace of mind and credibility not found with any other agency, both for you and anyone needing to verify your translated documents.</p>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- why us end --> */}

            {/* <section className="translate-section">
                <div className="container">
                    <div className="section-heading">
                        <h3>Languages we translate</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                            et dolore
                            magna aliqua. Ut enim ad minim veniam,</p>
                    </div>

                    <div className="translate-ul">
                        <ul>
                            {languages && languages.length > 0 && languages.map((item) =>
                                <li><a href="#">{item?.languageName}</a></li>

                            )}
                        </ul>

                    </div>

                </div>

            </section> */}

            <section className="Services-Include">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="Services-Include-left">
                                <img src="/images/images.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="Services-Include-right">
                                <h3>What Our Translation Services Include</h3>
                                <p>At Lingvara, our translation service is meticulously crafted to enhance the accuracy and fluency of your written content, ensuring it effectively communicates across languages and cultures. Here are some insights into what our translation service encompasses:</p>

                                <ul>
                                    <li><strong>Accurate Translation</strong>: Our team of expert linguists ensures precise and contextually accurate translations that capture the nuances of your content.</li>
                                    <li><strong>Certified Translation</strong>: We provide certified translations for official documents, ensuring legal and official recognition.</li>
                                    <li><strong>Revisions and Feedback</strong>: We welcome your input and offer revisions to ensure your complete satisfaction with the final translation.
                                    </li>
                                    <li><strong>Cultural Sensitivity</strong>: We consider cultural nuances and adapt content accordingly to ensure it resonates with the target audience.</li>
                                    <li><strong>Customer Support</strong>: Our dedicated support team is available to assist you throughout the translation process, answering questions and providing guidance.
                                    </li>
                                    <li><strong>Confidentiality</strong>: Your content's privacy and security are of utmost importance; we maintain strict confidentiality standards.
                                    </li>
                                    <li><strong>Quality Assurance</strong>:  Before delivering the proofread document to you, we conduct a final quality check to ensure it meets our high standards of excellence.</li>

                                </ul>
                                {/* <Link to="/" className="btn">Upload </Link> */}
                                <DragDropUploadButton onChange={uploadJobFiles} btnCss={"upload-f"} multiple={false} btnName={"Upload Document"} />

                            </div>
                        </div>

                    </div>

                </div>

            </section>

            {/* <CustomerReview /> */}
            {/* <section className="proofreading-services padding-bottom padding-top">
                <div className="container">

                    <div className="section-heading">
                        {/* <h3><span className="g-bg">Industries</span> where we provide proofreading <span className="g-bg">services</span>
                        </h3> */}
            {/* <h4>Industries we have served</h4> */}
            {/* </div> */}

            {/* <div className="proofreading-slider-wrap"> */}
            {/* <OwlCarousel className='proofreading-slider owl-carousel owl-theme' items={3} dots={false} loop margin={10} nav> */}

            {/* <OwlCarousel className='owl-carousel owl-theme proofreading-slider' {...options}> */}
            {/* {staticSlider?.map((item, index) =>
                                <div key={index} className="item proofreading-slider-item">
                                    <div className="proofreading-slider-img-wrap">
                                        <img src={item?.src} alt="" />
                                    </div>
                                    <div className="proofreading-slider-content" data-aos="fade-up" data-aos-offset="300">
                                        <h4>{item?.title}</h4>
                                    </div>
                                </div>
                            )} */}
            {/* {staticCarouselData?.map((val, index) => <div key={index} className="item soultion-b-item"> */}
            {/* <h5>Ecommerce</h5>
                                <img src="/images/img6.png" alt="" />
                                <h6>{val?.headings}</h6>
                                <ul>
                                    <li>{val?.subHeadings1}</li>
                                    <li>{val?.subHeadings2}</li>
                                    <li>{val?.subHeadings3}</li>
                                    <li>{val?.subHeadings4}</li>

                                </ul> */}
            {/* </div>)} */}

            {/* </OwlCarousel>

                    </div>

                </div> */}

            {/* // </section> */}
            <BusinessSolutionSec />

            {/* <!-- customer end --> */}
            <CallToSignup />

        </>
    )
}

export default Translation