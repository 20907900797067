import React from 'react'
import UserSwitch from '../../../components/common/UserSwitch'
import { NavLink } from 'react-router-dom'

export const ProcessStepBar = ({ slectedUserRole, setSelectedtUserRole }) => {
  return (
    <>

      <section className="steps-sec padding-bottom padding-top">
        <div className="container">
          <div className="section-heading text-center">
            <h3>How it works</h3>
          </div>
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item" onClick={() => setSelectedtUserRole(1)}>
              <a className={`nav-link  ${slectedUserRole === 1 && "active"} `} data-bs-toggle="tab" href="#home">
                Translator
              </a>
            </li>
            <li className="nav-item" onClick={() => setSelectedtUserRole(2)}>
              <a className={`nav-link  ${slectedUserRole === 2 && "active"} `} data-bs-toggle="tab" href="#menu1">
                Proofreader
              </a>
            </li>
          </ul>


          <div className="tab-content">
            <div id="home" className={`container tab-pane ${slectedUserRole === 1 && "active"} `}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="step-left-video">
                    <div className="video-playbtn">
                      <a href="#">
                        <img src="/images/play_icon.png" alt="" />
                      </a>
                    </div>

                    <div className="video-mask">
                      <video
                        autoPlay="autoplay"
                        muted="muted"
                        playsInline="playsinline"
                        preload="auto"
                        className="video-splash"
                        id="video-desktop"
                      >
                        <source src="videos/banner-video.mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="tab-left-content">
                    <div className="section-heading text-start mb-0">
                      <h3>The Process</h3>
                      {/* <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                        do eiusmod tempor incididunt ut labore et.
                      </p> */}
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">1</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_one.png" alt="" />
                      </div>
                      <h6>Signup</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">2</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_one.png" alt="" />
                      </div>
                      <h6>Upload your qualifications</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">3</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_two.png" alt="" />
                      </div>
                      <h6>Access the Job board</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">4</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_three.png" alt="" />
                      </div>
                      <h6>Pick a job</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">5</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_four.png" alt="" />
                      </div>
                      <h6>Start working</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">6</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_four.png" alt="" />
                      </div>
                      <h6>Get credited your pay when client approves the order</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">7</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_four.png" alt="" />
                      </div>
                      <h6>See your updated rank after each job.
                        <NavLink to='/translator-ranking-system'>
                          {/* <NavLink to='/'> */}

                          Learn more about ranks</NavLink>
                      </h6>
                    </div>
                    <div className="step-sec-btn">
                      <NavLink to='/signup?usertype=1' className="btn">
                        Sign up as translator
                      </NavLink>
                      <NavLink to='/signup?usertype=2' className="btn btn-blue-solid">
                        Sign up as proofreader
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="menu1" className={`container tab-pane ${slectedUserRole === 2 && "active"} `}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="step-left-video">
                    <div className="video-playbtn">
                      <a href="#">
                        <img src="/images/play_icon.png" alt="" />
                      </a>
                    </div>

                    <div className="video-mask">
                      <video
                        autoPlay="autoplay"
                        muted="muted"
                        playsInline="playsinline"
                        preload="auto"
                        className="video-splash"
                        id="video-desktop"
                      >
                        <source src="videos/banner-video.mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="tab-left-content">
                    <div className="section-heading text-start mb-0">
                      <h3>The Process</h3>
                      {/* <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                        do eiusmod tempor incididunt ut labore et.
                      </p> */}
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">1</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_one.png" alt="" />
                      </div>
                      <h6>Signup</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">2</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_one.png" alt="" />
                      </div>
                      <h6>Upload your qualifications</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">3</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_two.png" alt="" />
                      </div>
                      <h6>Access the Job board</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">4</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_three.png" alt="" />
                      </div>
                      <h6>Pick a job</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">5</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_four.png" alt="" />
                      </div>
                      <h6>Start working</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">6</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_four.png" alt="" />
                      </div>
                      <h6>Get credited your pay when client approves the order</h6>
                    </div>
                    <div className="tab-box-wrap">
                      <div className="tab-number">
                        <span className="s-number">7</span>
                      </div>
                      <div className="tab-icon">
                        <img src="/images/process_four.png" alt="" />
                      </div>
                      <h6>See your updated rank after each job.
                        <NavLink to='/proofreader-ranking-system'>
                          {/* <NavLink to='/'> */}

                          Learn more about ranks</NavLink>
                      </h6>
                    </div>
                    <div className="step-sec-btn">
                      <NavLink to='/signup?usertype=1' className="btn">
                        Sign up as translator
                      </NavLink>
                      <NavLink to='/signup?usertype=2' className="btn btn-blue-solid">
                        Sign up as proofreader
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>

  )
}
export default ProcessStepBar
