import React from 'react'
import ContactForm from './ContactForm'

const ContactUs = () => {
    return (
        <>
            {/* <!-- banner section --> */}
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/contact-us.jpg" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <div className="banner-text-wrap" data-aos="fade-up" data-aos-duration="3000">
                                    <h1>Contact us</h1>
                                    <p>Speak the world's language. You Need a translation? We've got you covered. Reach out now!</p>


                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </section>
            {/* <!-- banner end --> */}

            <section className="contact-sec-wrap padding-bottom padding-top">

                <div className="container">
                    <h3>Feel Free to Get in Touch With Us</h3>
                    <p>At Lingvara, we understand that communication is the heart of connection. With a team of seasoned linguists from around the globe, we're dedicated to ensuring your message is heard, no matter the language. From business documents to literary pieces, our translation services are tailored to meet your unique needs. Dive into a world where boundaries fade and opportunities arise. Let's embark on this linguistic journey together. Get in touch today, and let's speak the world's language.</p>

                    <ContactForm />

                </div>

            </section>
        </>
    )
}

export default ContactUs