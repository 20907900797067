import React, { useEffect, useState } from "react";
import { getAllNotifications } from "../../../services/api/profileApi";
import { timeAgoFunc } from "../../../utils/timeAgoFunc";
import { useNavigate } from "react-router-dom";
import NoContentAvailable from "../NoContentAvailable";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loadingSlice";
import { updateNotification } from "../../../redux/reducers/authSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  chatAccess,
  checkChatAvailability,
} from "../../../services/api/chatRoomApis";
import { setActiveroom } from "../../../redux/reducers/chatRoomSlice";
import { getChatRoomMessage, sendMessage } from "../../../services/socket";

const Notification = ({ role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifications, currentPageNotification, user } = useSelector(
    (state) => state.auth
  );
  const { activeRoom, messages, typingData } = useSelector(
    (state) => state.chatRoom
  );
  //   const [userGetPage, setUserGetPage] = useState(0);

  const getNotification = async (page) => {
    dispatch(startLoading());
    let notifis = await getAllNotifications({
      dataPerPage: 10,
      pageNumber: page ? page : 1,
    });
    if (notifis?.result?.data) {
      dispatch(
        updateNotification({
          notifications: notifis?.result?.data,
          pageNumber: notifis?.result?.pageNumber,
        })
      );
    }
    dispatch(stopLoading());
  };
  useEffect(() => {
    getNotification();
  }, []);
  const gotoJob = (id) => {
    navigate(`/${role}-manager-my-jobs/details`, { state: { id: id } });
  };
  const loadMoreData = async (page) => {
    getNotification(page);
  };
  const gotoToChat = async (data) => {
    let chatAvailabilityCheck = await checkChatAvailability(
      data?.senderId?._id
    );
    if (chatAvailabilityCheck) {
      let accessData = await chatAccess(data?.senderId?._id);
      if (accessData?.serverResponse?.code === 200) {
        dispatch(setActiveroom({ activeRoom: accessData?.result[0] })); // will put this to socket event when roomid and userid will be send in an object
        getChatRoomMessage({
          userId: user?._id,
          chatRoomId: accessData?.result[0]?._id,
          pageNumber: 1,
          dataPerPage: 10,
        });
        let payload = {
          messageText: "“Hello, I am interested in finding out other ways that I can upgrade my rank.” ",
          senderId: user?._id,
          receiverId: activeRoom?.members?.find((usr) => usr?._id !== user?._id)
            ?._id,
          chatRoomId: activeRoom?._id,
        };

        sendMessage(payload);
      }
      navigate(`/${role}-manager-chat`);
    }
  };
  return (
    <section className="pm-notification-sec padding-bottom padding-top">
      <div className="container">
        <div className="notification-wrap">
          <div className="headin-flex-n">
            <h4>Notifications</h4>
            {/* <a href="#">Mark all as read</a> */}
          </div>
          <div className="notification-box ">
            {notifications && (
              <InfiniteScroll
                dataLength={notifications.length} //This is important field to render the next data
                next={() => loadMoreData(currentPageNotification + 1)}
                hasMore={true}
                height={500}
              >
                {notifications?.length ? (
                  notifications.map((item) => {
                    return (
                      <div
                        className="notifi-single"
                        onClick={() => {
                          item?.notificationType !== "chat" &&
                            gotoJob(item?.jobId);
                        }}
                      >
                        <div className="notifi-flex">
                          <h5>
                            {" "}
                            From {item?.senderId?.name}{" "}
                            <span className="notification-mail">
                              ({item?.senderId?.email})
                            </span>
                          </h5>
                          {item?.notificationType === "chat" && (
                            <div
                              className={`chat-btn-notifiy`}
                              onClick={() => gotoToChat(item)}
                            >
                              Discuss In Chat
                            </div>
                          )}
                          <div className="notifi-time">
                            {timeAgoFunc(item?.createdAt)}
                          </div>
                        </div>
                        <p>{item?.notificationMessage}</p>
                        {/* <div className="blue-dot"></div> */}
                      </div>
                    );
                  })
                ) : (
                  <NoContentAvailable title={"No Notifications available"} />
                )}
              </InfiniteScroll>
            )}
          </div>
          {/* <div className="more-notification text-center">
                        <a href="#" className="more-notification-a">
                            Load more
                        </a>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default Notification;
