import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import ChangeRatingModal from '../../../components/common/changeRatingModal/ChangeRatingModal';

const SingleCardProofreader = ({ item, getUserListData }) => {
    const [ratingModal, setRatingModal] = useState(false)
    const getDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);

        return `${day}/${month}/${year}`;
    }
    return (
        <div className="list-item" data-id={19}>
            <div className='avatar'>
                <a href="#" data-abc="true">
                    <span className="w-48 avatar gd-warning">{item?.name[0]?.toUpperCase()}</span>
                </a>
            </div>
            <div className="flex">
                <a href="#" className="item-author text-color" data-abc="true">
                    {item?.name}
                </a>
            </div>
            <div className="no-wrap">
                <div className="item-date text-muted text-sm d-none d-md-block">
                    {getDate(item?.createdAt)}
                </div>
            </div>
            <div className="no-wrap rating-star-section">
                <div className="item-date text-muted text-sm d-none d-md-block">
                    {item?.averageRating} Star
                </div>
                <div onClick={() => setRatingModal(true)}>
                    <FontAwesomeIcon icon={faEdit} />
                </div>
            </div>
            <ChangeRatingModal ratingModal={ratingModal} setRatingModal={setRatingModal} selectedItem={item} getUserListData={getUserListData} />
        </div>
    )
}

export default SingleCardProofreader