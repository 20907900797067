import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../services/api/http";

const initialState = {
  users: null,
  companyUser: [],
  myUsers: null,
  currentPage: 1,
  totalUsersCount: 0,
  pageNumber: 1,
  dataPerPage: 10,
};


export const createACompany = createAsyncThunk(
  "company/create",
  async (values, { rejectWithValue }) => {
    try {
      console.log('values Create Company', values);
      const response = await http.post(`/companies/guest/create`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createACompanyUser = createAsyncThunk(
  "/api/company-users/create",
  async (values, { rejectWithValue }) => {
    try {
      const response = await http.post(`/company-users/create`, values);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyUserSlice = createSlice({
  name: "companyUser",
  initialState,
  reducers: {
    setUsers: (state, actions) => {
      const { users, pageNumber, totalJobCount } = actions.payload;
      if (state.myUsers?.length > 0 && users) {
        state.myUsers = [...state.myUsers, ...users];
      } else {
        state.myUsers = users;
      }
      state.currentPage = pageNumber;
      state.totalJobCount = totalJobCount;
    },
    updateCompany: (state, action) => {
      const { companyUser } = action.payload;
      state.companyUser = companyUser;
    },
    clearUsers: (state, actions) => {
      state.users = null;
      state.myUsers = null;
      state.currentPage = 1;
      state.totalUsersCount = 0;
      state.pageNumber = 1;
      state.dataPerPage = 10;
    },
  },
  extraReducers: {

  },
});
// this is for dispatch
export const {
  setUsers,
  updateCompany,
  clearUsers
} = companyUserSlice.actions;

export default companyUserSlice.reducer;
