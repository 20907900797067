import { faBars, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import useOutsideObserver from "../../../utils/outsideObserver/outsideObserver";
import { navigateTo } from "../../../services/appNavigator";
import {
  navigateToEditProfile,
  userLogout,
} from "../../../services/api/authApis";

const AuthHeader = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const openMenu = () => {
    setShowMenu((prev) => !prev);
  };
  const closeMenu = () => {
    setShowMenu((prev) => !prev);
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const moreActionRef = useRef(null);
  const moreActionRef2 = useRef(null);
  const hideMoreAction = () => setToggle(false);
  const hideMoreAction2 = () => setToggle2(false);
  useOutsideObserver(moreActionRef, hideMoreAction);
  useOutsideObserver(moreActionRef2, hideMoreAction2);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const goToJobs = () => {
    navigate("/job-list");
    closeMenu()
  };
  return (
    <header
      className={`${scrollPosition > 0 && "fixed"} main-header header-border`}
    >
      <div className="container">
        <div className="header-row">
          <div className="logo">
            <NavLink to="/">
              <img src="/images/logo.png" alt="" />
            </NavLink>
          </div>
          <div className="hdr-rt">
            <div
              className="main-menu"
              style={{ transform: showMenu && `translateX(0)` }}
            >
              <div className="nav_close" onClick={closeMenu}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
              <div className="menu-list-wrap">
                <div className="menu-list">
                  <ul>
                    <li onClick={closeMenu}>
                      <NavLink to="/translation">Translation</NavLink>
                    </li>
                    <li onClick={closeMenu}>
                      <NavLink to="/proofReading">Proofreading</NavLink>
                    </li>
                    <li onClick={closeMenu}>
                      <NavLink to="/pricing">Pricing</NavLink>
                    </li>
                    {user?.role === ('translator' || 'proofreader') && <li onClick={closeMenu}>
                    <NavLink to={(user?.role === 'translator' && user?.isQualificationVerified) ? '/translator-jobs' : (user?.role === 'proofreader' && user?.isQualificationVerified) ? '/proofreader-jobs' : `/${user?.role}-dashboard`}>Jobs</NavLink>
                    </li>}
                    {/* <li>
                      <NavLink to="/subscription">Subscription plans</NavLink>
                    </li> */}
                    <li>
                      <NavLink to="/contact-us">Contact</NavLink>
                    </li>
                    <li onClick={closeMenu}>
                      <NavLink to="/affilate">Affiliate marketing</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="menu-link">
                  <ul>
                    {/* <li onClick={closeMenu}>
                      <a href="#">
                        <span>
                          <img src="/images/language.png" alt="" />
                        </span>{" "}
                        English
                      </a>
                    </li> */}
                    <li>
                      {user ?
                        (<div className="filter-box filter-box-d" ref={moreActionRef}>
                          <div className={`select-menu ${toggle && "active"} `}>
                            <div onClick={() => setToggle((prev) => !prev)} className="select-btn" >
                              <span className="sBtn-text">{user.name}</span>
                              <svg role="img" viewBox="0 0 512 512">
                                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                              </svg>
                            </div>
                            <ul
                              className="options"
                              style={{
                                display: ` ${toggle ? "block" : "none"}`,
                              }}
                            >
                              <li
                                className="option"
                                onClick={() => { navigateTo(user.role, navigate); closeMenu() }}
                              >
                                <img src="/images/Dashboard.png" alt="" />
                                <span className="option-text">Dashboard</span>
                              </li>
                              <li className="option" onClick={goToJobs}>
                                <img src="/images/job.png" alt="" />
                                <span className="option-text">My jobs</span>
                              </li>
                              <li
                                onClick={() => { navigateToEditProfile(user.role, navigate); closeMenu() }
                                }
                                className="option"
                              >
                                <img src="/images/setting.png" alt="" />
                                <span className="option-text">Settings</span>
                              </li>
                              <li
                                onClick={() => { userLogout(dispatch); closeMenu() }}
                                className="option"
                              >
                                <img src="/images/log-out.png" alt="" />
                                <span className="option-text">Log Out</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        ) : (
                          <NavLink to="/login" onClick={closeMenu}>
                            <span>
                              <img src="/images/login.png" alt="" />
                            </span>
                            Log in
                          </NavLink>
                        )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {user && <div className="filter-box filter-box-m" ref={moreActionRef2}>
              <div className={`select-menu ${toggle2 && "active"} `}>

                <div onClick={() => setToggle2((prev) => !prev)} className="select-btn" >
                  <div className="sbtn-wrap"> <span className="sBtn-text">{user.name}</span> </div>
                  <svg role="img" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </div>
                <ul className="options">
                  <li className="option" onClick={() => navigateTo(user.role, navigate)}>
                    <img src="/images/Dashboard.png" alt="" />
                    <span className="option-text">Dashboard</span>
                  </li>
                  <li className="option" onClick={goToJobs}>
                    <img src="/images/job.png" alt="" />
                    <span className="option-text">My jobs</span>
                  </li>
                  <li onClick={() => navigateToEditProfile(user.role, navigate)} className="option">
                    <img src="/images/setting.png" alt="" />
                    <span className="option-text">Settings</span>
                  </li>
                  <li onClick={() => userLogout(dispatch)} className="option">
                    <img src="/images/log-out.png" alt="" />
                    <span className="option-text">Log Out</span>
                  </li>
                </ul>
              </div>
            </div>}
            <div onClick={openMenu} className="nav_btn">
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AuthHeader;
