import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'

const Loading = () => {
  return (
    <div className='universalLoader'>
      <Modal show={true} centered className="loading-modal modal-ls">
        <ModalBody>
          <img src='/images/Loading.gif' />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Loading