import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const ReedemWalletModal = ({ open, handleClose, handleAmmount, jobPrice, checkPrice }) => {
    const [price, setPrice] = useState(0)
    const changePrice = (e) => {
        if (!(isNaN(e?.target?.value))) {
            setPrice((Number((e?.target?.value)) > 0) ? (((Number(e?.target?.value) <= checkPrice) ? e?.target?.value : checkPrice) > (jobPrice - 2)) ? (jobPrice - 2) : ((Number(e?.target?.value) <= checkPrice) ? e?.target?.value : checkPrice) : 0)
        }
    }
    useEffect(()=>{
        if(!open){
            setPrice(0)
        }

    },[open])
    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={open}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Use wallet amount
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Ammount to redeem</label>
                        <input type="number" placeholder="" className="form-control" value={price} onChange={changePrice} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { handleAmmount(price); handleClose() }}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReedemWalletModal