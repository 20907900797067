import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllJobs } from '../../services/api/jobApis';
import { setJobs, setSelectedJobDetails } from '../../redux/reducers/jobSlice';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/reducers/loadingSlice';
import SelectCurrenyModal from "../../components/common/selectCurrenyModal/SelectCurrenyModal";
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const linkStyle = {
	color: 'blue',
	textDecoration: 'none',
};

const buttonStyle = {
	padding: '6px 30px',
	background: '#9eb213',
	color: 'white',
	fontWeight: 'bold',
	borderRadius: '5px'
};

const TableBusiness = ({ data }) => {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { myJobs } = useSelector((state) => state.job)
	const { user } = useSelector((state) => state.auth)
	const [showModal, setShowModal] = useState(false)
	const [totalAmount, setTotalAmount] = useState(0)
	const [jobId, setJobId] = useState(null)

	const gotoSelectedProject = (data) => {
		dispatch(setSelectedJobDetails({ selectedJob: data }))
		navigate(`/job-details`, { state: { id: data._id } })
	}
	const getOngoingProjects = async () => {
		dispatch(startLoading())
		let data = await getAllJobs('all', 1)
		if (data?.data?.length > 0) {
			dispatch(setJobs({ jobs: data?.data, pageNumber: data?.pageNumber, totalJobCount: data?.dataCount }))
		}
		dispatch(stopLoading())
	}

	const setTotalModal = async (item) => {
		setTotalAmount(item.totalJobPrice);
		setJobId(item._id);
		setShowModal(true);
	}
	useEffect(() => {
		if (!myJobs?.length) {
			getOngoingProjects()
		}
	}, [myJobs])
	useEffect(() => {
		return () => {
			dispatch(setJobs({ jobs: null, pageNumber: 1, totalJobCount: 0 }));
		}
	}, [])

	return (
		<div className="">
			{
				myJobs && myJobs?.length > 0 ?
					<Table striped>
						<thead>
							<tr>
								<th className='text-center'>User Code</th>
								<th className='text-center'>User Name</th>
								<th className='text-center'>Order no.</th>
								<th className='text-center'>Original Doc</th>
								<th className='text-center'>Finished Doc</th>
								<th className='text-center'>Certificate</th>
								<th className='text-center'>Type of Service</th>
								<th className='text-center'>Invoice</th>
								<th className='text-center'>Payment Status</th>
								<th className='text-center'>Total USD/JOD</th>
								<th className='text-center'>Action</th>
							</tr>
						</thead>
						<tbody>
							{myJobs?.map((item, idx) => (
								<tr key={idx}>
									<td className='text-center'>{user?.companyCode ? user.companyCode : 'N/A'}</td>
									<td className='text-center'>{user?.name ? user.name : 'N/A'}</td>
									<td className='text-center'>
										<a onClick={() => gotoSelectedProject(item)} style={linkStyle}>{item?.orderNumber ? item.orderNumber : 'N/A'}</a>
									</td>
									<td className='text-center'>
										{item.jobFile.location !== '' ? <Link target='_blank' to={item.jobFile.location} style={linkStyle}>Download</Link> : 'N/A'}
									</td>
									<td className='text-center'>
										{item?.jobCompletedFile ? <Link target='_blank' to={item?.jobCompletedFile?.location} style={linkStyle}>Download</Link> : 'N/A'}
									</td>
									<td className='text-center'>
										{item.jobFile.certificateLink ? <Link target='_blank' to={item.jobFile.certificateLink} style={linkStyle}>Download</Link> : 'N/A'}
									</td>
									<td className='text-center text-capitalize'>
										{item.specificService ? item.specificService : 'N/A'}
									</td>
									<td className='text-center'><Link target='_blank' to={item?.invoice?.invoiceLink} style={linkStyle}>Download</Link></td>
									<td className={`text-center text-capitalize ${item.reciept?.recieptLink ? 'text-success' : 'text-warning'}`}>{item.reciept?.recieptLink ? 'Paid' : 'Pending'}</td>
									<td className='text-center'>{item.totalJobPrice} {item?.reciept ? item.reciept.currency === 'USD' ? '$' : 'JOD' : ''}</td>
									<td className='text-center'>
										{
											item.reciept?.recieptLink ?
												<Link to={item?.reciept?.recieptLink} target='_blank' style={linkStyle}>Download Receipt</Link> :
												<Link style={buttonStyle} onClick={() => setTotalModal(item)}>Pay Now</Link>
										}

									</td>
								</tr>
							))}
						</tbody>
					</Table> : <h1 className='text-center'>No Job Found</h1>
			}
			<SelectCurrenyModal showModal={showModal} setShowModal={setShowModal} totalAmount={totalAmount} jobId={jobId} />
		</div>
	)
}

export default TableBusiness;
