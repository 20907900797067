import React, { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { changePassword } from "../../../services/api/profileApi";
import { toast } from "react-toastify";
import { changePassFormValidatorProofReader } from "../../../services/formValidator/proofReaderValidator";

const ChangePassProofReader = () => {
  const changePassFormik = useFormik({
    initialValues: changePassFormValidatorProofReader.initialValues,
    validationSchema: changePassFormValidatorProofReader.validationSchema,
    onSubmit: async (values) => {
      let { serverResponse } = await changePassword(
        values.newPassword,
        values.confrmPassword
      );
      if (serverResponse.code === 201) {
        toast.success("password successfully changed");
      } else {
        toast.error("password not changed");
      }
    },
  });
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const newPasswordHandler = () => {
    setNewShowPassword((prev) => !prev);
  };
  const confirmPasswordHandler = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  return (
    <>
      <div className="col-lg-10">
        <div className="translator-edit-profile-right for-changing-password">
          <div className="content common-btm-mrgn">
            <h4>Change Password</h4>
          </div>
          <div className="signin-form-wrap">
            <form onSubmit={changePassFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group password">
                    <label>New password</label>
                    <input
                      type={newShowPassword ? "text" : "password"}
                      placeholder=""
                      className="form-control"
                      id="newPassword"
                      name="newPassword"
                      value={changePassFormik?.values.newPassword}
                      onChange={changePassFormik.handleChange}
                    />
                    <p className="form-error-message">
                      {changePassFormik.touched.newPassword &&
                        changePassFormik.errors.newPassword}
                    </p>
                    <span
                      onClick={newPasswordHandler}
                      className="forgot-password-eye-toggle "
                    >
                      <FontAwesomeIcon
                        icon={newShowPassword ? faEyeSlash : faEye}
                      />
                    </span>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group password">
                    <label>Confirm new password</label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder=""
                      className="form-control"
                      id="confrmPassword"
                      name="confrmPassword"
                      value={changePassFormik?.values.confrmPassword}
                      onChange={changePassFormik.handleChange}
                    />
                    <p className="form-error-message">
                      {changePassFormik.touched.confrmPassword &&
                        changePassFormik.errors.confrmPassword}
                    </p>
                    <span
                      onClick={confirmPasswordHandler}
                      className="forgot-password-eye-toggle "
                    >
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-0">
                    <button type="submit" className="btn w-100">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassProofReader;
