import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { chooseTranslatorJson, deadlineStaticArray, specalisationJson } from '../../../constants/config'
import DragDropUploadButton from '../../../components/common/DragDropUploadButton'

import { checkWordCount, removeFileFromS3, uploadJobFile } from '../../../services/api/jobPostApis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'
import { splitTransJobValidatior } from '../../../services/formValidator/translatorManagerValidator'
import { toast } from 'react-toastify'

const ConfigurationCard = ({ index, postSplit, setPostSplit, setJobArr }) => {
    const { selectedJobDetails } = useSelector((state) => state.job)
    const [showConfiguration, setShowConfiguration] = useState(false)
    const [jobFiles, setJobFiles] = useState(null)
    const [wordCount, setWordCount] = useState(null)

    const wordCountCheck = async (data) => {
        let uploadFileData = await checkWordCount(data[0])
        if (uploadFileData?.result) {
            setWordCount(uploadFileData?.result?.data?.wordCount)
            splitFileFormik?.setFieldValue('jobFile', data[0])
            splitFileFormik?.setFieldValue('wordCount', uploadFileData?.result?.data?.wordCount)
        }
        setJobFiles(data[0])
    }

    const removeItem = async () => {
        setJobFiles(null)
    }

    // splitFileFormik
    const splitFileFormik = useFormik({
        initialValues: splitTransJobValidatior(selectedJobDetails, index, 'initialValues'),
        validationSchema: splitTransJobValidatior(selectedJobDetails, index, 'validationSchema'),
        onSubmit: (values) => {
            if (jobFiles) {
                setJobArr((prev) => [...prev, values])
            }
            else {
                toast.error(`Fill all fields in job ${index + 1}`)
            }
        }
    })

    const selectDeadLine = (e) => {
        let perWordPrice = deadlineStaticArray[deadlineStaticArray?.findIndex((item) => item?.value === e.target.value)]?.priceValue
        splitFileFormik?.setFieldValue('deadline', e.target.value);
        splitFileFormik?.setFieldValue('totalJobPrice', perWordPrice * splitFileFormik?.values?.wordCount);
        splitFileFormik?.setFieldValue('perWordPrice', Number(perWordPrice.toFixed(4)));
    }
    useEffect(() => {
        if (postSplit) {
            if (jobFiles && !splitFileFormik.isValid) {
                toast.error(`Fill all fields in job ${index + 1}`)
            }
            splitFileFormik.handleSubmit()
            setPostSplit(false)
        }
    }, [postSplit])
    return (
        <div className="acc-wrap-pm">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"
                            onClick={() => setShowConfiguration(!showConfiguration)}
                        >
                            <div className="button-table btn-table">
                                <div className="button-table-head">
                                    <div className="tab-2">#Order</div>
                                    <div className="tab-2">Wordcount</div>
                                    <div className="tab-2">Deadline</div>
                                    <div className="tab-2">Type of doc</div>
                                    <div className="tab-2">Language</div>
                                    <div className="tab-2">Price/word</div>
                                    <div>
                                        {
                                            showConfiguration ?
                                                <div data-aos="fade-up"
                                                    data-aos-offset={100}
                                                    data-aos-easing="ease-in-sine">
                                                    <img src={'/images/acc-arrow1.png'} height={40} />
                                                </div>
                                                :
                                                <div data-aos="fade-up"
                                                    data-aos-offset={100}
                                                    data-aos-easing="ease-in-sine">
                                                    <img src={'/images/acc-arrow2.png'} height={40} />
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="button-table-body">
                                    <div className="tab-2">
                                        {selectedJobDetails?._id.slice(-5)}
                                    </div>
                                    <div className="tab-2">{jobFiles?.wordCount ?? '--'}</div>
                                    <div className="tab-2">24hr : 00min : 00sec</div>
                                    <div className="tab-2">{selectedJobDetails?.jobDocumentType}</div>
                                    <div className="tab-2">{selectedJobDetails?.translateFrom} to {selectedJobDetails?.translateTo}</div>
                                    <div className="tab-2">
                                        <div className="a-border">
                                            <input type='number' className="a-border" name='perWordPrice' id='perWordPrice' value={splitFileFormik?.values?.perWordPrice} onChange={(e) => splitFileFormik?.setFieldValue('perWordPrice', (e.target.value > selectedJobDetails?.perWordPrice) ? selectedJobDetails?.perWordPrice : (e.target.value < 0) ? 0 : e.target.value)} style={{ textAlign: 'center' }} />
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                        </button>
                    </h2>
                    {showConfiguration && <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                    >
                        <div className="accordion-body">
                            <div className="acc-body-item">
                                <div className="item-acc-1">
                                    <div className="signin-form-wrap">
                                        <form onSubmit={splitFileFormik?.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="item-acc-wrap">
                                                        <div className="form-group">
                                                            <label htmlFor="">
                                                                Select deadline
                                                            </label>
                                                            <select
                                                                className="form-select"
                                                                aria-label="Default select example"
                                                                name='deadline' id='deadline' onChange={(e) => { selectDeadLine(e) }}
                                                                disabled={!jobFiles}
                                                            >
                                                                {
                                                                    deadlineStaticArray?.map((item) => <option selected={item?.value === splitFileFormik?.values?.deadline}>{item?.time}</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="item-acc-wrap">
                                                        <div className="form-group">
                                                            <label htmlFor="">
                                                                Type of document
                                                            </label>
                                                            <select
                                                                className="form-select"
                                                                aria-label="Default select example"
                                                                disabled
                                                            >
                                                                <option selected="">
                                                                    {selectedJobDetails?.jobDocumentType}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="item-acc-wrap">
                                                        <div className="form-group">
                                                            <label htmlFor="">Word count</label>
                                                            <input
                                                                type="text"
                                                                placeholder={500}
                                                                className="form-control"
                                                                disabled
                                                                value={wordCount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {jobFiles ?
                                                    <div className="file-list-table">
                                                        <div className="signin-form-wrap e-ch upload-file-section">
                                                            <div className="form-group">
                                                                <label>Name</label>
                                                                <input type="text" placeholder="" id='jobTitle' name='jobTitle' className="form-control" value={splitFileFormik?.values?.jobTitle} onChange={splitFileFormik?.handleChange} />
                                                                <p className='form-error-message'>{splitFileFormik.touched.jobTitle &&
                                                                    splitFileFormik.errors.jobTitle}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Additional Comments?</label>
                                                                <textarea name="addComments" id="addComments" cols="10" rows="7" className="form-control" value={splitFileFormik?.values?.addComments} onChange={splitFileFormik?.handleChange}></textarea>
                                                                <p className='form-error-message'>{splitFileFormik.touched.addComments &&
                                                                    splitFileFormik.errors.addComments}</p>
                                                            </div>
                                                            <table className="table table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="2">File name</th>
                                                                        <th scope="col">Word Count</th>
                                                                        <th scope="col"></th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <h6><span><i className="fas fa-file-alt"></i></span>{jobFiles?.name}</h6>
                                                                        </td>
                                                                        <td>{wordCount}</td>
                                                                        <td>
                                                                            <p onClick={removeItem}><FontAwesomeIcon icon={faTrashAlt} /></p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-lg-12">
                                                        <div className="form-group file-upload">
                                                            <label htmlFor="myfile">
                                                                <span>Upload</span>
                                                                or Drag &amp; drop your entire document
                                                                here
                                                            </label>
                                                            <DragDropUploadButton
                                                                multiple={true}
                                                                onChange={wordCountCheck}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row-section">
                                                    <h5>Choose Language Pair:</h5>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="item-acc-wrap">
                                                                <div className="form-group">
                                                                    <label htmlFor="">
                                                                        Translate From
                                                                    </label>
                                                                    <select
                                                                        className="form-select"
                                                                        aria-label="Default select example"
                                                                        disabled
                                                                    >
                                                                        <option selected="">
                                                                            German
                                                                        </option>
                                                                        <option value={1}>{selectedJobDetails?.translateFrom}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="item-acc-wrap">
                                                                <div className="form-group">
                                                                    <label htmlFor="">
                                                                        Translate to
                                                                    </label>
                                                                    <select
                                                                        className="form-select"
                                                                        aria-label="Default select example"
                                                                        disabled
                                                                    >
                                                                        <option value={1}>{selectedJobDetails?.translateTo}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-section">
                                                    <h5>Choose your Translator</h5>
                                                    <div className="radio-li half-radio-li">
                                                        <ul>
                                                            {chooseTranslatorJson?.map((item) => {
                                                                return (
                                                                    <li>
                                                                        <div className="radio-group">
                                                                            <input
                                                                                type="radio"
                                                                                name="plan"
                                                                                disabled
                                                                                value={item.value}
                                                                                checked={selectedJobDetails.chooseTranslator === item?.value}
                                                                            />
                                                                            <label htmlFor="html">
                                                                                <h5>{item?.name}</h5>
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {!!selectedJobDetails.translatorSpecialisation && <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <h5>
                                                            Which best describes the field of your
                                                            document?
                                                        </h5>
                                                        <div className="row">
                                                            {specalisationJson?.map((item) => <div className="col-lg-4 mb-3">
                                                                <div className="rdio-btn-for-db-frm">
                                                                    <input
                                                                        type="radio"
                                                                        id="test4"
                                                                        name="radio-group2"
                                                                        value={item.value}
                                                                        checked={selectedJobDetails.translatorSpecialisation === item?.value}
                                                                        disabled
                                                                    />
                                                                    <label htmlFor="test4">
                                                                        {item?.name}
                                                                    </label>
                                                                </div>
                                                            </div>)}
                                                        </div>
                                                    </div>
                                                </div>}
                                                {/* <div className="col-lg-12">
                                                    <div className="pm-tr-button">
                                                        <button type="submit" className="btn">
                                                            Post
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-border-g"
                                                        >
                                                            Assign
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ConfigurationCard