import React from 'react'

const EnvironmentalPioneers = () => {
    return (
        <>
            <section className="home-banner inner-banner">
                <div className="container">
                    <div className="home-banner-wrap">
                        <div className="home-banner-img">
                            <img src="/images/environmental-consciousness.webp" alt="" />

                        </div>
                        <div className="banner-t-wrap white-text">
                            <div className="container">
                                <h1>Environmental Pioneers</h1>
                                <p>Lingvara Redefinines Certified Translation through Environmental Consciousness and Digital Innovation</p>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            {/* <section className="contact-sec-wrap padding-bottom padding-top"> */}
            <section className="contact-sec common-btm-padd mt-4">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <p>In an era where environmental sustainability is paramount, Lingvara stands out as a pioneering translation agency committed to revolutionizing certified translation practices. Our core philosophy revolves around environmental consciousness, where we prioritize eco-friendly solutions and strive to eliminate paper usage entirely. By harnessing cutting-edge digital technology, we aim to redefine the traditional approach to certified translation, making it more efficient, secure, and environmentally friendly.
                            </p>
                            <p>At Lingvara, we recognize the environmental impact of paper consumption in the translation industry. From sourcing raw materials to printing and distribution, the paper-based process contributes to deforestation, energy consumption, and waste generation. Therefore, we have embarked on a mission to minimize our ecological footprint by embracing paperless operations.</p>

                        </div>
                        <div className="col-lg-6">
                            <img src="/images/eco-friendly.avif" className="rounded" />
                        </div>
                    </div>
                    <br />
                    <p>Central to our innovation is the replacement of physical paper with digital alternatives. Unlike conventional translation agencies that rely on stamped paper documents, we have introduced a groundbreaking solution: the integration of QR codes for document verification. This revolutionary approach not only eliminates the need for paper but also enhances the security and accessibility of certified translations.</p>

                    <div className="row">
                        <div className="col-lg-6">
                            <img src="/images/certificate.jpeg" className="rounded border" />


                        </div>
                        <div className="col-lg-6">
                            <p>
                                Here's how it works: Upon completion of a translation project, clients receive a digital copy of their certified document along with a unique QR code. This QR code serves as a digital seal of authenticity, securely embedded with encrypted information regarding the document's origin and accuracy. To verify the authenticity of the translated document, concerned parties can simply scan the QR code using a smartphone or computer.
                            </p>
                            <p>Furthermore, to ensure utmost security, access to the digital document is protected by a PIN code provided exclusively to the document owner. This dual-layer authentication process guarantees that only authorized individuals can access and compare the translated document with the original copy delivered by Lingvara.</p>

                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-6">
                            <p>
                            By transitioning to digital verification methods, Lingvara not only streamlines the certification process but also significantly reduces environmental impact. Our paperless approach conserves natural resources, minimizes waste generation, and mitigates carbon emissions associated with traditional printing methods. No need to drive back and forth to the translator. Moreover, the adoption of digital technology enhances accessibility, enabling clients to conveniently access and share certified translations anytime, anywhere.
                            </p>
                        </div>
                        <div className="col-lg-6 text-center">
                            <img src="/images/no-car-icon.svg" className="rounded" />
                        </div>
                    </div>
                    <br />
                    <p>
                    In essence, Lingvara is leading the way in reimagining certified translation services through a combination of environmental consciousness and digital innovation. By embracing paperless practices and leveraging QR code technology, we are setting a new standard for sustainability and efficiency in the translation industry. Join us in our journey towards a greener, more digitally empowered future.
                    </p>
                </div>

            </section>


        </>
    )
}

export default EnvironmentalPioneers