import axios from "axios";
import { API_URL } from "../../constants/config";
import { refreshLoggedInUser, userLogout } from "./authApis";
import { ResponseValidator } from "../validatior/responseValidator";
import { toast } from "react-toastify";



/***
 * *axios request configs
 */
axios.interceptors.request.use((config) => {
    config.baseURL = API_URL;
    const token = sessionStorage.getItem("accessToken");
    if (token) {
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
    } else {
        axios.defaults.headers.common.Authorization &&
            delete axios.defaults.headers.common.Authorization;
        config.headers = axios.defaults.headers.common;
    }
    return config;
});

/***
 * *axios response configs
 */
axios.interceptors.response.use(async (result) => {
    let response = await ResponseValidator({ code: result.data?.serverResponse?.code, message: result.data?.serverResponse?.message })
    if (response) {
        return result.data
    }
}, async (error) => {
    const expectedErrorRefreshToken = error.response && error.response.status === 401;
    if (expectedErrorRefreshToken) {
        const checkLogin = async () => {
            const refreshToken = sessionStorage.getItem("refreshToken");
            if (refreshToken) {
                const res = await refreshLoggedInUser({ refreshToken });
                if (!res) {
                    window.location.reload();
                    return false;
                } else {
                    error.config.headers['Authorization'] = 'Bearer ' + res.access.token;
                    if (error.config?.data) { error.config.data = JSON.parse(error.config.data) }
                    error.config.baseURL = undefined;
                    // Return actual call response.
                    return await axios.request(error.config);
                }
            } else {
                localStorage.removeItem('persist:lingvara')
                localStorage.removeItem('reDirect_url')
                window.location.reload();
                return false;
            }
        };
        let response = await checkLogin();

        if (response) {
            return Promise.resolve(response);
        }
    }

    const expectedError = error.response.status && error.response.status >= 400 && error.response.status <= 500;
    if (!expectedError) {
        userLogout();
        setTimeout(() => {
            window.location.href = "/";
        }, 1000);
    }
    else {
        toast.error(error.response.data?.message)
    }
    // return Promise.reject(error);
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
};